import React, { useState, useEffect } from 'react'
import $ from "jquery";
import glass from '../../../../static/images/glass.png'
import blackhole from '../../../../static/images/blackhole.png'
import mountain from '../../../../static/images/mountain.png'
import scribblemask from '../../../../static/images/scribblemask.png'
import speechmask from '../../../../static/images/speechmask.png'
import splash from '../../../../static/images/splash.png'
import streak from '../../../../static/images/streak.png'

function ImagePopUp(
  { id,
    addCustomStyle,
    element,
    activeElement,
    setElementType
  }
) {
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('imagepopup')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])
  var imageMask;
  if(element.elementObject && element.elementObject.imageMask){
    if(element.elementObject.imageMask==='none'){
      imageMask=''
    }
    else if(element.elementObject.imageMask==='glass'){
      imageMask=`-webkit-mask-image: url(${glass})`
    }
    else if(element.elementObject.imageMask==='streak'){
      imageMask=`-webkit-mask-image: url(${streak})`
    }
    else if(element.elementObject.imageMask==='splash'){
      imageMask=`-webkit-mask-image: url(${splash})`
    }
    else if(element.elementObject.imageMask==='speechmask'){
      imageMask=`-webkit-mask-image: url(${speechmask})`
    }
    else if(element.elementObject.imageMask==='scribblemask'){
      imageMask=`-webkit-mask-image: url(${scribblemask})`
    }
    else if(element.elementObject.imageMask==='mountain'){
      imageMask=`-webkit-mask-image: url(${mountain})`
    }
    else if(element.elementObject.imageMask==='blackhole'){
      imageMask=`-webkit-mask-image: url(${blackhole})`
    }
  }
  
  $(function() {
    $('a[data-modal]').on('click', function() {
      $($(this).data('modal')).modal({
    fadeDuration: 250
  });
      return false;
    });
  });
  return (
    <>
      
      <div class="modal fade" id={`popup_elem_${id}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <img src={element.elementObject ? element.elementObject.imagepopupURL : 'https://images.unsplash.com/photo-1575936123452-b6…8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'} Style='z-index:3035; max-height:80vh;' />
            
          </div>
        </div>
      </div>

      <div Style={`overflow:visible; text-align:${element.elementObject ? element.elementObject.textAlign : 'center'}; ${imageMask}; -webkit-mask-size: contain; -webkit-mask-position: center top; -webkit-mask-repeat: no-repeat;  width: fit-content;`} id={id} onClick={handleEditorClick}>
        <div>
        </div>
        <img action="URL" data-bs-toggle="modal" data-bs-target={`#popup_elem_${id}`} className={`${element.elementObject ? element.elementObject.imageAnimation : ''}`} src={element.elementObject ? element.elementObject.imageURL : ''} alt={element.elementObject ? element.elementObject.imageAltText : ''} width={element.elementObject ? element.elementObject.imageWidth : ''} height={element.elementObject ? element.elementObject.imageHeight : ''} Style={`max-width: 100%; box-shadow: ${element.elementObject ? element.elementObject.imageBoxShadow : ''}; border: ${element.elementObject ? element.elementObject.imageBoxBorder : ''}; border-radius:${element.elementObject ? element.elementObject.imageRadius : ''}; opacity: ${element.elementObject ? element.elementObject.elementOpacity : ''}; filter: ${element.elementObject ? element.elementObject.imageEditing : ''};`} />
      </div>
    </>
  )
}

export default ImagePopUp