import React, { useState } from 'react'
import { SketchPicker, CirclePicker, SwatchesPicker } from 'react-color'

function ColorPicker({color,Css,setCss, setRowchange, setEnableBorderColor, setBorderColor,type}) {
    const [enableCustomColor, setEnableCustomColor] = useState(false)
    const [enableGradientColor, setEnableGradientColor] = useState(false)
    function addcustomcolor() {
        setEnableGradientColor(false)
        setEnableCustomColor(!enableCustomColor)
    }
    function addgradientcolor() {
        setEnableCustomColor(false)
        setEnableGradientColor(!enableGradientColor)
    }
    function handleChange(currentcolor){
        if(type==='section'){
            if(currentcolor==='rgba(255, 255, 255, 0)'){
                setBorderColor(currentcolor)
                var data=Css
                data.borderstyleCss.color=currentcolor
                setCss(data)
                setRowchange(Css.borderstyleCss)
                setEnableBorderColor(false)
    
            }
            else{
                setBorderColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data=Css
                data.borderstyleCss.color=`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(Css.borderstyleCss)
    
            }

        }
        else if(type==='row'){
            if(currentcolor==='rgba(255, 255, 255, 0)'){
                setBorderColor(currentcolor)
                var data=Css
                data.borderstyleCss.color=currentcolor
                setCss(data)
                setRowchange(Css.borderstyleCss)
                setEnableBorderColor(false)
    
            }
            else{
                setBorderColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data=Css
                data.borderstyleCss.color=`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(Css.borderstyleCss)
    
            }

        }
        else if(type==='btn'){
            if(currentcolor==='rgba(255, 255, 255, 0)'){
                var data=Css
                data.btnborderstyleCss.color=currentcolor
                setCss(data)
                setRowchange(Css.borderstyleCss)
                setEnableBorderColor(false)
    
            }
            else{
                var data=Css
                data.btnborderstyleCss.color=`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(Css.borderstyleCss)
    
            }

        }
        
    }
    return (
        <div Style='margin:auto;padding:20px'>
            <div Style='margin-bottom:10px'>
                <CirclePicker onChangeComplete={handleChange} color={color} onChange={()=>{setEnableBorderColor(false)}}/>
                <div className='d-flex flex-wrap justify-content-center'>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { addcustomcolor() }}>Custom</button>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { addgradientcolor() }}>Gradient</button>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { handleChange('rgba(255, 255, 255, 0)') }}>Transparent</button>
                </div>
            </div>
            {enableCustomColor ? <SketchPicker color={color} onChangeComplete={handleChange}/> : ''}
            {enableGradientColor ? <SwatchesPicker color={color} onChangeComplete={handleChange}/> : ''}
        </div>
    )
}

export default ColorPicker