import React, { useState, useEffect } from 'react'

function Map({ size,
    id,
    addCustomStyle,
    element,
    activeElement,
    setElementType }) {
    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
        e.stopPropagation();
        setElementType('map')
        if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
        }
    }

    useEffect(() => {
        addCustomStyle(`elem_${id}`);
    }, [])
    const [lonLatUrl, setLonLatUrl] = useState()
    useEffect(() => {
        if (element.elementObject) {
            setLonLatUrl()
        }
    }, [element.mapUrl])

    return (
        <div onClick={handleEditorClick}>
            {element.elementObject ? element.elementObject.mapType === "embaded" ? <iframe src={element.elementObject.mapEmbaded} width="100%" height={`${element.elementObject.mapHeight}`} Style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                : <iframe width="100%" height={`${element.elementObject.mapHeight}`} Style="border:0;" allowfullscreen="" loading="lazy"
                    src={element.mapUrl}
                >
                </iframe> : ''}
        </div>
    )
}

export default Map