import React, { useEffect, useState } from 'react'
import BorderColorPicker from '../BorderColorPicker';
import img from '../../static/images/backgroundfreeimage.png'
import ShapeColor from '../ShapeColor';
import { Slider, TextField, Select, InputLabel, MenuItem, FormControl } from "@mui/material";

function AdvancedElement({ setSpaceCss, spaceCss, sectionIdTrack, setRowchange, setBorderColor, borderColor, shapeColor, setShapeColor }) {
    const [borderRadiusType, setBorderRadiusType] = useState(spaceCss.borderRadiusType)
    const [borderRadiusData, setBorderRadiusData] = useState(spaceCss.borderRadiusData)
    const [borderRadius, setBorderRadius] = useState()
    const [enableBorderColor, setEnableBorderColor] = useState(false)
    const [enableShapeColor, setEnableShapeColor] = useState(false)
    useEffect(() => {
        if (borderRadiusType === 'AllBorderEdges') {
            setBorderRadius(borderRadiusData)
        }
        else if (borderRadiusType === 'TopBorderEdges') {
            setBorderRadius(borderRadiusData + ' ' + borderRadiusData + ' ' + '0px' + ' ' + '0px')
        }
        else if (borderRadiusType === 'BottomBorderEdges') {
            setBorderRadius('0px' + ' ' + '0px' + ' ' + borderRadiusData + ' ' + borderRadiusData)
        }
        let spacecssData = spaceCss
        spacecssData.borderRadiusData = borderRadiusData
        spacecssData.borderRadiusType = borderRadiusType
        setSpaceCss(spacecssData)
    }, [borderRadiusType, borderRadiusData])

    useEffect(() => {
        var data = spaceCss
        data.borderRadius = borderRadius
        setRowchange(borderRadius)
    }, [borderRadius])

    // Copy Section Id to clipboard 
    function copySectionId() {
        var copyText = document.getElementById("sectionIdElem");
        navigator.clipboard.writeText(copyText.innerText);
        document.getElementById('sectionCopytext').classList.add("active");
        setTimeout(function () {
            document.getElementById('sectionCopytext').classList.remove("active");
        }, 2500);
    }
    function setBorderStyle(data, type) {
        var sectiondcss = spaceCss
        if (type === 'type') {
            sectiondcss.borderstyleCss.type = data
            setSpaceCss(sectiondcss)
            setRowchange(spaceCss.borderstyleCss)
        }
        else if (type === 'pixel') {
            sectiondcss.borderstyleCss.pixel = data
            setSpaceCss(sectiondcss)
            setRowchange(spaceCss.borderstyleCss)
        }

    }
    var openButton;
    if (spaceCss.borderstyleCss.color === 'rgba(255, 255, 255, 0)') {
        openButton = `background-image: url(${img})`
    }
    else {
        openButton = `background-color: ${borderColor} !important`
    }

    var shapeColorSelector;
    if (spaceCss.shapecolor === 'rgba(255, 255, 255, 0)') {
        shapeColorSelector = `background-image: url(${img})`
    }
    else {
        shapeColorSelector = `background-color: ${shapeColor} !important`
    }
    var enableSwitchInvert = false;
    var enableSwitchFlip = false;
    if (spaceCss.shapetype === 'curve' || spaceCss.shapetype === 'triangle' || spaceCss.shapetype === 'zigzag' || spaceCss.shapetype === 'arrow' || spaceCss.shapetype === 'split' || spaceCss.shapetype === 'book') {
        enableSwitchFlip = true
    }
    else {
        enableSwitchFlip = false;
    }

    if (spaceCss.shapetype === 'wavesbrush' || spaceCss.shapetype === 'brush' || spaceCss.shapetype === 'zigzag' || spaceCss.shapetype === 'mountains' || spaceCss.shapetype === 'tilt') {
        enableSwitchInvert = true;
    }
    else {
        enableSwitchInvert = false;
    }
    return (
        <div className="" id="nav-advanced" role="tabpanel">
            <div id="AdvancedProperties" Style="padding-top: 10px;text-align: start;">
                <div className="container" Style="padding: 0px 10px 0px 10px;">
                    <div className="SectionPropsWrapper mb-3 mt-3" Style="padding: 10px 10px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        <div Style="display: flex; justify-content: center; flex-direction: column; align-items: center; padding-top: 15px; width: 100%;">
                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">Border</InputLabel>
                                <Select
                                    labelId="border-label"
                                    id='borderType'
                                    value={spaceCss.border}
                                    name='border'
                                    onChange={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.value }) }}
                                    label="Border"
                                >
                                    <MenuItem value={'noBorder'}>No Border</MenuItem>
                                    <MenuItem value={""}>Full Border</MenuItem>
                                    <MenuItem value={"-top"}>Top Border</MenuItem>
                                    <MenuItem value={"-bottom"}>Bottom Border</MenuItem>
                                    <MenuItem value={"-block"}>Top and Bottom Border</MenuItem>
                                    <MenuItem value={"-left"}>Left Border</MenuItem>
                                    <MenuItem value={"-right"}>Right Border</MenuItem>
                                    <MenuItem value={"-inline"}>Left and Right Border</MenuItem>
                                </Select>
                            </FormControl>

                            <div id="BorderOptions" Style={`display:${spaceCss.border === 'noBorder' ? 'none' : 'block'}`}>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Border Style</InputLabel>
                                    <Select
                                        labelId="borderType-label"
                                        id='borderType'
                                        value={spaceCss.borderstyleCss.type}
                                        name='type'
                                        onChange={(e) => { setBorderStyle(e.target.value, 'type') }}
                                        label="Border Type"
                                    >
                                        <MenuItem value={'solid'}>Solid</MenuItem>
                                        <MenuItem value={"dashed"}>Dashed</MenuItem>
                                        <MenuItem value={"dotted"}>Dotted</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Border Width</InputLabel>
                                    <Select
                                        labelId="pixel-label"
                                        id='borderType'
                                        value={spaceCss.borderstyleCss.pixel}
                                        name='pixel'
                                        onChange={(e) => { setBorderStyle(e.target.value, 'pixel') }}
                                        label="Border Type"
                                    >
                                        <MenuItem value={'1px'}>1px</MenuItem>
                                        <MenuItem value={"2px"}>2px</MenuItem>
                                        <MenuItem value={"3px"}>3px</MenuItem>
                                        <MenuItem value={"5px"}>5px</MenuItem>
                                        <MenuItem value={"10px"}>10px</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Border Radius</InputLabel>
                                    <Select
                                        labelId="borderRadiusData-label"
                                        id='borderRadiusData'
                                        value={borderRadiusData}
                                        name='borderRadiusData'
                                        onChange={(e) => { setBorderRadiusData(e.target.value) }}
                                        label="Border Radius Data"
                                    >
                                        <MenuItem value={'0px'}>0px</MenuItem>
                                        <MenuItem value={"5px"}>5px</MenuItem>
                                        <MenuItem value={"10px"}>10px</MenuItem>
                                        <MenuItem value={"15px"}>15px</MenuItem>
                                        <MenuItem value={"20px"}>20px</MenuItem>
                                        <MenuItem value={"25px"}>25px</MenuItem>
                                        <MenuItem value={"50px"}>50px</MenuItem>
                                        <MenuItem value={"75px"}>75px</MenuItem>
                                        <MenuItem value={"100px"}>100px</MenuItem>
                                        <MenuItem value={"125px"}>125px</MenuItem>
                                        <MenuItem value={"150px"}>150px</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Radius Edge</InputLabel>
                                    <Select
                                        labelId="borderRadiusType-label"
                                        id='borderRadiusType'
                                        value={borderRadiusType}
                                        name='borderRadiusType'
                                        onChange={(e) => { setBorderRadiusType(e.target.value) }}
                                        label="Border Radius Type"
                                    >
                                        <MenuItem value={'AllBorderEdges'}>All Edges</MenuItem>
                                        <MenuItem value={"TopBorderEdges"}>Top Only Edges</MenuItem>
                                        <MenuItem value={"BottomBorderEdges"}>Bottom Only Edges</MenuItem>
                                    </Select>
                                </FormControl>

                                <div className="border-picker-wrapper all-picker-wrappers" Style="padding:0px;">
                                    <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                        <span>Background Color:</span>
                                        <div className="color-picker-button float-right" Style={`${openButton};border-radius: 15px;`} onClick={() => { setEnableBorderColor(!enableBorderColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                    </div>
                                    {/* <div Style='display:flex;justify-content: center;padding: 0px 20px;margin-bottom: 15px;'>
                                        <button className="color-picker-button btn float-right" Style={`${openButton}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableBorderColor(!enableBorderColor) }}>Background Color</button>
                                    </div> */}
                                    {enableBorderColor ? <BorderColorPicker Css={spaceCss} setCss={setSpaceCss} setRowchange={setRowchange} setEnableBorderColor={setEnableBorderColor} setBorderColor={setBorderColor} type='section' /> : ''}
                                </div>
                            </div>
                        </div>

                        <div Style="display: flex;justify-content: center; flex-direction: column; align-items: center; padding-top: 15px; width:100%">
                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">Shape Style</InputLabel>
                                <Select
                                    labelId="shapetype-label"
                                    id='shapetype'
                                    value={spaceCss.shapetype}
                                    name='shapetype'
                                    onChange={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.value }) }}
                                    label="Border Radius Type"
                                >
                                    <MenuItem value={'none'}>None</MenuItem>
                                    <MenuItem value={"waves"}>Waves</MenuItem>
                                    <MenuItem value={"wavesbrush"}>Waves (Brush)</MenuItem>
                                    <MenuItem value={"zigzag"}>Zigzag</MenuItem>
                                    <MenuItem value={"cloud"}>Cloud</MenuItem>
                                    <MenuItem value={"mountains"}>Mountains</MenuItem>
                                    <MenuItem value={"curve"}>Curve</MenuItem>
                                    <MenuItem value={"curveAS"}>Curve (Asymmetrical)</MenuItem>
                                    <MenuItem value={"triangle"}>Triangle</MenuItem>
                                    <MenuItem value={"triangleAS"}>Triangle (Asymmetrical)</MenuItem>
                                    <MenuItem value={"tilt"}>Tilt</MenuItem>
                                    <MenuItem value={"arrow"}>Arrow</MenuItem>
                                    <MenuItem value={"split"}>Split</MenuItem>
                                    <MenuItem value={"book"}>Book</MenuItem>
                                    <MenuItem value={"brush"}>Brush</MenuItem>
                                </Select>
                            </FormControl>

                            <div id="DividerOptions" Style={`display:${spaceCss.shapetype === 'none' ? 'none' : 'block'};`}>
                                <div id="ColourOptions">
                                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                            <span>Background Color:</span>
                                            <div className="color-picker-button float-right" Style={`${shapeColorSelector};border-radius: 15px;`} onClick={() => { setEnableShapeColor(!enableShapeColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                        </div>
                                        {/* <div Style='display:flex;justify-content: center;padding: 0px 10px;margin-bottom: 15px;'>
                                            <button className="color-picker-button btn float-right" Style={`${shapeColorSelector}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableShapeColor(!enableShapeColor) }}>Background Color</button>
                                        </div> */}
                                        {enableShapeColor ? <ShapeColor spaceCss={spaceCss} setSpaceCss={setSpaceCss} setShapeColor={setShapeColor} setEnableShapeColor={setEnableShapeColor} /> : ''}
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input mb-3" id="flipSwitch" name='shapeflip' checked={spaceCss.shapeflip} onClick={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.checked }) }} disabled={enableSwitchFlip} />
                                    <label className="form-check-label" for="flexSwitchCheckChecked">Flip Horizontally</label>
                                </div>
                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input mb-3" id="InvertSwitch" name='shapeinvert' checked={spaceCss.shapeinvert} onClick={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.checked }) }} disabled={enableSwitchInvert} />
                                    <label className="form-check-label" for="flexSwitchCheckChecked">Invert</label>
                                </div>
                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input mb-3" id="topbottomSwitch" name='shapetopbottom' checked={spaceCss.shapetopbottom} onClick={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.checked }) }} />
                                    <label className="form-check-label" for="flexSwitchCheckChecked">Top/Bottom</label>
                                </div>
                                <div className="input-group mb-3 d-flex justify-content-start ">
                                    <div className='d-flex flex-column'>
                                        <TextField label="Height" id="height" value={spaceCss.shapeheight} size="small" name='shapeheight' onChange={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.value }) }} />
                                        <Slider value={spaceCss.shapeheight} aria-label="Default" valueLabelDisplay="auto" min={100} max={300} name='shapeheight' onChange={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.value }) }} />
                                    </div>
                                </div>
                                <div className="input-group mb-3 d-flex justify-content-start ">
                                    <div className='d-flex flex-column'>
                                        <TextField label="Width" id="height" value={spaceCss.shapewidth} size="small" name='shapewidth' onChange={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.value }) }} />
                                        <Slider value={spaceCss.shapewidth} aria-label="Default" valueLabelDisplay="auto" min={100} max={300} name='shapewidth' onChange={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.value }) }} />
                                    </div>
                                </div>
                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input mb-3" id="sendToBackSwitch" name='bringtofront' checked={spaceCss.bringtofront} onClick={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.checked }) }} />
                                    <label className="form-check-label" for="flexSwitchCheckChecked">Bring to front</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="SectionPropsWrapper mb-3 mt-3" Style="display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 10px;">
                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                            <InputLabel id="demo-simple-select-standard-label">Animations (when visible)</InputLabel>
                            <Select
                                labelId="animationsection-label"
                                id='animationsection'
                                value={spaceCss.animationsection}
                                name='animationsection'
                                onChange={(e) => { setSpaceCss({ ...spaceCss, [e.target.name]: e.target.value }) }}
                                label="Animations (when visible)"
                            >
                                <MenuItem value={'none'}>No Animation</MenuItem>
                                <MenuItem value={"come-in-right"}>Slide in from right</MenuItem>
                                <MenuItem value={"come-in-left t"}>Slide in from left</MenuItem>
                                <MenuItem value={"come-in-top"}>Slide in from top</MenuItem>
                                <MenuItem value={"come-in-bottom"}>Slide in from bottom</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <div class="container">
                            <div class="copy-text " id='sectionCopytext'>
                                <span type="text" class="text" id='sectionIdElem' Style='width:50%'>#{sectionIdTrack}</span>
                                <button onClick={copySectionId}><i class="fa fa-clone"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="inBetweenAdvanced"></div>
            </div>
        </div>
    )
}

export default AdvancedElement