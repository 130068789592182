import React from 'react'

function InputFields({size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType}) {
    return (
        <div className={`d-flex flex-column justify-content-start align-items-start form-group  global${element.elementObject? element.elementObject.fonttype:''}Font`} Style={` font-family:${element.elementObject && element.elementObject.fonttype==="Custom" ? element.elementObject.fontstyle : ''}`}>
            <label className="customInputLabel globalHeaderFont" Style={`font-size:15px;display:${element.elementObject && (element.elementObject.showlabel===false)?'none':'block'}; color:${element.elementObject?element.elementObject.formLabelColor:''}`}>{element.elementObject?element.elementObject.inputlabeltext:'Input Label'}</label>
            <input required={element.elementObject && element.elementObject.inputrequired==='true'?true:false} name={element.elementObject?element.elementObject.inputtype:'EmailAddress'} type={element.elementObject?element.elementObject.inputtype:'Email'} dfontsize="16px" mfontsize="16px" iconpos="right" className="form-control globalHeaderFont customInputElm" placeholder={element.elementObject?element.elementObject.inputplaceholdertext:'Enter your Email here...'} disabled="" Style={` cursor: not-allowed; background-color:${element.elementObject?element.elementObject.formBackgroundcolor:'transparent'}; color:${element.elementObject?element.elementObject.inputTextColor:'black'};  font-size:${element.elementObject?element.elementObject.formInputDesktopSize:'16'}px; height:${element.elementObject?element.elementObject.formInputHeight:'38'}px; font-weight:${element.elementObject?element.elementObject.formInputfontweight:''}; text-align:${element.elementObject?element.elementObject.formInputtextalign:''}; font-weight:${element.elementObject?element.elementObject.formInputfontweight:''}; border-radius:${element.elementObject?element.elementObject.formInputborderradius:'0px'};`} />
        </div>
    )
}

export default InputFields