import React from 'react'
import { animated, useSpring } from '@react-spring/web'
import Select from 'react-select'

function ElementFontStyle({ setOpenFontStyle, setElementCss, elementCss, setRowchange }) {
    const styles = useSpring({
        from: {
            width: "0px",
            display: "inline",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "1000"
        },
        to: {
            width: "100%",
            display: "inline",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "1000"
        },
        config: { mass: 2, tension: 3000, friction: 120 },
    })

    function addElementFontStyle(data) {
        var dummyCss = elementCss
        dummyCss.fontstyle = data
        setElementCss(dummyCss)
        setRowchange(dummyCss.fontstyle)
    }



    const options = [{ value: 'Arial', label: <div data-value="Arial" data-query="arial" Style="font-family: Arial;" onClick={() => { addElementFontStyle("Arial") }} class="">Arial</div> },
    { value: 'Helvetica Neue', label: <li data-value="Helvetica+Neue" data-query="helvetica+neue" Style="font-family: &quot;Helvetica Neue&quot;;" onClick={() => { addElementFontStyle("Helvetica Neue") }} class="">Helvetica Neue</li> },
    { value: 'Courier New', label: <li data-value="Courier+New" data-query="courier+new" Style="font-family: &quot;Courier New&quot;;" onClick={() => { addElementFontStyle("Courier New") }} class="">Courier New</li> },
    { value: 'Times New Roman', label: <li data-value="Times+New+Roman" data-query="times+new+roman" Style="font-family: &quot;Times New Roman&quot;;" onClick={() => { addElementFontStyle("Times New Roman") }} class="">Times New Roman</li> },
    { value: 'Comic Sans MS', label: <li data-value="Comic+Sans+MS" data-query="comic+sans+ms" Style="font-family: &quot;Comic Sans MS&quot;;" onClick={() => { addElementFontStyle("Comic Sans MS") }} class="">Comic Sans MS</li> },
    { value: 'Impact', label: <li data-value="Impact" data-query="impact" Style="font-family: Impact;" onClick={() => { addElementFontStyle("Impact") }} class="active">Impact</li> },
    { value: 'Source Pro', label: <li data-value="Source+Pro" data-query="source+pro" Style="font-family: &quot;Source Pro&quot;;" onClick={() => { addElementFontStyle("Source Pro") }} class="">Source Pro</li> },
    { value: 'Baskerville', label: <li data-value="Baskerville" data-query="baskerville" Style="font-family: Baskerville;" onClick={() => { addElementFontStyle("Baskerville") }} class="">Baskerville</li> },
    { value: 'Sue Elen Francisco', label: <li data-value="Sue+Elen+Francisco" data-query="sue+elen+francisco" Style="font-family: &quot;Sue Elen Francisco&quot;;" onClick={() => { addElementFontStyle("Sue Elen Francisco") }} class="">Sue Elen Francisco</li> },
    { value: 'Playfair', label: <li data-value="Playfair" data-query="playfair" Style="font-family: Playfair;" onClick={() => { addElementFontStyle("Playfair") }}>Playfair</li> },
    { value: 'Old Standard', label: <li data-value="Old+Standard" data-query="old+standard" Style="font-family: &quot;Old Standard&quot;;" onClick={() => { addElementFontStyle("Old Standard") }} class="">Old Standard</li> },
    { value: 'Armina Madurai', label: <li data-value="Armina+Madurai" data-query="armina+madurai" Style="font-family: &quot;Armina Madurai&quot;;" onClick={() => { addElementFontStyle("Armina Madurai") }}>Armina Madurai</li> },
    { value: 'Into Light Two', label: <li data-value="Into+Light+Two" data-query="into+light+two" Style="font-family: &quot;Into Light Two&quot;;" onClick={() => { addElementFontStyle("Into Light Two") }}>Into Light Two</li> },
    { value: 'Droid Mono', label: <li data-value="Droid+Mono" data-query="droid+mono" Style="font-family: &quot;Droid Mono&quot;;" onClick={() => { addElementFontStyle("Droid Mono") }}>Droid Mono</li> },
    { value: 'Source Code', label: <li data-value="Source+Code" data-query="source+code" Style="font-family: &quot;Source Code&quot;; " onClick={() => { addElementFontStyle("Source Code") }} class="">Source Code</li> },
    { value: 'Hubbali', label: <li data-value="Hubbali" data-query="hubbali" Style="font-family: Hubbali; font-weight: 400;" onClick={() => { addElementFontStyle("Hubbali") }}>Hubbali</li> },
    { value: 'Rubik', label: <li data-value="Rubik" data-query="rubik" Style="font-family: Rubik; font-weight: 400;" onClick={() => { addElementFontStyle("Rubik") }} class="">Rubik</li> },
    { value: 'Poiret One', label: <li data-value="Poiret+One" data-query="poiret+one" Style="font-family: &quot;Poiret One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Poiret One") }} class="">Poiret One</li> },
    { value: 'Fira Sans', label: <li data-value="Fira+Sans" data-query="fira+sans" Style="font-family: &quot;Fira Sans&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Fira Sans") }} class="">Fira Sans</li> },
    { value: 'Noto Sans', label: <li data-value="Noto+Sans" data-query="noto+sans" Style="font-family: &quot;Noto Sans&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Noto Sans") }} class="">Noto Sans</li> },
    { value: 'Oxygen', label: <li data-value="Oxygen" data-query="oxygen" Style="font-family: Oxygen; font-weight: 400;" onClick={() => { addElementFontStyle("Oxygen") }} class="">Oxygen</li> },
    { value: 'Fjalla One', label: <li data-value="Fjalla+One" data-query="fjalla+one" Style="font-family: &quot;Fjalla One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Fjalla One") }} class="">Fjalla One</li> },
    { value: 'Poppins', label: <li data-value="Poppins" data-query="poppins" Style="font-family: Poppins; font-weight: 400;" onClick={() => { addElementFontStyle("Poppins") }} class="">Poppins</li> },
    { value: 'Slabo 27px', label: <li data-value="Slabo+27px" data-query="slabo+27px" Style="font-family: &quot;Slabo 27px&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Slabo 27px") }}>Slabo 27px</li> },
    { value: 'Roboto Slab', label: <li data-value="Roboto+Slab" data-query="roboto+slab" Style="font-family: &quot;Roboto Slab&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Roboto Slab") }}>Roboto Slab</li> },
    { value: 'Adamina', label: <li data-value="Adamina" data-query="adamina" Style="font-family: Adamina; font-weight: 400;" onClick={() => { addElementFontStyle("Adamina") }}>Adamina</li> },
    { value: 'Crete Round', label: <li data-value="Crete+Round" data-query="crete+round" Style="font-family: &quot;Crete Round&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Crete Round") }}>Crete Round</li> },
    { value: 'Domine', label: <li data-value="Domine" data-query="domine" Style="font-family: Domine; font-weight: 400;" onClick={() => { addElementFontStyle("Domine") }}>Domine</li> },
    { value: 'Noto Serif', label: <li data-value="Noto+Serif" data-query="noto+serif" Style="font-family: &quot;Noto Serif&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Noto Serif") }}>Noto Serif</li> },
    { value: 'Pompiere', label: <li data-value="Pompiere" data-query="pompiere" Style="font-family: Pompiere; font-weight: 400;" onClick={() => { addElementFontStyle("Pompiere") }}>Pompiere</li> },
    { value: 'Alfa Slab One', label: <li data-value="Alfa+Slab+One" data-query="alfa+slab+one" Style="font-family: &quot;Alfa Slab One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Alfa Slab One") }}>Alfa Slab One</li> },
    { value: 'Baloo Bhaina', label: <li data-value="Baloo+Bhaina" data-query="baloo+bhaina" Style="font-family: &quot;Baloo Bhaina&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Baloo Bhaina") }}>Baloo Bhaina</li> },
    { value: 'Fredoka One', label: <li data-value="Fredoka+One" data-query="fredoka+one" Style="font-family: &quot;Fredoka One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Fredoka One") }}>Fredoka One</li> },
    { value: 'Passion One', label: <li data-value="Passion+One" data-query="passion+one" Style="font-family: &quot;Passion One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Passion One") }}>Passion One</li> },
    { value: 'Patua One', label: <li data-value="Patua+One" data-query="patua+one" Style="font-family: &quot;Patua One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Patua One") }}>Patua One</li> },
    { value: 'Ranga', label: <li data-value="Ranga" data-query="ranga" Style="font-family: Ranga; font-weight: 400;" onClick={() => { addElementFontStyle("Ranga") }}>Ranga</li> },
    { value: 'Graduate', label: <li data-value="Graduate" data-query="graduate" Style="font-family: Graduate; font-weight: 400;" onClick={() => { addElementFontStyle("Graduate") }} class="">Graduate</li> },
    { value: 'Patrick Hand SC', label: <li data-value="Patrick+Hand+SC" data-query="patrick+hand+sc" Style="font-family: &quot;Patrick Hand SC&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Patrick Hand SC") }} class="">Patrick Hand SC</li> },
    { value: 'Alex Brush', label: <li data-value="Alex+Brush" data-query="alex+brush" Style="font-family: &quot;Alex Brush&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Alex Brush") }} class="">Alex Brush</li> },
    { value: 'Caveat', label: <li data-value="Caveat" data-query="caveat" Style="font-family: Caveat; font-weight: 400;" onClick={() => { addElementFontStyle("Caveat") }}>Caveat</li> },
    { value: 'Gloria Hallelujah', label: <li data-value="Gloria+Hallelujah" data-query="gloria+hallelujah" Style="font-family: &quot;Gloria Hallelujah&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Gloria Hallelujah") }}>Gloria Hallelujah</li> },
    { value: 'Handlee', label: <li data-value="Handlee" data-query="handlee" Style="font-family: Handlee; font-weight: 400;" onClick={() => { addElementFontStyle("Handlee") }}>Handlee</li> },
    { value: 'Pangolin', label: <li data-value="Pangolin" data-query="pangolin" Style="font-family: Pangolin; font-weight: 400;" onClick={() => { addElementFontStyle("Pangolin") }}>Pangolin</li> },
    { value: 'Overpass Mono', label: <li data-value="Overpass+Mono" data-query="overpass+mono" Style="font-family: &quot;Overpass Mono&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Overpass Mono") }}>Overpass Mono</li> },
    { value: 'PT Mono', label: <li data-value="PT+Mono" data-query="pt+mono" Style="font-family: &quot;PT Mono&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("PT Mono") }}>PT Mono</li> },
    { value: 'Roboto Mono', label: <li data-value="Roboto+Mono" data-query="roboto+mono" Style="font-family: &quot;Roboto Mono&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Roboto Mono") }} class="">Roboto Mono</li> },
    { value: 'Ubuntu Mono', label: <li data-value="Ubuntu+Mono" data-query="ubuntu+mono" Style="font-family: &quot;Ubuntu Mono&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Ubuntu Mono") }} class="">Ubuntu Mono</li> },
    { value: 'Roboto', label: <li data-value="Roboto" data-query="roboto" Style="font-family: Roboto; font-weight: 400;" onClick={() => { addElementFontStyle("Roboto") }}>Roboto</li> },
    { value: 'Aclonica', label: <li data-value="Aclonica" data-query="aclonica" Style="font-family: Aclonica; font-weight: 400;" onClick={() => { addElementFontStyle("Aclonica") }}>Aclonica</li> },
    { value: 'Allan', label: <li data-value="Allan" data-query="allan" Style="font-family: Allan; font-weight: 400;" onClick={() => { addElementFontStyle("Allan") }}>Allan</li> },
    { value: 'Annie Use Your Telescope', label: <li data-value="Annie+Use+Your+Telescope" data-query="annie+use+your+telescope" Style="font-family: &quot;Annie Use Your Telescope&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Annie Use Your Telescope") }}>Annie Use Your Telescope</li> },
    { value: 'Anonymous Pro', label: <li data-value="Anonymous+Pro" data-query="anonymous+pro" Style="font-family: &quot;Anonymous Pro&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Anonymous Pro") }}>Anonymous Pro</li> },
    { value: 'Allerta Stencil', label: <li data-value="Allerta+Stencil" data-query="allerta+stencil" Style="font-family: &quot;Allerta Stencil&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Allerta Stencil") }} class="">Allerta Stencil</li> },
    { value: 'Allerta', label: <li data-value="Allerta" data-query="allerta" Style="font-family: Allerta; font-weight: 400;" onClick={() => { addElementFontStyle("Allerta") }}>Allerta</li> },
    { value: 'Amaranth', label: <li data-value="Amaranth" data-query="amaranth" Style="font-family: Amaranth; font-weight: 400;" onClick={() => { addElementFontStyle("Amaranth") }} class="">Amaranth</li> },
    { value: 'Anton', label: <li data-value="Anton" data-query="anton" Style="font-family: Anton; font-weight: 400;" onClick={() => { addElementFontStyle("Anton") }} class="">Anton</li> },
    { value: 'Architects Daughter', label: <li data-value="Architects+Daughter" data-query="architects+daughter" Style="font-family: &quot;Architects Daughter&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Architects Daughter") }} class="">Architects Daughter</li> },
    { value: 'Arimo', label: <li data-value="Arimo" data-query="arimo" Style="font-family: Arimo; font-weight: 400;" onClick={() => { addElementFontStyle("Arimo") }} class="">Arimo</li> },
    { value: 'Artifika', label: <li data-value="Artifika" data-query="artifika" Style="font-family: Artifika; font-weight: 400;" onClick={() => { addElementFontStyle("Artifika") }}>Artifika</li> },
    { value: 'Arvo', label: <li data-value="Arvo" data-query="arvo" Style="font-family: Arvo; font-weight: 400;" onClick={() => { addElementFontStyle("Arvo") }}>Arvo</li> },
    { value: 'Asset', label: <li data-value="Asset" data-query="asset" Style="font-family: Asset; font-weight: 400;" onClick={() => { addElementFontStyle("Asset") }}>Asset</li> },
    { value: 'Astloch', label: <li data-value="Astloch" data-query="astloch" Style="font-family: Astloch; font-weight: 400;" onClick={() => { addElementFontStyle("Astloch") }}>Astloch</li> },
    { value: 'Bangers', label: <li data-value="Bangers" data-query="bangers" Style="font-family: Bangers; font-weight: 400;" onClick={() => { addElementFontStyle("Bangers") }}>Bangers</li> },
    { value: 'Bentham', label: <li data-value="Bentham" data-query="bentham" Style="font-family: Bentham; font-weight: 400;" onClick={() => { addElementFontStyle("Bentham") }}>Bentham</li> },
    { value: 'Bevan', label: <li data-value="Bevan" data-query="bevan" Style="font-family: Bevan; font-weight: 400;" onClick={() => { addElementFontStyle("Bevan") }}>Bevan</li> },
    { value: 'Bigshot One', label: <li data-value="Bigshot+One" data-query="bigshot+one" Style="font-family: &quot;Bigshot One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Bigshot One") }} class="">Bigshot One</li> },
    { value: 'Bowlby One', label: <li data-value="Bowlby+One" data-query="bowlby+one" Style="font-family: &quot;Bowlby One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Bowlby One") }}>Bowlby One</li> },
    { value: 'Bowlby One SC', label: <li data-value="Bowlby+One+SC" data-query="bowlby+one+sc" Style="font-family: &quot;Bowlby One SC&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Bowlby One SC") }} class="">Bowlby One SC</li> },
    { value: 'Brawler', label: <li data-value="Brawler" data-query="brawler" Style="font-family: Brawler; font-weight: 400;" onClick={() => { addElementFontStyle("Brawler") }} class="">Brawler</li> },
    { value: 'Buda 300', label: <li data-value="Buda:300" data-query="buda:300" Style="font-family: Buda; font-weight: 300;" onClick={() => { addElementFontStyle("Buda 300") }} class="">Buda 300</li> },
    { value: 'Cabin', label: <li data-value="Cabin" data-query="cabin" Style="font-family: Cabin; font-weight: 400;" onClick={() => { addElementFontStyle("Cabin") }}>Cabin</li> },
    { value: 'Calligraffitti', label: <li data-value="Calligraffitti" data-query="calligraffitti" Style="font-family: Calligraffitti; font-weight: 400;" onClick={() => { addElementFontStyle("Calligraffitti") }}>Calligraffitti</li> },
    { value: 'Candal', label: <li data-value="Candal" data-query="candal" Style="font-family: Candal; font-weight: 400;" onClick={() => { addElementFontStyle("Candal") }}>Candal</li> },
    { value: 'Cantarell', label: <li data-value="Cantarell" data-query="cantarell" Style="font-family: Cantarell; font-weight: 400;" onClick={() => { addElementFontStyle("Cantarell") }}>Cantarell</li> },
    { value: 'Cardo', label: <li data-value="Cardo" data-query="cardo" Style="font-family: Cardo; font-weight: 400;" onClick={() => { addElementFontStyle("Cardo") }}>Cardo</li> },
    { value: 'Carter One', label: <li data-value="Carter+One" data-query="carter+one" Style="font-family: &quot;Carter One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Carter One") }} class="">Carter One</li> },
    { value: 'Caudex', label: <li data-value="Caudex" data-query="caudex" Style="font-family: Caudex; font-weight: 400;" onClick={() => { addElementFontStyle("Caudex") }}>Caudex</li> },
    { value: 'Cedarville Cursive', label: <li data-value="Cedarville+Cursive" data-query="cedarville+cursive" Style="font-family: &quot;Cedarville Cursive&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Cedarville Cursive") }}>Cedarville Cursive</li> },
    { value: 'Cherry Cream Soda', label: <li data-value="Cherry+Cream+Soda" data-query="cherry+cream+soda" Style="font-family: &quot;Cherry Cream Soda&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Cherry Cream Soda") }}>Cherry Cream Soda</li> },
    { value: 'Chewy', label: <li data-value="Chewy" data-query="chewy" Style="font-family: Chewy; font-weight: 400;" onClick={() => { addElementFontStyle("Chewy") }}>Chewy</li> },
    { value: 'Coda', label: <li data-value="Coda" data-query="coda" Style="font-family: Coda; font-weight: 400;" onClick={() => { addElementFontStyle("Coda") }}>Coda</li> },
    { value: 'Coming Soon', label: <li data-value="Coming+Soon" data-query="coming+soon" Style="font-family: &quot;Coming Soon&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Coming Soon") }}>Coming Soon</li> },
    { value: 'Copse', label: <li data-value="Copse" data-query="copse" Style="font-family: Copse; font-weight: 400;" onClick={() => { addElementFontStyle("Copse") }}>Copse</li> },
    { value: 'Corben', label: <li data-value="Corben" data-query="corben" Style="font-family: Corben; font-weight: 400;" onClick={() => { addElementFontStyle("Corben") }} class="">Corben</li> },
    { value: 'Cousine', label: <li data-value="Cousine" data-query="cousine" Style="font-family: Cousine; font-weight: 400;" onClick={() => { addElementFontStyle("Cousine") }}>Cousine</li> },
    { value: 'Covered By Your Grace', label: <li data-value="Covered+By+Your+Grace" data-query="covered+by+your+grace" Style="font-family: &quot;Covered By Your Grace&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Covered By Your Grace") }}>Covered By Your Grace</li> },
    { value: 'Crafty Girls', label: <li data-value="Crafty+Girls" data-query="crafty+girls" Style="font-family: &quot;Crafty Girls&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Crafty Girls") }} class="">Crafty Girls</li> },
    { value: 'Crimson Text', label: <li data-value="Crimson+Text" data-query="crimson+text" Style="font-family: &quot;Crimson Text&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Crimson Text") }} class="">Crimson Text</li> },
    { value: 'Crushed', label: <li data-value="Crushed" data-query="crushed" Style="font-family: Crushed; font-weight: 400;" onClick={() => { addElementFontStyle("Crushed") }}>Crushed</li> },
    { value: 'Cuprum', label: <li data-value="Cuprum" data-query="cuprum" Style="font-family: Cuprum; font-weight: 400;" onClick={() => { addElementFontStyle("Cuprum") }} class="">Cuprum</li> },
    { value: 'Damion', label: <li data-value="Damion" data-query="damion" Style="font-family: Damion; font-weight: 400;" onClick={() => { addElementFontStyle("Damion") }}>Damion</li> },
    { value: 'Dancing Script', label: <li data-value="Dancing+Script" data-query="dancing+script" Style="font-family: &quot;Dancing Script&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Dancing Script") }} class="">Dancing Script</li> },
    { value: 'Dawning of a New Day', label: <li data-value="Dawning+of+a+New+Day" data-query="dawning+of+a+new+day" Style="font-family: &quot;Dawning of a New Day&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Dawning of a New Day") }} class="">Dawning of a New Day</li> },
    { value: 'Didact Gothic', label: <li data-value="Didact+Gothic" data-query="didact+gothic" Style="font-family: &quot;Didact Gothic&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Didact Gothic") }} class="">Didact Gothic</li> },
    { value: 'Droid Sans', label: <li data-value="Droid+Sans" data-query="droid+sans" Style="font-family: &quot;Droid Sans&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Droid Sans") }}>Droid Sans</li> },
    { value: 'Droid Serif', label: <li data-value="Droid+Serif" data-query="droid+serif" Style="font-family: &quot;Droid Serif&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Droid Serif") }}>Droid Serif</li> },
    { value: 'EB Garamond', label: <li data-value="EB+Garamond" data-query="eb+garamond" Style="font-family: &quot;EB Garamond&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("EB Garamond") }}>EB Garamond</li> },
    { value: 'Expletus Sans', label: <li data-value="Expletus+Sans" data-query="expletus+sans" Style="font-family: &quot;Expletus Sans&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Expletus Sans") }}>Expletus Sans</li> },
    { value: 'Fontdiner Swanky', label: <li data-value="Fontdiner+Swanky" data-query="fontdiner+swanky" Style="font-family: &quot;Fontdiner Swanky&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Fontdiner Swanky") }}>Fontdiner Swanky</li> },
    { value: 'Forum', label: <li data-value="Forum" data-query="forum" Style="font-family: Forum; font-weight: 400;" onClick={() => { addElementFontStyle("Forum") }}>Forum</li> },
    { value: 'Francois One', label: <li data-value="Francois+One" data-query="francois+one" Style="font-family: &quot;Francois One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Francois One") }}>Francois One</li> },
    { value: 'Geo', label: <li data-value="Geo" data-query="geo" Style="font-family: Geo; font-weight: 400;" onClick={() => { addElementFontStyle("Geo") }}>Geo</li> },
    { value: 'Give You Glory', label: <li data-value="Give+You+Glory" data-query="give+you+glory" Style="font-family: &quot;Give You Glory&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Give You Glory") }}>Give You Glory</li> },
    { value: 'Goblin One', label: <li data-value="Goblin+One" data-query="goblin+one" Style="font-family: &quot;Goblin One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Goblin One") }} class="">Goblin One</li> },
    { value: 'Goudy Bookletter 1911', label: <li data-value="Goudy+Bookletter+1911" data-query="goudy+bookletter+1911" Style="font-family: &quot;Goudy Bookletter 1911&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Goudy Bookletter 1911") }} class="">Goudy Bookletter 1911</li> },
    { value: 'Gravitas One', label: <li data-value="Gravitas+One" data-query="gravitas+one" Style="font-family: &quot;Gravitas One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Gravitas One") }}>Gravitas One</li> },
    { value: 'Gruppo', label: <li data-value="Gruppo" data-query="gruppo" Style="font-family: Gruppo; font-weight: 400;" onClick={() => { addElementFontStyle("Gruppo") }} class="">Gruppo</li> },
    { value: 'Hammersmith One', label: <li data-value="Hammersmith+One" data-query="hammersmith+one" Style="font-family: &quot;Hammersmith One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Hammersmith One") }} class="">Hammersmith One</li> },
    { value: 'Holtwood One SC', label: <li data-value="Holtwood+One+SC" data-query="holtwood+one+sc" Style="font-family: &quot;Holtwood One SC&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Holtwood One SC") }} class="">Holtwood One SC</li> },
    { value: 'Homemade Apple', label: <li data-value="Homemade+Apple" data-query="homemade+apple" Style="font-family: &quot;Homemade Apple&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Homemade Apple") }}>Homemade Apple</li> },
    { value: 'Inconsolata', label: <li data-value="Inconsolata" data-query="inconsolata" Style="font-family: Inconsolata; font-weight: 400;" onClick={() => { addElementFontStyle("Inconsolata") }} class="">Inconsolata</li> },
    { value: 'Indie Flower', label: <li data-value="Indie+Flower" data-query="indie+flower" Style="font-family: &quot;Indie Flower&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Indie Flower") }}>Indie Flower</li> },
    { value: 'Irish Grover', label: <li data-value="Irish+Grover" data-query="irish+grover" Style="font-family: &quot;Irish Grover&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Irish Grover") }}>Irish Grover</li> },
    { value: 'Istok Web', label: <li data-value="Istok+Web" data-query="istok+web" Style="font-family: &quot;Istok Web&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Istok Web") }}>Istok Web</li> },
    { value: 'Josefin Sans', label: <li data-value="Josefin+Sans" data-query="josefin+sans" Style="font-family: &quot;Josefin Sans&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Josefin Sans") }}>Josefin Sans</li> },
    { value: 'Josefin Slab', label: <li data-value="Josefin+Slab" data-query="josefin+slab" Style="font-family: &quot;Josefin Slab&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Josefin Slab") }}>Josefin Slab</li> },
    { value: 'Judson', label: <li data-value="Judson" data-query="judson" Style="font-family: Judson; font-weight: 400;" onClick={() => { addElementFontStyle("Judson") }}>Judson</li> },
    { value: 'Jura', label: <li data-value="Jura" data-query="jura" Style="font-family: Jura; font-weight: 400;" onClick={() => { addElementFontStyle("Jura") }}>Jura</li> },
    { value: 'Just Another Hand', label: <li data-value="Just+Another+Hand" data-query="just+another+hand" Style="font-family: &quot;Just Another Hand&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Just Another Hand") }}>Just Another Hand</li> },
    { value: 'Just Me Again Down Here', label: <li data-value="Just+Me+Again+Down+Here" data-query="just+me+again+down+here" Style="font-family: &quot;Just Me Again Down Here&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Just Me Again Down Here") }}>Just Me Again Down Here</li> },
    { value: 'Kameron', label: <li data-value="Kameron" data-query="kameron" Style="font-family: Kameron; font-weight: 400;" onClick={() => { addElementFontStyle("Kameron") }}>Kameron</li> },
    { value: 'Kenia', label: <li data-value="Kenia" data-query="kenia" Style="font-family: Kenia; font-weight: 400;" onClick={() => { addElementFontStyle("Kenia") }}>Kenia</li> },
    { value: 'Kranky', label: <li data-value="Kranky" data-query="kranky" Style="font-family: Kranky; font-weight: 400;" onClick={() => { addElementFontStyle("Kranky") }}>Kranky</li> },
    { value: 'Kreon', label: <li data-value="Kreon" data-query="kreon" Style="font-family: Kreon; font-weight: 400;" onClick={() => { addElementFontStyle("Kreon") }}>Kreon</li> },
    { value: 'Kristi', label: <li data-value="Kristi" data-query="kristi" Style="font-family: Kristi; font-weight: 400;" onClick={() => { addElementFontStyle("Kristi") }}>Kristi</li> },
    { value: 'La Belle Aurore', label: <li data-value="La+Belle+Aurore" data-query="la+belle+aurore" Style="font-family: &quot;La Belle Aurore&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("La Belle Aurore") }}>La Belle Aurore</li> },
    { value: 'Lato', label: <li data-value="Lato" data-query="lato" Style="font-family: Lato; font-weight: 400;" onClick={() => { addElementFontStyle("Lato") }} class="">Lato</li> },
    { value: 'League Script', label: <li data-value="League+Script" data-query="league+script" Style="font-family: &quot;League Script&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("League Script") }} class="">League Script</li> },
    { value: 'Lekton', label: <li data-value="Lekton" data-query="lekton" Style="font-family: Lekton; font-weight: 400;" onClick={() => { addElementFontStyle("Lekton") }} class="">Lekton</li> },
    { value: 'Limelight', label: <li data-value="Limelight" data-query="limelight" Style="font-family: Limelight; font-weight: 400;" onClick={() => { addElementFontStyle("Limelight") }}>Limelight</li> },
    { value: 'Lobster', label: <li data-value="Lobster" data-query="lobster" Style="font-family: Lobster; font-weight: 400;" onClick={() => { addElementFontStyle("Lobster") }}>Lobster</li> },
    { value: 'Lobster Two', label: <li data-value="Lobster+Two" data-query="lobster+two" Style="font-family: &quot;Lobster Two&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Lobster Two") }}>Lobster Two</li> },
    { value: 'Lora', label: <li data-value="Lora" data-query="lora" Style="font-family: Lora; font-weight: 400;" onClick={() => { addElementFontStyle("Lora") }}>Lora</li> },
    { value: 'Love Ya Like A Sister', label: <li data-value="Love+Ya+Like+A+Sister" data-query="love+ya+like+a+sister" Style="font-family: &quot;Love Ya Like A Sister&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Love Ya Like A Sister") }}>Love Ya Like A Sister</li> },
    { value: 'Loved by the King', label: <li data-value="Loved+by+the+King" data-query="loved+by+the+king" Style="font-family: &quot;Loved by the King&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Loved by the King") }}>Loved by the King</li> },
    { value: 'Luckiest Guy', label: <li data-value="Luckiest+Guy" data-query="luckiest+guy" Style="font-family: &quot;Luckiest Guy&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Luckiest Guy") }}>Luckiest Guy</li> },
    { value: 'Maiden Orange', label: <li data-value="Maiden+Orange" data-query="maiden+orange" Style="font-family: &quot;Maiden Orange&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Maiden Orange") }}>Maiden Orange</li> },
    { value: 'Mako', label: <li data-value="Mako" data-query="mako" Style="font-family: Mako; font-weight: 400;" onClick={() => { addElementFontStyle("Mako") }}>Mako</li> },
    { value: 'Maven Pro', label: <li data-value="Maven+Pro" data-query="maven+pro" Style="font-family: &quot;Maven Pro&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Maven Pro") }}>Maven Pro</li> },
    { value: 'Maven Pro 900', label: <li data-value="Maven+Pro:900" data-query="maven+pro:900" Style="font-family: &quot;Maven Pro&quot;; font-weight: 900;" onClick={() => { addElementFontStyle("Maven Pro 900") }}>Maven Pro 900</li> },
    { value: 'Meddon', label: <li data-value="Meddon" data-query="meddon" Style="font-family: Meddon; font-weight: 400;" onClick={() => { addElementFontStyle("Meddon") }} class="">Meddon</li> },
    { value: 'MedievalSharp', label: <li data-value="MedievalSharp" data-query="medievalsharp" Style="font-family: MedievalSharp; font-weight: 400;" onClick={() => { addElementFontStyle("MedievalSharp") }}>MedievalSharp</li> },
    { value: 'Megrim', label: <li data-value="Megrim" data-query="megrim" Style="font-family: Megrim; font-weight: 400;" onClick={() => { addElementFontStyle("Megrim") }}>Megrim</li> },
    { value: 'Merriweather', label: <li data-value="Merriweather" data-query="merriweather" Style="font-family: Merriweather; font-weight: 400;" onClick={() => { addElementFontStyle("Merriweather") }} class="">Merriweather</li> },
    { value: 'Metrophobic', label: <li data-value="Metrophobic" data-query="metrophobic" Style="font-family: Metrophobic; font-weight: 400;" onClick={() => { addElementFontStyle("Metrophobic") }} class="">Metrophobic</li> },
    { value: 'Michroma', label: <li data-value="Michroma" data-query="michroma" Style="font-family: Michroma; font-weight: 400;" onClick={() => { addElementFontStyle("Michroma") }}>Michroma</li> },
    { value: 'Miltonian Tattoo', label: <li data-value="Miltonian+Tattoo" data-query="miltonian+tattoo" Style="font-family: &quot;Miltonian Tattoo&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Miltonian Tattoo") }} class="">Miltonian Tattoo</li> },
    { value: 'Miltonian', label: <li data-value="Miltonian" data-query="miltonian" Style="font-family: Miltonian; font-weight: 400;" onClick={() => { addElementFontStyle("Miltonian") }}>Miltonian</li> },
    { value: 'Modern Antiqua', label: <li data-value="Modern+Antiqua" data-query="modern+antiqua" Style="font-family: &quot;Modern Antiqua&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Modern Antiqua") }}>Modern Antiqua</li> },
    { value: 'Monofett', label: <li data-value="Monofett" data-query="monofett" Style="font-family: Monofett; font-weight: 400;" onClick={() => { addElementFontStyle("Monofett") }}>Monofett</li> },
    { value: 'Molengo', label: <li data-value="Molengo" data-query="molengo" Style="font-family: Molengo; font-weight: 400;" onClick={() => { addElementFontStyle("Molengo") }}>Molengo</li> },
    { value: 'Mountains of Christmas', label: <li data-value="Mountains+of+Christmas" data-query="mountains+of+christmas" Style="font-family: &quot;Mountains of Christmas&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Mountains of Christmas") }}>Mountains of Christmas</li> },
    { value: 'Muli 300', label: <li data-value="Muli:300" data-query="muli:300" Style="font-family: Muli; font-weight: 300;" onClick={() => { addElementFontStyle("Muli 300") }}>Muli 300</li> },
    { value: 'Muli', label: <li data-value="Muli" data-query="muli" Style="font-family: Muli; font-weight: 400;" onClick={() => { addElementFontStyle("Muli") }}>Muli</li> },
    { value: 'Neucha', label: <li data-value="Neucha" data-query="neucha" Style="font-family: Neucha; font-weight: 400;" onClick={() => { addElementFontStyle("Neucha") }}>Neucha</li> },
    { value: 'Neuton', label: <li data-value="Neuton" data-query="neuton" Style="font-family: Neuton; font-weight: 400;" onClick={() => { addElementFontStyle("Neuton") }}>Neuton</li> },
    { value: 'News Cycle', label: <li data-value="News+Cycle" data-query="news+cycle" Style="font-family: &quot;News Cycle&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("News Cycle") }}>News Cycle</li> },
    { value: 'Nixie One', label: <li data-value="Nixie+One" data-query="nixie+one" Style="font-family: &quot;Nixie One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nixie One") }}>Nixie One</li> },
    { value: 'Nobile', label: <li data-value="Nobile" data-query="nobile" Style="font-family: Nobile; font-weight: 400;" onClick={() => { addElementFontStyle("Nobile") }}>Nobile</li> },
    { value: 'Nova Cut', label: <li data-value="Nova+Cut" data-query="nova+cut" Style="font-family: &quot;Nova Cut&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nova Cut") }}>Nova Cut</li> },
    { value: 'Nova Flat', label: <li data-value="Nova+Flat" data-query="nova+flat" Style="font-family: &quot;Nova Flat&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nova Flat") }}>Nova Flat</li> },
    { value: 'Nova Mono', label: <li data-value="Nova+Mono" data-query="nova+mono" Style="font-family: &quot;Nova Mono&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nova Mono") }} class="">Nova Mono</li> },
    { value: 'Nova Oval', label: <li data-value="Nova+Oval" data-query="nova+oval" Style="font-family: &quot;Nova Oval&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nova Oval") }}>Nova Oval</li> },
    { value: 'Nova Round', label: <li data-value="Nova+Round" data-query="nova+round" Style="font-family: &quot;Nova Round&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nova Round") }}>Nova Round</li> },
    { value: 'Nova Script', label: <li data-value="Nova+Script" data-query="nova+script" Style="font-family: &quot;Nova Script&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nova Script") }}>Nova Script</li> },
    { value: 'Nova Slim', label: <li data-value="Nova+Slim" data-query="nova+slim" Style="font-family: &quot;Nova Slim&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nova Slim") }}>Nova Slim</li> },
    { value: 'Nova Square', label: <li data-value="Nova+Square" data-query="nova+square" Style="font-family: &quot;Nova Square&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Nova Square") }}>Nova Square</li> },
    { value: 'Nunito', label: <li data-value="Nunito" data-query="nunito" Style="font-family: Nunito; font-weight: 400;" onClick={() => { addElementFontStyle("Nunito") }}>Nunito</li> },
    { value: 'Old Standard TT', label: <li data-value="Old+Standard+TT" data-query="old+standard+tt" Style="font-family: &quot;Old Standard TT&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Old Standard TT") }} class="">Old Standard TT</li> },
    { value: 'Open Sans 300', label: <li data-value="Open+Sans:300" data-query="open+sans:300" Style="font-family: &quot;Open Sans&quot;; font-weight: 300;" onClick={() => { addElementFontStyle("Open Sans 300") }} class="">Open Sans 300</li> },
    { value: 'Open Sans', label: <li data-value="Open+Sans" data-query="open+sans" Style="font-family: &quot;Open Sans&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Open Sans") }} class="">Open Sans</li> },
    { value: 'Open Sans 600', label: <li data-value="Open+Sans:600" data-query="open+sans:600" Style="font-family: &quot;Open Sans&quot;; font-weight: 600;" onClick={() => { addElementFontStyle("Open Sans 600") }} class="">Open Sans 600</li> },
    { value: 'Open Sans 800', label: <li data-value="Open+Sans:800" data-query="open+sans:800" Style="font-family: &quot;Open Sans&quot;; font-weight: 800;" onClick={() => { addElementFontStyle("Open Sans 800") }}>Open Sans 800</li> },
    { value: 'Open Sans Condensed 300', label: <li data-value="Open+Sans+Condensed:300" data-query="open+sans+condensed:300" Style="font-family: &quot;Open Sans Condensed&quot;; font-weight: 300;" onClick={() => { addElementFontStyle("Open Sans Condensed 300") }}>Open Sans Condensed 300</li> },
    { value: 'Orbitron', label: <li data-value="Orbitron" data-query="orbitron" Style="font-family: Orbitron; font-weight: 400;" onClick={() => { addElementFontStyle("Orbitron") }} class="">Orbitron</li> },
    { value: 'Orbitron 500', label: <li data-value="Orbitron:500" data-query="orbitron:500" Style="font-family: Orbitron; font-weight: 500;" onClick={() => { addElementFontStyle("Orbitron 500") }}>Orbitron 500</li> },
    { value: 'Orbitron 700', label: <li data-value="Orbitron:700" data-query="orbitron:700" Style="font-family: Orbitron; font-weight: 700;" onClick={() => { addElementFontStyle("Orbitron 700") }} class="">Orbitron 700</li> },
    { value: 'Orbitron 900', label: <li data-value="Orbitron:900" data-query="orbitron:900" Style="font-family: Orbitron; font-weight: 900;" onClick={() => { addElementFontStyle("Orbitron 900") }}>Orbitron 900</li> },
    { value: 'Oswald', label: <li data-value="Oswald" data-query="oswald" Style="font-family: Oswald; font-weight: 400;" onClick={() => { addElementFontStyle("Oswald") }}>Oswald</li> },
    { value: 'Over the Rainbow', label: <li data-value="Over+the+Rainbow" data-query="over+the+rainbow" Style="font-family: &quot;Over the Rainbow&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Over the Rainbow") }}>Over the Rainbow</li> },
    { value: 'Reenie Beanie', label: <li data-value="Reenie+Beanie" data-query="reenie+beanie" Style="font-family: &quot;Reenie Beanie&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Reenie Beanie") }} class="">Reenie Beanie</li> },
    { value: 'Pacifico', label: <li data-value="Pacifico" data-query="pacifico" Style="font-family: Pacifico; font-weight: 400;" onClick={() => { addElementFontStyle("Pacifico") }}>Pacifico</li> },
    { value: 'Patrick Hand', label: <li data-value="Patrick+Hand" data-query="patrick+hand" Style="font-family: &quot;Patrick Hand&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Patrick Hand") }}>Patrick Hand</li> },
    { value: 'Paytone One', label: <li data-value="Paytone+One" data-query="paytone+one" Style="font-family: &quot;Paytone One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Paytone One") }}>Paytone One</li> },
    { value: 'Permanent Marker', label: <li data-value="Permanent+Marker" data-query="permanent+marker" Style="font-family: &quot;Permanent Marker&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Permanent Marker") }}>Permanent Marker</li> },
    { value: 'Philosopher', label: <li data-value="Philosopher" data-query="philosopher" Style="font-family: Philosopher; font-weight: 400;" onClick={() => { addElementFontStyle("Philosopher") }} class="">Philosopher</li> },
    { value: 'Play', label: <li data-value="Play" data-query="play" Style="font-family: Play; font-weight: 400;" onClick={() => { addElementFontStyle("Play") }}>Play</li> },
    { value: 'Playfair Display', label: <li data-value="Playfair+Display" data-query="playfair+display" Style="font-family: &quot;Playfair Display&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Playfair Display") }}>Playfair Display</li> },
    { value: 'Podkova', label: <li data-value="Podkova" data-query="podkova" Style="font-family: Podkova; font-weight: 400;" onClick={() => { addElementFontStyle("Podkova") }}>Podkova</li> },
    { value: 'Press Start 2P', label: <li data-value="Press+Start+2P" data-query="press+start+2p" Style="font-family: &quot;Press Start 2P&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Press Start 2P") }}>Press Start 2P</li> },
    { value: 'Puritan', label: <li data-value="Puritan" data-query="puritan" Style="font-family: Puritan; font-weight: 400;" onClick={() => { addElementFontStyle("Puritan") }}>Puritan</li> },
    { value: 'Quattrocento', label: <li data-value="Quattrocento" data-query="quattrocento" Style="font-family: Quattrocento; font-weight: 400;" onClick={() => { addElementFontStyle("Quattrocento") }}>Quattrocento</li> },
    { value: 'Quattrocento Sans', label: <li data-value="Quattrocento+Sans" data-query="quattrocento+sans" Style="font-family: &quot;Quattrocento Sans&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Quattrocento Sans") }}>Quattrocento Sans</li> },
    { value: 'Radley', label: <li data-value="Radley" data-query="radley" Style="font-family: Radley; font-weight: 400;" onClick={() => { addElementFontStyle("Radley") }}>Radley</li> },
    { value: 'Raleway 100', label: <li data-value="Raleway:100" data-query="raleway:100" Style="font-family: Raleway; font-weight: 100;" onClick={() => { addElementFontStyle("Raleway 100") }}>Raleway 100</li> },
    { value: 'Redressed', label: <li data-value="Redressed" data-query="redressed" Style="font-family: Redressed; font-weight: 400;" onClick={() => { addElementFontStyle("Redressed") }} class="">Redressed</li> },
    { value: 'Rock Salt', label: <li data-value="Rock+Salt" data-query="rock+salt" Style="font-family: &quot;Rock Salt&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Rock Salt") }}>Rock Salt</li> },
    { value: 'Rokkitt', label: <li data-value="Rokkitt" data-query="rokkitt" Style="font-family: Rokkitt; font-weight: 400;" onClick={() => { addElementFontStyle("Rokkitt") }}>Rokkitt</li> },
    { value: 'Ruslan Display', label: <li data-value="Ruslan+Display" data-query="ruslan+display" Style="font-family: &quot;Ruslan Display&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Ruslan Display") }}>Ruslan Display</li> },
    { value: 'Schoolbell', label: <li data-value="Schoolbell" data-query="schoolbell" Style="font-family: Schoolbell; font-weight: 400;" onClick={() => { addElementFontStyle("Schoolbell") }} class="">Schoolbell</li> },
    { value: 'Shadows Into Light', label: <li data-value="Shadows+Into+Light" data-query="shadows+into+light" Style="font-family: &quot;Shadows Into Light&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Shadows Into Light") }}>Shadows Into Light</li> },
    { value: 'Shanti', label: <li data-value="Shanti" data-query="shanti" Style="font-family: Shanti; font-weight: 400;" onClick={() => { addElementFontStyle("Shanti") }}>Shanti</li> },
    { value: 'Sigmar One', label: <li data-value="Sigmar+One" data-query="sigmar+one" Style="font-family: &quot;Sigmar One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Sigmar One") }}>Sigmar One</li> },
    { value: 'Six Caps', label: <li data-value="Six+Caps" data-query="six+caps" Style="font-family: &quot;Six Caps&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Six Caps") }}>Six Caps</li> },
    { value: 'Slackey', label: <li data-value="Slackey" data-query="slackey" Style="font-family: Slackey; font-weight: 400;" onClick={() => { addElementFontStyle("Slackey") }}>Slackey</li> },
    { value: 'Smythe', label: <li data-value="Smythe" data-query="smythe" Style="font-family: Smythe; font-weight: 400;" onClick={() => { addElementFontStyle("Smythe") }}>Smythe</li> },
    { value: 'Sniglet', label: <li data-value="Sniglet" data-query="sniglet" Style="font-family: Sniglet; font-weight: 400;" onClick={() => { addElementFontStyle("Sniglet") }}>Sniglet</li> },
    { value: 'Sniglet 800', label: <li data-value="Sniglet:800" data-query="sniglet:800" Style="font-family: Sniglet; font-weight: 800;" onClick={() => { addElementFontStyle("Sniglet 800") }}>Sniglet 800</li> },
    { value: 'Special Elite', label: <li data-value="Special+Elite" data-query="special+elite" Style="font-family: &quot;Special Elite&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Special Elite") }}>Special Elite</li> },
    { value: 'Stardos Stencil', label: <li data-value="Stardos+Stencil" data-query="stardos+stencil" Style="font-family: &quot;Stardos Stencil&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Stardos Stencil") }}>Stardos Stencil</li> },
    { value: 'Sunshiney', label: <li data-value="Sunshiney" data-query="sunshiney" Style="font-family: Sunshiney; font-weight: 400;" onClick={() => { addElementFontStyle("Sunshiney") }} class="">Sunshiney</li> },
    { value: 'Swanky and Moo Moo', label: <li data-value="Swanky+and+Moo+Moo" data-query="swanky+and+moo+moo" Style="font-family: &quot;Swanky and Moo Moo&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Swanky and Moo Moo") }}>Swanky and Moo Moo</li> },
    { value: 'Syncopate', label: <li data-value="Syncopate" data-query="syncopate" Style="font-family: Syncopate; font-weight: 400;" onClick={() => { addElementFontStyle("Syncopate") }}>Syncopate</li> },
    { value: 'Tangerine', label: <li data-value="Tangerine" data-query="tangerine" Style="font-family: Tangerine; font-weight: 400;" onClick={() => { addElementFontStyle("Tangerine") }}>Tangerine</li> },
    { value: 'Tenor Sans', label: <li data-value="Tenor+Sans" data-query="tenor+sans" Style="font-family: &quot;Tenor Sans&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Tenor Sans") }}>Tenor Sans</li> },
    { value: 'Terminal Dosis Light', label: <li data-value="Terminal+Dosis+Light" data-query="terminal+dosis+light" Style="font-family: &quot;Terminal Dosis Light&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Terminal Dosis Light") }}>Terminal Dosis Light</li> },
    { value: 'The Girl Next Door', label: <li data-value="The+Girl+Next+Door" data-query="the+girl+next+door" Style="font-family: &quot;The Girl Next Door&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("The Girl Next Door") }} class="">The Girl Next Door</li> },
    { value: 'Tinos', label: <li data-value="Tinos" data-query="tinos" Style="font-family: Tinos; font-weight: 400;" onClick={() => { addElementFontStyle("Tinos") }} class="">Tinos</li> },
    { value: 'Ubuntu', label: <li data-value="Ubuntu" data-query="ubuntu" Style="font-family: Ubuntu; font-weight: 400;" onClick={() => { addElementFontStyle("Ubuntu") }}>Ubuntu</li> },
    { value: 'Ultra', label: <li data-value="Ultra" data-query="ultra" Style="font-family: Ultra; font-weight: 400;" onClick={() => { addElementFontStyle("Ultra") }}>Ultra</li> },
    { value: 'Unkempt', label: <li data-value="Unkempt" data-query="unkempt" Style="font-family: Unkempt; font-weight: 400;" onClick={() => { addElementFontStyle("Unkempt") }}>Unkempt</li> },
    { value: 'UnifrakturCook bold', label: <li data-value="UnifrakturCook:bold" data-query="unifrakturcook:bold" Style="font-family: UnifrakturCook; font-weight: bold;" onClick={() => { addElementFontStyle("UnifrakturCook bold") }}>UnifrakturCook bold</li> },
    { value: 'UnifrakturMaguntia', label: <li data-value="UnifrakturMaguntia" data-query="unifrakturmaguntia" Style="font-family: UnifrakturMaguntia; font-weight: 400;" onClick={() => { addElementFontStyle("UnifrakturMaguntia") }}>UnifrakturMaguntia</li> },
    { value: 'Varela', label: <li data-value="Varela" data-query="varela" Style="font-family: Varela; font-weight: 400;" onClick={() => { addElementFontStyle("Varela") }}>Varela</li> },
    { value: 'Varela Round', label: <li data-value="Varela+Round" data-query="varela+round" Style="font-family: &quot;Varela Round&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Varela Round") }}>Varela Round</li> },
    { value: 'Vibur', label: <li data-value="Vibur" data-query="vibur" Style="font-family: Vibur; font-weight: 400;" onClick={() => { addElementFontStyle("Vibur") }}>Vibur</li> },
    { value: 'Vollkorn', label: <li data-value="Vollkorn" data-query="vollkorn" Style="font-family: Vollkorn; font-weight: 400;" onClick={() => { addElementFontStyle("Vollkorn") }}>Vollkorn</li> },
    { value: 'VT323', label: <li data-value="VT323" data-query="vt323" Style="font-family: VT323; font-weight: 400;" onClick={() => { addElementFontStyle("VT323") }}>VT323</li> },
    { value: 'Waiting for the Sunrise', label: <li data-value="Waiting+for+the+Sunrise" data-query="waiting+for+the+sunrise" Style="font-family: &quot;Waiting for the Sunrise&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Waiting for the Sunrise") }}>Waiting for the Sunrise</li> },
    { value: 'Wallpoet', label: <li data-value="Wallpoet" data-query="wallpoet" Style="font-family: Wallpoet; font-weight: 400;" onClick={() => { addElementFontStyle("Wallpoet") }} class="">Wallpoet</li> },
    { value: 'Walter Turncoat', label: <li data-value="Walter+Turncoat" data-query="walter+turncoat" Style="font-family: &quot;Walter Turncoat&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Walter Turncoat") }} class="">Walter Turncoat</li> },
    { value: 'Wire One', label: <li data-value="Wire+One" data-query="wire+one" Style="font-family: &quot;Wire One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Wire One") }}>Wire One</li> },
    { value: 'Yanone Kaffeesatz', label: <li data-value="Yanone+Kaffeesatz" data-query="yanone+kaffeesatz" Style="font-family: &quot;Yanone Kaffeesatz&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Yanone Kaffeesatz") }}>Yanone Kaffeesatz</li> },
    { value: 'Yeseva One', label: <li data-value="Yeseva+One" data-query="yeseva+one" Style="font-family: &quot;Yeseva One&quot;; font-weight: 400;" onClick={() => { addElementFontStyle("Yeseva One") }}>Yeseva One</li> },
    { value: 'Zeyada', label: <li data-value="Zeyada" data-query="zeyada" Style="font-family: Zeyada; font-weight: 400;" onClick={() => { addElementFontStyle("Zeyada") }}>Zeyada</li> },
    { value: 'Montserrat', label: <li data-value="Montserrat" data-query="montserrat" Style="font-family: Montserrat; font-weight: 400;" onClick={() => { addElementFontStyle("Montserrat") }} class="">Montserrat</li> },
    ]
    return (
        <>
            <animated.div id="FontsSidenav" class="leftNav customNav custom_scroll bg-light" style={styles}>
                <a href="javascript:void(0)" class="closebtn" id="closeNavBtn">
                    <i class="fa fa-times" onClick={() => { setOpenFontStyle(false) }} ></i>
                    &nbsp;&nbsp;
                    <span Style=" font-size: 16px; color: #4a5568; font-weight: 700; margin-right:25px "> Fonts</span>
                </a>
                <div class="container" Style="text-align:center;  padding-right: 25px;">
                    <custom>Choose from Google fonts</custom>
                    <div class="fs-drop" Style="display: block;"><div class="fs-search">
                        <ul class="fs-results">
                            <Select options={options} />
                        </ul>
                    </div>
                    </div>
                    <custom>Import custom font (use @import CSS)</custom>
                    <input class="form-control" id="customFontImport" type="text" />
                    <button Style="width: 100%; margin-top: 10px; background-color: rgba(13, 110, 253, 0.1) !important;" type="button" class="btn nav-buttons" id="importFontBtn">Import!</button>
                    <div id="importedFontsBlock"><br /><br /><custom Style="float:left;">Imported Fonts</custom><hr /><div id="importedBtns" Style="display:flex;flex-wrap:wrap;"><button Style="flex-basis: 30%;font-family:Lato;" type="button" class="btn BtnFontOptions" onclick="loadSetFonts(&quot;Lato&quot;)">
                        <div class="font-title">L</div>
                        <div class="font-name">Lato</div>
                    </button><button Style="flex-basis: 30%;font-family:Open Sans;" type="button" class="btn BtnFontOptions" onclick="loadSetFonts(&quot;Open Sans&quot;)">
                            <div class="font-title">O</div>
                            <div class="font-name">Open Sans</div>
                        </button><button Style="flex-basis: 30%;font-family:Montserrat;" type="button" class="btn BtnFontOptions" onclick="loadSetFonts(&quot;Montserrat&quot;)">
                            <div class="font-title">M</div>
                            <div class="font-name">Montserrat</div>
                        </button><button Style="flex-basis: 30%;font-family:Comic Sans MS;" type="button" class="btn BtnFontOptions" onclick="loadSetFonts(&quot;Comic Sans MS&quot;)">
                            <div class="font-title">C</div>
                            <div class="font-name">Comic Sans MS</div>
                        </button><button Style="flex-basis: 30%;font-family:Alexandria;" type="button" class="btn BtnFontOptions" onclick="loadSetFonts(&quot;Alexandria&quot;)">
                            <div class="font-title">A</div>
                            <div class="font-name">Alexandria</div>
                        </button><button Style="flex-basis: 30%;font-family:Alexandria;" type="button" class="btn BtnFontOptions" onclick="loadSetFonts(&quot;Alexandria&quot;)">
                            <div class="font-title">A</div>
                            <div class="font-name">Alexandria</div>
                        </button></div></div>
                    <div id="fontButtons">
                        <div>
                            <br />
                            <br />
                            <custom Style="float:left;">Sans-Serif</custom><hr />
                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Montserrat;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Montserrat') }}>
                                        <div class="font-title">Mo</div>
                                        <div class="font-name">Montserrat</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Open Sans;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Open Sans') }}>
                                        <div class="font-title">Os</div>
                                        <div class="font-name">Open Sans</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Gilroy;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Gilroy') }}>
                                        <div class="font-title">Gi</div>
                                        <div class="font-name">Gilroy</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Inder;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Inder') }}>
                                        <div class="font-title">In</div>
                                        <div class="font-name">Inder</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:SegoeUI;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('SegoeUI') }}>
                                        <div class="font-title">Se</div>
                                        <div class="font-name">SegoeUI</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Roboto;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Roboto') }}>
                                        <div class="font-title">Ro</div>
                                        <div class="font-name">Roboto</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Lato;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Lato') }}>
                                        <div class="font-title">Lo</div>
                                        <div class="font-name">Lato</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Droid Sans;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Droid Sans') }}>
                                        <div class="font-title">Ds</div>
                                        <div class="font-name">Droid Sans</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Fira Sans;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Fira Sans') }}>
                                        <div class="font-title">Fs</div>
                                        <div class="font-name">Fira Sans</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Source Pro;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Source Pro') }}>
                                        <div class="font-title">Sp</div>
                                        <div class="font-name">Source Pro</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Ubuntu;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Ubuntu') }}>
                                        <div class="font-title">Ub</div>
                                        <div class="font-name">Ubuntu</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Noto Sans;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Noto Sans') }}>
                                        <div class="font-title">Ns</div>
                                        <div class="font-name">Noto Sans</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Oxygen;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Oxygen') }}>
                                        <div class="font-title">Ox</div>
                                        <div class="font-name">Oxygen</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Oswald;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Oswald') }}>
                                        <div class="font-title">Os</div>
                                        <div class="font-name">Oswald</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Fjalla One;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Fjalla One') }}>
                                        <div class="font-title">Fj</div>
                                        <div class="font-name">Fjalla One</div>
                                    </button>
                                </div>
                            </div>

                            <br /><br /><custom Style="float:left;">Serif</custom><hr />
                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Poppins;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Poppins') }}>
                                        <div class="font-title">Po</div>
                                        <div class="font-name">Poppins</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Slabo 27;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Slabo 27') }}>
                                        <div class="font-title">Sl</div>
                                        <div class="font-name">Slabo 27</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Roboto Slab;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Roboto Slab') }}>
                                        <div class="font-title">Rs</div>
                                        <div class="font-name">Roboto Slab</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Adamina;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Adamina') }}>
                                        <div class="font-title">Ad</div>
                                        <div class="font-name">Adamina</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Crete Round;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Crete Round') }}>
                                        <div class="font-title">Cr</div>
                                        <div class="font-name">Crete Round</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Domine;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Domine') }}>
                                        <div class="font-title">Do</div>
                                        <div class="font-name">Domine</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Droid Serif;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Droid Serif') }}>
                                        <div class="font-title">Ds</div>
                                        <div class="font-name">Droid Serif</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Baskerville;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Baskerville') }}>
                                        <div class="font-title">Bv</div>
                                        <div class="font-name">Baskerville</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Lora;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Lora') }}>
                                        <div class="font-title">La</div>
                                        <div class="font-name">Lora</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <br /><br />
                            <custom Style="float:left;">Display</custom><hr />
                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Merriweather;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Merriweather') }}>
                                        <div class="font-title">Me</div>
                                        <div class="font-name">Merriweather</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Playfair;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Playfair') }}>
                                        <div class="font-title">Pf</div>
                                        <div class="font-name">Playfair</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Old Standard;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Old Standard') }}>
                                        <div class="font-title">Ol</div>
                                        <div class="font-name">Old Standard</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Poiret One;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Poiret One') }}>
                                        <div class="font-title">Po</div>
                                        <div class="font-name">Poiret One</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Pompiere;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Pompiere') }}>
                                        <div class="font-title">Pi</div>
                                        <div class="font-name">Pompiere</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Alfa Slab One;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Alfa Slab One') }}>
                                        <div class="font-title">As</div>
                                        <div class="font-name">Alfa Slab One</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Arima Madurai;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Arima Madurai') }}>
                                        <div class="font-title">Am</div>
                                        <div class="font-name">Arima Madurai</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Baloo Bhaina;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Baloo Bhaina') }}>
                                        <div class="font-title">Bb</div>
                                        <div class="font-name">Baloo Bhaina</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Fredoka One;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Fredoka One') }}>
                                        <div class="font-title">Fr</div>
                                        <div class="font-name">Fredoka One</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Lobster;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Lobster') }}>
                                        <div class="font-title">Lb</div>
                                        <div class="font-name">Lobster</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Passion One;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Passion One') }}>
                                        <div class="font-title">Po</div>
                                        <div class="font-name">Passion One</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Patua One;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Patua One') }}>
                                        <div class="font-title">Pa</div>
                                        <div class="font-name">Patua One</div>
                                    </button>
                                </div>
                            </div>

                            <br /><br /><custom Style="float:left;">Handwriting</custom><hr />
                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Ranga;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Ranga') }}>
                                        <div class="font-title">Ra</div>
                                        <div class="font-name">Ranga</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Special Elite;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Special Elite') }}>
                                        <div class="font-title">Se</div>
                                        <div class="font-name">Special Elite</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Graduate;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Graduate') }}>
                                        <div class="font-title">Gr</div>
                                        <div class="font-name">Graduate</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Patrick Hand;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Patrick Hand') }}>
                                        <div class="font-title">Ph</div>
                                        <div class="font-name">Patrick Hand</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Patrick Hand SC;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Patrick Hand SC') }}>
                                        <div class="font-title">PhS</div>
                                        <div class="font-name">Patrick Hand SC</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Alex Brush;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Alex Brush') }}>
                                        <div class="font-title">Ab</div>
                                        <div class="font-name">Alex Brush</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Caveat;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Caveat') }}>
                                        <div class="font-title">Cv</div>
                                        <div class="font-name">Caveat</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Dancing Script;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Dancing Script') }}>
                                        <div class="font-title">Ds</div>
                                        <div class="font-name">Dancing Script</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Gloria Hallelujah;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Gloria Hallelujah') }}>
                                        <div class="font-title">Gh</div>
                                        <div class="font-name">Gloria Hallelujah</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Handlee;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Handlee') }}>
                                        <div class="font-title">Hl</div>
                                        <div class="font-name">Handlee</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Just Me Again Down Here;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Just Me Again Down Here') }}>
                                        <div class="font-title">Jm</div>
                                        <div class="font-name">Just Me Again Down Here</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Pangolin;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Pangolin') }}>
                                        <div class="font-title">Pa</div>
                                        <div class="font-name">Pangolin</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <br /><br /><custom Style="float:left;">MonoSpace</custom><hr />
                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Reenie Beanie;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Reenie Beanie') }}>
                                        <div class="font-title">Rb</div>
                                        <div class="font-name">Reenie Beanie</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Rock Salt;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Rock Salt') }}>
                                        <div class="font-title">Rs</div>
                                        <div class="font-name">Rock Salt</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Into Light Two;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Into Light Two') }}>
                                        <div class="font-title">Li</div>
                                        <div class="font-name">Into Light Two</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Cousine;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Cousine') }}>
                                        <div class="font-title">Co</div>
                                        <div class="font-name">Cousine</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Droid Mono;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Droid Mono') }}>
                                        <div class="font-title">Dm</div>
                                        <div class="font-name">Droid Mono</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Inconsolata;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Inconsolata') }}>
                                        <div class="font-title">Ic</div>
                                        <div class="font-name">Inconsolata</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Overpass Mono;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Overpass Mono') }}>
                                        <div class="font-title">Om</div>
                                        <div class="font-name">Overpass Mono</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:PT Mono;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('PT Mono') }}>
                                        <div class="font-title">Pm</div>
                                        <div class="font-name">PT Mono</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Roboto Mono;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Roboto Mono') }}>
                                        <div class="font-title">Rm</div>
                                        <div class="font-name">Roboto Mono</div>
                                    </button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-4">
                                    <button Style="font-family:Source Code;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Source Code') }}>
                                        <div class="font-title">Sc</div>
                                        <div class="font-name">Source Code</div>
                                    </button>
                                </div>




                                <div class="col-md-4">
                                    <button Style="font-family:Ubuntu Mono;" type="button" class="btn BtnFontOptions" onClick={() => { addElementFontStyle('Ubuntu Mono') }}>
                                        <div class="font-title">Um</div>
                                        <div class="font-name">Ubuntu Mono</div>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </animated.div>
        </>
    )
}

export default ElementFontStyle