import { useState, useMemo, useEffect, createContext, lazy, Suspense } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Container } from '../dndcomponent/Container';
import { useLocation } from 'react-router';
import { Base64 } from 'js-base64';
// import DummyNav from '../components/DummyNav';
// import Heading from '../dndcomponent/createdcomponent/ElementComponentsCreated/TextComponents/Heading';
const Navbar = lazy(() => import('../components/Navbar'));
const Sidebar = lazy(() => import('../components/Sidebar'));
const ManageSidebar = lazy(() => import('../components/ManageSidebar'));
const AllFunnelData= lazy(() => import('../components/AllFunnelData'));
const LeftNavbar = lazy(() => import('../components/LeftNavbar')); 

const HomeData = createContext()
function Home({ baseUrl }) {
  // Set rightside bar section and open state
  const [sideNav, setSideNav] = useState()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  // Set managerightside bar section and open state
  const [manageSideBar, setManageSideBar] = useState()
  const [manageSidebarOpen, setManageSidebarOpen] = useState(false)
  // Section or all page JSON data
  const [sections, setSections] = useState([])
  // Set section id value
  const [sectionIdCount, setSectionIdCount] = useState(0)
  const [addSectionIndex, setAddSectionIndex] = useState(sections.length)
  const [columnIdTrack, setColumnIdTrack] = useState()
  // Section Edit Bar
  const [enableEditSection, setEnableEditSection] = useState(false)
  // Row Edit Bar
  const [enableRowSection, setEnableRowSection] = useState(false)
  // Column Edit bar
  const [enableColumnBar, setEnableColumnBar] = useState(false)
  // Element Edit bar
  const [elementEditBar, setElementEditBar] = useState(false)


  const [cssAddingBarByClick, setCssAddingBarByClick] = useState(0)
  // Row Id tracking
  const [rowId, setRowId] = useState(0)
  const [elementIdCount, setElementIdCount] = useState(0)

  const [rowchange, setRowchange] = useState()
  const [change, setChange] = useState(false)

  const [enableDrop, setEnabledrop] = useState(true)

  const [addRowIndex, setAddRowIndex] = useState()
  const [addColumnIndex, setAddColumnIndex] = useState()

  // Set preview
  const [preview, setPreview] = useState(false)

  const [elementType, setElementType] = useState()

  const [openFunnelData, setOpenFunnelData] = useState(false)

  const [funnelId, setFunnelId] = useState()
  const [oldTitle, setOldTitle] = useState()

  const [enableTabs, setEnableTabs] = useState(false)
  const [tabsSection, setTabSection] = useState([])

  const [allImg, setAllImg] = useState([{ image_id: "1", image_path: "63/images/22912.png" }, { image_id: "2", image_path: "63/images/31664.png" }, { image_id: "3", image_path: "63/images/33799.png" }])

  const [mobileView, setMobileView] = useState(false)
  const [tabView, setTabView] = useState(false)

  const [pathData, setPathData] = useState()

  const [progress, setProgress] = useState(5)

  const [saving, setSaving] = useState(false)

  const [attachedImage, setAttachedImage] = useState([])

  const [backgroungData, setBackgroungData] = useState({ "mobileBg": "", "desktopBg": "" })

  const [undoComponent, setUndoComponent] = useState([])
  const [redoComponent, setRedoComponent] = useState([])


  const [saveSection, setSaveSection] = useState([])

  const [pro, setPro] = useState(false)

  const [proElement, setProElement] = useState([])

  const [upgradeClick, setUpgradeClick]=useState(false)

  const [enableColumnResize, setEnableColumnResize] = useState(false)

  var sideElement = useMemo(() => {
    return <Sidebar sideNav={sideNav} undoComponent={undoComponent} setUndoComponent={setUndoComponent} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} addSectionIndex={addSectionIndex} sections={enableTabs ? tabsSection : sections} setSections={enableTabs ? setTabSection : setSections} sectionIdCount={sectionIdCount} setSectionIdCount={setSectionIdCount} setRowchange={setRowchange} setAddSectionIndex={setAddSectionIndex} enableCssBarClick={enableCssBarClick} setRowId={setRowId} rowId={rowId} addRowIndex={addRowIndex} addColumnIndex={addColumnIndex} columnIdTrack={columnIdTrack} elementIdCount={elementIdCount} setElementIdCount={setElementIdCount} setAddColumnIndex={setAddColumnIndex} setAddRowIndex={setAddRowIndex} setElementType={setElementType} enableTabs={enableTabs} funnelId={funnelId} />
  }, [addSectionIndex, sidebarOpen])
  useEffect(() => {
    if (sections.length === 1) {
      setAddSectionIndex(1)
    }
    if (sections.length > 1) {
      setAddSectionIndex(sections.length - 1)
    }
  }, [])

  useEffect(() => {
    console.log(addSectionIndex)
  }, [addSectionIndex])
  
  function enableCssBarClick(data) {
    setCssAddingBarByClick(data)
  }

  const location = useLocation();
  async function getFunnelData(url, user_id) {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        user_id: user_id,
        funnel_id: Base64.decode(location.pathname.slice(1)),
      })
    })
    return response.json();
  }

  async function fetchImg() {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/php/viewImages.php`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        funnel_id: Base64.decode(location.pathname.slice(1))
      })
    })
    if (response.ok) {
      return response.json()
    }
    throw new Error('Something went wrong');
  }
  useEffect(() => {
    document.getElementById('progressBarRenderBtn').click()
    var progressData = 5
    const progressInterval = setInterval(() => {
      progressData = progressData + 2
      setProgress(progressData)
      if (progressData === 83) {
        clearInterval(progressInterval)
      }
    }, 1000);
    getFunnelData(`${process.env.REACT_APP_BASE_URL}/php/view.php`, 2289).then((data) => {
      if (data) {
        setFunnelId(Base64.decode(location.pathname.slice(1)))
        setOldTitle(data.title)
        setPathData(data.back_path)
        if (data.savesectiondata !== null) {
          setSaveSection(JSON.parse(data.savesectiondata).savedSectionData)
        }
        var jsonData = data.jsondata.replaceAll('\t', '    ')
        setSections(JSON.parse(jsonData).htmlData)
        setSectionIdCount(Number(JSON.parse(data.jsondata).sectionCount) + 1)
        setRowId(Number(JSON.parse(data.jsondata).rowCount))
        setElementIdCount(Number(JSON.parse(data.jsondata).elementCount)+1)
        if (JSON.parse(data.jsondata).backgroundImageData) {
          setBackgroungData(JSON.parse(data.jsondata).backgroundImageData)
        }
        if (JSON.parse(data.jsondata).attachedImage) {
          setAttachedImage(JSON.parse(data.jsondata).attachedImage)
        }
        var subscriptionElement=JSON.parse(data.subscriptionElements)
        setProElement(subscriptionElement)
        if(data.subscription.expire <= Date.now()){
          setPro(false)
        }
        else{
          setPro(true)
        }
        setProgress(100)
        clearInterval(progressInterval);
        setTimeout(() => {
          setProgress(5)
          document.getElementById('progressBarRenderBtn').click()
        }, 1000);
      }
    }).catch((error)=>{
      console.log(error)
      // window.location.href=process.env.REACT_APP_MAIN_URL
    })
  },[])

  useEffect(() => {
    fetchImg().then((imgdata) => {
      setAllImg(imgdata.image_path)
    })
  }, [])

  useEffect(() => {
    if (saveSection === null) {
      setSaveSection([])
    }
  }, [])

  function upgrade(){
    setUpgradeClick(true);
    setTimeout(() => {
      document.getElementById('saveBtnLeftNav').click();
    }, 500);
    document.getElementById('upgradeModalBtn').click();
  }


  return (
    <div>
        <HomeData.Provider value={{ saveSection, setSaveSection, preview, elementIdCount, pro, proElement, setUpgradeClick, upgradeClick, setEnableColumnResize, enableColumnResize, saving }} >
      
          <button type="button" class="btn btn-primary d-none" id="upgradeModalBtn" data-bs-toggle="modal" data-bs-target="#upgradeModal">
            Launch static backdrop modal
          </button>

          <div class="modal fade" id="upgradeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                
                <div class="modal-body">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" Style="position:absolute; right:10px"></button>
                  <h2>Get Premium</h2>
                  <p>Please upgrade to use these premium elements</p>
                  <button className='btn btn-primary' onClick={upgrade}>Upgrade</button>
                </div>
              </div>
            </div>
          </div>
          <div className="Home">

            <LeftNavbar progress={progress} backgroungData={backgroungData} setTabView={setTabView} setMobileView={setMobileView} attachedImage={attachedImage} setSaving={setSaving} setProgress={setProgress} sectionIdCount={sectionIdCount} rowId={rowId} sections={sections} sideNav={sideNav} setSideNav={setSideNav} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} setManageSideBar={setManageSideBar} setManageSidebarOpen={setManageSidebarOpen} preview={preview} setPreview={setPreview} setOpenFunnelData={setOpenFunnelData} openFunnelData={openFunnelData} funnelId={funnelId} oldTitle={oldTitle} />
            <Navbar setSections={setSections} redoComponent={redoComponent} setRedoComponent={setRedoComponent} undoComponent={undoComponent} setUndoComponent={setUndoComponent} progress={progress} backgroungData={backgroungData} setBackgroungData={setBackgroungData} attachedImage={attachedImage} setAttachedImage={setAttachedImage} saving={saving} allImg={allImg} setAllImg={setAllImg} setRowchange={setRowchange} setProgress={setProgress} sectionIdCount={sectionIdCount} rowId={rowId} sections={sections} setSideNav={setSideNav} setSidebarOpen={setSidebarOpen} setManageSideBar={setManageSideBar} setManageSidebarOpen={setManageSidebarOpen} preview={preview} setPreview={setPreview} setOpenFunnelData={setOpenFunnelData} openFunnelData={openFunnelData} funnelId={funnelId} oldTitle={oldTitle} setMobileView={setMobileView} mobileView={mobileView} tabView={tabView} setTabView={setTabView} pathData={pathData} />
            <DndProvider backend={HTML5Backend}>
                {sidebarOpen ? sideElement : ''}
                {openFunnelData ? <AllFunnelData setOpenFunnelData={setOpenFunnelData} openFunnelData={openFunnelData} setSections={setSections} setSectionIdCount={setSectionIdCount} setRowId={setRowId} /> : ''}
                {manageSidebarOpen ? <ManageSidebar manageSideBar={manageSideBar} setManageSidebarOpen={setManageSidebarOpen} /> : ''}
              <Suspense fallback={<div>Loading...</div>}>
                <Container containType={'container'} attachedImage={attachedImage} backgroungData={backgroungData} setAttachedImage={setAttachedImage} setPreview={setPreview} sections={sections} setSections={setSections} setRowchange={setRowchange} rowchange={rowchange} change={change} setChange={setChange} sectionIdCount={sectionIdCount} setSectionIdCount={setSectionIdCount} setAddSectionIndex={setAddSectionIndex} setSidebarOpen={setSidebarOpen} enableEditSection={enableEditSection} setEnableEditSection={setEnableEditSection} cssAddingBarByClick={cssAddingBarByClick} setSideNav={setSideNav} setRowId={setRowId} rowId={rowId} setEnabledrop={setEnabledrop} enableDrop={enableDrop} enableRowSection={enableRowSection} setEnableRowSection={setEnableRowSection} setEnableColumnBar={setEnableColumnBar} enableColumnBar={enableColumnBar} elementIdCount={elementIdCount} setElementIdCount={setElementIdCount} elementEditBar={elementEditBar} setElementEditBar={setElementEditBar} setAddRowIndex={setAddRowIndex} setAddColumnIndex={setAddColumnIndex} setColumnIdTrack={setColumnIdTrack} columnIdTrack={columnIdTrack} preview={preview} setElementType={setElementType} elementType={elementType} sidebarOpen={sidebarOpen} openFunnelData={openFunnelData} tabsSection={tabsSection} enableTabs={enableTabs} setEnableTabs={setEnableTabs} setTabSection={setTabSection} funnelId={funnelId} allImg={allImg} setAllImg={setAllImg} mobileView={mobileView} tabView={tabView} saving={saving} undoComponent={undoComponent} setUndoComponent={setUndoComponent} />
              </Suspense>
            </DndProvider>
          </div>
        </HomeData.Provider>
    </div>
  );
}

export default Home;
export { HomeData };