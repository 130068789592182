import React, { useEffect } from 'react'
import { TextField} from "@mui/material";

function ElementVideoAdd({ setElementCss, elementCss }) {
    useEffect(() => {
        var embadedAddedCss = elementCss
        if (embadedAddedCss.elementVideoURL.includes("https://www.youtube.com/")) {
            let index = embadedAddedCss.elementVideoURL.indexOf("https://www.youtube.com/watch?v=");
            if (index === 0) {
                embadedAddedCss.elementVideoEmbaded = embadedAddedCss.elementVideoURL.slice(32)

            }
            if (embadedAddedCss.elementVideoURL.slice(32).length === 11 && embadedAddedCss.elementVideoURL.includes("https://www.youtube.com/watch?v=")) {
                embadedAddedCss.youtubeValidUrl = true
            }
            else {
                embadedAddedCss.youtubeValidUrl = false
            }
        }
        else if (embadedAddedCss.elementVideoURL.includes("https://vimeo.com/")) {
            let index = embadedAddedCss.elementVideoURL.indexOf("https://vimeo.com/");
            if (index === 0) {
                embadedAddedCss.elementVideoEmbaded = embadedAddedCss.elementVideoURL.slice(18)
            }
        }
        setElementCss(embadedAddedCss)
    }, [elementCss.elementVideoURL])
    return (
        <>
            <TextField  label="Video URL" variant="outlined" size="small"  name='elementVideoURL' value={elementCss.elementVideoURL} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
            <p id="errorMessage" Style="color:red;"></p>
        </>
    )
}

export default ElementVideoAdd