import React, { useEffect } from 'react'

function Survey({ size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType }) {

    useEffect(() => {
        setTimeout(() => {
            document.getElementById(`firstBtn0${id}`).click()
        }, 500);
    }, [])

    return (
        <div>
            <div selected="true" className="noBorder Element globalHeaderFont globalHeaderFont ui-draggable" submissiontext="Thanks for filling out this survey, hit submit to finish." elementtype="Survey" id="Survey71760" margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="0" margin-bottom-desktop="0" Style="padding: 10px;  border-radius: 0px;  text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; margin-top: 0px; margin-bottom: 0px; outline: none;" name="Survey" theme="#ff9900">
                {<>
                    <h6 Style="text-align: center;">Step <span className="currentStep">1</span> of <span className="totalStep">1</span></h6>
                    <nav action="gotostep">
                        <div class="tab-v2">
                            <div className="nav nav-tabs globalHeaderFont" id="myTab" role="tablist" Style={`justify-content: center; height: 53px; border:none;align-items: center;`}>
                                <ul class="nav nav-tabs mb-3" id="ex1" role="tablist">
                                    {element.elementObject ? element.elementObject.surveyData.map((data, index) => {
                                        return <li class="nav-item" role="presentation">
                                            <button subtext="SubText Goes Here" Style={`border-radius:25px; background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'}; color:${element.elementObject ? element.elementObject.tabtextcolor : 'black'}`} question="Question Goes Here" tabtype="buttons" tabinputname="button1" className="surveyTabTitle surveyTabBtn customBtnTab active show" class="nav-link " data-bs-toggle="tab" href={`#survey-data${index}${id}`} id={`firstBtn${index}${id}`} role="tab" aria-selected="true">{data.tabtitle}</button>
                                        </li>
                                    }) : <li class="nav-item" role="presentation">
                                        <button subtext="SubText Goes Here" Style={`border-radius:25px; background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'}; color:${element.elementObject ? element.elementObject.tabtextcolor : 'black'}`} question="Question Goes Here" tabtype="buttons" tabinputname="button1" className="surveyTabTitle surveyTabBtn customBtnTab active show" class="nav-link " data-bs-toggle="tab" href="#survey-data" id='firstBtn' role="tab" aria-selected="true">1</button>
                                    </li>}
                                    {/* <li class="nav-item" role="presentation">
                                        <button subtext="SubText Goes Here" Style={`border-radius:25px; background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'}; color:${element.elementObject ? element.elementObject.tabtextcolor : 'black'}`} question="Question Goes Here" tabtype="buttons" tabinputname="button1" className="surveyTabTitle surveyTabBtn customBtnTab active show" class="nav-link " data-bs-toggle="tab" href="#survey-data" id='firstBtn' role="tab" aria-selected="true">1</button>
                                    </li> */}
                                    <li class="nav-item" role="presentation">
                                        <button subtext="SubText Goes Here" Style={`border-radius:25px; background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'}; color:${element.elementObject ? element.elementObject.tabtextcolor : 'black'}`} question="Question Goes Here" tabtype="buttons" tabinputname="button1" className="surveyTabTitle surveyTabBtn customBtnTab active show" class="nav-link" data-bs-toggle="tab" href={`#nav-final${id}`} role="tab" aria-selected="true">Submit</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <style>
            #Survey71760 .surveyTabBtn.active, #Survey71760 .surveyTabBtn.completed {
                background-color: #ff9900;
                border: 2px solid #bcc1c7;
            }
            </style> */}
                    </nav>
                </>}
                <div class="tab-content">
                    {element.elementObject ? element.elementObject.surveyData.map((data, index) => {
                        return <><div className={`custom-tab-content tab-content tab-pane fade in  global${element.elementObject? element.elementObject.fonttype:''}Font`} id={`survey-data${index}${id}`} Style={` font-family:${element.elementObject && element.elementObject.fonttype==="Custom" ? element.elementObject.fontstyle : ''}`}>
                            <div className="tab-pane fade show active text-center" id="nav-1d2d6" role="tabpanel" Style="padding: 15px;">
                                <h3 className="questionBlock" Style={`text-align: center;font-size:${element.elementObject ? element.elementObject.questionfontsize : '30'}px; overflow-wrap: break-word;`}>{data.question}</h3>
                                <h6 className="subTextBlock" Style={`text-align: center;font-size:${element.elementObject ? element.elementObject.subtextfontsize : '30'}px; overflow-wrap: break-word;`}>{data.subtext}</h6>
                                {data.tabtype === 'buttons' ? <div className="btnsHolder d-flex flex-wrap" >
                                    {data.buttonsData.map((buttonData) => {
                                        return <button formvalue={buttonData.buttonvalue} id="surveyBtn-7bf85" className="btn rounded text-white m-2 tabButtons" Style={`background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'};padding:40px 0px; flex: 1 0 22%;font-size:${element.elementObject ? element.elementObject.buttontextfontsize : '30'}px; overflow-wrap: break-word; font-family: Gilroy !important;`}>{buttonData.buttontext}</button>
                                    })}
                                </div> : ''}
                                {data.tabtype === 'inputs' ? <>
                                    <form>
                                        {data.inputData.map((data2) => {
                                            return <div class="inputBlocks" id="surveyInput-3fda7" Style="text-align:undefined">
                                                <label>{data2.inputlabel}</label>
                                                <input type="text" class="form-control tabInputs mb-3" maxlength="23" name={data2.inputfield} required={data2.inputrequired} />
                                            </div>
                                        })}
                                    </form>
                                </> : ''}
                            </div>
                        </div>
                            {data.tabtype === 'inputs' ? <div className="tab-pane fade text-center tab-pane fade in pt-40" id={`nav-final${id}`} role="tabpanel" Style='padding: 40px 5px;'>
                                <h3 className="questionBlock" Style="text-align: center;">{element.elementObject ? element.elementObject.submissiontext : 'Thanks for filling out this survey, hit submit to finish.'}</h3>
                                <button className="btn btn-success" redirect={element.elementObject ? element.elementObject.surveyurl:''} onclick="submitCustomForm(this,70,406,1618)">Submit</button>
                            </div> : ''}
                            {data.tabtype === 'buttons' ? <div className="tab-pane fade text-center tab-pane fade in pt-40" id={`nav-final${id}`} role="tabpanel" Style='padding: 40px 5px;'>
                                <h3 className="questionBlock" Style="text-align: center;">{element.elementObject ? element.elementObject.submissiontext : 'Thanks for filling out this survey, hit submit to finish.'}</h3>
                                <button className="btn btn-success" redirect={element.elementObject ? element.elementObject.surveyurl:''} onclick="submitCustomForm(this,70,406,1618)">Submit</button>
                            </div> : ''}
                        </>
                    }) : <div className="custom-tab-content tab-content">
                        <div className="tab-pane fade show active text-center" id="nav-1d2d6" role="tabpanel" Style="padding: 15px;">
                            <h3 className="questionBlock" Style={`text-align: center;font-size:${element.elementObject ? element.elementObject.questionfontsize : '30'}px`}>Question Goes Here</h3>
                            <h6 className="subTextBlock" Style={`text-align: center;font-size:${element.elementObject ? element.elementObject.subtextfontsize : '30'}px`}>SubText Goes Here</h6>
                            <div className="btnsHolder d-flex flex-wrap" >
                                <button formvalue="button 1" id="surveyBtn-7bf85" className="btn rounded text-white m-2 tabButtons" Style={`background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'};padding:40px 0px; flex: 1 0 22%;font-size:${element.elementObject ? element.elementObject.buttontextfontsize : '30'}px`}>Option 1</button>
                                <button formvalue="button 2" id="surveyBtn-d6d9b" className="btn rounded text-white m-2 tabButtons" Style={`background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'};padding:40px 0px; flex: 1 0 22%;font-size:${element.elementObject ? element.elementObject.buttontextfontsize : '30'}px`}>Option 2</button>
                                <button formvalue="button 3" id="surveyBtn-8435c" className="btn rounded text-white m-2 tabButtons" Style={`background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'};padding:40px 0px; flex: 1 0 22%;font-size:${element.elementObject ? element.elementObject.buttontextfontsize : '30'}px`}>Option 3</button>
                                <button formvalue="button 4" id="surveyBtn-45407" className="btn rounded text-white m-2 tabButtons" Style={`background-color:${element.elementObject ? element.elementObject.themecolor : 'rgba(255,152,0,1)'};padding:40px 0px; flex: 1 0 22%;font-size:${element.elementObject ? element.elementObject.buttontextfontsize : '30'}px`}>Option 4</button>
                            </div>
                        </div>
                    </div>}

                </div>
            </div>
        </div>
    )
}

export default Survey