import './App.css';
import { useEffect} from 'react';
import './Main.css'
import Home from './NavigationComponents/Home';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import AboutUs from './NavigationComponents/AboutUs';
// import ContactUs from './NavigationComponents/ContactUs';

function App() {
// const Navigate=useNavigate()
const baseUrl=process.env.REACT_APP_BASE_URL


  return (
    <div className="App">
      <BrowserRouter >
        <Routes >
          <Route path='/:funnelid' element={<Home baseUrl={baseUrl} RedirectMainPage={RedirectMainPage}/>} />
          <Route path='/' element={<RedirectMainPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function RedirectMainPage(){
  useEffect(() => {
    window.location.href=process.env.REACT_APP_MAIN_URL
  }, [])
  
  return(
    <div>Redirecting....</div>
  )
}

export default App;
