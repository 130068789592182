import React, { useState,useEffect } from 'react'

function SliderShowcase(
  { id,
    addCustomStyle,
    element,
    activeElement,
    setElementType }
) {
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('slidershowcase')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }
  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])
  
  return (
    <div  id={id} onClick={handleEditorClick}>
      <div>
        <marquee behavior="alternate" direction={element.elementObject ? element.elementObject.sliderDirection : ''} Style="">
          {element.elementObject ? element.elementObject.sliderShowCase.map((elem) => {
            return <span class="marqueeItem" name="Image 1" >
              <a href={elem.sliderRedirect==="URL"?elem.sliderRedirectURL:'#'} target={elem.sliderRedirect==="URL" && elem.sliderRedirectURL?"_blank":''} Style="display: inline-block;"><img class="zoom" src={elem.url} Style={`margin-right:20px; border-radius:5px; height:${elem.height}px; width:${elem.width}px;`} alt={elem.altText} /></a>
            </span>
          }) : ''}
        </marquee>
      </div>
    </div>
  )
}

export default SliderShowcase