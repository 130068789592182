import React, { useState, useEffect, useMemo, memo } from 'react'
import { ItemTypes } from '../ItemTypes';
import Element from './Element';
import { useDrop } from 'react-dnd'
import RowContainerDustbin from './RowContainerDustbin';
import Heading from './ElementComponentsCreated/TextComponents/Heading';
import BulletList from './ElementComponentsCreated/TextComponents/BulletList';
import SubHeading from './ElementComponentsCreated/TextComponents/SubHeading';
import ImageList from './ElementComponentsCreated/TextComponents/ImageList';
import Paragraph from './ElementComponentsCreated/TextComponents/Paragraph';
import Icon from './ElementComponentsCreated/TextComponents/Icon';
import Video from './ElementComponentsCreated/MediaComponents/Video';
import Image from './ElementComponentsCreated/MediaComponents/Image';
import ImagePopUp from './ElementComponentsCreated/MediaComponents/ImagePopUp';
import VideoPopUp from './ElementComponentsCreated/MediaComponents/VideoPopUp';
import ImageFeature from './ElementComponentsCreated/MediaComponents/ImageFeature';
import SliderShowcase from './ElementComponentsCreated/MediaComponents/SliderShowcase';
import Button from './ElementComponentsCreated/ButtonLinkComponents/Button';
import Link from './ElementComponentsCreated/ButtonLinkComponents/Link';
import SocialShare from './ElementComponentsCreated/ButtonLinkComponents/SocialShare';
import InputFields from './ElementComponentsCreated/Forms/InputFields';
import TextAreaField from './ElementComponentsCreated/Forms/TextAreaField';
import CheckBox from './ElementComponentsCreated/Forms/CheckBox';
import SelectField from './ElementComponentsCreated/Forms/SelectField';
import Survey from './ElementComponentsCreated/Forms/Survey';
import PremadeForm from './ElementComponentsCreated/Forms/PremadeForm';
import Countdown from './ElementComponentsCreated/CountdownComponents/CountDownElem';
import Minutetimer from './ElementComponentsCreated/CountdownComponents/Minutetimer';
import Daytimer from './ElementComponentsCreated/CountdownComponents/Daytimer';
import Divider from './ElementComponentsCreated/BlockComponents/Divider';
import ProgressBar from './ElementComponentsCreated/BlockComponents/ProgressBar';
import TestimonialElement from '../../components/sidebarcomponent/ElementComponents/BlocksComponents/TestimonialElement';


const RowContainer = memo(function RowContainer({
    containerClass,
    id,
    key,
    elementclass,
    index,
    rowId,
    sections,
    rowchange,
    rows,
    row,
    section,
    column,
    setSections,
    rowcontainer,
    deleteRow,
    addCustomStyle,
    columnStyle,
    columnBgElem,
    setEnableColumnResize,
    enableColumnResize,
    setRowchange,
    columnIdTrack,
    sectionIdTrack,
    rowIdTrack,
    detectRow,
    columnIndex,
    columnSize,
    setColumnSize,
    setColumnCss,
    columnCss,
    targetColumnSize,
    nextColumnSize,
    setElementIdCount,
    elementIdCount,
    setColumnChange,
    setSidebarOpen,
    rowIndex,
    sectionIndex,
    activeElement,
    prevElement,
    setElementName,
    elementName,
    setElementHeading,
    setElementCss,
    elementCss,
    setAddSectionIndex,
    setSideNav,
    setAddRowIndex,
    setAddColumnIndex,
    preview,
    setElementType,
    elementType,
    nextContainer,
    prevContainer,
    sectionIdCount,

    setSectionIdCount,
    enableEditSection,
    setEnableEditSection,
    cssAddingBarByClick,
    setRowId,
    setEnabledrop,
    enableDrop,
    enableRowSection,
    setEnableRowSection,
    setEnableColumnBar,
    enableColumnBar,
    elementEditBar,
    setElementEditBar,
    setColumnIdTrack,
    sidebarOpen,
    openFunnelData,
    rowStateId,
    setRowStateId,

    tabsSection,
    enableTabs,
    setEnableTabs,
    setTabSection,
    mobileView,
    saving,
    undoComponent,
    setUndoComponent,
}) {

    const [enableForm, setEnableForm] = useState(false)

    // const [size, setSize] = useState({ width: columnSize, position: "relative" });
    // const [enableResize, setEnableResize] = useState(true)

    //HandleDrop function
    const handleDrop = (item) => {
        setSidebarOpen(false)
        var sectionData = sections;
        var sectionIndex;
        var rowIndex;
        var columnIndex;
        sectionData.forEach((element) => {
            element.RowComponent.forEach((elem) => {
                elem.RowContainer.forEach((elem2) => {
                    if (elem2.id === id) {
                        sectionIndex = sectionData.indexOf(element)
                        rowIndex = element.RowComponent.indexOf(elem)
                        columnIndex = elem.RowContainer.indexOf(elem2)
                    }
                })
            })
        })
        if (item.name === 'headline') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "headline", "elementName": "Header", "value": '<h1><span class="ql-size-medium">Enter your heading</span></h1>', "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "icon": "blank", "iconWidth": "25", "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('headline')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'subheadline') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "subheadline", "elementName": "SubHeader", "value": '<h2><span class="ql-size-medium">Enter your sub-heading</span></h2>', "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "icon": "blank", "iconWidth": "25", "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('subheadline')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'paragraph') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "paragraph", "elementName": "Paragraph", "value": '<p>Enter your Paragraph</p>', "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "icon": "blank", "iconWidth": "25", "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('paragraph')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'bulletlist') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "bulletlist", "elementName": "BulletList", "value": "<ul><li>Enter your list</li></ul>", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "icon": "blank", "iconWidth": "25", "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('bulletlist')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'imagelist') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "imagelist", "elementName": "ImageList", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('imagelist')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'icon') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "icon", "elementName": "Icon", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('icon')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'video') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "video", "elementName": "Video", "value": "", "elementStyle": "min-height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('video')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'image') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "image", "elementName": "Image", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('image')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'imagepopup') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "imagepopup", "elementName": "Image-Popup", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('imagepopup')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'videopopup') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "videopopup", "elementName": "Video-Popup", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('videopopup')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'imagefeature') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "imagefeature", "elementName": "Image-Feature", "headValue": "<h3>Large Call to Action Headline</h3>", "paraValue": "<p>Your Paragraph text goes Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias, numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt, recusandae. here </p>", "elementStyle": "height:120px; width:100%; position:relative", "imageFeatureTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": 'black', "imgFeatureTextColor": 'black' }, }]
            setSections(sectionData)
            setElementType('imagefeature')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'slidershowcase') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "slidershowcase", "elementName": "SliderShowcase", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('slidershowcase')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'button') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "button", "elementName": "Button", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('button')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'link') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "link", "elementName": "Link", "value": "", "elementStyle": " width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('link')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'socialshare') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "socialshare", "elementName": "Share", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('socialshare')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'inputfield') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "inputfield", "elementName": "CustomInput", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('inputfield')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'textarea') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "textarea", "elementName": "CustomTextarea", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('textarea')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'checkbox') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "checkbox", "elementName": "CustomCheckbox", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('checkbox')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'selectfield') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "selectfield", "elementName": "CustomSelect", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('selectfield')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'survey') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "survey", "elementName": "Survey", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('survey')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'countdown') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "countdown", "elementName": "Countdown", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" }]
            setSections(sectionData)
            setElementType('countdown')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'minutetimer') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "minutetimer", "elementName": "Minutetimer", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" }]
            setSections(sectionData)
            setElementType('minutetimer')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'daytimer') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "daytimer", "elementName": "Daytimer", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" }]
            setSections(sectionData)
            setElementType('daytimer')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'premadeform') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "inputfield", "elementName": "Premadeforminput", "value": "", "elementStyle": "padding-top:15px; padding-bottom:15px; padding-left:10px; padding-right:10px; margin-top:0px; margin-bottom:0px; box-shadow:none; background-color: transparent; bordernoBorder: 1px solid #000000; border-radius:0px; color:black; letter-spacing:0; width:100%; position:relative;", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount + 1, "name": "inputfield", "elementName": "Premadeforminput", "value": "", "elementStyle": "padding-top:15px; padding-bottom:15px; padding-left:10px; padding-right:10px; margin-top:0px; margin-bottom:0px; box-shadow:none; background-color: transparent; bordernoBorder: 1px solid #000000; border-radius:0px; color:black; letter-spacing:0; width:100%; position:relative;", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount + 2, "name": "inputfield", "elementName": "Premadeforminput", "value": "", "elementStyle": "padding-top:15px; padding-bottom:15px; padding-left:10px; padding-right:10px; margin-top:0px; margin-bottom:0px; box-shadow:none; background-color: transparent; bordernoBorder: 1px solid #000000; border-radius:0px; color:black; letter-spacing:0; width:100%; position:relative;", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount + 3, "name": "inputfield", "elementName": "Premadeforminput", "value": "", "elementStyle": "padding-top:15px; padding-bottom:15px; padding-left:10px; padding-right:10px; margin-top:0px; margin-bottom:0px; box-shadow:none; background-color: transparent; bordernoBorder: 1px solid #000000; border-radius:0px; color:black; letter-spacing:0; width:100%; position:relative;", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount + 4, "name": "textarea", "elementName": "premadeformtextarea", "value": "", "elementStyle": "padding-top:15px; padding-bottom:15px; padding-left:10px; padding-right:10px; margin-top:0px; margin-bottom:0px; box-shadow:none; background-color: transparent; bordernoBorder: 1px solid #000000; border-radius:0px; color:black; letter-spacing:0; width:100%; position:relative;", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount + 5, "name": "checkbox", "elementName": "premadeformcheckbox", "value": "", "elementStyle": "padding-top:15px; padding-bottom:15px; padding-left:10px; padding-right:10px; margin-top:0px; margin-bottom:0px; box-shadow:none; background-color: transparent; bordernoBorder: 1px solid #000000; border-radius:0px; color:black; letter-spacing:0; width:100%; position:relative;", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount + 6, "name": "button", "elementName": "premadeformbutton", "value": "", "elementStyle": "padding-top:15px; padding-bottom:15px; padding-left:10px; padding-right:10px; margin-top:0px; margin-bottom:0px; box-shadow:none; background-color: transparent; bordernoBorder: 1px solid #000000; border-radius:0px; color:black; letter-spacing:0; width:100%; position:relative;", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('premadeform')
            setEnableForm(true)
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 7)
        }
        else if (item.name === 'divider') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "divider", "elementName": "Divider", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" }]
            setSections(sectionData)
            setElementType('divider')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'progressbar') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "progressbar", "elementName": "Progressbar", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" }]
            setSections(sectionData)
            setElementType('progressbar')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'testimonial') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "testimonial", "elementName": "Testimonial", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" }]
            setSections(sectionData)
            setElementType('testimonial')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'tabs') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "tabs", "elementName": "Tabs", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00", "tabComponent": [{ "id": "sec" + "_" + sectionIdCount + 1, "sectionId": sectionIdCount + 1, "sectionstyle": "", "sectionClass": " container-fluid sectionFull  sectionConstCss", "sectionName": "Section", "RowComponent": [] },] }]
            setSections(sectionData)
            setElementType('tabs')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
            setSectionIdCount(sectionIdCount + 1)
        }
        else if (item.name === 'customjshtml') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "customjshtml", "elementName": "CustomJSHTML", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00", }]
            setSections(sectionData)
            setElementType('customjshtml')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'map') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "map", "elementName": "Map", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00", "mapUrl": '', }]
            setSections(sectionData)
            setElementType('map')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'customtab') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "customtab", "elementName": "Customtab", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, }]
            setSections(sectionData)
            setElementType('customtab')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'carousel') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "carousel", "elementName": "Carousel", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" }]
            setSections(sectionData)
            setElementType('carousel')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else if (item.name === 'singlecarousel') {
            sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer = [...sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer, { "id": id + "elem" + elementIdCount, "name": "singlecarousel", "elementName": "Single Carousel", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" }]
            setSections(sectionData)
            setElementType('singlecarousel')
            setRowchange(elementIdCount)
            setColumnChange(elementIdCount)
            setElementIdCount(elementIdCount + 1)
        }
        else {
            var sectionData = sections
            var srcSectionIndex;
            var srcRowIndex;
            var srcColumnIndex;
            var srcElementIndex;
            var srcElement;
            sectionData.forEach((elem) => {
                elem.RowComponent.forEach((elem1) => {
                    elem1.RowContainer.forEach((elem2) => {
                        elem2.ColumnContainer.forEach((elem3) => {
                            if (elem3.id === item.id) {
                                srcSectionIndex = sectionData.indexOf(elem)
                                srcRowIndex = elem.RowComponent.indexOf(elem1)
                                srcColumnIndex = elem1.RowContainer.indexOf(elem2)
                                srcElementIndex = elem2.ColumnContainer.indexOf(elem3)
                                srcElement = elem3
                                sectionData[srcSectionIndex].RowComponent[srcRowIndex].RowContainer[srcColumnIndex].ColumnContainer.splice(srcElementIndex, 1)
                                sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.push(elem3)
                            }
                        })
                    })
                })
            })
            setSections(sectionData)
            addCustomStyle(`elem_${item.id}`)
            setRowchange(srcColumnIndex)
        }
        
        var componentData = undoComponent
        componentData.push({ "arrayData": JSON.parse(JSON.stringify(sectionData)), "id": id + "elem" + elementIdCount })
        setUndoComponent(componentData)
    }

    useEffect(() => {
        var sectionChangeData = sections
        sectionChangeData.forEach((element) => {
            if (element.id === sectionIdTrack) {
                element.RowComponent.forEach((elem) => {
                    if (elem.id === rowIdTrack) {
                        elem.RowContainer.forEach((elem2) => {
                            if (elem2.id === columnIdTrack) {
                                elem2.ColumnContainer.forEach((elem3) => {
                                    if (activeElement) {
                                        if (elem3.id === activeElement.slice(5)) {
                                            setElementName(elem3.elementName);
                                            if (elem3.elementObject) {
                                                setElementCss(elem3.elementObject)
                                            }
                                            else {
                                                setElementCss({
                                                    "paddingTopMobile": 15,
                                                    "paddingBottomMobile": 15,
                                                    "paddingTop": 15,
                                                    "paddingBottom": 15,
                                                    "paddingLeft": 10,
                                                    "paddingRight": 10,
                                                    "marginTopMobile": 0,
                                                    "marginBottomMobile": 0,
                                                    "marginTop": 0,
                                                    "marginBottom": 0,
                                                    "boxShadow": "none",
                                                    "backgroundImageDesktopSingle": '',
                                                    "backgroundImageDesktopSingleCss": 'background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover',
                                                    "backgroundImageOpacity": 1,
                                                    "fullWidth": false,
                                                    "displayAfterLoading": false,
                                                    "backgroundVideo": "",
                                                    "embededVideoCode": "",
                                                    "youtubeValidUrl": false,
                                                    "BgCarouselImageType": 'bgimage',
                                                    "BgCarouselImage": [{
                                                        "bgImage": '',
                                                        "bgImageText": '',
                                                        "bgImageSubText": ''
                                                    }],
                                                    "displayAfterLoading": 0,
                                                    "border": 'noBorder',
                                                    'animation': '',
                                                    "elementbackgroundcolor": 'transparent',
                                                    "borderstyleCss": {
                                                        "pixel": '1px',
                                                        "type": "solid",
                                                        "color": "#000000"
                                                    },
                                                    "borderRadiusData": '0px',
                                                    "borderRadiusType": 'AllBorderEdges',
                                                    "borderRadius": '0px',
                                                    "btnborder": 'noBorder',
                                                    "btnborderstyleCss": {
                                                        "pixel": '1px',
                                                        "type": "solid",
                                                        "color": "#000000"
                                                    },
                                                    "btnborderRadiusData": '0px',
                                                    "btnborderRadiusType": 'AllBorderEdges',
                                                    "btnborderRadius": '0px',
                                                    "shapetype": 'none',
                                                    "shapecolor": 'rgb(217, 217, 217)',
                                                    "shapewidth": '100',
                                                    "shapeheight": '205',
                                                    "shapeflip": false,
                                                    "shapeinvert": false,
                                                    "shapetopbottom": false,
                                                    "bringtofront": true,
                                                    'desktoprowwidth': 100,
                                                    'mobilerowwidth': 100,
                                                    'transformintocarousel': false,
                                                    'colPerRowMobile': 'none',
                                                    "animationsection": 'none',
                                                    "rowAlign": "center",
                                                    "textColor": "black",
                                                    "mobileFontSize": 25,
                                                    "desktopFontSize": 25,
                                                    "elementLetterSpacing": "0",
                                                    "elementTextShadow": "rgba(0, 0, 0, 0) 0px 0px 0px",
                                                    "elementOpacity": "1",
                                                    "textAlign": "left",
                                                    "imageList": ['This is the first Image List Item', 'This is the second Image List Item', 'This is the third Image List Item'],
                                                    "elementIcon": 'blank',
                                                    "iconSize": 16,
                                                    "imagelistfontweight": 300,
                                                    "separatorColor": 'rgb(255, 116, 2);',
                                                    "iconColor": "rgb(33, 37, 41);",
                                                    "elementVideoURL": "",
                                                    "elementVideoEmbaded": "",
                                                    "autoPlay": false,
                                                    "controls": true,
                                                    "videoWidth": 100,
                                                    "imageURL": 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80',
                                                    "imageWidth": "auto",
                                                    "imageHeight": "auto",
                                                    "imageAltText": "Image Alt Text",
                                                    "imageRadius": "0px",
                                                    "imageRadiusType": "AllBorderEdges",
                                                    "imageBoxShadow": "none",
                                                    "imageBoxBorder": "0px none rgb(33, 37, 41)",
                                                    "imageEditing": "none",
                                                    "imageAnimation": 'none',
                                                    "imagepopupURL": 'https://via.placeholder.com/400x300.png?text=Put%20Your%20Image%20Here',
                                                    "videopopupEmbaded": '',
                                                    "videopopupUrl": "",
                                                    "sliderShowCase": [{ "name": "image 1", "url": "https://via.placeholder.com/400x300.png?text=Put Your Image Here", "height": "", "width": "", "altText": "Image Alt text", "sliderRedirect": "URL", "sliderRedirectURL": "", }, { "name": "image 2", "url": "https://via.placeholder.com/400x300.png?text=Put Your Image Here", "height": "", "width": "", "altText": "Image Alt text", "sliderRedirect": "URL", "sliderRedirectURL": "", }, { "name": "image 3", "url": "https://via.placeholder.com/400x300.png?text=Put Your Image Here", "height": "", "width": "", "altText": "Image Alt text", "sliderRedirect": "URL", "sliderRedirectURL": "", }, { "name": "image 4", "url": "https://via.placeholder.com/400x300.png?text=Put Your Image Here", "height": "", "width": "", "altText": "Image Alt text", "sliderRedirect": "URL", "sliderRedirectURL": "", }, { "name": "image 5", "url": "https://via.placeholder.com/400x300.png?text=Put Your Image Here", "height": "", "width": "", "altText": "Image Alt text", "sliderRedirect": "URL", "sliderRedirectURL": "", }, { "name": "image 6", "url": "https://via.placeholder.com/400x300.png?text=Put Your Image Here", "height": "", "width": "", "altText": "Image Alt text", "sliderRedirect": "URL", "sliderRedirectURL": "", }],
                                                    "sliderDirection": 'right',
                                                    "columnSize": '3',
                                                    "columnOrder": "row",
                                                    "headerMobileFontSize": '30',
                                                    "headerDesktopFontSize": '30',
                                                    "textMobileFontSize": '16',
                                                    "textDesktopFontSize": '16',
                                                    "imgFeatureTextColor": 'black',
                                                    "imgFeatureHeaderColor": 'black',
                                                    "linktext": "Your Link Goes Here",
                                                    "link": "",
                                                    "gotonewpage": true,
                                                    "linkTextColor": 'black',
                                                    "enableTwitter": true,
                                                    "enableFacebook": true,
                                                    "enableLinkedin": true,
                                                    "twitterUrl": '',
                                                    "facebookUrl": '',
                                                    "linkedinUrl": '',
                                                    "headerMobileFontSizeBtn": 20,
                                                    "headerDesktopFontSizeBtn": 20,
                                                    "textMobileFontSizeBtn": 15,
                                                    "textDesktopFontSizeBtn": 15,
                                                    "btntext": 'Click to Sign Up',
                                                    "btnsubtext": '',
                                                    "imageMask": 'none',
                                                    "btnShadow": 'none',
                                                    "btnAnimation": 'none',
                                                    "btnTextFormat": 'none',
                                                    "btnWidth": 'auto',
                                                    "btnBackgroundColor": 'rgba(33,150,243,1)',
                                                    "btnThemeClass": '',
                                                    "btnThemeCss": '',
                                                    "videoThemeCss": '',
                                                    "elementTextIcon": '',
                                                    "selectIconValue":'',
                                                    "inputtype": "EmailAddress",
                                                    "inputplaceholdertext": "Enter your EmailAddress here...",
                                                    "textareaplaceholdertext": "Enter your info here...",
                                                    "selectfieldplaceholdertext": '',
                                                    "showlabel": true,
                                                    "inputlabeltext": "Input Label",
                                                    "textarealabeltext": "Textarea Label",
                                                    "selectfieldlabeltext": "Select Label",
                                                    "inputrequired": 'true',
                                                    "formBackgroundcolor": 'transparent',
                                                    "formLabelColor": 'black',
                                                    "inputTextColor": 'black',
                                                    "formInputMobileSize": 16,
                                                    "formInputDesktopSize": 16,
                                                    "formInputHeight": 38,
                                                    "formInputfontweight": 400,
                                                    "formInputtextalign": 'start',
                                                    "formInputborderradius": "0px",
                                                    "checkBoxInputText": "This is a Checkbox element",
                                                    "lineHeight": 0.7,
                                                    "ckBoxTextShadow": "1",
                                                    "ckBoxLetterSpacing": '0px',
                                                    "selectLabelType": 'allCountries',
                                                    "customSelectLabel": [{ 'value': '', 'text': '' },],
                                                    "tabtextcolor": 'black',
                                                    "themecolor": 'rgba(255,152,0,1)',
                                                    "submissiontext": 'Thanks for filling out this survey, hit submit to finish.',
                                                    "linkaction": 'simpleURLAction',
                                                    "surveyurl": "https://www.youtube.com/",
                                                    "questionfontsize": 30,
                                                    "subtextfontsize": 15,
                                                    "buttontextfontsize": 15,
                                                    "surveyData": [{ "tabtitle": '1', "question": "Question Goes Here", "subtext": 'SubText Goes Here', "fieldname": 'button1', "tabtype": 'buttons', "buttonsData": [{ "buttonvalue": 'Option 1', "buttontext": 'button 1' }, { "buttonvalue": 'Option 2', "buttontext": 'button 2' }, { "buttonvalue": 'Option 3', "buttontext": 'button 3' }, { "buttonvalue": 'Option 4', "buttontext": 'button 4' }], "inputData": [] },],
                                                    "endDate": '',
                                                    "endTime": '',
                                                    "countDownMobFontSize": 48,
                                                    "countDownDeskFontSize": 48,
                                                    "countDownTextMobFontSize": 10,
                                                    "countDownTextDeskFontSize": 10,
                                                    "countdownbgcolor": 'white',
                                                    "countdowntextcolor": 'black',
                                                    "countdownposition": 'center',
                                                    "minuteTimerHour": 0,
                                                    "minuteTimerMinute": 0,
                                                    "minuteTimerSecond": 0,
                                                    "fontstyle": 'Open Sans',
                                                    "fonttype": 'Header',
                                                    "divideralign": 'start',
                                                    "dividerwidth": '100%',
                                                    "dividerlineheight": '1px',
                                                    "dividerheight": 'solid',
                                                    "progresswidth": '60%',
                                                    "progresssize": "25px",
                                                    "progresstext": 'Progressing...',
                                                    "stripeanimation": true,
                                                    "testimonialdata": [{ "name": 'Name1', "desc": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo", "avatar": 'https://i.imgur.com/0slNT2o.jpg', "stars": "sssss" }, { "name": 'Name2', "desc": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo", "avatar": 'https://i.imgur.com/Er0QyhU.png', "stars": "sss" }, { "name": 'Name3', "desc": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo", "avatar": 'https://i.imgur.com/VnbitUs.png', "stars": "ssss" },],
                                                    "carouselDesktopWidth": 100,
                                                    "carouselMobileWidth": 100,
                                                    "carouselImages": ["https://via.placeholder.com/400x300.png?text=Put", "https://via.placeholder.com/400x300.png?text=Put", "https://via.placeholder.com/400x300.png?text=Put", "https://via.placeholder.com/400x300.png?text=Put", "https://via.placeholder.com/400x300.png?text=Put"],
                                                    "hideThumbnails": false,
                                                    "customHtml": '',
                                                    "mapType": "embaded",
                                                    "mapEmbaded": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d-70.56068388481569!3d41.45496659976631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus",
                                                    "lonlatURL": "https://maps.google.com/maps?q=49.46800006494457,17.11514008755796&hl=es&z=14&amp",
                                                    "mapHeight": 200,
                                                    "customTabs": [{ "tabName": 'First Tab', "tabHTML": '' }, { "tabName": 'Second Tab', "tabHTML": '' },],
                                                    "buttonAction": 'none',
                                                    "buttonActionValue": '',
                                                })
                                            }
                                        }

                                    }
                                })
                            }
                        })
                    }
                })
            }
            setElementHeading(elementName)
            setSections(sectionChangeData);
        });
        setRowchange(activeElement)
    }, [activeElement])

    return (
        <>
            <RowContainerDustbin
                index={index}
                key={key}
                containerClass={containerClass}
                id={id}
                rowId={rowId}
                elementclass={elementclass}
                rowchange={rowchange}
                sections={sections}
                setSections={setSections}
                section={section}
                rows={rows}
                row={row}
                column={column}
                rowcontainer={rowcontainer}
                deleteRow={deleteRow}
                addCustomStyle={addCustomStyle}
                columnStyle={columnStyle}
                columnBgElem={columnBgElem}
                setEnableColumnResize={setEnableColumnResize}
                enableColumnResize={enableColumnResize}
                setRowchange={setRowchange}
                columnIdTrack={columnIdTrack}
                detectRow={detectRow}
                columnIndex={columnIndex}
                columnSize={columnSize}
                setColumnSize={setColumnSize}
                setColumnCss={setColumnCss}
                columnCss={columnCss}
                targetColumnSize={targetColumnSize}
                nextColumnSize={nextColumnSize}
                sectionIdTrack={sectionIdTrack}
                rowIdTrack={rowIdTrack}
                setElementIdCount={setElementIdCount}
                elementIdCount={elementIdCount}
                onDrop={(item) => handleDrop(item)}
                setSidebarOpen={setSidebarOpen}
                rowIndex={rowIndex}
                sectionIndex={sectionIndex}
                activeElement={activeElement}
                prevElement={prevElement}
                setAddSectionIndex={setAddSectionIndex}
                setSideNav={setSideNav}
                setAddRowIndex={setAddRowIndex}
                setAddColumnIndex={setAddColumnIndex}
                preview={preview}
                setElementType={setElementType}
                elementType={elementType}
                nextContainer={nextContainer}
                prevContainer={prevContainer}
                enableForm={enableForm}
                setEnableForm={setEnableForm}
                sectionIdCount={sectionIdCount}


                setSectionIdCount={setSectionIdCount}
                enableEditSection={enableEditSection}
                setEnableEditSection={setEnableEditSection}
                cssAddingBarByClick={cssAddingBarByClick}
                setRowId={setRowId}
                setEnabledrop={setEnabledrop}
                enableDrop={enableDrop}
                enableRowSection={enableRowSection}
                setEnableRowSection={setEnableRowSection}
                setEnableColumnBar={setEnableColumnBar}
                enableColumnBar={enableColumnBar}
                elementEditBar={elementEditBar}
                setElementEditBar={setElementEditBar}
                setColumnIdTrack={setColumnIdTrack}
                sidebarOpen={sidebarOpen}
                openFunnelData={openFunnelData}
                rowStateId={rowStateId}
                setRowStateId={setRowStateId}

                tabsSection={tabsSection}
                enableTabs={enableTabs}
                setEnableTabs={setEnableTabs}
                setTabSection={setTabSection}
                mobileView={mobileView}
                saving={saving}
                undoComponent={undoComponent}
                setUndoComponent={setUndoComponent}
            />
        </>
    )
})

export default RowContainer