import React, { useState, useEffect } from 'react'

function Video(
    {   id,
        addCustomStyle,
        element,
        activeElement,
        setElementType }
) {
    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
        e.stopPropagation();
        setElementType('video')
        if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
        }
    }

    useEffect(() => {
        addCustomStyle(`elem_${id}`);
    }, [])

    var autoPlayEnable='0'
    var controlsEnable='0'
    var videoStyle='width: 100%; padding-bottom:60%;'
    if(element.elementObject){
        if(element.elementObject.autoPlay){
            autoPlayEnable=1
        }
        else{
            autoPlayEnable=0
        }
        if(element.elementObject.controls){
            controlsEnable=1
        }
        else{
            controlsEnable=0
        }
        if(element.elementObject.videoWidth==='100'){
            videoStyle='width: 100%; padding-bottom:60%;'
        }
        else if(element.elementObject.videoWidth==='75'){
            videoStyle='width: 75%; padding-bottom:40%;'
        }
        else if(element.elementObject.videoWidth==='50'){
            videoStyle='width: 50%; padding-bottom:30%;'
        }
    }
    const [showThumbnail,setShowThumbnail]=useState(true)

    

    if(element.elementObject && element.elementObject.elementVideoURL){
        if(element.elementObject.elementVideoURL.includes('https://www.youtube.com/watch?v=') && element.elementObject.youtubeValidUrl){
            if( element.elementObject.youtubeValidUrl){
                async function fetchVideo(){
                    const response=await fetch(`https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${element.elementObject.elementVideoEmbaded}&format=json`)
                    if(response.ok===true){
                        setShowThumbnail(false)
                    }
                    else if(response.ok===false){
                        setShowThumbnail(true)
                    }
                }
                fetchVideo()
            }
            
        }
        else if(element.elementObject.elementVideoURL.includes('https://vimeo.com/')){
            async function fetchVideo(){
                const response=await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${element.elementObject.elementVideoEmbaded}`)
                if(response.ok===true){
                    setShowThumbnail(false)
                }
                else if(response.ok===false){
                    setShowThumbnail(true)
                }
            }
            fetchVideo()
        }
    }
    
    return (
        <div class="videoHolder" id={id}  onClick={handleEditorClick}>
            <div class="video-placeholder Controllable" videotype="youtube" Style={`margin: 0 auto; ${videoStyle} position: relative; background-repeat: no-repeat; background-size: cover; background-position: 50%;`}>
                {showThumbnail?<div class="video-placeholder Controllable" videotype="youtube" Style={`position: absolute; top: 0px; right: 0; height: 100%; width: 100%; background: url(https://app.netflow.co/builderassets/css/images/youtubePlaceholder.png); background-repeat: no-repeat;background-size: cover !important;background-position: 50%; border-radius: ${element.elementObject?element.elementObject.borderRadius:''}`}></div>: element.elementObject && element.elementObject.elementVideoURL.includes('https://vimeo.com/') ?<iframe  Style={`position: absolute; top: 0px; right: 0; height: 100%; width: 100%;border-radius: ${element.elementObject?element.elementObject.borderRadius:''}`} src={`https://player.vimeo.com/video/${element.elementObject?element.elementObject.elementVideoEmbaded:''}?autoplay=${autoPlayEnable}&controls=${controlsEnable}`}>
                </iframe>: element.elementObject && element.elementObject.elementVideoURL.includes('https://www.youtube.com/watch?v=') && element.elementObject.youtubeValidUrl?<iframe  Style={`position: absolute; top: 0px; right: 0; height: 100%; width: 100%;border-radius: ${element.elementObject?element.elementObject.borderRadius:''}`} src={`https://www.youtube.com/embed/${element.elementObject?element.elementObject.elementVideoEmbaded:''}?autoplay=${autoPlayEnable}&controls=${controlsEnable}`}></iframe>:<div class="video-placeholder Controllable" videotype="youtube" Style={`position: absolute; top: 0px; right: 0; height: 100%; width: 100%; background: url(https://app.netflow.co/builderassets/css/images/youtubePlaceholder.png); background-repeat: no-repeat;background-size: cover !important;background-position: 50%;border-radius: ${element.elementObject?element.elementObject.borderRadius:''}`}></div>}
            </div>
        </div>
    )
}

export default Video