import React, { useState, useEffect } from 'react'
import * as FontAwesome from "react-icons/fa";
import parse from 'html-react-parser';

function ImageList({ size,
    id,
    addCustomStyle,
    element,
    activeElement,
    setElementType
}
) {
    const [clickDetect, setClickDetect] = useState(0)
    const [addicon, setAddIcon]=useState()
    const handleEditorClick = (e) => {
        e.stopPropagation();
        setElementType('imagelist')
        if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
        }
    }

    useEffect(() => {
        addCustomStyle(`elem_${id}`);
    }, [])


    var icon = element.elementObject ? <div Style={`height:${element.elementObject ? element.elementObject.iconSize : ''}px; display:flex; color:${element.elementObject ? element.elementObject.iconColor : ''}`}>{element.elementObject && element.elementIcon === 'blank' ? <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg"><path d="M248 167.5l64.9 98.8H183.1l64.9-98.8zM496 256c0 136.9-111.1 248-248 248S0 392.9 0 256 111.1 8 248 8s248 111.1 248 248zm-99.8 82.7L248 115.5 99.8 338.7h30.4l33.6-51.7h168.6l33.6 51.7h30.2z"></path></svg> : element.elementIcon.includes('</svg>')?<svg stroke="currentColor" fill="currentcolor" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg">{parse(element.elementIcon.slice(element.elementIcon.indexOf("<path"), element.elementIcon.indexOf("</svg>")))}</svg>:<svg stroke="currentColor" fill="currentcolor" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto"  xmlns="http://www.w3.org/2000/svg">{parse(element.elementIcon)}</svg>}</div> : ''
    var textStyle = element.elementObject ? `font-weight:${element.elementObject.imagelistfontweight}; border-color:${element.elementObject.separatorColor}; font-family:${element.elementObject && element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}; text-align: ${element.elementObject ? element.elementObject.textAlign : 'left'} ` : ''
    return (
        <div id={id} onClick={handleEditorClick}>
            <div className="imageListHolder" mediatype="icon" currenticon="fa-solid fa-0">
                {element.elementObject ? element.elementObject.imageList.map((element) => {
                    return <><div className="imageListItem" id="imageListItem0977a" >
                        <div className="ili-image" Style="font-size: 16px;margin-right: 5px;">
                            {icon}
                        </div>
                            <p Style={textStyle} className={` global${element.elementObject ? element.elementObject.fonttype : ''}Font `}>{element}</p>
                    </div>
                    </>
                }) : ''}
            </div>
        </div>
    )
}

export default ImageList