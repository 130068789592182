import React, { memo, useState } from 'react'
import { useRef, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from '../ItemTypes'
import RowContainer from './RowContainer'

import Editor from "@monaco-editor/react";

export const Row = memo(function Row({
  rowstyle,
  id,
  rowId,
  index,
  moveCardRow,
  setSections,
  rows,
  rowcontainer,
  rowclassName,
  sections,
  section,
  setSectionAccept,
  sectionHandleId,
  rowchange,
  setRowchange,
  setRowStateId,
  rowStateId,
  addRow,
  row,
  addCustomStyle,
  rowName,
  rowBgElem,
  setRowIdTrack,
  columnBgElem,
  setEnableColumnResize,
  enableColumnResize,
  columnIdTrack,
  sectionIdTrack,
  rowIdTrack,
  detectRow,
  columnIndex,
  columnSize,
  setColumnSize,
  setColumnCss,
  columnCss,
  targetColumnSize,
  nextColumnSize,
  setElementIdCount,
  elementIdCount,
  setColumnChange,
  setSidebarOpen,
  sectionIndex,
  activeElement,
  prevElement,
  setElementName,
  elementName,
  setElementHeading,
  setElementCss,
  elementCss,
  setAddSectionIndex,
  addSectionIndex,
  setSideNav,
  setAddRowIndex,
  setAddColumnIndex,
  preview,
  setElementType,
  elementType,
  openModalAndSave,
  sectionIdCount,
  setSectionIdCount,
  bgElem,
  setRowId,
  setEnabledrop,
  enableDrop,
  setRowCss,
  rowCss,
  setRowHeading,
  setRowName,
  setColumnIdTrack,
  columnName,
  setColumnName,
  setColumnHeading,
  setTargetColumnSize,
  setNextColumnSize,
  setPrevElement,
  setActiveElement,

  enableEditSection,
  setEnableEditSection,
  cssAddingBarByClick,
  enableRowSection,
  setEnableRowSection,
  setEnableColumnBar,
  enableColumnBar,
  elementEditBar,
  setElementEditBar,
  sidebarOpen,
  openFunnelData,
  tabsSection,
  enableTabs,
  setEnableTabs,
  setTabSection,
  mobileView,
  saving,
  undoComponent,
  setUndoComponent,
}) {

  const [rowAddCss, setRowAddCss] = useState(`#${rowId}{

}`)
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.ROWUPDOWN,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCardRow(dragIndex, hoverIndex, item, monitor)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({

    type: ItemTypes.ROWUPDOWN,
    item: () => {
      return { rowId, index, sectionHandleId }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  if (enableColumnResize === false && preview == false) {
    drag(drop(ref));
  }
  else {
    drag()
  }

  const renderCard = (container, columnindex) => {
    if (rowcontainer.length - 1 > columnindex) {
      var nextContainer = rowcontainer[columnindex + 1]
    }
    if (columnindex > 0) {
      var prevContainer = rowcontainer[columnindex - 1]
    }
    return (
      <RowContainer
        index={columnindex}
        key={container.id}
        containerClass={container.class}
        id={container.id}
        rowId={rowId}
        elementclass={container.Rowclass}
        rowchange={rowchange}
        sections={sections}
        section={section}
        sectionIndex={sectionIndex}
        setSections={setSections}
        rows={rows}
        row={row}
        rowIndex={index}
        column={container}
        nextContainer={nextContainer}
        prevContainer={prevContainer}
        rowcontainer={rowcontainer}
        deleteRow={deleteRow}
        addCustomStyle={addCustomStyle}
        columnStyle={container.columnStyle}
        columnBgElem={columnBgElem}
        setEnableColumnResize={setEnableColumnResize}
        enableColumnResize={enableColumnResize}
        setRowchange={setRowchange}
        columnIdTrack={columnIdTrack}
        detectRow={detectRow}
        columnIndex={columnIndex}
        columnSize={columnSize}
        setColumnSize={setColumnSize}
        setColumnCss={setColumnCss}
        columnCss={columnCss}
        targetColumnSize={targetColumnSize}
        nextColumnSize={nextColumnSize}
        sectionIdTrack={sectionIdTrack}
        rowIdTrack={rowIdTrack}
        setElementIdCount={setElementIdCount}
        elementIdCount={elementIdCount}
        setColumnChange={setColumnChange}
        setSidebarOpen={setSidebarOpen}
        activeElement={activeElement}
        prevElement={prevElement}
        setElementName={setElementName}
        elementName={elementName}
        setElementHeading={setElementHeading}
        setElementCss={setElementCss}
        elementCss={elementCss}
        addSectionIndex={addSectionIndex}
        setAddSectionIndex={setAddSectionIndex}
        setSideNav={setSideNav}
        setAddRowIndex={setAddRowIndex}
        setAddColumnIndex={setAddColumnIndex}
        preview={preview}
        setElementType={setElementType}
        elementType={elementType}
        sectionIdCount={sectionIdCount}
        setSectionIdCount={setSectionIdCount}
        bgElem={bgElem}
        setRowId={setRowId}
        rowStateId={rowStateId}
        setRowStateId={setRowStateId}
        setEnabledrop={setEnabledrop}
        enableDrop={enableDrop}
        setRowCss={setRowCss}
        rowCss={rowCss}
        setRowHeading={setRowHeading}
        rowName={rowName}
        setRowName={setRowName}
        setRowIdTrack={setRowIdTrack}
        rowBgElem={rowBgElem}
        setColumnIdTrack={setColumnIdTrack}
        columnName={columnName}
        setColumnName={setColumnName}
        setColumnHeading={setColumnHeading}
        setTargetColumnSize={setTargetColumnSize}
        setNextColumnSize={setNextColumnSize}
        setPrevElement={setPrevElement}
        setActiveElement={setActiveElement}


        enableEditSection={enableEditSection}
        setEnableEditSection={setEnableEditSection}
        cssAddingBarByClick={cssAddingBarByClick}
        enableRowSection={enableRowSection}
        setEnableRowSection={setEnableRowSection}
        setEnableColumnBar={setEnableColumnBar}
        enableColumnBar={enableColumnBar}
        elementEditBar={elementEditBar}
        setElementEditBar={setElementEditBar}
        sidebarOpen={sidebarOpen}
        openFunnelData={openFunnelData}
        tabsSection={tabsSection}
        enableTabs={enableTabs}
        setEnableTabs={setEnableTabs}
        setTabSection={setTabSection}
        mobileView={mobileView}
        saving={saving}
        undoComponent={undoComponent}
        setUndoComponent={setUndoComponent}

      />
    )

  }

  // Row css for hovering
  useEffect(() => {
    document.getElementById(rowId).onmouseenter = () => {
      if (preview === false) {
        document.getElementById(rowId + "ControlLabelsRow").classList.remove("disable")
        document.getElementById(rowId + "PlusSectionButtonRow").classList.remove("disable")
        document.getElementById(rowId).classList.add("allRowStyle")
        document.getElementById(rowId + "typeNameRow").classList.add("typeNameHover")
        document.getElementById(rowId + "typeNameRow").classList.remove("disable")
        document.getElementById(section.id + "ControlLabels").classList.add("disable")
        document.getElementById(section.id + "PlusSectionButton").classList.add("disable")
        document.getElementById(section.id + "typeName").classList.add("disable")
        document.getElementById("dust" + section.id).classList.remove("allSections")
      }
    }
    document.getElementById(rowId).onmouseleave = () => {
      if (preview === false) {
        document.getElementById(rowId + "ControlLabelsRow").classList.add("disable")
        document.getElementById(rowId + "PlusSectionButtonRow").classList.add("disable")
        document.getElementById(rowId).classList.remove("allRowStyle")
        document.getElementById(rowId + "typeNameRow").classList.remove("typeNameHover")
        document.getElementById(rowId + "typeNameRow").classList.add("disable")
        document.getElementById(section.id + "ControlLabels").classList.remove("disable")
        document.getElementById(section.id + "PlusSectionButton").classList.remove("disable")
        document.getElementById(section.id + "typeName").classList.remove("disable")
        document.getElementById("dust" + section.id).classList.add("allSections")
      }
    }
  }, [rowchange, sections, section, preview])
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("dustsec" + "_" + (id - 1) + 1).classList.add("allSections")
    }, 100);
  }, [section.RowComponent])

  // Move up Row 
  function moveUpRow(id) {
    var sectionIndex;
    var movedData = sections;
    var rowIndex;
    sectionIndex = movedData.indexOf(section)
    movedData[sectionIndex].RowComponent.forEach((element) => {
      if (element.id === id) {
        rowIndex = movedData[sectionIndex].RowComponent.indexOf(element)
        if (rowIndex > 0) {
          movedData[sectionIndex].RowComponent[rowIndex] = movedData[sectionIndex].RowComponent.splice(rowIndex - 1, 1, movedData[sectionIndex].RowComponent[rowIndex])[0]
          setSections(movedData)
          setRowchange(movedData)
        }
      }

    });
    var componentData = undoComponent
    componentData.push({ "arrayData": JSON.parse(JSON.stringify(movedData)), "id": id })
    setUndoComponent(componentData)
  }

  // Move Down Row
  function moveDownRow(id) {
    var sectionIndex;
    var movedData = sections;
    var rowIndex;
    var isSetting = true
    sectionIndex = movedData.indexOf(section)
    movedData[sectionIndex].RowComponent.forEach((element) => {
      if (element.id === id && isSetting === true) {
        rowIndex = movedData[sectionIndex].RowComponent.indexOf(element)
        if (rowIndex < movedData[sectionIndex].RowComponent.length - 1) {
          movedData[sectionIndex].RowComponent[rowIndex] = movedData[sectionIndex].RowComponent.splice(rowIndex + 1, 1, movedData[sectionIndex].RowComponent[rowIndex])[0]
          setSections(movedData)
          setRowchange(movedData)
          isSetting = false
        }
      }

    });
    var componentData = undoComponent
    componentData.push({ "arrayData": JSON.parse(JSON.stringify(movedData)), "id": id })
    setUndoComponent(componentData)
    isSetting = true
  }

  // Copy Row
  function copyRow(id) {
    var sectionIndex;
    var sectionsData = sections
    var rowIndex;
    var updateId;
    sectionIndex = sections.indexOf(section)
    sections[sectionIndex].RowComponent.forEach((element) => {
      if (element.id === id) {
        rowIndex = sections[sectionIndex].RowComponent.indexOf(element)
      }
    });
    var sectionId = section.id
    var sectionTrackId = section.sectionId
    updateId = "row" + "-" + rowStateId + 1 + "_" + sectionId
    var updaterowId = sectionTrackId
    var rowConstData = JSON.parse(JSON.stringify(sections[sectionIndex].RowComponent[rowIndex]));
    rowConstData.id = updateId
    rowConstData.rowId = updaterowId
    rowConstData.RowContainer.forEach((element) => {
      var columnIndex = rowConstData.RowContainer.indexOf(element)
      var columnId = "row" + "-" + rowStateId + 1 + "_" + sectionId + element.id.slice(13)
      rowConstData.RowContainer[columnIndex].id = columnId
      element.ColumnContainer.forEach((elem2) => {
        var copyElementId = elem2.id.slice(0, elem2.id.indexOf('row')) + "row" + "-" + rowStateId + 1 + "_" + elem2.id.slice(elem2.id.indexOf('sec'))
        elem2.id = copyElementId
      })
    })
    sectionsData[sectionIndex].RowComponent.splice(rowIndex + 1, 0, rowConstData)
    setSections(sectionsData)
    var componentData = undoComponent
    componentData.push({ "arrayData": JSON.parse(JSON.stringify(sectionsData)), "id": updateId })
    setUndoComponent(componentData)
    setRowStateId(rowStateId + 1)
    setRowchange(sectionsData[sectionIndex].RowComponent)
  }

  // Delete Row
  function deleteRow(id) {
    var sectionIndex;
    var movedData = sections;
    var rowIndex;
    sectionIndex = movedData.indexOf(section)
    movedData[sectionIndex].RowComponent.forEach((element) => {
      if (element.id === id) {
        rowIndex = movedData[sectionIndex].RowComponent.indexOf(element)
        movedData[sectionIndex].RowComponent.splice(rowIndex, 1)
        setSections(movedData)
        setRowchange(movedData)
      }

    });
    var componentData = undoComponent
    componentData.push({ "arrayData": JSON.parse(JSON.stringify(movedData)), "id": id })
    setUndoComponent(componentData)
    document.getElementById("dust" + section.id).classList.add('allSections')
    setRowIdTrack(undefined)
  }

  // Sections background Image and Crousel and video set
  const sectionBackgroundStyle = [`inset:0px;position:absolute;background-image:url(${row.rowObject ? mobileView ? row.rowObject.backgroundImageMobileSingle : row.rowObject.backgroundImageDesktopSingle : ""});opacity:${row.rowObject ? row.rowObject.backgroundImageOpacity : 1};border-radius:${row.rowObject ? row.rowObject.borderRadius : '0px'};`]
  const sectionCrouselBackgroundStyle = [`inset:0px;position:absolute;opacity:${row.rowObject ? row.rowObject.backgroundImageOpacity : 1}`]
  var backgroundImageDesktopSingleCss = row.rowObject ? row.rowObject.backgroundImageDesktopSingleCss : ''
  var backgroundSetElem;
  if (row.rowObject) {
    if (row.rowObject.BgCarouselImageType === 'bgimage') {
      backgroundSetElem = <div className="BgImgHolder singleImgDiv Parallax" bgsettings="Parallax" Style={sectionBackgroundStyle + ";" + backgroundImageDesktopSingleCss + ";" + "z-index:0;"} desktop={row.rowObject ? row.rowObject.backgroundImageDesktopSingle : ""} prev={row.rowObject ? row.rowObject.backgroundImageDesktopSingle : ""}></div>
    }
    else if (row.rowObject.BgCarouselImageType === 'bgvideo') {
      backgroundSetElem = <div className="youtube-background rowBgVideo" id="" Style={`height: 100%; width: 100%; z-index: 0; position: absolute; overflow: hidden; inset: 0px; pointer-events: none; background-image: url("https://img.youtube.com/vi/${row.rowObject.embededVideoCode}/maxresdefault.jpg"); background-size: cover; background-repeat: no-repeat; background-position: center center;border-radius:${row.rowObject ? row.rowObject.borderRadius : '0px'};`}><iframe frameborder="0" allow="autoplay; mute" src={`https://www.youtube.com/embed/${row.rowObject.embededVideoCode}?enablejsapi=1&disablekb=1&controls=0&rel=0&iv_load_policy=3&cc_load_policy=0&playsinline=1&showinfo=0&modestbranding=1&fs=0&mute=1&autoplay=1&loop=1`} id={id + "_" + "sectionVideo"} Style={`top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); position: absolute; ${row.rowObject.youtubeValidUrl ? 'opacity: 1' : 'opacity: 0'}; width: ${window.outerWidth}px; height: ${window.outerHeight}px`} title=""></iframe></div>
    }
    else if (row.rowObject.BgCarouselImageType === 'bgcarouselimage') {
      backgroundSetElem = <div className="BgImgHolder Parallax" bgsettings="FillAllWidth" Style={`inset: 0px; position: absolute; background-image: none;border-radius:${row.rowObject ? row.rowObject.borderRadius : '0px'};`}>
        <div id={"carouselExampleFade" + rowId} className="carousel slide carousel-fade" data-bs-ride="carousel" Style='height:100%; z-index:0'>
          <div className="carousel-inner" Style={`height:100%; border-radius:${row.rowObject ? row.rowObject.borderRadius : '0px'}`}>
            {row.rowObject ? row.rowObject.BgCarouselImage.map((element, index) => {
              return <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} Style={`background-image:url(${element.bgImage});height:100%`}></div>
            }) : ''}
          </div>
          <button className="carousel-control-next" type="button" data-bs-target={"#carouselExampleFade" + rowId} Style='display:none' id={'crouselIdNext' + rowId} data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    }
  }
  else {
    backgroundSetElem = ''
  }

  // carousel image button click
  useEffect(() => {
    if (row.rowObject) {
      if (rowBgElem === 'bgcarouselimage' && row.rowObject.BgCarouselImageType === 'bgcarouselimage') {
        document.getElementById('crouselIdNext' + rowId).click()
      }

    }
  }, [rowBgElem])

  // set Row id for preventing bubble click
  function setRowId(e) {
    e.stopPropagation();
    addCustomStyle(rowId)
  }

  // Enable full width
  var widthStyle = ''
  if (section.styleObject) {
    if (section.styleObject.fullWidth) {
      widthStyle = 'max-width:100%'
    }
  }

  function handleEditorChange(value, event) {
    setRowAddCss(value)
  }

  function saveSectionAddCss() {
    document.getElementById(`${rowId}Style`).innerHTML = `<style>${rowAddCss}</style>`
  }

  useEffect(() => {
    if (preview === true) {
      document.getElementById(rowId).classList.remove('selectRowStyle')
    }
  }, [preview])

  function addingRowAfter() {
    addRow(section.id)
    setAddRowIndex(index)
  }

  useEffect(() => {
    document.getElementById(`${rowId}responsiveStyle`).innerHTML = `<style>@media only screen and (max-width: 700px) { #${rowId}{${row.rowObject ? `background-image:url(${row.rowObject.backgroundImageMobileSingle}) !important; ${backgroundImageDesktopSingleCss}; padding-top:${row.rowObject.paddingTopMobile} !important;  padding-bottom:${row.rowObject.paddingBottomMobile} !important;  margin-top:${row.rowObject.marginTopMobile} !important; margin-bottom:${row.rowObject.marginBottomMobile} !important; width:${row.rowObject.mobilerowwidth}% !important ` : ''}} .BgImgHolder{z-index:-5 !important;} .singleImgDiv{display:none !important} }</style>`
  }, [preview,backgroundImageDesktopSingleCss])

  useEffect(() => {
    document.getElementById(rowId).click()
  }, [])

  return (
    <div id={rowId} ref={ref} Style={mobileView ? row.mobilerowstyle + widthStyle + ";" + `flex-wrap:${mobileView ? 'wrap' : 'nowrap'}; position:relative; margin-left: auto; margin-right: auto;` : rowstyle + widthStyle + ";" + `flex-wrap:${mobileView ? 'wrap' : 'nowrap'}; position:relative; `} data-handler-id={handlerId} className={`row  allRow justify-content-between row-align-${row.rowObject ? row.rowObject.rowAlign : 'center'} rowIndex`} onClick={setRowId} name={rowName}>
      {backgroundSetElem}
      <div id={`${rowId}Style`} Style='display:none'>
        <style>

        </style>
      </div>

      <div id={`${rowId}responsiveStyle`} Style='display:none'>
        <style>

        </style>
      </div>
      {preview ? '' : <div class="modal fade" id={`${rowId}CssEditorModalElement`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <span Style="font-size: 20px;">
                <i class="fas fa-code"></i>
                <custom Style="font-size: 20px;">&nbsp; Custom CSS</custom>
              </span>
              <div class="mt-3 cssEditorHolder" Style='text-align:left'>
                <Editor
                  height="50vh"
                  theme="vs-dark"
                  defaultLanguage="css"
                  defaultValue={rowAddCss}
                  onChange={handleEditorChange}
                />
              </div>
            </div>
            <div class="modal-footer" Style="display:flex; justify-content: center;">
              <div>
                <p Style="color:red;"><em><u>Caution!</u></em> Global selectors like <b><i>html</i></b> or <b><i>button</i></b> will result in the entire page being affected.</p>
                <p Style="color:#ff6a00;"><em><u>Tip:</u></em> If your styling won't work, try adding a <i>!important</i> flag after it</p>
              </div>
              <div>
                <button type="button" class="btn btn-success saveCustomCss" Style="width: 100px;" onClick={saveSectionAddCss}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {rowcontainer.map((container, i) => {
        return renderCard(container, i)
      })}
      {preview ? '' : <>
        <div className='typeNameRow disable' id={rowId + "typeNameRow"} >Row</div>
        <div className="ControlLabelsRow disable" id={rowId + "ControlLabelsRow"} Style="display: flex; z-index: 10;height:100%; flex-direction:column;flex-wrap:wrap">
          <div className="grow MoveSection ms-1" data-bs-toggle="tooltip" title="Drag" placement="top" ><i className="fa fa-arrows-alt" id={id + "MoveSection"} ></i></div>
          <div className="grow MoveUp ms-1" data-bs-toggle="tooltip" title="Move Up"><i className="fa fa-arrow-up" onClick={() => { moveUpRow(rowId) }}></i></div>
          <div className="grow MoveDown ms-1" data-bs-toggle="tooltip" title="Move Down"><i className="fa fa-arrow-down" onClick={() => { moveDownRow(rowId) }}></i></div>
          <div className="grow CopySection ms-1" data-bs-toggle="tooltip" title="Clone"><i className="fa fa-copy" onClick={() => { copyRow(rowId) }}></i></div>
          <div className="grow openCustomCss ms-1" title="Open custom css" data-bs-toggle="modal" data-bs-target={`#${rowId}CssEditorModalElement`}><i className="fab fa-css3-alt"></i></div>
          <div className="grow DeleteSection ms-1" data-bs-toggle="tooltip" title="Delete"><i className="fa fa-trash" onClick={() => { deleteRow(rowId) }}></i></div>
        </div></>}
      {preview ? '' : <button clicked="false" className="PlusSectionButtonRow disable" id={rowId + "PlusSectionButtonRow"} onClick={() => { addingRowAfter() }} ><span Style="display:flex"><i className="fas fa-plus"></i></span></button>}
    </div>
  )
})
