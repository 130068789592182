import React, { useState, useEffect } from 'react'
import BgCarouselCard from './BgCarouselComponents/BgCarouselCard'

function BgCarouselImage({Css, setCss,setRowchange, setEnableEditComponent,setBgCrouselElem,type,setUrlTargetIndex,setUrlTarget}) {
    const [carouselInputBox, setCarouselInputBox] = useState(0)
    const [urlData,setUrlData]=useState()
    // const [bgCarouselImageData,setBgCarouselImageData]=useState({
    //     "bgImage":'',
    //     "bgImageText":'',
    //     "bgImageSubText":''
    // })
   
    useEffect(() => {
      setRowchange(urlData)
    }, [Css,urlData])
    
    return (
        <div id="carouselImagesHolder" >
            {Css.BgCarouselImage.map((element,index)=>{
              return  <BgCarouselCard Css={Css} setCss={setCss}  carouselInputBox={carouselInputBox} setCarouselInputBox={setCarouselInputBox} index={index} setEnableEditComponent={setEnableEditComponent} setBgCrouselElem={setBgCrouselElem} urlData={urlData} setUrlData={setUrlData} type={type} setUrlTarget={setUrlTarget} setUrlTargetIndex={setUrlTargetIndex}/>
            })}
        </div>
    )
}

export default BgCarouselImage