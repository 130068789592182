import React, { useState, useEffect } from 'react'
import { animated, useSpring } from '@react-spring/web'

import ColumnAdvancedElement from './LeftColumnSideBarComponents/ColumnAdvancedElement'
import ElementGeneralBar from './LeftElementSidebarComponents/ElementGeneralBar'
import ElementAdvancedBar from './LeftElementSidebarComponents/ElementAdvancedBar'
import ElementThemeBar from './LeftElementSidebarComponents/ElementThemeBar'
import { BsArrowBarRight } from "react-icons/bs";

function LeftElementSideBar({setAttachedImage, attachedImage, sections,setAllImg, funnelId, allImg, sectionIdTrack, setSections, setRowchange, setCssEditElemReRender, cssEditElemReRender, setColumnBgElem, columnBgElem, setElementCss, elementCss, elementHeading, rowIdTrack, setElementHeading, setEnableColumnBar, enableColumnBar, setElementName, elementName, columnIdTrack, setElementEditBar, activeElement, elementType }) {
    // Section Heading control
    const [leftBarElement, setLeftBarElement] = useState(true)
    const [leftThemeBar, setLeftThemeBar] = useState(false)
    const [leftFontBar, setLeftFontBar] = useState(false)
    const [barWidth, setBarWidth] = useState("width:30%")

    const styles = useSpring({
        from: {
            width: "0px",
            position: "relative"
        },
        to: {
            width: "350",
            position: "relative"
        },
        config: { mass: 2, tension: 3000, friction: 100 },
        delay: 300,
    })
    const [color, setColor] = useState(elementCss.elementbackgroundcolor)
    const [textColor, setTextColor] = useState(elementCss.textColor)
    const [separatorColor, setSeparatorColor] = useState(elementCss.separatorColor)
    const [iconColor, setIconColorColor] = useState(elementCss.iconColor)
    const [borderColor, setBorderColor] = useState(elementCss.borderstyleCss.color)
    const [imgFeatureHeaderColor, setImgFeatureHeaderColor] = useState(elementCss.imgFeatureHeaderColor)
    const [imgFeatureTextColor, setImgFeatureTextColor] = useState(elementCss.imgFeatureTextColor)
    const [linkTextColor, setLinkTextColor] = useState(elementCss.linkTextColor)

    useEffect(() => {
        var sectionChangeData = sections
        // var ElementStyle = `padding-top:${elementCss.paddingTop}px; padding-bottom:${elementCss.paddingBottom}px; padding-left:${elementCss.paddingLeft}px; padding-right:${elementCss.paddingRight}px; margin-top:${elementCss.marginTop}px; margin-bottom:${elementCss.marginBottom}px; box-shadow:${elementCss.boxShadow}; background-color: ${elementCss.elementbackgroundcolor}; border${elementCss.border}: ${elementCss.borderstyleCss.pixel} ${elementCss.borderstyleCss.type} ${elementCss.borderstyleCss.color}; border-radius:${elementCss.borderRadius}; color:${elementCss.textColor}; letter-spacing:${elementCss.elementLetterSpacing}; width:100%; position:relative`
        // var MobileColumnStyle = `padding-top:${elementCss.paddingTopMobile}px; padding-bottom:${elementCss.paddingBottomMobile}px; padding-left:${elementCss.paddingLeft}px; padding-right:${elementCss.paddingRight}px; margin-top:${elementCss.marginTopMobile}px; margin-bottom:${elementCss.marginBottomMobile}px; box-shadow:${elementCss.boxShadow}; background-color: ${elementCss.sectionbackgroundcolor}; border${elementCss.border}: ${elementCss.borderstyleCss.pixel} ${elementCss.borderstyleCss.type} ${elementCss.borderstyleCss.color}; border-radius:${elementCss.borderRadius}; `
        var ElementTextStyle = `text-align: none; opacity: ${elementCss.elementOpacity}; text-shadow: ${elementCss.elementTextShadow};font-size:${elementCss.desktopFontSize}px; text-align:${elementCss.textAlign}; font-family:${elementCss.fonttype === "Custom" ? elementCss.fontstyle : ''}`
        var MobileElementTextStyle=`text-align: none; opacity: ${elementCss.elementOpacity}; text-shadow: ${elementCss.elementTextShadow};font-size:${elementCss.mobileFontSize}px; text-align:${elementCss.textAlign};font-family:${elementCss.fonttype === "Custom" ? elementCss.fontstyle : ''}`
        // var ImageFeatureTextStyle = `text-align: none; opacity: ${elementCss.elementOpacity}; text-shadow: ${elementCss.elementTextShadow}; text-align:${elementCss.textAlign}`
        var imageFeatureCssObj = { "headerMobileFontSize": elementCss.headerMobileFontSize, "headerDesktopFontSize": elementCss.headerDesktopFontSize, "textMobileFontSize": elementCss.textMobileFontSize, "textDesktopFontSize": elementCss.textDesktopFontSize, "imgFeatureHeaderColor": elementCss.imgFeatureHeaderColor, "imgFeatureTextColor": elementCss.imgFeatureTextColor, "elementTextShadow":elementCss.elementTextShadow }
        sectionChangeData.forEach((elem) => {
            if (elem.id === sectionIdTrack) {
                elem.RowComponent.forEach((elem1) => {
                    if (elem1.id === rowIdTrack) {
                        elem1.RowContainer.forEach((elem2) => {
                            if (elem2.id === columnIdTrack) {
                                elem2.ColumnContainer.forEach((elem3) => {
                                    if (activeElement && elem3.id === activeElement.slice(5)) {
                                        setTimeout(() => {
                                            elem3.elementName = elementName;
                                            // elem3.elementStyle = ElementStyle;
                                            elem3.elementObject = elementCss;
                                            elem3.elementTextStyle = ElementTextStyle
                                            elem3.mobileElementTextStyle=MobileElementTextStyle
                                            // elem3.mobileColumnStyle=MobileColumnStyle
                                            if (elementType === 'imagefeature') {
                                                // elem3.imageFeatureTextStyle = ImageFeatureTextStyle
                                                elem3.imageFeatureCssObj = imageFeatureCssObj
                                            }
                                            if (elementType === 'headline' || elementType === 'subheadline' || elementType === 'paragraph' || elementType === 'bulletlist' ) {
                                                elem3.icon = elementCss.elementTextIcon
                                                elem3.iconColor = elementCss.iconColor
                                                elem3.iconWidth = Number(elementCss.desktopFontSize) + 10
                                                elem3.mobileIconWidth = Number(elementCss.mobileFontSize) + 10
                                            }
                                            if(elementType==='icon' || elementType === 'imagelist'){
                                                elem3.elementIcon = elementCss.elementIcon
                                            }
                                            if (elementType === "countdown" || elementType === "daytimer") {
                                                elem3.date = elementCss.endDate
                                                elem3.time = elementCss.endTime
                                            }
                                            if (elementType === "minutetimer") {
                                                elem3.time = `${elementCss.minuteTimerHour}:${elementCss.minuteTimerMinute}:${elementCss.minuteTimerSecond}`
                                            }
                                            if (elementType === "map") {
                                                elem3.mapUrl = elementCss.lonlatURL
                                            }
                                        }, 10);
                                    }
                                })
                            }
                        })
                    }
                })
            }
            setElementHeading(elementName);
            setSections(sectionChangeData);
        });
        setTimeout(() => {
            setRowchange(JSON.stringify(elementCss))
        }, 15);
    }, [sections, elementCss, elementName, color, borderColor, textColor, iconColor, separatorColor, imgFeatureHeaderColor, imgFeatureTextColor])


    function leftBarElementSet(id) {
        if (id === "general") {
            setLeftBarElement(true)
            setLeftThemeBar(false)
            setBarWidth("width:30%")
        }
        else if (id === "advanced") {
            setLeftBarElement(false)
            setLeftThemeBar(false)
            setBarWidth("width:90%")
        }
        else if (id === "theme") {
            setLeftBarElement(false)
            setLeftThemeBar(true)
            setBarWidth("width:60%")
        }
    }
    useEffect(() => {
        setRowchange(color)
        setTimeout(() => {
            document.getElementById('leftBarGeneral').click()
        }, 100);
    }, [color, rowIdTrack, elementType])


    return (
        <animated.div id="leftSideNav" className="leftNav customNav custom_scroll bg-light" style={styles}>
            <div Style="position: sticky; background-color: #f8f9fa; z-index: 10; top: 0px; width: 100%;">
                <hr className='m-0' />
                <nav>
                    <div className="nav nav-tabs justify-content-around" id="nav-tab" role="tablist">
                        <button className="btnTab btnMainTabs " id='leftBarGeneral' data-bs-step="1" data-toggle="tab" href="#nav-general" role="tab" aria-selected="true" onClick={() => { leftBarElementSet("general") }} Style={elementType==='video' || elementType==='button'?'padding:0px;font-size:15px':''}>General</button>
                        {elementType==='video' || elementType==='button'?<button className="btnTab btnMainTabs " id='leftBarTheme' data-bs-step="1" data-toggle="tab" href="#nav-general" role="tab" aria-selected="true" onClick={() => { leftBarElementSet("theme") }}>Theme</button>:''}
                        <button className="btnTab btnMainTabs" id='leftBarAdvanced' data-bs-step="4" data-toggle="tab" href="#nav-advanced" role="tab" aria-selected="false" onClick={() => { leftBarElementSet("advanced") }} Style={elementType==='video' || elementType==='button'?'padding:0px;font-size:15px':''}>Advanced</button>
                        <span onClick={() => { setElementEditBar(false) }} Style='font-size: 25px; cursor:pointer; padding-top: 8px;'><BsArrowBarRight /></span>
                        <div className="progress bg-transparent" Style="height: 3px;position: absolute;width: 100%;bottom: 0;">
                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="5" Style={barWidth}></div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="tab-content" id="nav-tabContent" Style="box-shadow:none">
                {cssEditElemReRender ? leftBarElement ? <ElementGeneralBar setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} setElementCss={setElementCss} elementCss={elementCss} setElementName={setElementName} elementName={elementName} setColor={setColor} color={color} setcolumnBgElem={setColumnBgElem} columnBgElem={columnBgElem} setEnableColumnBar={setEnableColumnBar} setRowchange={setRowchange} textColor={textColor} setTextColor={setTextColor} elementType={elementType} separatorColor={separatorColor} iconColor={iconColor} setSeparatorColor={setSeparatorColor} setIconColorColor={setIconColorColor} imgFeatureHeaderColor={imgFeatureHeaderColor} setImgFeatureHeaderColor={setImgFeatureHeaderColor} imgFeatureTextColor={imgFeatureTextColor} setImgFeatureTextColor={setImgFeatureTextColor} setLinkTextColor={setLinkTextColor} linkTextColor={linkTextColor} /> : leftThemeBar ? <ElementThemeBar elementType={elementType} setElementCss={setElementCss} elementCss={elementCss} setRowchange={setRowchange} /> : <ElementAdvancedBar setElementCss={setElementCss} elementCss={elementCss} setRowchange={setRowchange} setBorderColor={setBorderColor} borderColor={borderColor} activeElement={activeElement} elementType={elementType} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} /> : ""}
            </div>
        </animated.div>
    )
}

export default LeftElementSideBar