import React,{useEffect} from 'react'

function BgVideo({Css,setCss}) {
    useEffect(() => {
        var embadedAddedCss=Css
        let index=embadedAddedCss.backgroundVideo.indexOf("watch?v=");
        if(index!=-1){
            embadedAddedCss.embededVideoCode=embadedAddedCss.backgroundVideo.slice(index+8)
            setCss(embadedAddedCss)
        }
        if(embadedAddedCss.backgroundVideo.includes("https://www.youtube.com/watch?v=")){
            if(embadedAddedCss.backgroundVideo.slice(32).length===11){
                embadedAddedCss.youtubeValidUrl=true
            }
            else{
                embadedAddedCss.youtubeValidUrl=false
            }
        }
    }, [Css])
    return (
        <div id="VideoBGImage" >
            <span>Background Video</span>
            <div className="input-group mt-1 mb-3">
                <input type="text" className="form-control BgVideoInput" placeholder="Youtube URL" name="backgroundVideo" value={Css.backgroundVideo} onChange={(e)=>{setCss({ ...Css, [e.target.name]: e.target.value })}}/>
            </div>
        </div>
    )
}

export default BgVideo