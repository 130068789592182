import React, { useEffect, useState } from 'react'
import BorderColorPicker from '../BorderColorPicker';
import img from '../../static/images/backgroundfreeimage.png'
import ShapeColor from '../ShapeColor';
import { Slider, TextField, Select, InputLabel, MenuItem, FormControl } from "@mui/material";

function RowAdvancedElement({ setRowCss, rowCss, rowIdTrack, setRowchange, setBorderColor, borderColor }) {
    const [borderRadiusType, setBorderRadiusType] = useState(rowCss.borderRadiusType)
    const [borderRadiusData, setBorderRadiusData] = useState(rowCss.borderRadiusData)
    const [borderRadius, setBorderRadius] = useState()
    const [enableBorderColor, setEnableBorderColor] = useState(false)
    // const [enableShapeColor, setEnableShapeColor] = useState(false)
    // const [shapeWidth,setShapeWidth]=useState()
    // const [shapeHeight,setShapeHeight]=useState()
    useEffect(() => {
        if (borderRadiusType === 'AllBorderEdges') {
            setBorderRadius(borderRadiusData)
        }
        else if (borderRadiusType === 'TopBorderEdges') {
            setBorderRadius(borderRadiusData + ' ' + borderRadiusData + ' ' + '0px' + ' ' + '0px')
        }
        else if (borderRadiusType === 'BottomBorderEdges') {
            setBorderRadius('0px' + ' ' + '0px' + ' ' + borderRadiusData + ' ' + borderRadiusData)
        }
        let spacecssData = rowCss
        spacecssData.borderRadiusData = borderRadiusData
        spacecssData.borderRadiusType = borderRadiusType
        setRowCss(spacecssData)
    }, [borderRadiusType, borderRadiusData])

    useEffect(() => {
        var data = rowCss
        data.borderRadius = borderRadius
        setRowchange(borderRadius)
    }, [borderRadius])

    // Copy Section Id to clipboard
    function copySectionId() {
        var copyText = document.getElementById("rowIdElem");
        navigator.clipboard.writeText(copyText.innerText);
        document.getElementById('rowCopytext').classList.add("active");
        setTimeout(function () {
            document.getElementById('rowCopytext').classList.remove("active");
        }, 2500);
    }
    function setBorderStyle(data, type) {
        var sectiondcss = rowCss
        if (type === 'type') {
            sectiondcss.borderstyleCss.type = data
            setRowCss(sectiondcss)
            setRowchange(rowCss.borderstyleCss)
        }
        else if (type === 'pixel') {
            sectiondcss.borderstyleCss.pixel = data
            setRowCss(sectiondcss)
            setRowchange(rowCss.borderstyleCss)
        }

    }
    var openButton;
    if (rowCss.borderstyleCss.color === 'rgba(255, 255, 255, 0)') {
        openButton = `background-image: url(${img})`
    }
    else {
        openButton = `background-color: ${borderColor} !important`
    }


    return (
        <div className="" id="nav-advanced" role="tabpanel">
            <div id="AdvancedProperties" Style="padding-top: 10px;text-align: start;">
                <div className="container" Style="padding: 0px 10px 0px 10px;">
                    <div className="SectionPropsWrapper mb-3 mt-3 d-flex justify-content-center" Style="padding-bottom: 30px">
                        <div Style="margin-top: 10px; background-color: rgb(255, 255, 255);width:100%;display:flex">
                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">Border</InputLabel>
                                <Select
                                    labelId="border-label"
                                    id='borderType'
                                    value={rowCss.border}
                                    name='border'
                                    onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }}
                                    label="Border"
                                >
                                    <MenuItem value={'noBorder'}>No Border</MenuItem>
                                    <MenuItem value={""}>Full Border</MenuItem>
                                    <MenuItem value={"-top"}>Top Border</MenuItem>
                                    <MenuItem value={"-bottom"}>Bottom Border</MenuItem>
                                    <MenuItem value={"-block"}>Top and Bottom Border</MenuItem>
                                    <MenuItem value={"-left"}>Left Border</MenuItem>
                                    <MenuItem value={"-right"}>Right Border</MenuItem>
                                    <MenuItem value={"-inline"}>Left and Right Border</MenuItem>
                                </Select>
                            </FormControl>
                            <div id="BorderOptions" Style={`display:${rowCss.border === 'noBorder' ? 'none' : 'block'}`}>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Border Style</InputLabel>
                                    <Select
                                        labelId="borderType-label"
                                        id='borderType'
                                        value={rowCss.borderstyleCss.type}
                                        name='type'
                                        onChange={(e) => { setBorderStyle(e.target.value, 'type') }}
                                        label="Border Type"
                                    >
                                        <MenuItem value={'solid'}>Solid</MenuItem>
                                        <MenuItem value={"dashed"}>Dashed</MenuItem>
                                        <MenuItem value={"dotted"}>Dotted</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Border Width</InputLabel>
                                    <Select
                                        labelId="pixel-label"
                                        id='borderType'
                                        value={rowCss.borderstyleCss.pixel}
                                        name='pixel'
                                        onChange={(e) => { setBorderStyle(e.target.value, 'pixel') }}
                                        label="Border Type"
                                    >
                                        <MenuItem value={'1px'}>1px</MenuItem>
                                        <MenuItem value={"2px"}>2px</MenuItem>
                                        <MenuItem value={"3px"}>3px</MenuItem>
                                        <MenuItem value={"5px"}>5px</MenuItem>
                                        <MenuItem value={"10px"}>10px</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Border Radius</InputLabel>
                                    <Select
                                        labelId="borderRadiusData-label"
                                        id='borderRadiusData'
                                        value={borderRadiusData}
                                        name='borderRadiusData'
                                        onChange={(e) => { setBorderRadiusData(e.target.value) }}
                                        label="Border Radius Data"
                                    >
                                        <MenuItem value={'0px'}>0px</MenuItem>
                                        <MenuItem value={"5px"}>5px</MenuItem>
                                        <MenuItem value={"10px"}>10px</MenuItem>
                                        <MenuItem value={"15px"}>15px</MenuItem>
                                        <MenuItem value={"20px"}>20px</MenuItem>
                                        <MenuItem value={"25px"}>25px</MenuItem>
                                        <MenuItem value={"50px"}>50px</MenuItem>
                                        <MenuItem value={"75px"}>75px</MenuItem>
                                        <MenuItem value={"100px"}>100px</MenuItem>
                                        <MenuItem value={"125px"}>125px</MenuItem>
                                        <MenuItem value={"150px"}>150px</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Radius Edge</InputLabel>
                                    <Select
                                        labelId="borderRadiusType-label"
                                        id='borderRadiusType'
                                        value={borderRadiusType}
                                        name='borderRadiusType'
                                        onChange={(e) => { setBorderRadiusType(e.target.value) }}
                                        label="Border Radius Type"
                                    >
                                        <MenuItem value={'AllBorderEdges'}>All Edges</MenuItem>
                                        <MenuItem value={"TopBorderEdges"}>Top Only Edges</MenuItem>
                                        <MenuItem value={"BottomBorderEdges"}>Bottom Only Edges</MenuItem>
                                    </Select>
                                </FormControl>

                                <div className="border-picker-wrapper all-picker-wrappers" Style="padding:0px;">
                                    <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                        <span>Background Color:</span>
                                        <div className="color-picker-button float-right" Style={`${openButton};border-radius: 15px;`} onClick={() => { setEnableBorderColor(!enableBorderColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                    </div>
                                    {/* <div Style='display:flex;justify-content: center;padding: 0px 20px;margin-bottom: 15px;'>
                                        <button className="color-picker-button btn float-right" Style={`${openButton}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableBorderColor(!enableBorderColor) }}>Background Color</button>
                                    </div> */}
                                    {enableBorderColor ? <BorderColorPicker Css={rowCss} setCss={setRowCss} setRowchange={setRowchange} setEnableBorderColor={setEnableBorderColor} setBorderColor={setBorderColor} type='section' /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="inBetweenAdvanced"></div>
                <div className="container">
                    <div className="SectionPropsWrapper mb-3 mt-3" Style="display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 10px;">
                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                            <InputLabel id="demo-simple-select-standard-label">Animations (when visible)</InputLabel>
                            <Select
                                labelId="animationsection-label"
                                id='animationsection'
                                value={rowCss.animationsection}
                                name='animationsection'
                                onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }}
                                label="Animations (when visible)"
                            >
                                <MenuItem value={'none'}>No Animation</MenuItem>
                                <MenuItem value={"come-in-right"}>Slide in from right</MenuItem>
                                <MenuItem value={"come-in-left t"}>Slide in from left</MenuItem>
                                <MenuItem value={"come-in-top"}>Slide in from top</MenuItem>
                                <MenuItem value={"come-in-bottom"}>Slide in from bottom</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <div class="container">
                            <div class="copy-text " id='rowCopytext'>
                                <span type="text" class="text" id='rowIdElem' Style='width:50%'>#{rowIdTrack}</span>
                                <button onClick={copySectionId}><i class="fa fa-clone"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RowAdvancedElement