import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown';

function Minutetimer({ size,
    id,
    addCustomStyle,
    element,
    activeElement,
    setElementType,
    mobileView
}) {
    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
        e.stopPropagation();
        setElementType('minutetimer')
        if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
        }
    }

    useEffect(() => {
        addCustomStyle(`elem_${id}`);
    }, [])
    var dateArr = element.date.split('-')
    var timeArr = element.time.split(':')
    const [timeHour, setTimeHour] = useState(new Date().getHours())
    const [timeMinute, setTimeMinute] = useState(new Date().getMinutes())
    const [timeSecond , setTimeSecond] = useState(new Date().getSeconds())
    const [countDownDate, setCountDownDate] = useState(new Date().setHours(timeHour + Number(timeArr[0]), timeMinute + Number(timeArr[1]), timeSecond + Number(timeArr[2])))

    useEffect(() => {
        setTimeHour(new Date().getHours())
        setTimeMinute(new Date().getMinutes())
        setTimeSecond(new Date().getSeconds())
        setCountDownDate(new Date().setHours(timeHour + Number(timeArr[0]), timeMinute + Number(timeArr[1]), timeSecond + Number(timeArr[2])))
    }, [element.time])
    

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
            var days = Math.floor((countDownDate - new Date().getTime()) / (1000 * 60 * 60 * 24));
            var hours = Math.floor(((countDownDate - new Date().getTime()) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor(((countDownDate - new Date().getTime()) % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor(((countDownDate - new Date().getTime()) % (1000 * 60)) / 1000);
            document.getElementById(`countdownDemo_${id}`).innerHTML = "";
            if (isNaN(days) || days < 0) {
                document.getElementById(`${id}hoursDiv`).innerHTML = 0
                document.getElementById(`${id}minuteDiv`).innerHTML = 0
                document.getElementById(`${id}secondDiv`).innerHTML = 0
            }
            else {
                document.getElementById(`${id}hoursDiv`).innerHTML = hours
                document.getElementById(`${id}minuteDiv`).innerHTML = minutes
                document.getElementById(`${id}secondDiv`).innerHTML = seconds
            }
            if((countDownDate - new Date().getTime())<0){
                clearInterval(interval)
                document.getElementById(`countdownDemo_${id}`).innerHTML = "EXPIRED";
                document.getElementById(`${id}hoursDiv`).innerHTML = 0
                document.getElementById(`${id}minuteDiv`).innerHTML = 0
                document.getElementById(`${id}secondDiv`).innerHTML = 0
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);


    useEffect(() => {
        document.getElementById(`${id}scriptTag`).innerHTML = `<script>var countDownDate_${id.slice(id.lastIndexOf('_')+1)} = ${countDownDate}; var distance_${id.slice(id.lastIndexOf('_')+1)} = ${countDownDate}; var interval_${id.slice(id.lastIndexOf('_')+1)} = setInterval(function () { var now = new Date().getTime(); distance_${id.slice(id.lastIndexOf('_')+1)} = countDownDate_${id.slice(id.lastIndexOf('_')+1)} - now;  var hours = Math.floor((distance_${id.slice(id.lastIndexOf('_')+1)} % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); var minutes = Math.floor((distance_${id.slice(id.lastIndexOf('_')+1)} % (1000 * 60 * 60)) / (1000 * 60)); var seconds = Math.floor((distance_${id.slice(id.lastIndexOf('_')+1)} % (1000 * 60)) / 1000); document.getElementById("${id}hoursDiv").innerHTML = hours; document.getElementById("${id}minuteDiv").innerHTML = minutes; document.getElementById("${id}secondDiv").innerHTML = seconds; if (distance_${id.slice(id.lastIndexOf('_')+1)} < 1) { clearInterval(interval_${id.slice(id.lastIndexOf('_')+1)}); document.getElementById("countdownDemo_${id}").innerHTML = "EXPIRED"; document.getElementById("${id}hoursDiv").innerHTML = 0; document.getElementById("${id}minuteDiv").innerHTML = 0; document.getElementById("${id}secondDiv").innerHTML = 0; }; }, 1000);</script>`
    }, [countDownDate])

    useEffect(() => {
        document.getElementById(`minutetimer_${id}responsiveStyle`).innerHTML = `<style>@media only screen and (max-width: 700px) { ${element.elementObject ? ` #${id}hoursDiv{ font-size:${element.elementObject.countDownMobFontSize} !important } #${id}minuteDiv{ font-size:${element.elementObject.countDownMobFontSize} !important } #${id}secondDiv{ font-size:${element.elementObject.countDownMobFontSize} !important }  #${id}hoursText{ font-size:${element.elementObject.countDownMobFontSize} !important } #${id}minuteText{ font-size:${element.elementObject.countDownMobFontSize} !important } #${id}secondText{ font-size:${element.elementObject.countDownMobFontSize} !important }` : ''} }</style>`
        // console.log(element.elementObject)
    }, [mobileView])

    return (
        <div onClick={handleEditorClick} >
            <div id={`${id}scriptTag`} className="d-none">

            </div>
            <div id={`countdownDemo_${id}`}>

            </div>
            <div id={`minutetimer_${id}responsiveStyle`} Style='display:none'>
                <style>

                </style>
            </div>
            <span className={`d-flex flex-wrap  global${element.elementObject ? element.elementObject.fonttype : ''}Font`} Style={`color:${element.elementObject ? element.elementObject.countdowntextcolor : 'black'}; justify-content:${element.elementObject ? element.elementObject.countdownposition : 'center'}; font-family:${element.elementObject && element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}`}>
                
                 <div className='countdownItems' Style={`background-color:${element.elementObject ? element.elementObject.countdownbgcolor : 'white'}`}>
                    <span id={`${id}hoursDiv`} Style={`font-size:${element.elementObject ? mobileView? element.elementObject.countDownMobFontSize: element.elementObject.countDownDeskFontSize : '50'}px; display: block;`}></span>
                    <span class="countdownItemSubText" dfontsize="10px" mfontsize="10px" Style={`font-size:${element.elementObject ? mobileView ? element.elementObject.countDownTextMobFontSize : element.elementObject.countDownTextDeskFontSize : '10'}px; display: block;`}>Hours</span>
                </div>
                <div className='countdownItems' Style={`background-color:${element.elementObject ? element.elementObject.countdownbgcolor : 'white'}`}>
                    <span id={`${id}minuteDiv`} Style={`font-size:${element.elementObject ? mobileView? element.elementObject.countDownMobFontSize: element.elementObject.countDownDeskFontSize : '50'}px; display: block;`}></span>
                    <span class="countdownItemSubText" dfontsize="10px" mfontsize="10px" Style={`font-size:${element.elementObject ? mobileView ? element.elementObject.countDownTextMobFontSize : element.elementObject.countDownTextDeskFontSize : '10'}px; display: block;`}>Minute</span>
                </div>
                <div className='countdownItems' Style={`background-color:${element.elementObject ? element.elementObject.countdownbgcolor : 'white'}`}>
                    <span id={`${id}secondDiv`} Style={`font-size:${element.elementObject ? mobileView? element.elementObject.countDownMobFontSize: element.elementObject.countDownDeskFontSize : '50'}px; display: block;`}></span>
                    <span class="countdownItemSubText" dfontsize="10px" mfontsize="10px" Style={`font-size:${element.elementObject ? mobileView ? element.elementObject.countDownTextMobFontSize : element.elementObject.countDownTextDeskFontSize : '10'}px; display: block;`}>Second</span>
                </div>
            </span>

        </div>
    )
}

export default Minutetimer