import React, { useState, useEffect } from 'react'
import ColorPicker from '../ColorPicker';
// import BgCarouselImage from './GeneralElementComponent/BgCarouselImage';
import BgImage from '../../components/LeftSideBarComponents/GeneralElementComponent/BgImage';
import BgVideo from '../../components/LeftSideBarComponents/GeneralElementComponent/BgVideo';
import BgCarouselImage from '../../components/LeftSideBarComponents/GeneralElementComponent/BgCarouselImage';
import img from '../../static/images/backgroundfreeimage.png'
import { Slider, TextField, Select, InputLabel, MenuItem, FormControl } from "@mui/material";

function RowGeneralElement({ setAttachedImage, attachedImage, setRowCss, rowCss, funnelId, allImg, setAllImg, fetchImg, rowName, setRowName, setBgElem, bgElem, setEnableRowSection, color, setColor, setRowchange, setRowBgElem, rowBgElem }) {
    const [bgCrouselElem, setBgCrouselElem] = useState(true)
    const [enableBgColor, setEnableBgColor] = useState(false)

    const [urlTarget, setUrlTarget] = useState()
    const [urlTargetIndex, setUrlTargetIndex] = useState()

    const [selectedImg, setSelectedImg] = useState()

    function choseImageType(data) {
        let sectionDatacss = rowCss
        sectionDatacss.BgCarouselImageType = data
        setRowCss(sectionDatacss)
        setRowBgElem(data)
    }
    var BgImageAddingElem;
    if (rowBgElem === 'bgimage') {
        BgImageAddingElem = <BgImage Css={rowCss} setCss={setRowCss} setUrlTarget={setUrlTarget} />
    }
    else if (rowBgElem === 'bgvideo') {
        BgImageAddingElem = <BgVideo Css={rowCss} setCss={setRowCss} />
    }
    else if (rowBgElem === 'bgcarouselimage') {
        BgImageAddingElem = bgCrouselElem ? <BgCarouselImage Css={rowCss} setCss={setRowCss} setEnableEditComponent={setEnableRowSection} setBgCrouselElem={setBgCrouselElem} setRowchange={setRowchange} type='row' setUrlTarget={setUrlTarget} setUrlTargetIndex={setUrlTargetIndex} /> : ''
    }
    else {
        BgImageAddingElem = <BgImage Css={rowCss} setCss={setRowCss} setUrlTarget={setUrlTarget} />
    }

    var openButton;
    if (rowCss.rowbackgroundcolor === 'transparent') {
        openButton = `background-image: url(${img})`
    }
    else {
        openButton = `background-color: ${color} !important`
    }

    function saveFileData(imgdata) {
        document.getElementById('rowRodalContentFileMan').classList.add('modalBodyFileMan')
        document.getElementById('rowShowLoad').classList.remove('d-none')
        var reader = new FileReader();
        reader.readAsDataURL(imgdata);
        reader.onload = function () {
            uploadImg(reader.result, 2289).then((data) => {
                fetchImg().then((imagedata) => {
                    setAllImg(imagedata.image_path)
                    setRowchange(imagedata.image_path)
                    document.getElementById('rowRodalContentFileMan').classList.remove('modalBodyFileMan')
                    document.getElementById('rowShowLoad').classList.add('d-none')
                })
            })
        };
        reader.onerror = function (error) {
            alert('Error, Please Enter valid data');
        };
    }

    async function uploadImg(data, user_id) {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/php/addImages.php`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                funnel_id: funnelId,
                imagedata: data,
            })
        })
        return response.json()
    }

    async function fetchImg() {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/php/viewImages.php`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                funnel_id: funnelId
            })
        })
        return response.json()
    }

    function addImgURL(urldata) {
        var sectionCss = rowCss
        if (urlTarget === 'backgroundImageDesktopSingle') {
            sectionCss.backgroundImageDesktopSingle = urldata
        }
        if (urlTarget === 'backgroundImageMobileSingle') {
            sectionCss.backgroundImageMobileSingle = urldata
        }
        if (urlTarget === 'bgImage') {
            sectionCss.BgCarouselImage[urlTargetIndex].bgImage = urldata
        }
        setRowCss(sectionCss)
        setRowchange(sectionCss.urlTarget)
        document.getElementById('sectionFileManagerClose').click()
    }

    function addSelected() {
        if(selectedImg){
            var imageData = attachedImage
            if (imageData.length === 0) {
                imageData.push(selectedImg)
            }
            else {
                if (!imageData.includes(selectedImg)) {
                    imageData.push(selectedImg)
                }
            }
            setAttachedImage(imageData)
            addImgURL(`${process.env.REACT_APP_BASE_URL}/${selectedImg.image_path}`)
        }
        else{
            document.getElementById("rowImageSelectError").classList.remove("d-none")
            setTimeout(() => {
                document.getElementById("rowImageSelectError").classList.add("d-none")
            }, 5000);
        }
    }

    async function deleteImg() {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/php/deleteImages.php`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                file_path: selectedImg.image_path,
                image_id: selectedImg.image_id
            })
        })
        return response.json()
    }

    function deleteImgFileMan() {
        document.getElementById('rowConformaionTab').classList.add('d-none')
        document.getElementById('rowShowLoad').classList.remove('d-none')
        if (selectedImg) {
            deleteImg().then((data) => {
                fetchImg().then((imagedata) => {
                    setAllImg(imagedata.image_path)
                    setRowchange(imagedata.image_path)
                    document.getElementById('rowRodalContentFileMan').classList.remove('modalBodyFileMan')
                    document.getElementById('rowShowLoad').classList.add('d-none')
                })

            })
        }
    }
    function openConfirmTab(type) {
        if (type === 'open') {
            document.getElementById('rowRodalContentFileMan').classList.add('modalBodyFileMan')
            document.getElementById('rowConformaionTab').classList.remove('d-none')
        }
        else if (type === 'close') {
            document.getElementById('rowRodalContentFileMan').classList.remove('modalBodyFileMan')
            document.getElementById('rowConformaionTab').classList.add('d-none')
        }

    }
    return (
        <div className="tab-pane fade active show" id="nav-general" role="tabpanel">
            <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#sectionFileManager">
                Launch static backdrop modal
            </button>
            <div class="modal fade" id="sectionFileManager" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" Style=' margin-top: 80px;'>
                    <div class="modal-content ">
                        <div id='rowConformaionTab' className='confirmTab d-none'>
                            <h6 Style='color: black;margin-bottom: 20px;font-weight: 900;'>Are you sure to delete image</h6>
                            <button className='btn btn-danger me-4' onClick={() => { openConfirmTab('close') }}>Cancel</button>
                            <button className='btn btn-primary ms-4' onClick={deleteImgFileMan}>Confirm</button>
                        </div>
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Image Manager</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id='sectionFileManagerClose' Style='z-index:5000'></button>
                        </div>
                        <div class="modal-body " id='rowRodalContentFileMan'>

                            <div class="lds-roller d-none" id='rowShowLoad'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            <div class="row mb-3">
                                <div class="col-sm-5">
                                    <label class="input-group-text btn btn-primary me-2" for="inputGroupFile01"><i class="fas fa-upload"></i></label>
                                    <input type="file" class="form-control d-none" id="inputGroupFile01" onChange={(e) => { saveFileData(e.target.files[0]) }} />
                                    <button type="button" title="Delete" id="button-delete" class="btn btn-danger" onClick={() => { openConfirmTab('open') }} ><i class="fas fa-trash-alt"></i></button>
                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                {allImg !== undefined ? allImg.map((element) => {
                                    return <div className='col col-2'>
                                        <div class="card" Style={`padding: 5px; border-radius: 10px; background-color:${selectedImg === element ? 'red' : 'white'}`}>
                                            <img src={`${process.env.REACT_APP_BASE_URL}/${element.image_path}`} class="card-img-top" alt={`${process.env.REACT_APP_BASE_URL}/${element.image_path}`} id={`img_${element.image_path}`} Style='border-radius: 5px; cursor:pointer' onClick={() => { setSelectedImg(element) }} onDoubleClick={() => { addImgURL(`${process.env.REACT_APP_BASE_URL}/${element.image_path}`) }} />
                                        </div>
                                    </div>
                                }) : ''}
                            </div>
                            <hr />
                            <p className='text-danger mb-3 d-none' id="rowImageSelectError">Please select an image to process</p>
                            <button type="button" class="btn btn-primary" onClick={addSelected}>Attach</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="SideBarTitle" Style="width: 92%;margin: auto; ">
                <div className="input-group mb-3 SectionTitle">
                    <TextField label="Name" value={rowName} size="small" className="form-control TitleInput" onChange={(e) => { setRowName(e.target.value) }} />
                </div>
            </div>
            <div id="ColourOptions">
                <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                    <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                        <span>Background Color:</span>
                        <div className="color-picker-button float-right" Style={`${openButton};border-radius: 15px;`} onClick={() => { setEnableBgColor(!enableBgColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                    </div>
                    {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                        <button className="color-picker-button btn float-right" Style={`${openButton}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableBgColor(!enableBgColor) }}>Background Color</button>
                    </div> */}
                    {enableBgColor ? <ColorPicker color={color} setColor={setColor} Css={rowCss} setCss={setRowCss} setEnableBgColor={setEnableBgColor} type='row' /> : ''}
                </div>
                <div Style='padding: 14px;'>
                    <div Style='display:flex; text-align:start; flex-direction:column'>
                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                            <InputLabel id="colPerRowMobile-label">Columns per row (mobile)</InputLabel>
                            <Select
                                labelId="colPerRowMobile"
                                id="colPerRowMobile"
                                value={rowCss.colPerRowMobile}
                                name='colPerRowMobile'
                                onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }}
                                label="colPerRowMobile"
                            >
                                <MenuItem value={'none'}>1 col</MenuItem>
                                <MenuItem value={'twobytwo'}>2 cols</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="form-check form-switch">
                        <input type="checkbox" className="form-check-input" id="transformintocarousel" name='transformintocarousel' onClick={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.checked }) }} />
                        <label className="form-check-label" for="flexSwitchCheckChecked">Transform to carousel</label>
                    </div>
                </div>
            </div>
            <div id="ExtraProperties">
                <div className="container" Style="padding: 0px 10px 0px 10px;text-align:start">
                    <div className="SectionPropsWrapper mb-3 mt-3">
                        <div className="SectionProps d-flex flex-column justify-content-center align-items-center">
                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">Box Shadow</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={rowCss.boxShadow}
                                    name='boxShadow'
                                    onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }}
                                    label="Box Shadow"
                                >
                                    <MenuItem value="none">
                                        <em>No Drop Shadow</em>
                                    </MenuItem>
                                    <MenuItem value={'rgba(0, 0, 0, 0.05) 0px 1px 3px 0px'}>5% Drop Shadow</MenuItem>
                                    <MenuItem value={"rgba(0, 0, 0, 0.1) 0px 1px 5px 0px"}>10% Drop Shadow</MenuItem>
                                    <MenuItem value={"rgba(0, 0, 0, 0.2) 0px 1px 5px 0px"}>20% Drop Shadow</MenuItem>
                                    <MenuItem value={"rgba(0, 0, 0, 0.3) 0px 1px 5px 0px"}>30% Drop Shadow</MenuItem>
                                    <MenuItem value={"rgba(0, 0, 0, 0.4) 0px 1px 5px 0px"}>40% Drop Shadow</MenuItem>

                                    <MenuItem value={"rgba(0, 0, 0, 0.05) 0px 1px 3px 0px inset"}>5% Inner Shadow</MenuItem>
                                    <MenuItem value={"rgba(0, 0, 0, 0.1) 0px 1px 3px 0px inset"}>10% Inner Shadow</MenuItem>
                                    <MenuItem value={"rgba(0, 0, 0, 0.20) 0px 1px 3px 0px inset"}>20% Inner Shadow</MenuItem>
                                    <MenuItem value={"rgba(0, 0, 0, 0.30) 0px 1px 3px 0px inset"}>30% Inner Shadow</MenuItem>
                                    <MenuItem value={"rgba(0, 0, 0, 0.40) 0px 1px 3px 0px inset"}>40% Inner Shadow</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-Sticky">Align</InputLabel>
                                <Select
                                    labelId="rowAlign-Sticky"
                                    id="rowAlign-small"
                                    value={rowCss.rowAlign}
                                    name='rowAlign'
                                    onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }}
                                    label="rowAlign"
                                >
                                    <MenuItem value={'center'}>Center</MenuItem>
                                    <MenuItem value={'left'}>Left</MenuItem>
                                    <MenuItem value={"right"}>Right</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-Sticky">Background Image Type</InputLabel>
                                <Select
                                    labelId="demo-bgImgVideorow"
                                    id="demo-bgImgVideorow"
                                    value={rowCss.BgCarouselImageType}
                                    name='stickness'
                                    onChange={(e) => { choseImageType(e.target.value) }}
                                    label="Sticky"
                                >
                                    <MenuItem value={'bgimage'}>Single Background Image</MenuItem>
                                    <MenuItem value={'bgcarouselimage'}>Carousel Background Images</MenuItem>
                                    <MenuItem value={"bgvideo"}>Youtube Video Background</MenuItem>
                                </Select>
                            </FormControl>
                            {BgImageAddingElem}
                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-Image Options">Image Options</InputLabel>
                                <Select
                                    labelId="demo-select-Image Options"
                                    id="demo-select-small"
                                    value={rowCss.backgroundImageDesktopSingleCss}
                                    name='backgroundImageDesktopSingleCss'
                                    onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }}
                                    label="Image Options"
                                >
                                    <MenuItem value={'background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover'}>Full Center (Parallax)</MenuItem>
                                    <MenuItem value={'background-attachment: scroll; background-position: 50%; background-repeat: repeat; background-size: cover'}>Full Center</MenuItem>
                                    <MenuItem value={"background-attachment: scroll; background-position: 0% 0%; background-repeat: no-repeat; background-size: 100% auto"}>Fill 100% Width</MenuItem>

                                    <MenuItem value={"background-attachment: scroll; background-position: 0% 0%; background-repeat: repeat; background-size: 100% 100%"}>Fill 100% Width and Height</MenuItem>
                                    <MenuItem value={"background-attachment: scroll; background-position: 0% 0%; background-repeat: no-repeat; background-size: auto"}>No Repeat</MenuItem>
                                    <MenuItem value={"background-attachment: scroll; background-position: 0% 0%; background-repeat: repeat; background-size: auto"}>Repeat</MenuItem>
                                    <MenuItem value={"background-attachment: scroll; background-position: 0% 0%; background-repeat: repeat-x; background-size: auto"}>Repeat Horizontally</MenuItem>
                                    <MenuItem value={"background-attachment: scroll; background-position: 0% 0%; background-repeat: repeat-y; background-size: auto"}>Repeat Vertically</MenuItem>
                                    <MenuItem value={"background-attachment: scroll; background-position: top; background-repeat: repeat-x; background-size: auto"}>Repeat Horizontally - Fix to Top</MenuItem>
                                    <MenuItem value={"background-attachment: scroll; background-position: bottom; background-repeat: repeat-x; background-size: auto"}>Repeat Horizontally - Fix to Bottom</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-Sticky">Background Image Opacity</InputLabel>
                                <Select
                                    labelId="demo-select-bgImage"
                                    id="demo-select-bgImage"
                                    value={rowCss.backgroundImageOpacity}
                                    name='backgroundImageOpacity'
                                    onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }}
                                    label="bgImage"
                                >
                                    <MenuItem value={'1'}>None</MenuItem>
                                    <MenuItem value={'0.8'}>Light Fade</MenuItem>
                                    <MenuItem value={'0.5'}>Half Fade</MenuItem>
                                    <MenuItem value={"0.3"}>Heavy Fade</MenuItem>
                                </Select>
                            </FormControl>
                            <Slider value={rowCss.backgroundImageOpacity} aria-label="Default" valueLabelDisplay="auto" name='backgroundImageOpacity' min={0.1} max={1} step={0.1} onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                            <div>
                                <div class="input-group mb-3 mt-2">
                                    <TextField label="Desktop Row Width" id="desktopWidth" value={rowCss.desktoprowwidth} size="small" name='desktoprowwidth' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={rowCss.desktoprowwidth} aria-label="Default" valueLabelDisplay="auto" name='desktoprowwidth' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>
                            <div>
                                <div class="input-group mb-3">
                                    <TextField label="Mobile Row Width" id="mobileWidth" value={rowCss.mobilerowwidth} size="small" name='mobilerowwidth' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={rowCss.mobilerowwidth} aria-label="Default" valueLabelDisplay="auto" name='mobilerowwidth' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="SideBarProps">
                <div className="container" Style="padding: 0px 10px 0px 10px;">
                    <div className="SectionPropsWrapper mb-3 mt-3">
                        <div className="SectionProps">
                            <div className="input-group mb-3  addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Top (Mobile)" id="outlined-size-small" value={rowCss.paddingTopMobile} size="small" name='paddingTopMobile' onChange={(e) => {e.target.value>4? setRowCss({ ...rowCss, [e.target.name]: e.target.value }): setRowCss({ ...rowCss, [e.target.name]: 5 })}} />
                                    <Slider value={rowCss.paddingTopMobile} aria-label="Default" valueLabelDisplay="auto" name='paddingTopMobile' onChange={(e) => {e.target.value>4? setRowCss({ ...rowCss, [e.target.name]: e.target.value }): setRowCss({ ...rowCss, [e.target.name]: 5 })}} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Bottom (Mobile)" id="outlined-size-small" value={rowCss.paddingBottomMobile} size="small" name='paddingBottomMobile' onChange={(e) => {e.target.value>4? setRowCss({ ...rowCss, [e.target.name]: e.target.value }): setRowCss({ ...rowCss, [e.target.name]: 5 })}} />
                                    <Slider value={rowCss.paddingBottomMobile} aria-label="Default" valueLabelDisplay="auto" name='paddingBottomMobile' onChange={(e) => {e.target.value>4? setRowCss({ ...rowCss, [e.target.name]: e.target.value }): setRowCss({ ...rowCss, [e.target.name]: 5 })}} />
                                </div>
                            </div>

                            <div className="input-group mb-3  addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Top (Desktop)" id="outlined-size-small" value={rowCss.paddingTop} size="small" name='paddingTop' onChange={(e) => {e.target.value>4? setRowCss({ ...rowCss, [e.target.name]: e.target.value }): setRowCss({ ...rowCss, [e.target.name]: 5 })}} />
                                    <Slider value={rowCss.paddingTop} aria-label="Default" valueLabelDisplay="auto" name='paddingTop' onChange={(e) => {e.target.value>4? setRowCss({ ...rowCss, [e.target.name]: e.target.value }): setRowCss({ ...rowCss, [e.target.name]: 5 })}} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Bottom (Desktop)" id="outlined-size-small" value={rowCss.paddingBottom} size="small" name='paddingBottom' onChange={(e) => {e.target.value>4? setRowCss({ ...rowCss, [e.target.name]: e.target.value }): setRowCss({ ...rowCss, [e.target.name]: 5 })}} />
                                    <Slider value={rowCss.paddingBottom} aria-label="Default" valueLabelDisplay="auto" name='paddingBottom' onChange={(e) => {e.target.value>4? setRowCss({ ...rowCss, [e.target.name]: e.target.value }): setRowCss({ ...rowCss, [e.target.name]: 5 })}} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Left" id="outlined-size-small" value={rowCss.paddingLeft} size="small" name='paddingLeft' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={rowCss.paddingLeft} aria-label="Default" valueLabelDisplay="auto" name='paddingLeft' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Right" id="outlined-size-small" value={rowCss.paddingRight} size="small" name='paddingRight' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={rowCss.paddingRight} aria-label="Default" valueLabelDisplay="auto" name='paddingRight' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Margin Top (Mobile)" id="outlined-size-small" value={rowCss.marginTopMobile} size="small" name='marginTopMobile' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={rowCss.marginTopMobile} aria-label="Default" valueLabelDisplay="auto" name='marginTopMobile' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Margin Bottom (Mobile)" id="outlined-size-small" value={rowCss.marginBottomMobile} size="small" name='marginBottomMobile' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={rowCss.marginBottomMobile} aria-label="Default" valueLabelDisplay="auto" name='marginBottomMobile' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Margin Top (Desktop)" id="outlined-size-small" value={rowCss.marginTop} size="small" name='marginTop' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={rowCss.marginTop} aria-label="Default" valueLabelDisplay="auto" name='marginTop' min={-10} onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>
                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Margin Bottom (Desktop)" id="outlined-size-small" value={rowCss.marginBottom} size="small" name='marginBottom' onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={rowCss.marginBottom} aria-label="Default" valueLabelDisplay="auto" name='marginBottom' min={-10} onChange={(e) => { setRowCss({ ...rowCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RowGeneralElement