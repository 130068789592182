import React,{useState,useEffect} from 'react'

function Divider({ size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType }) {
    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
      e.stopPropagation();
      setElementType('divider')
      if (activeElement !== `elem_${id}`) {
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
    }
  
    useEffect(() => {
      addCustomStyle(`elem_${id}`);
    }, [])
  return (
    <div Style={`display:flex; justify-content:${element.elementObject ? element.elementObject.divideralign : 'start'}`} onClick={handleEditorClick}>
      <div className="dividerDiv" Style={`border-width: ${element.elementObject ? element.elementObject.dividerlineheight : '1px'} ;border-bottom-style: initial;border-bottom-color: initial;border-right-style: initial;border-right-color: initial; border-left-style:initial; border-left-color:initial; width:${element.elementObject ? element.elementObject.dividerwidth : '100%'} ; border-top-style: ${element.elementObject ? element.elementObject.dividerheight : 'solid'} ; border-top-color:${element.elementObject ? element.elementObject.textColor : 'rgb(0, 0, 0)'} ;`}></div>
    </div>
  )
}

export default Divider