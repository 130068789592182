import React from 'react'

function ElementThemeBar({ elementType, setElementCss, elementCss, setRowchange }) {
    function setBtnTheme(data) {
        var cssData = elementCss
        cssData.btnThemeClass = data
        cssData.btnThemeCss = ''
        cssData.btnBackgroundColor = 'none'
        cssData.textColor = 'none'
        setElementCss(cssData)
        setRowchange(data)
    }

    function removeTheme() {
        var cssData = elementCss
        cssData.btnThemeClass = ''
        cssData.btnThemeCss = ''
        cssData.btnBackgroundColor = 'rgba(13,110,253,1)'
        cssData.textColor = 'rgba(255,255,255,1)'
        setElementCss(cssData)
        setRowchange(cssData)
    }

    function removeVideoTheme() {
        var cssData = elementCss
        cssData.videoThemeCss = ''
        cssData.btnBackgroundColor = 'transparent'
        setElementCss(cssData)
        setRowchange(cssData)
    }

    function videoThemeAdd(data){
        var cssData = elementCss
        cssData.videoThemeCss =data
        setElementCss(cssData)
        setRowchange(cssData)
    }

    return (
        <>
            {elementType === 'video' ? <div class="container" id="themesDiv">
                <button selectedclass="btn-primary" value="btn-primary" name='btnThemeClass' onClick={(e) => { removeVideoTheme() }} class="switchBtnTheme btn mt-2 mb-3 btn-primary" Style="width:50%; height:50px; display:block; margin:0 auto; color:white; font-size:15px">Remove Theme</button>
                <span>Choose Your Theme</span>
                <div value="" selectedclass="fullBorder" class="Element mt-2 mb-3fullBorder ui-draggable" elementtype="Video" name='videoThemeCss' onClick={(e) => { videoThemeAdd("font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(255, 0, 0); margin-top: 0px; margin-bottom: 0px; border-width: 1px !important;") }} margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="19" margin-bottom-desktop="0" Style="font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(255, 0, 0); margin-top: 0px; margin-bottom: 0px; border-width: 1px !important;" >
                    <div class="videoHolder videoTheme">
                        <div class="video-placeholder" videotype="youtube" Style="margin: 0 auto; width: 100%; padding-bottom:60%; background: url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'); background-repeat: no-repeat; background-size: cover; background-position: 50%;"></div>
                    </div>
                </div>
                <div value="" selectedclass="fullBorder" class="Element mt-2 mb-3fullBorder ui-draggable" elementtype="Video" name='videoThemeCss' onClick={(e) => { videoThemeAdd("font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; margin-top: 0px; margin-bottom: 0px; border-style: dashed; border-radius: 10px; border-color: rgb(0, 0, 0); border-width: 2px !important; ")}} margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="19" margin-bottom-desktop="0" Style="font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; margin-top: 0px; margin-bottom: 0px; border-style: dashed; border-radius: 10px; border-color: rgb(0, 0, 0); border-width: 2px !important;">
                    <div class="videoHolder videoTheme">
                        <div class="video-placeholder" videotype="youtube" Style="margin: 0 auto; width: 100%; padding-bottom:60%; background: url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'); background-repeat: no-repeat; background-size: cover; background-position: 50%;"></div>
                    </div>
                </div>
                <div value="" selectedclass="fullBorder" class="Element mt-2 mb-3fullBorder ui-draggable" elementtype="Video" name='videoThemeCss' onClick={(e) => { videoThemeAdd("font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(255, 0, 0); margin-bottom: 0px; background-color: rgb(220, 53, 69); border-width: 1px !important; ")}} margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="19" margin-bottom-desktop="0" Style="font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(255, 0, 0); margin-bottom: 0px; background-color: rgb(220, 53, 69); border-width: 1px !important;">
                    <div class="videoHolder videoTheme">
                        <div class="video-placeholder" videotype="youtube" Style="margin: 0 auto; width: 100%; padding-bottom:60%; background: url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'); background-repeat: no-repeat; background-size: cover; background-position: 50%;"></div>
                    </div>
                </div>
                <div value=" " selectedclass="fullBorder" class="Element mt-2 mb-3fullBorder ui-draggable" elementtype="Video" name='videoThemeCss' onClick={(e) => { videoThemeAdd("font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(255, 0, 0); margin-bottom: 0px; background-color: rgb(44, 62, 80); border-width: 1px !important;")}} margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="19" margin-bottom-desktop="0" Style="font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(255, 0, 0); margin-bottom: 0px; background-color: rgb(44, 62, 80); border-width: 1px !important;">
                    <div class="videoHolder videoTheme">
                        <div class="video-placeholder" videotype="youtube" Style="margin: 0 auto; width: 100%; padding-bottom:60%; background: url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'); background-repeat: no-repeat; background-size: cover; background-position: 50%;"></div>
                    </div>
                </div><div value="" selectedclass="fullBorder" class="Element mt-2 mb-3fullBorder ui-draggable" elementtype="Video" name='videoThemeCss' onClick={(e) => { videoThemeAdd("font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(44, 62, 80); margin-bottom: 0px; background-color: rgb(39, 174, 96); border-width: 1px !important; ")}} margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="19" margin-bottom-desktop="0" Style="font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(44, 62, 80); margin-bottom: 0px; background-color: rgb(39, 174, 96); border-width: 1px !important;">
                    <div class="videoHolder videoTheme">
                        <div class="video-placeholder" videotype="youtube" Style="margin: 0 auto; width: 100%; padding-bottom:60%; background: url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'); background-repeat: no-repeat; background-size: cover; background-position: 50%;"></div>
                    </div>
                </div><div value="" selectedclass="fullBorder" class="Element mt-2 mb-3fullBorder ui-draggable" elementtype="Video" name='videoThemeCss' onClick={(e) => { videoThemeAdd("font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(44, 62, 80); margin-bottom: 0px; background-color: rgb(0, 123, 255); border-width: 1px !important; ")}} margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="19" margin-bottom-desktop="0" Style="font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(44, 62, 80); margin-bottom: 0px; background-color: rgb(0, 123, 255); border-width: 1px !important;">
                    <div class="videoHolder videoTheme">
                        <div class="video-placeholder" videotype="youtube" Style="margin: 0 auto; width: 100%; padding-bottom:60%; background: url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'); background-repeat: no-repeat; background-size: cover; background-position: 50%;"></div>
                    </div>
                </div><div value=" " selectedclass="fullBorder" class="Element mt-2 mb-3fullBorder ui-draggable" elementtype="Video" name='videoThemeCss' onClick={(e) => { videoThemeAdd("font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(208, 69, 27); margin-bottom: 0px; background-color: rgb(241, 196, 15); border-width: 1px !important;")}} margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="19" margin-bottom-desktop="0" Style="font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(208, 69, 27); margin-bottom: 0px; background-color: rgb(241, 196, 15); border-width: 1px !important;">
                    <div class="videoHolder videoTheme">
                        <div class="video-placeholder" videotype="youtube" Style="margin: 0 auto; width: 100%; padding-bottom:60%; background: url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'); background-repeat: no-repeat; background-size: cover; background-position: 50%;"></div>
                    </div>
                </div><div value=" " selectedclass="fullBorder" class="Element mt-2 mb-3fullBorder ui-draggable" elementtype="Video" name='videoThemeCss' onClick={(e) => { videoThemeAdd("font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(208, 69, 27); margin-bottom: 0px; background-color: rgb(142, 68, 173); opacity: 1; border-width: 1px !important;")}} margin-top-mobile="0" margin-bottom-mobile="0" margin-top-desktop="19" margin-bottom-desktop="0" Style="font-size: 20px; padding: 10px; color: white; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; outline: none; border-style: solid; border-radius: 10px; border-color: rgb(208, 69, 27); margin-bottom: 0px; background-color: rgb(142, 68, 173); opacity: 1; border-width: 1px !important;">
                    <div class="videoHolder videoTheme">
                        <div class="video-placeholder" videotype="youtube" Style="margin: 0 auto; width: 100%; padding-bottom:60%; background: url('https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'); background-repeat: no-repeat; background-size: cover; background-position: 50%;"></div>
                    </div>
                </div></div> : ''}
            {elementType === 'button' ? <div class="container" id="themesDiv">
                <button selectedclass="btn-primary" value="btn-primary" name='btnThemeClass' onClick={(e) => { removeTheme() }} class="switchBtnTheme btn mt-2 mb-3 btn-primary" Style="width:50%; height:50px; display:block; margin:0 auto; color:white; font-size:15px">Remove Theme</button>
                <span>Choose Your Theme</span>
                <button selectedclass="btn-primary" value="btn-primary" name='btnThemeClass' onClick={(e) => { setBtnTheme('btn-primary') }} class="switchBtnTheme btn mt-2 mb-3 btn-primary" Style="width:95%; height:50px; display:block; margin:0 auto; color:white">Click to Sign Up</button>
                <button selectedclass="btn-secondary" value="btn-secondary" name='btnThemeClass' onClick={(e) => { setBtnTheme('btn-secondary') }} class="switchBtnTheme btn mt-2 mb-3 btn-secondary" Style="width:95%; height:50px; display:block; margin:0 auto; color:white">Click to Sign Up</button>
                <button selectedclass="btn-success" value="btn-success" name='btnThemeClass' onClick={(e) => { setBtnTheme('btn-success') }} class="switchBtnTheme btn mt-2 mb-3 btn-success" Style="width:95%; height:50px; display:block; margin:0 auto; color:white">Click to Sign Up</button>
                <button selectedclass="btn-danger" value="btn-danger" name='btnThemeClass' onClick={(e) => { setBtnTheme('btn-danger') }} class="switchBtnTheme btn mt-2 mb-3 btn-danger" Style="width:95%; height:50px; display:block; margin:0 auto; color:white">Click to Sign Up</button>
                <button selectedclass="btn-warning" value="btn-warning" name='btnThemeClass' onClick={(e) => { setBtnTheme('btn-warning') }} class="switchBtnTheme btn mt-2 mb-3 btn-warning" Style="width:95%; height:50px; display:block; margin:0 auto; color:white">Click to Sign Up</button>
                <button selectedclass="btn-info" value="btn-info" name='btnThemeClass' onClick={(e) => { setBtnTheme('btn-info') }} class="switchBtnTheme btn mt-2 mb-3 btn-info" Style="width:95%; height:50px; display:block; margin:0 auto; color:white">Click to Sign Up</button>
                <button selectedclass="btn-dark" value="btn-dark" name='btnThemeClass' onClick={(e) => { setBtnTheme('btn-dark') }} class="switchBtnTheme btn mt-2 mb-3 btn-dark" Style="width:95%; height:50px; display:block; margin:0 auto; color:white">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(52, 152, 219); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;border-width: 3px; border-style:solid; border-radius:10px; border-color:rgb(41, 128, 185)" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(52, 152, 219); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;border-width: 3px; border-style:solid; border-radius:10px; border-color:rgb(41, 128, 185)">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(44, 62, 80); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 10px; border-color: rgb(52, 73, 94); border-width: 3px" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(44, 62, 80); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 10px; border-color: rgb(52, 73, 94); border-width: 3px">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(44, 62, 80); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgba(255, 255, 255, 0); border-width: 1px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(44, 62, 80); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgba(255, 255, 255, 0); border-width: 1px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(220, 53, 69); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgba(255, 255, 255, 0); border-width: 1px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(220, 53, 69); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgba(255, 255, 255, 0); border-width: 1px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(158, 183, 75); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgba(255, 255, 255, 0); border-width: 1px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(158, 183, 75); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgba(255, 255, 255, 0); border-width: 1px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(208, 69, 27); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgba(255, 255, 255, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgb(208, 69, 27); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(208, 69, 27); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgba(255, 255, 255, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgb(208, 69, 27); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 0, 129); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgba(255, 255, 255, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgb(255, 0, 129); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 0, 129); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgba(255, 255, 255, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgb(255, 0, 129); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(44, 62, 80); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgba(255, 255, 255, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgb(44, 62, 80); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(44, 62, 80); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgba(255, 255, 255, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 0px; border-color: rgb(44, 62, 80); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(44, 62, 80); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;border-width: 3px; border-style:solid; border-radius:50px; border-color:rgb(33, 37, 41);" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(44, 62, 80); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;border-width: 3px; border-style:solid; border-radius:50px; border-color:rgb(33, 37, 41);">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(7, 123, 206); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(52, 152, 219); border-width: 3px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(7, 123, 206); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(52, 152, 219); border-width: 3px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(36, 109, 166); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(52, 152, 219); border-width: 3px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(36, 109, 166); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(52, 152, 219); border-width: 3px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(36, 109, 166); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 20px; border-color: rgb(41, 128, 185); border-width: 3px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(36, 109, 166); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 20px; border-color: rgb(41, 128, 185); border-width: 3px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(208, 69, 27); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;border-width: 2px; border-style:solid; border-radius:5px; border-color:rgb(52, 73, 94);" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(208, 69, 27); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;border-width: 2px; border-style:solid; border-radius:5px; border-color:rgb(52, 73, 94);">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(158, 183, 75); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(40, 167, 69); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(158, 183, 75); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(40, 167, 69); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(255, 152, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(230, 126, 34); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(255, 152, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(230, 126, 34); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(48, 160, 92); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(40, 167, 69); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(48, 160, 92); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(40, 167, 69); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(225, 51, 45); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(208, 69, 27); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(225, 51, 45); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(208, 69, 27); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(225, 51, 45); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 50px; border-color: rgb(208, 69, 27); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(225, 51, 45); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 50px; border-color: rgb(208, 69, 27); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(0, 128, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(40, 167, 69); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(0, 128, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(40, 167, 69); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(96, 125, 139); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(52, 73, 94); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(96, 125, 139); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(52, 73, 94); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(0, 0, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(52, 73, 94); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(0, 0, 0); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(52, 73, 94); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(241, 183, 80); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(230, 126, 34); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(241, 183, 80); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(230, 126, 34); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(230, 33, 67); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(230, 126, 34); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(230, 33, 67); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(230, 126, 34); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(255, 0, 129); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(230, 126, 34); border-width: 2px ;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 12px 35px 12px 37px; background-color: rgb(255, 0, 129); box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px; border-style: solid; border-radius: 5px; border-color: rgb(230, 126, 34); border-width: 2px ;">Click to Sign Up</button>
                <button selectedclass="fullBorder" name='btnThemeCss' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} value="font-weight: normal; border-width: 1px; border-style: solid; border-color: rgb(255, 0, 0); color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 20px 10px; background-color: rgb(52, 152, 219); border-radius: 0px;" class="switchBtnTheme btn mt-2 mb-3 fullBorder" Style="width:95%; height:50px; display:block; margin:0 auto; font-weight: normal; border-width: 1px; border-style: solid; border-color: rgb(255, 0, 0); color: rgb(255, 255, 255); text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px; padding: 20px 10px; background-color: rgb(52, 152, 219); border-radius: 0px;">Click to Sign Up</button>
            </div> : ''}
        </>
    )
}

export default ElementThemeBar