import { memo, useEffect, createRef } from 'react'
import { useDrop } from 'react-dnd'
import { Section } from './createdcomponent/Section'


export const Dustbin = memo(function Dustbin({
    accept,
    sections,
    onDrop,
    setSections,
    setRowchange,
    sectionIdCount,
    setSectionIdCount,
    setAddSectionIndex,
    setSidebarOpen,
    addCustomStyle,
    bgElem,
    setSideNav,
    setRowId,
    rowId,
    rowchange,
    setEnabledrop,
    enableDrop,
    setRowCss,
    rowCss,
    setRowHeading,
    rowName,
    setRowName,
    rowIdTrack,
    setRowIdTrack,
    sectionIdTrack,
    rowBgElem,
    setColumnIdTrack,
    columnIdTrack,
    columnName,
    setColumnName,
    setColumnHeading,
    setColumnCss,
    columnBgElem,
    columnCss,
    detectRow,
    columnIndex,
    columnSize,
    setColumnSize,
    targetColumnSize,
    nextColumnSize,
    setTargetColumnSize,
    setNextColumnSize,
    setElementIdCount,
    elementIdCount,
    activeElement,
    prevElement,
    setElementName,
    elementName,
    setElementHeading,
    setElementCss,
    elementCss,
    setAddRowIndex,
    setAddColumnIndex,
    preview,
    setElementType,
    elementType,
    setPrevElement,
    setActiveElement,

    openFunnelData,
    enableEditSection,
    setEnableEditSection,
    cssAddingBarByClick,
    enableRowSection,
    setEnableRowSection,
    setEnableColumnBar,
    enableColumnBar,
    elementEditBar,
    setElementEditBar,
    sidebarOpen,
    containType,
    tabsSection,
    enableTabs,
    setEnableTabs,
    setTabSection,
    mobileView,
    tabView,
    saving,
    setPreview,
    backgroungData,
    undoComponent, 
    setUndoComponent,
}) {

    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })
    const moveCard = (dragIndex, hoverIndex) => {
        var data = sections
        data[dragIndex] = data.splice(hoverIndex, 1, data[dragIndex])[0];
        setSections(data)
        setRowchange(data)
    }


    const renderCard = (section, index) => {
        return (
            <Section
                key={section.id}
                index={index}
                sectionId={section.sectionId}
                id={section.id}
                style={section.sectionstyle}
                moveCard={moveCard}
                setSections={setSections}
                sections={sections}
                section={section}
                sectionClass={section.sectionClass}
                rowchange={rowchange}
                setRowchange={setRowchange}
                sectionIdCount={sectionIdCount}
                setSectionIdCount={setSectionIdCount}
                setAddSectionIndex={setAddSectionIndex}
                setSidebarOpen={setSidebarOpen}
                addCustomStyle={addCustomStyle}
                bgElem={bgElem}
                setSideNav={setSideNav}
                setRowId={setRowId}
                rowId={rowId}
                setEnabledrop={setEnabledrop}
                enableDrop={enableDrop}
                setRowCss={setRowCss}
                rowCss={rowCss}
                setRowHeading={setRowHeading}
                rowName={rowName}
                setRowName={setRowName}
                rowIdTrack={rowIdTrack}
                setRowIdTrack={setRowIdTrack}
                sectionIdTrack={sectionIdTrack}
                rowBgElem={rowBgElem}
                setColumnIdTrack={setColumnIdTrack}
                columnIdTrack={columnIdTrack}
                columnName={columnName}
                setColumnName={setColumnName}
                setColumnHeading={setColumnHeading}
                setColumnCss={setColumnCss}
                columnCss={columnCss}
                columnBgElem={columnBgElem}
                detectRow={detectRow}
                columnIndex={columnIndex}
                columnSize={columnSize}
                setColumnSize={setColumnSize}
                targetColumnSize={targetColumnSize}
                nextColumnSize={nextColumnSize}
                setTargetColumnSize={setTargetColumnSize}
                setNextColumnSize={setNextColumnSize}
                setElementIdCount={setElementIdCount}
                elementIdCount={elementIdCount}
                activeElement={activeElement}
                prevElement={prevElement}
                setElementName={setElementName}
                elementName={elementName}
                setElementHeading={setElementHeading}
                setElementCss={setElementCss}
                elementCss={elementCss}
                setAddRowIndex={setAddRowIndex}
                setAddColumnIndex={setAddColumnIndex}
                preview={preview}
                setElementType={setElementType}
                elementType={elementType}
                setPrevElement={setPrevElement}
                setActiveElement={setActiveElement}

                enableEditSection={enableEditSection}
                setEnableEditSection={setEnableEditSection}
                cssAddingBarByClick={cssAddingBarByClick}
                enableRowSection={enableRowSection}
                setEnableRowSection={setEnableRowSection}
                setEnableColumnBar={setEnableColumnBar}
                enableColumnBar={enableColumnBar}
                elementEditBar={elementEditBar}
                setElementEditBar={setElementEditBar}
                sidebarOpen={sidebarOpen}
                openFunnelData={openFunnelData}
                containType={containType}
                tabsSection={tabsSection}
                enableTabs={enableTabs}
                setEnableTabs={setEnableTabs}
                setTabSection={setTabSection}
                mobileView={mobileView}
                saving={saving}
                undoComponent={undoComponent}
                setUndoComponent={setUndoComponent}
            />
        )
    }
    useEffect(() => {
        document.getElementById('main-dustbin').addEventListener('click', () => {
            setSidebarOpen(false)
        })
    }, [])
    
    return (
        <div ref={drop} Style={`height: 100%; background-color:white; background-image:url(${backgroungData.desktopBg}); ${mobileView ? `width:414px;margin:auto; padding:10px 0px !important; background-image:url(${backgroungData.mobileBg})` : tabView ? 'width:768px;margin:auto' : 'width:100%'};display: flex;flex-direction: column;align-items: center;overflow: ${containType === 'tabContainer' ? 'hidden' : preview ? 'auto' : 'scroll'};padding: 20px 2px;background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover;`} data-testid="dustbin" id="main-dustbin">
            {sections.map((section, i) => {
                return renderCard(section, i)
            })}
        </div>
    )
})
