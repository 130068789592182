import React, { useEffect, useState } from 'react'

function BgCarouselCard({ Css, setCss, setCarouselInputBox, carouselInputBox, index, setEnableEditComponent, setBgCrouselElem, setUrlData, type,setUrlTargetIndex,setUrlTarget }) {
    const [data, setdata] = useState(Css.BgCarouselImage[index])
    var dummyData = {
        "bgImage": '',
        "bgImageText": '',
        "bgImageSubText": ''
    }
    useEffect(() => {
        // if(type==='section'){
        //     var updatingData = spaceCss
        //     updatingData.BgCarouselImage[index] = data
        //     setSpaceCss(updatingData)
        //     setUrlData(data)
        // }
        var updatingData = Css
        updatingData.BgCarouselImage[index] = data
        setCss(updatingData)
        setUrlData(data)
        // else if(type==='row'){
        //     var updatingData = spaceCss
        //     updatingData.BgCarouselImage[index] = data
        //     setSpaceCss(updatingData)
        //     setUrlData(data)
        // }
    }, [data])
    function addCardForCarousel() {
        // if(type==='section'){
        //     var cssData = spaceCss
        //     cssData.BgCarouselImage.push(dummyData)
        //     setSpaceCss(cssData)
        //     setBgCrouselElem(false)
        //     setTimeout(() => {
        //         setBgCrouselElem(true)
        //     }, 10);
            
        // }
        var cssData = Css
        cssData.BgCarouselImage.push(dummyData)
        setCss(cssData)
        setBgCrouselElem(false)
        setTimeout(() => {
            setBgCrouselElem(true)
        }, 10);
        // else if(type==='row'){
        //     var cssData = spaceCss
        //     cssData.BgCarouselImage.push(dummyData)
        //     setSpaceCss(cssData)
        //     setBgCrouselElem(false)
        //     setTimeout(() => {
        //         setBgCrouselElem(true)
        //     }, 10);
            
        // }
    }
    function deleteCrouselCard(index){
        var cssData= Css
        var updateCrousel=cssData.BgCarouselImage
        updateCrousel.splice(index,1)
        cssData.BgCarouselImage=updateCrousel
        setCss(cssData)
        setBgCrouselElem(false)
        setTimeout(() => {
            setBgCrouselElem(true)
        }, 10);
    }

    function openModal(){
        setUrlTargetIndex(index)
        setUrlTarget('bgImage')
    }

    return (
        <div className="carouselOptionGroup" Style="position:relative">
            <div className='d-flex justify-content-between'>
                <span>Image #{index + 1}</span>
                <i class="fa fa-trash" aria-hidden="true" Style='cursor:pointer' onClick={()=>{deleteCrouselCard(index)}}></i>
            </div>
            <div className="input-group mb-3">
                <input id="carouselImage1" type="text" className="form-control BgImageCarousel" placeholder="Image URL" name='bgImage' value={data.bgImage} onChange={(e) => { setdata({ ...data, [e.target.name]: e.target.value }) }} />
                <div className="input-group-append">
                    <button inputid="carouselImage1" className="OpenImageModalBtn btn btn-outline-secondary" type="button" data-bs-toggle="modal" data-bs-target="#sectionFileManager" onClick={openModal}><i className="fa fa-image"></i></button>
                </div>
            </div>
            <div className="row" Style="margin-bottom: 15px;">
                <div className="col-md-6">
                    <span>Image #{index + 1} Title</span>
                    <input id="carouselImage1Title" maxlength="20" type="text" className="form-control carouselTexts" placeholder="Title" name='bgImageText' value={data.bgImageText} onChange={(e) => { setdata({ ...data, [e.target.name]: e.target.value }) }} />
                </div>
                <div className="col-md-6" Style="padding-left: 0px;">
                    <span>Image #{index + 1} SubText</span>
                    <input id="carouselImage1Sub" maxlength="60" type="text" className="form-control carouselTexts" placeholder="Sub Text" name='bgImageSubText' value={data.bgImageSubText} onChange={(e) => { setdata({ ...data, [e.target.name]: e.target.value }) }} />
                </div>
            </div>
            <button clicked="false" onClick={() => { addCardForCarousel() }} class="PlusSectionButton " Style="display: block; z-index: 1; padding: 4px; color: white; position: absolute; border-radius: 100px; background-color: rgb(55, 202, 55); height: 22px; width: 22px; left: 45%; top: calc(100% - 10px); border: none; outline: none;bottom: 0px;z-index: 5;"><span Style="display:flex"><i class="fas fa-plus" aria-hidden="true"></i></span></button>
        </div>
    )
}

export default BgCarouselCard