import React, { useEffect, useState, useContext } from "react";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { HomeData } from "../../../../../NavigationComponents/Home";



import ReactQuill from "react-quill";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
  console.log(this.quill.history)
}
function redoChange() {
  console.log(this.quill.history)
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor

// Formats objects for setting up the Quill editor
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

function ImageFeatureHeading({
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType,
  detectActiveImageFeature,
  mobileView
}) {
  const [HTMLValue, setHTMLValue] = useState({ value: element.headValue });
  const handleChange = (value) => {
    setHTMLValue({ value });
    // console.log(value)
    var sectionData = sections
    sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[index].headValue = value
    setSections(sectionData)
  };
  const modules = {
    toolbar: {
      container: `#toolbar_heading_${id}`,
      handlers: {
        undo: undoChange,
        redo: redoChange
      }
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true
    }
  };
  const { setEnableColumnResize, enableColumnResize } = useContext(HomeData)

  const [clickDetect, setClickDetect] = useState(0)
  const [iconStyle, setIconStyle] = useState()



    const handleEditorClick = (e) => {
    e.stopPropagation();
    // setElementType('headline')
    if (activeElement !== `imgFeatureHead${id}`) {
      if (activeElement !== `elem_${id}`) {
        setElementType('imagefeature')
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
      detectActiveImageFeature(`imgFeatureHead${id}`)
    }
  }

  useEffect(() => {
    detectActiveImageFeature(`imgFeatureHead${id}`);
  }, [])



  useEffect(() => {
    setTimeout(() => {
      document.getElementById(id + "_heading_editor").children[0].children[0].innerHTML = element.headValue
      if (activeElement) {
        var activId = activeElement
        var prevId = prevElement
        console.log(activId)
        console.log(prevId)
        if(activId.includes("imgFeatureHead")){
          document.getElementById("toolbar_heading_"+activId.slice(14)).style.display = "block"
          document.getElementById("toolbar_para_"+prevId.slice(14)).style.display = "none"
        }
      }
    }, 140);
  }, [prevElement, activeElement, clickDetect])

  useEffect(() => {
      document.getElementById("toolbar_heading_"+id).style.display = "none"
  }, [])


  useEffect(() => {
    if (preview === true) {
      document.getElementById("toolbar_heading_" + id).style.display = `none`
      var editorEditable = document.getElementById(id + "_heading_editor").children[0].children[0]
      editorEditable.contentEditable = 'false'
    }
    if (preview === false) {
      setTimeout(() => {
        var editorEditable = document.getElementById(id + "_heading_editor").children[0].children[0]
        editorEditable.contentEditable = 'true'
      }, 300);
    }
  }, [preview, clickDetect])


  useEffect(() => {
    if (element.icon) {
      if (element.icon !== "blank") {
        if (element.icon.includes("</svg>")) {
          setIconStyle(`#${id + "_heading_editor"}>.ql-container>.ql-editor>h3::before {
          content: url('data:image/svg+xml, <svg stroke="currentColor" fill="${element.iconColor ? element.iconColor : 'currentcolor'}" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg">${element.icon.slice(element.icon.indexOf("<path"), element.icon.indexOf("</svg>"))}</svg>');
          display: inline-block;
          width: ${element.icon ? mobileView?element.mobileIconWidth:element.iconWidth : ''}px;
        };
        `)
        } else {
          setIconStyle(`#${id + "_heading_editor"}>.ql-container>.ql-editor>h3::before {
          content: url('data:image/svg+xml, <svg stroke="currentColor" fill="${element.iconColor ? element.iconColor : 'currentcolor'}" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto"  xmlns="http://www.w3.org/2000/svg">${element.icon}</svg>');
          display: inline-block;
          width: ${element.icon ? mobileView?element.mobileIconWidth:element.iconWidth : ''}px;
        };
        `)
        }
      }
      else {
        setIconStyle(`#${id + "_heading_editor"}>.ql-container>.ql-editor>h3::before {
        content: url('data:image/svg+xml, ${element.icon}');
        display: inline-block;
        width: 0px;
      };
      `)
      }
    }
  }, [element.icon, element.iconWidth, element.iconColor, element.mobileIconWidth, mobileView])



  useEffect(() => {
    document.getElementById(`imageFeatureHeading_${id}responsiveStyle`).innerHTML = `<style>@media only screen and (max-width: 768px) { #${id + "_heading_editor"}>.ql-container{${element.elementObject ? `font-size:${element.imageFeatureCssObj.headerMobileFontSize} !important` : ''}} #${id + "_heading_editor"}>.ql-container>.ql-editor>h3::before{width: ${element.icon ? element.mobileIconWidth : ''}px !important;}}</style>`
  }, [preview])


  useEffect(() => {
    setTimeout(() => {
      document.getElementById(id + "_heading_editor").children[0].children[0].style.textAlign = element.elementObject.textAlign
      mobileView ? document.getElementById(id + "_heading_editor").children[0].style = `font-size:${element.imageFeatureCssObj.headerMobileFontSize}px; color:${element.imageFeatureCssObj.imgFeatureHeaderColor}; text-shadow: ${element.imageFeatureCssObj.elementTextShadow}` : document.getElementById(id + "_heading_editor").children[0].style = `font-size:${element.imageFeatureCssObj.headerDesktopFontSize}px; color:${element.imageFeatureCssObj.imgFeatureHeaderColor}; text-shadow: ${element.imageFeatureCssObj.elementTextShadow}`
    }, 230);
    // console.log(element.imageFeatureCssObj)
  }, [element.imageFeatureCssObj, mobileView])



  function mouseDown() {
    setEnableColumnResize(true)
  }
  function mouseUp() {
    setEnableColumnResize(false)
  }

  function pressEnter(event){
    if (event.keyCode === 13 && !event.shiftKey) {
      var valueData=HTMLValue.value.replaceAll('<p><br></p>','').replaceAll('<p> </p>','').replaceAll('<h3><span class="ql-size-medium"> </span></h3>','')+'<h3><span class="ql-size-medium"><br></span></h3>'
      setHTMLValue({ value: valueData });
      document.getElementById(id).click()
    }
  }

 
  

  return (
    <>
      <div Style='display:none'>
        <style >
          {iconStyle}
        </style>
      </div>
      <div id={`imageFeatureHeading_${id}responsiveStyle`} Style='display:none'>
        <style>

        </style>
      </div>
        <div className="text-editor" id={`imgFeatureHead${id}_heading`} onClick={handleEditorClick} onMouseDown={mouseDown} onMouseUp={mouseUp}>
          <ReactQuill
            theme="snow"
            value={HTMLValue.value}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            id={id + "_heading_editor"}
            preserveWhitespace={true}
            onKeyDown={pressEnter}
          />
        </div>
        <div id={`toolbar_heading_${id}`} className="imageFeatureToolbar">
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
          </span>
          <span className="ql-formats">
            <select className="ql-color" />
            <select className="ql-background" />
          </span>
        </div>
    </>
  );
};
export default ImageFeatureHeading