import React from 'react'
import { TextField } from "@mui/material";
import { RiImageAddLine } from "react-icons/ri";

function BgImage({ Css, setCss, setUrlTarget }) {
    return (
        <div id="StaticBGImage" className='w-100'>
            <div className="input-group mt-1 mb-3" Style='position:relative'>
                <TextField label="Background Image (Desktop)" id="backgroundImageDesktopSingle" value={Css.backgroundImageDesktopSingle} size="small" name='backgroundImageDesktopSingle' onChange={(e) => { setCss({ ...Css, [e.target.name]: e.target.value }) }} />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary bgImage btn-7"   type="button" data-bs-toggle="modal" data-bs-target="#sectionFileManager" onClick={() => { setUrlTarget('backgroundImageDesktopSingle') }}><span><RiImageAddLine/></span></button>
                </div>
            </div>
            <div className="input-group mt-1 mb-3" Style='position:relative'>
                <TextField label="Background Image (Mobile)" id="outlined-size-small" value={Css.backgroundImageMobileSingle} size="small" name='backgroundImageMobileSingle' onChange={(e) => { setCss({ ...Css, [e.target.name]: e.target.value }) }} />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary bgImage btn-7" type="button" data-bs-toggle="modal" data-bs-target="#sectionFileManager" onClick={() => { setUrlTarget('backgroundImageMobileSingle') }}><span><RiImageAddLine/></span></button>
                </div>
            </div>
        </div>
    )
}

export default BgImage