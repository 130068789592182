import React, { useState, useEffect } from 'react'
import { SketchPicker, CirclePicker, SwatchesPicker,AlphaPicker,HuePicker,ChromePicker  } from 'react-color'

function ColorPicker({ color, setColor, Css, setCss, setEnableBgColor, type , setRowchange}) {
    const [enableCustomColor, setEnableCustomColor] = useState(false)
    const [enableGradientColor, setEnableGradientColor] = useState(false)
    const [colorType,setColorType]=useState()
    function addcustomcolor() {
        setEnableGradientColor(false)
        setEnableCustomColor(!enableCustomColor)
    }
    function addgradientcolor() {
        setEnableCustomColor(false)
        setEnableGradientColor(!enableGradientColor)
    }
    function handleChange(currentcolor) {
        if (type === 'section') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.sectionbackgroundcolor = currentcolor
                setCss(data)
                // setRowchange(currentcolor)
                setEnableBgColor(false)
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.sectionbackgroundcolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                // setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                

            }

        }
        if (type === 'row') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.rowbackgroundcolor = currentcolor
                setCss(data)
                // setRowchange(currentcolor)
                setEnableBgColor(false)
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.rowbackgroundcolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                // setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                

            }

        }
        if (type === 'column') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.columnbackgroundcolor = currentcolor
                setCss(data)
                // setRowchange(currentcolor)
                setEnableBgColor(false)
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.columnbackgroundcolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                // setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                

            }

        }
        if (type === 'element') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.elementbackgroundcolor = currentcolor
                setCss(data)
                // setRowchange(currentcolor)
                setEnableBgColor(false)
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.elementbackgroundcolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                // setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'elementTextColor') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.textColor = currentcolor
                setCss(data)
                // setRowchange(currentcolor)
                setEnableBgColor(false)
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.textColor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                // setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'separatorColor') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.separatorColor = currentcolor
                setCss(data)
                // setRowchange(currentcolor)
                setEnableBgColor(false)
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.separatorColor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                // setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'iconColor') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.iconColor = currentcolor
                setCss(data)
                // setRowchange(currentcolor)
                setEnableBgColor(false)
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.iconColor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                // setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'imagefeatureheader') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.imgFeatureHeaderColor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.imgFeatureHeaderColor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'imagefeaturetext') {
            if (currentcolor === 'transparent') {
                setColor(currentcolor)
                var data = Css
                data.imgFeatureTextColor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                setColor(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                var data = Css
                data.imgFeatureTextColor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'btnBackground') {
            if (currentcolor === 'transparent') {
                var data = Css
                data.btnBackgroundColor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                var data = Css
                data.btnBackgroundColor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'formBackground') {
            if (currentcolor === 'transparent') {
                var data = Css
                data.formBackgroundcolor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                var data = Css
                data.formBackgroundcolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'formLabelColor') {
            if (currentcolor === 'transparent') {
                var data = Css
                data.formLabelColor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                var data = Css
                data.formLabelColor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'inputTextColor') {
            if (currentcolor === 'transparent') {
                var data = Css
                data.inputTextColor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                var data = Css
                data.inputTextColor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'themecolor') {
            if (currentcolor === 'transparent') {
                var data = Css
                data.themecolor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                var data = Css
                data.themecolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'tabtextcolor') {
            if (currentcolor === 'transparent') {
                var data = Css
                data.tabtextcolor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                var data = Css
                data.tabtextcolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'countdowntextColor') {
            if (currentcolor === 'transparent') {
                var data = Css
                data.countdowntextcolor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                var data = Css
                data.countdowntextcolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        if (type === 'countdownbgColor') {
            if (currentcolor === 'transparent') {
                var data = Css
                data.countdownbgcolor = currentcolor
                setCss(data)
                setRowchange(currentcolor)
                setEnableBgColor(false)
                
            }
            else {
                var data = Css
                data.countdownbgcolor = `rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`
                setCss(data)
                setRowchange(`rgba(${currentcolor.rgb.r},${currentcolor.rgb.g},${currentcolor.rgb.b},${currentcolor.rgb.a})`)
                
            }

        }
        
    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         if (enableCustomColor === false) {
    //             
    //         }
    //     }, 10);
    // }, [color])
    
    return (
        <div Style='margin:auto;padding:20px'>
            <div Style='margin-bottom:10px'>
                <CirclePicker  onChangeComplete={handleChange} color={color} onChange={()=>{setEnableBgColor(false)}}/>
                <div className='d-flex flex-wrap justify-content-center'>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { addcustomcolor() }}>Custom</button>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { addgradientcolor() }}>Gradient</button>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { handleChange('transparent') }}>Transparent</button>
                </div>
            </div>
            {enableCustomColor ? <SketchPicker color={color} onChangeComplete={handleChange} /> : ''}
            {enableGradientColor ? <SwatchesPicker color={color} onChangeComplete={handleChange} /> : ''}
        </div>
    )
}

export default ColorPicker