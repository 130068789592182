import React, { useState, useEffect, useContext } from 'react';
import 'quill/dist/quill.snow.css';
import ImageFeatureHeading from './ImageFeatureComponents/ImageFeatureHeading';
import ImageFeaturePara from './ImageFeatureComponents/ImageFeaturePara';
import { HomeData } from '../../../../NavigationComponents/Home';
import glass from '../../../../static/images/glass.png'
import blackhole from '../../../../static/images/blackhole.png'
import mountain from '../../../../static/images/mountain.png'
import scribblemask from '../../../../static/images/scribblemask.png'
import speechmask from '../../../../static/images/speechmask.png'
import splash from '../../../../static/images/splash.png'
import streak from '../../../../static/images/streak.png'
function ImageFeature(
  { size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType,
    setRowchange,
    elementType,
    mobileView
  }
) {
  const { setEnableColumnResize, enableColumnResize } = useContext(HomeData)
  const [clickDetect, setClickDetect] = useState(0)
  const [activeImageFeature, setActiveImageFeature] = useState()
  const [prevImageFeature, setPrevImageFeature] = useState()

  const handleEditorClickMain = (e) => {
    e.stopPropagation();
    setElementType('imagefeature')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }

  function detectActiveImageFeature(boxId) {
    if (activeImageFeature && activeImageFeature !== boxId) {
      setPrevImageFeature(activeImageFeature)
    }
    setActiveImageFeature(boxId)
    setRowchange(boxId)
  }


  useEffect(() => {
    setTimeout(() => {
      if (prevElement === `elem_${id}`) {
        if (activeImageFeature.includes("imgFeatureHead")) {
          document.getElementById("toolbar_heading_" + activeImageFeature.slice(14)).style.display = "none"
          document.getElementById("toolbar_para_" + prevImageFeature.slice(14)).style.display = "none"
        }
        else {
          document.getElementById("toolbar_para_" + activeImageFeature.slice(14)).style.display = "none"
          document.getElementById("toolbar_heading_" + prevImageFeature.slice(14)).style.display = "none"
        }
        // document.getElementById(activeImageFeature).children[0].style.display = "none"
        // document.getElementById(prevImageFeature).children[0].style.display = "none"
        setActiveImageFeature()
        setPrevImageFeature()
      }
    }, 250);
  }, [activeElement, prevElement])


  function mouseDown() {
    setEnableColumnResize(true)
  }
  function mouseUp() {
    setEnableColumnResize(false)
  }
  var imageMask;
  if(element.elementObject && element.elementObject.imageMask){
    if(element.elementObject.imageMask==='none'){
      imageMask=''
    }
    else if(element.elementObject.imageMask==='glass'){
      imageMask=`-webkit-mask-image: url(${glass})`
    }
    else if(element.elementObject.imageMask==='streak'){
      imageMask=`-webkit-mask-image: url(${streak})`
    }
    else if(element.elementObject.imageMask==='splash'){
      imageMask=`-webkit-mask-image: url(${splash})`
    }
    else if(element.elementObject.imageMask==='speechmask'){
      imageMask=`-webkit-mask-image: url(${speechmask})`
    }
    else if(element.elementObject.imageMask==='scribblemask'){
      imageMask=`-webkit-mask-image: url(${scribblemask})`
    }
    else if(element.elementObject.imageMask==='mountain'){
      imageMask=`-webkit-mask-image: url(${mountain})`
    }
    else if(element.elementObject.imageMask==='blackhole'){
      imageMask=`-webkit-mask-image: url(${blackhole})`
    }
  }
  return (
    <div id={id} className={`${element.elementObject ? element.elementObject.columnOrder : ''} d-flex`} Style='margin: 0px;' headerFontsize={element.elementObject ? element.elementObject.headerDesktopFontSize : ''} headerColor={element.elementObject ? element.elementObject.imgFeatureHeaderColor : ''} textFontsize={element.elementObject ? element.elementObject.textDesktopFontSize : ''} textColor={element.elementObject ? element.elementObject.imgFeatureTextColor : ''} onClick={handleEditorClickMain}>
      <div className={`ImageColumn col col-md-${element.elementObject ? element.elementObject.columnSize : '3'}`} Style="text-align: center; padding: 0px;">
        <div Style={`overflow:hidden; ${imageMask}; -webkit-mask-size: contain; -webkit-mask-position: center top; -webkit-mask-repeat: no-repeat;  width: fit-content;`}>
          <img action="URL" className={`${element.elementObject ? element.elementObject.imageAnimation : ''}`} src={element.elementObject ? element.elementObject.imageURL : ''} alt={element.elementObject ? element.elementObject.imageAltText : ''} width={element.elementObject ? element.elementObject.imageWidth : ''} height={element.elementObject ? element.elementObject.imageHeight : ''} Style={`max-width: 100%; box-shadow: ${element.elementObject ? element.elementObject.imageBoxShadow : ''}; border: ${element.elementObject ? element.elementObject.imageBoxBorder : ''}; border-radius:${element.elementObject ? element.elementObject.imageRadius : ''}; opacity: ${element.elementObject ? element.elementObject.elementOpacity : ''}; filter: ${element.elementObject ? element.elementObject.imageEditing : ''};`} />
        </div>
      </div>
      <div className={`col-${element.elementObject ? 12 - element.elementObject.columnSize : '9'}  global${element.elementObject ? element.elementObject.fonttype : ''}Font`} onMouseDown={mouseDown} onMouseUp={mouseUp}>
        <ImageFeatureHeading id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeImageFeature} prevElement={prevImageFeature} preview={preview} setElementType={setElementType} elementType={elementType} setRowchange={setRowchange} detectActiveImageFeature={detectActiveImageFeature} handleEditorClickMain={handleEditorClickMain} />
        <ImageFeaturePara id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeImageFeature} prevElement={prevImageFeature} preview={preview} setElementType={setElementType} elementType={elementType} setRowchange={setRowchange} detectActiveImageFeature={detectActiveImageFeature} handleEditorClickMain={handleEditorClickMain} />
      </div>
    </div>
  )
}

export default ImageFeature