import React,{useEffect} from 'react'
import { TextField} from "@mui/material";

function ElementPopupVideoAdd({setElementCss,elementCss}) {
    useEffect(() => {
        var embadedAddedCss=elementCss
        let index=embadedAddedCss.videopopupUrl.indexOf("watch?v=");
        if(index!=-1){
            embadedAddedCss.videopopupEmbaded=embadedAddedCss.videopopupUrl.slice(index+8)
            setElementCss(embadedAddedCss)
        }
        if(embadedAddedCss.videopopupUrl.includes("https://www.youtube.com/watch?v=")){
            if(embadedAddedCss.videopopupUrl.slice(32).length===11){
                embadedAddedCss.youtubeValidUrl=true
            }
            else{
                embadedAddedCss.youtubeValidUrl=false
            }
        }
    }, [elementCss.videopopupUrl])
    return (
        <>
            <TextField  label="Video URL" variant="outlined" size="small"  name='videopopupUrl' value={elementCss.videopopupUrl} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
            <p id="errorMessage" Style="color:red;"></p>
        </>
    )
}

export default ElementPopupVideoAdd