import React from 'react'

function TextAreaField({size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType}) {
    return (
        <div className={`d-flex flex-column justify-content-start align-items-start  global${element.elementObject? element.elementObject.fonttype:''}Font`} Style={` font-family:${element.elementObject && element.elementObject.fonttype==="Custom" ? element.elementObject.fontstyle : ''}`}>
            <label className="customInputLabel form-label" Style={`font-size:18px; display:${element.elementObject && (element.elementObject.showlabel===false)?'none':'block'}; color:${element.elementObject?element.elementObject.formLabelColor:''}`}>{element.elementObject?element.elementObject.textarealabeltext:'Textarea Label'}</label>
            <textarea required={element.elementObject && element.elementObject.inputrequired==='true'?true:false} name="textarea" dfontsize="16px" mfontsize="16px" className="form-control globalHeaderFont customInputElm form-control" placeholder={element.elementObject?element.elementObject.textareaplaceholdertext:'Enter your info here...'} disabled="" Style={`text-align: left; cursor: not-allowed; background-color:${element.elementObject?element.elementObject.formBackgroundcolor:'transparent'}; color:${element.elementObject?element.elementObject.inputTextColor:'black'}; font-size:${element.elementObject?element.elementObject.formInputDesktopSize:'16'}px; height:${element.elementObject?element.elementObject.formInputHeight:'38'}px; font-weight:${element.elementObject?element.elementObject.formInputfontweight:''}; text-align:${element.elementObject?element.elementObject.formInputtextalign:''}; font-weight:${element.elementObject?element.elementObject.formInputfontweight:''}; border-radius:${element.elementObject?element.elementObject.formInputborderradius:'0px'};`}></textarea>
        </div>
    )
}

export default TextAreaField