import React, { useState, useEffect } from 'react'
import { animated, useSpring } from '@react-spring/web'
import AdvancedElement from './LeftSideBarComponents/AdvancedElement'
import GeneralElement from './LeftSideBarComponents/GeneralElement'
import { BsArrowBarRight } from "react-icons/bs";

function LeftSideBar({setAttachedImage, attachedImage, mobileView, sidebarOpen, funnelId, setAllImg, fetchImg, allImg, setEnableEditSection, sectionName, setSectionName, sections, sectionIdTrack, setSections, setRowchange,  cssEditElemReRender, setSpaceCss, spaceCss, setSectionHeading, setBgElem, bgElem }) {
    // Section Heading control
    const [leftBarElement, setLeftBarElement] = useState(true)
    const [barWidth, setBarWidth] = useState("width:40%")

    const styles = useSpring(mobileView?{
        from: {
            width: "0px",
            position: "relative",
            right:'0',
        },
        to: {
            width: "350px",
            position: "relative",
            right:'0',
        },
        config: { mass: 2, tension: 3000, friction: 120 },
    }:{
        from: {
            width: "0px",
        },
        to: {
            width: "350px",
        },
        config: { mass: 2, tension: 3000, friction: 120 },
    })
    const [color, setColor] = useState(spaceCss.sectionbackgroundcolor)
    const [borderColor, setBorderColor] = useState(spaceCss.borderstyleCss.color)
    const [shapeColor, setShapeColor] = useState(spaceCss.shapecolor)

    // Function for changing value of sections
    useEffect(() => {
        var sectionChangeData = sections
        var sectionStyle = `padding-top:${spaceCss.paddingTop}px; padding-bottom:${spaceCss.paddingBottom}px; padding-left:${spaceCss.paddingLeft}px; padding-right:${spaceCss.paddingRight}px; margin-top:${spaceCss.marginTop}px; margin-bottom:${spaceCss.marginBottom}px; box-shadow:${spaceCss.boxShadow}; background-color: ${spaceCss.sectionbackgroundcolor}; border${spaceCss.border}: ${spaceCss.borderstyleCss.pixel} ${spaceCss.borderstyleCss.type} ${spaceCss.borderstyleCss.color}; border-radius:${spaceCss.borderRadius}; `
        var mobileSectionStyle = `padding-top:${spaceCss.paddingTopMobile}px; padding-bottom:${spaceCss.paddingBottomMobile}px; padding-left:${spaceCss.paddingLeft}px; padding-right:${spaceCss.paddingRight}px; margin-top:${spaceCss.marginTopMobile}px; margin-bottom:${spaceCss.marginBottomMobile}px; box-shadow:${spaceCss.boxShadow}; background-color: ${spaceCss.sectionbackgroundcolor}; border${spaceCss.border}: ${spaceCss.borderstyleCss.pixel} ${spaceCss.borderstyleCss.type} ${spaceCss.borderstyleCss.color}; border-radius:${spaceCss.borderRadius}; `
        sectionChangeData.forEach((element) => {
            if (element.id === sectionIdTrack) {
                element.sectionName = sectionName;
                element.sectionstyle = sectionStyle;
                element.mobilesectionstyle = mobileSectionStyle;
                element.styleObject = spaceCss
            }
            setSectionHeading(sectionName);
            setSections(sectionChangeData);
        });
        setRowchange(spaceCss)
    }, [sectionName, spaceCss, sections, color, borderColor, shapeColor])
    useEffect(() => {
        var data = sections
        sections.shapeColor = shapeColor
        setRowchange(data)
    }, [shapeColor])

    function leftBarElementSet(id) {
        if (id === "general") {
            setLeftBarElement(true)
            setBarWidth("width:40%")
        }
        else if (id === "advanced") {
            setLeftBarElement(false)
            setBarWidth("width:80%")
        }
    }
    useEffect(() => {
        setRowchange(color)
    }, [color])
    useEffect(() => {
        setRowchange(spaceCss.BgCarouselImage)
    }, [spaceCss.BgCarouselImage.bgImage])
    return (
        <>
            {sidebarOpen ? <div id="leftSideNav" className="leftNav customNav custom_scroll bg-light " Style={`width:350px; ${mobileView?'position:absolute; right:0':'position:relative'} `}>
                <div Style="position: sticky; background-color: #f8f9fa; z-index: 10; top: 0px; width: 100%;">
                    
                    <hr className='m-0'/>
                    <nav>
                        <div className="nav nav-tabs justify-content-around" id="nav-tab" role="tablist">
                            <button className="btnTab btnMainTabs " id='leftBarGeneral' data-bs-step="1" data-toggle="tab" href="#nav-general" role="tab" aria-selected="true" onClick={() => { leftBarElementSet("general") }}>General</button>
                            <button className="btnTab btnMainTabs" id='leftBarAdvanced' data-bs-step="4" data-toggle="tab" href="#nav-advanced" role="tab" aria-selected="false" onClick={() => { leftBarElementSet("advanced") }}>Advanced</button>
                            <span onClick={() => { setEnableEditSection(false) }} Style='font-size: 25px; cursor:pointer; padding-top: 8px;'><BsArrowBarRight /></span> 
                            <div className="progress bg-transparent" Style="height: 3px;position: absolute;width: 100%;bottom: 0;">
                                <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="5" Style={barWidth}></div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="tab-content" id="nav-tabContent" Style="box-shadow:none">
                    {cssEditElemReRender ? leftBarElement ? <GeneralElement setAttachedImage={setAttachedImage} attachedImage={attachedImage}  funnelId={funnelId} setAllImg={setAllImg} setSpaceCss={setSpaceCss} spaceCss={spaceCss} sectionName={sectionName} setSectionName={setSectionName} setBgElem={setBgElem} bgElem={bgElem} setEnableEditSection={setEnableEditSection} color={color} setColor={setColor} setRowchange={setRowchange} allImg={allImg} fetchImg={fetchImg} /> : <AdvancedElement setAttachedImage={setAttachedImage} attachedImage={attachedImage}  funnelId={funnelId} setSpaceCss={setSpaceCss} spaceCss={spaceCss} sectionName={sectionName} sectionIdTrack={sectionIdTrack} sections={sections} setRowchange={setRowchange} setEnableEditSection={setEnableEditSection} setBorderColor={setBorderColor} borderColor={borderColor} setShapeColor={setShapeColor} shapeColor={shapeColor} allImg={allImg} fetchImg={fetchImg} setAllImg={setAllImg} /> : ""}

                </div>
            </div> : <animated.div id="leftSideNav" className="leftNav customNav custom_scroll bg-light" style={styles}>
            <div Style="position: sticky; background-color: #f8f9fa; z-index: 10; top: 0px; width: 100%;">
                   
                    <hr className='m-0'/>
                    <nav>
                        <div className="nav nav-tabs justify-content-around" id="nav-tab" role="tablist">
                            <button className="btnTab btnMainTabs " id='leftBarGeneral' data-bs-step="1" data-toggle="tab" href="#nav-general" role="tab" aria-selected="true" onClick={() => { leftBarElementSet("general") }}>General</button>
                            <button className="btnTab btnMainTabs" id='leftBarAdvanced' data-bs-step="4" data-toggle="tab" href="#nav-advanced" role="tab" aria-selected="false" onClick={() => { leftBarElementSet("advanced") }}>Advanced</button>
                            <span onClick={() => { setEnableEditSection(false) }} Style='font-size: 25px; cursor:pointer; padding-top: 8px;'><BsArrowBarRight /></span> 
                            <div className="progress bg-transparent" Style="height: 3px;position: absolute;width: 100%;bottom: 0;">
                                <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="5" Style={barWidth}></div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="tab-content" id="nav-tabContent" Style="box-shadow:none">
                    {cssEditElemReRender ? leftBarElement ? <GeneralElement setAttachedImage={setAttachedImage} attachedImage={attachedImage}  funnelId={funnelId} setAllImg={setAllImg} allImg={allImg} setSpaceCss={setSpaceCss} spaceCss={spaceCss} sectionName={sectionName} setSectionName={setSectionName} setBgElem={setBgElem} bgElem={bgElem} setEnableEditSection={setEnableEditSection} color={color} setColor={setColor} setRowchange={setRowchange} fetchImg={fetchImg} /> : <AdvancedElement setAttachedImage={setAttachedImage} attachedImage={attachedImage}  funnelId={funnelId} setSpaceCss={setSpaceCss} spaceCss={spaceCss} sectionName={sectionName} sectionIdTrack={sectionIdTrack} sections={sections} setRowchange={setRowchange} setEnableEditSection={setEnableEditSection} setBorderColor={setBorderColor} borderColor={borderColor} setShapeColor={setShapeColor} shapeColor={shapeColor} allImg={allImg} fetchImg={fetchImg} setAllImg={setAllImg} /> : ""}

                </div>
            </animated.div>}
        </>
    )
}

export default LeftSideBar