import React, { useState, useEffect, useMemo, memo } from 'react'
import { ItemTypes } from '../ItemTypes';
import Element from './Element';
import { useDrop } from 'react-dnd'

import Editor from "@monaco-editor/react";

import { GrChapterAdd } from "react-icons/gr";
import { MdAddToQueue } from "react-icons/md";

const RowContainerDustbin = memo(function RowContainerDustbin({
    containerClass,
    id,
    elementclass,
    index,
    rowId,
    sections,
    section,
    rowchange,
    rows,
    row,
    column,
    setSections,
    rowcontainer,
    deleteRow,
    addCustomStyle,
    columnStyle,
    columnBgElem,
    setEnableColumnResize,
    enableColumnResize,
    setRowchange,
    columnIdTrack,
    sectionIdTrack,
    rowIdTrack,
    detectRow,
    columnIndex,
    columnSize,
    setColumnSize,
    setColumnCss,
    columnCss,
    targetColumnSize,
    nextColumnSize,
    setElementIdCount,
    elementIdCount,
    onDrop,
    rowIndex,
    sectionIndex,
    activeElement,
    prevElement,
    setAddSectionIndex,
    setSidebarOpen,
    setSideNav,
    setAddRowIndex,
    setAddColumnIndex,
    preview,
    setElementType,
    elementType,
    nextContainer,
    prevContainer,
    enableForm,
    setEnableForm,

    sectionIdCount,
    setSectionIdCount,
    enableEditSection,
    setEnableEditSection,
    cssAddingBarByClick,
    setRowId,
    setEnabledrop,
    enableDrop,
    enableRowSection,
    setEnableRowSection,
    setEnableColumnBar,
    enableColumnBar,
    elementEditBar,
    setElementEditBar,
    setColumnIdTrack,
    sidebarOpen,
    openFunnelData,
    rowStateId,
    setRowStateId,

    tabsSection,
    enableTabs,
    setEnableTabs,
    setTabSection,
    mobileView,
    saving,
    undoComponent,
    setUndoComponent,
}) {
    const [size, setSize] = useState({ width: 0, position: "relative" });
    const [enableResize, setEnableResize] = useState(true)

    const [containerAddCss, setContainerAddCss] = useState(`#${id}{

}`)

    const handler = (mouseDownEvent) => {
        setEnableColumnResize(true)
        setRowchange(enableColumnResize)
        const startPosition = { x: mouseDownEvent.pageX };
        function onMouseMove(mouseMoveEvent) {
            setSize(currentSize => ({
                width: Number(mouseMoveEvent.pageX) - Number(startPosition.x),
                position: "relative"
            }));
            if (nextContainer && (document.getElementById(id).scrollWidth < 100 || document.getElementById(nextContainer.id).scrollWidth < 100)) {
                setSize({ width: 0, position: "relative" })
            }
            if (nextContainer && (document.getElementById(id).scrollWidth < 107 || document.getElementById(nextContainer.id).scrollWidth < 107)) {
                document.body.removeEventListener("mousemove", onMouseMove);

            }
        }
        function onMouseUp() {
            if (nextContainer && (document.getElementById(id).scrollWidth < 100 || document.getElementById(nextContainer.id).scrollWidth < 100)) {
                setSize({ width: 0, position: "relative" })
            }
            if (nextContainer && (document.getElementById(id).scrollWidth < 107 || document.getElementById(nextContainer.id).scrollWidth < 107)) {
                if (nextContainer && document.getElementById(nextContainer.id).scrollWidth < 107) {
                    if (row.RowContainer && detectRow) {
                        var updateData = sections
                        updateData.forEach((element) => {
                            if (element === section) {
                                var index = updateData.indexOf(section)
                                updateData[index].RowComponent.forEach((elem) => {
                                    if (elem === detectRow) {
                                        var detectRowIndex = updateData[index].RowComponent.indexOf(detectRow)
                                        if (columnIndex < detectRow.RowContainer.length - 1) {
                                            var nextcolumnWidth = updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex + 1].columnWidth
                                            var columnWidth = updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex].columnWidth
                                            updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex].columnWidth = Number(columnWidth) - (110 - document.getElementById(nextContainer.id).scrollWidth)
                                            updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex + 1].columnWidth = Number(nextcolumnWidth) + (110 - document.getElementById(nextContainer.id).scrollWidth)
                                        }
                                    }
                                })
                            }
                        })
                        setSections(updateData)
                    }
                }
                else if (nextContainer && document.getElementById(id).scrollWidth < 107) {
                    if (row.RowContainer && detectRow) {
                        var updateData = sections
                        updateData.forEach((element) => {
                            if (element === section) {
                                var index = updateData.indexOf(section)
                                updateData[index].RowComponent.forEach((elem) => {
                                    if (elem === detectRow) {
                                        var detectRowIndex = updateData[index].RowComponent.indexOf(detectRow)
                                        if (columnIndex < detectRow.RowContainer.length - 1) {
                                            var nextcolumnWidth = updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex + 1].columnWidth
                                            var columnWidth = updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex].columnWidth
                                            updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex].columnWidth = Number(columnWidth) + (110 - document.getElementById(id).scrollWidth)
                                            updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex + 1].columnWidth = Number(nextcolumnWidth) - (110 - document.getElementById(id).scrollWidth)
                                        }
                                    }
                                })
                            }
                        })
                        setSections(updateData)
                    }
                }

            }
            setContainerWidth(column.columnWidth)
            if (nextContainer) {
                setNextContainerWidth(nextContainer.columnWidth)
            }
            setEnableColumnResize(false)
            document.body.removeEventListener("mousemove", onMouseMove);
            setRowchange(enableColumnResize)
            // uncomment the following line if not using `{ once: true }`
            // document.body.removeEventListener("mouseup", onMouseUp);
        }
        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, { once: true });
    };
    useEffect(() => {
        document.getElementById(id).onmouseenter = () => {
            if (preview === false) {
                document.getElementById(id).classList.add("columnHover")
                document.getElementById(id + "ControlLabelsColumn").classList.remove("disable")
                if (document.getElementById(id + "BtnAddElement") || document.getElementById(id + "PlusIconColumn")) {
                    document.getElementById(id + "BtnAddElement").classList.remove("disable")
                }
                document.getElementById(rowId).classList.remove("allRowStyle")
                document.getElementById(rowId + "ControlLabelsRow").classList.add("disable")
                document.getElementById(rowId + "PlusSectionButtonRow").classList.add("disable")
                document.getElementById(rowId + "typeNameRow").classList.remove("typeNameHover")
                document.getElementById(rowId + "typeNameRow").classList.add("disable")
                document.getElementById(id + "typeNameColumn").classList.add("typeNameHover")
                document.getElementById(id + "typeNameColumn").classList.remove("disable")
            }
        }
        document.getElementById(id).onmouseleave = () => {
            if (preview === false) {
                document.getElementById(id).classList.remove("columnHover")
                document.getElementById(id + "ControlLabelsColumn").classList.add("disable")

                document.getElementById(rowId).classList.add("allRowStyle")
                document.getElementById(rowId + "ControlLabelsRow").classList.remove("disable")
                document.getElementById(rowId + "PlusSectionButtonRow").classList.remove("disable")
                document.getElementById(id + "typeNameColumn").classList.remove("typeNameHover")
                document.getElementById(id + "typeNameColumn").classList.add("disable")
            }

        }
    }, [rowchange, sections, preview])

    // Clone or copy column
    function cloneColumn(id) {
        console.log(column)
        var sectionsData = sections
        var sectionIndex = sections.indexOf(section)
        var rowIndex = rows.indexOf(row)
        if (sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.length < 6) {
            // var columnIndex = rows.indexOf(column)
            var copyColumn = JSON.parse(JSON.stringify(column))
            var columnId = 0;
            for (let i = 0; i < rowcontainer.length; i++) {
                var elementId = rowcontainer[i].id.slice(-1)
                if (Number(elementId) > columnId) {
                    columnId = Number(elementId)
                }
            }

            var updateColumnId = (Number(columnId) + 1)
            copyColumn.id = copyColumn.id.slice(0, copyColumn.id.indexOf("elem")) + updateColumnId
            // copyColumn.Rowclass=copyColumn.id.slice(0, copyColumn.id.indexOf("elem"))
            copyColumn.ColumnContainer.forEach((elem, elemindex) => {
                elem.id = copyColumn.id.slice(0, copyColumn.id.indexOf("elem")) + updateColumnId + "elem" + elemindex
            })
            console.log(copyColumn)
            sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.splice(index, 0, copyColumn)
            var columnLength = sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.length;
            if (columnLength === 2) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " twoColumn"
                    element.columnWidthPercet = "49.5%"
                    element.columnWidth = '0'
                });
            }
            if (columnLength === 3) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " threeColumn"
                    element.columnWidthPercet = "32.7%"
                    element.columnWidth = '0'
                });
            }
            if (columnLength === 4) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " fourColumn"
                    element.columnWidthPercet = "24.2%"
                    element.columnWidth = '0'
                });
            }
            if (columnLength === 5) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " fiveColumn"
                    element.columnWidthPercet = "19.2%"
                    element.columnWidth = '0'
                });
            }
            if (columnLength === 6) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " sixColumn"
                    element.columnWidthPercet = "15.7%"
                    element.columnWidth = '0'
                });
            }

            setSections(sectionsData)
            var componentData = undoComponent
            componentData.push({ "arrayData": JSON.parse(JSON.stringify(sectionsData)), "id": id })
            setUndoComponent(componentData)
        }
    }

    // Delete Column
    function deleteColumn(id) {
        var sectionsData = sections
        var sectionIndex = sections.indexOf(section)
        var rowIndex = rows.indexOf(row)
        var columnIndex;
        row.RowContainer.forEach((allelem, allelemIndex) => {
            if (allelem.id === id) {
                columnIndex = allelemIndex
            }
        })
        if (sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.length > 1) {
            sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.splice(columnIndex, 1)
            var columnLength = sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.length;
            if (columnLength === 1) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " oneColumn"
                    element.columnWidthPercet = "100%"
                    element.columnWidth = '0'
                });
            }
            if (columnLength === 2) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " twoColumn"
                    element.columnWidthPercet = "49.5%"
                    element.columnWidth = '0'
                });
            }
            if (columnLength === 3) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " threeColumn"
                    element.columnWidthPercet = "32.7%"
                    element.columnWidth = '0'
                });
            }
            if (columnLength === 4) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " fourColumn"
                    element.columnWidthPercet = "24.2%"
                    element.columnWidth = '0'
                });
            }
            if (columnLength === 5) {
                sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.forEach((element) => {
                    var index = element.class.indexOf('col')
                    element.class = element.class.slice(0, index + 3) + " fiveColumn"
                    element.columnWidthPercet = "19.2%"
                    element.columnWidth = '0'
                });
            }
            var componentData = undoComponent
            componentData.push({ "arrayData": JSON.parse(JSON.stringify(sectionsData)), "id": id })
            setUndoComponent(componentData)
        }
        else {
            deleteRow(rowId)
        }
    }

    // Set column Id 
    function setColumnId(e) {
        e.stopPropagation();
        addCustomStyle("col" + id)
    }

    // Background element 
    const sectionBackgroundStyle = [`inset:0px;position:absolute;background-image:url(${column.columnObject ? mobileView ? column.columnObject.backgroundImageMobileSingle : column.columnObject.backgroundImageDesktopSingle : ""});opacity:${column.columnObject ? column.columnObject.backgroundImageOpacity : 1};border-radius:${column.columnObject ? column.columnObject.borderRadius : '0px'};`]
    const sectionCrouselBackgroundStyle = [`inset:0px;position:absolute;opacity:${column.columnObject ? column.columnObject.backgroundImageOpacity : 1}`]
    var backgroundImageDesktopSingleCss = column.columnObject ? column.columnObject.backgroundImageDesktopSingleCss : ''
    var backgroundSetElem;
    if (column.columnObject) {
        if (column.columnObject.BgCarouselImageType === 'bgimage') {
            backgroundSetElem = <div className="BgImgHolder singleImgDiv Parallax" bgsettings="Parallax" Style={sectionBackgroundStyle + ";" + backgroundImageDesktopSingleCss + ";" + "z-index:0;"} desktop={column.columnObject ? column.columnObject.backgroundImageDesktopSingle : ""} prev={column.columnObject ? column.columnObject.backgroundImageDesktopSingle : ""}></div>
        }
        else if (column.columnObject.BgCarouselImageType === 'bgvideo') {
            backgroundSetElem = <div className="youtube-background rowBgVideo" id="" Style={`height: 100%; width: 100%; z-index: 0; position: absolute; overflow: hidden; inset: 0px; pointer-events: none; background-image: url("https://img.youtube.com/vi/${column.columnObject.embededVideoCode}/maxresdefault.jpg"); background-size: cover; background-repeat: no-repeat; background-position: center center;border-radius:${column.columnObject ? column.columnObject.borderRadius : '0px'};`}><iframe frameborder="0" allow="autoplay; mute" src={`https://www.youtube.com/embed/${column.columnObject.embededVideoCode}?enablejsapi=1&disablekb=1&controls=0&rel=0&iv_load_policy=3&cc_load_policy=0&playsinline=1&showinfo=0&modestbranding=1&fs=0&mute=1&autoplay=1&loop=1`} id={id + "_" + "sectionVideo"} Style={`top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); position: absolute; ${column.columnObject.youtubeValidUrl ? 'opacity: 1' : 'opacity: 0'}; width: ${window.outerWidth}px; height: ${window.outerHeight}px`} title=""></iframe></div>
        }
        else if (column.columnObject.BgCarouselImageType === 'bgcarouselimage') {
            backgroundSetElem = <div className="BgImgHolder Parallax" bgsettings="FillAllWidth" Style={`inset: 0px; position: absolute; background-image: none;border-radius:${column.columnObject ? column.columnObject.borderRadius : '0px'};z-index:-1;`}>
                <div id={"carouselExampleFade" + id} className="carousel slide carousel-fade" data-bs-ride="carousel" Style='height:100%; z-index:0'>
                    <div className="carousel-inner" Style={`height:100%; border-radius:${column.columnObject ? column.columnObject.borderRadius : '0px'}`}>
                        {column.columnObject ? column.columnObject.BgCarouselImage.map((element, index) => {
                            return <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} Style={`background-image:url(${element.bgImage});height:100%`}></div>
                        }) : ''}
                    </div>
                    <button className="carousel-control-next" type="button" data-bs-target={"#carouselExampleFade" + id} Style='display:none' id={'crouselIdNext' + id} data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        }
    }
    else {
        backgroundSetElem = ''
    }
    useEffect(() => {
        if (column.columnObject) {
            if (columnBgElem === 'bgcarouselimage' && column.columnObject.BgCarouselImageType === 'bgcarouselimage') {
                document.getElementById('crouselIdNext' + id).click()
            }

        }
    }, [columnBgElem])

    const [containerWidth, setContainerWidth] = useState(0)
    const [nextContainerWidth, setNextContainerWidth] = useState(0)
    const [rowWidth, setRowWidth] = useState()

    // useEffect(() => {
    //     setRowWidth(document.getElementById(row.id).scrollWidth)
    //     //var containerSpaceWidth=10*(row.RowContainer.length-1)
    //     setContainerWidth(Math.floor((document.getElementById(row.id).scrollWidth / row.RowContainer.length)) - 20)
    //     setNextContainerWidth(Math.floor((document.getElementById(row.id).scrollWidth / row.RowContainer.length)) - 20)
    //     setSize({ width: `${Math.floor((document.getElementById(row.id).scrollWidth / row.RowContainer.length)) - 20}`, position: "relative" })
    //     var cssData = columnCss
    //     cssData.columnWidth = columnSize
    //     setColumnCss(cssData)
    //     if (row.RowContainer && row) {
    //         var updateData = sections
    //         updateData.forEach((element) => {
    //             if (element === section) {
    //                 var index = updateData.indexOf(section)
    //                 updateData[index].RowComponent.forEach((elem) => {
    //                     if (elem === row) {
    //                         var detectRowIndex = updateData[index].RowComponent.indexOf(row)
    //                         updateData[index].RowComponent[detectRowIndex].RowContainer.forEach((elem2) => {
    //                             elem2.columnWidth = Math.floor((document.getElementById(row.id).scrollWidth / row.RowContainer.length)) - 20
    //                         })
    //                     }
    //                 })
    //             }
    //         })
    //         setSections(updateData)
    //     }
    // }, [column])

    useEffect(() => {
        setContainerWidth(column.columnWidth)
        if (nextContainer) {
            setNextContainerWidth(nextContainer.columnWidth)
        }
        setEnableResize(true)
    }, [columnIdTrack])

    useEffect(() => {
        setColumnSize(size.width)
        if (row.RowContainer && detectRow) {
            var updateData = sections
            updateData.forEach((element) => {
                if (element === section) {
                    var index = updateData.indexOf(section)
                    updateData[index].RowComponent.forEach((elem) => {
                        if (elem === detectRow) {
                            var detectRowIndex = updateData[index].RowComponent.indexOf(detectRow)
                            if (columnIndex < detectRow.RowContainer.length - 1) {
                                updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex].columnWidth = Number(containerWidth) + size.width
                                updateData[index].RowComponent[detectRowIndex].RowContainer[columnIndex + 1].columnWidth = Number(nextContainerWidth) + (- size.width)
                            }
                        }
                    })
                }
            })
            setSections(updateData)
        }
    }, [size.width])



    const [{ isOver, canDrop, handlerId }, drop] = useDrop({
        accept: [ItemTypes.ELEMENT, ItemTypes.ELEMENTUPDOWN],
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            handlerId: monitor.getHandlerId(),
        }),
    })

    const moveCardElement = (item, monitor, columnHandlerId) => {
        // if (handlerId !== columnHandlerId) {
        //     var sectionData = sections
        //     sectionData.RowComponent.forEach((elem) => {
        //         elem.RowContainer.forEach((elem2) => {
        //             elem2.ColumnContainer.forEach((elem3) => {
        //                 if (elem3.id === item.id) {
        //                 }
        //             })
        //         })
        //     })
        // }
    }

    const renderCard = (element, elementindex) => {
        return (
            <Element
                element={element}
                id={element.id}
                openElementbar={openElementbar}
                index={elementindex}
                key={elementindex}
                size={size}
                rowId={rowId}
                columnId={id}
                moveCardElement={moveCardElement}
                columnHandlerId={handlerId}
                addCustomStyle={addCustomStyle}
                columnIndex={index}
                rowIndex={rowIndex}
                sectionIndex={sectionIndex}
                sections={sections}
                setSections={setSections}
                activeElement={activeElement}
                prevElement={prevElement}
                section={section}
                row={row}
                column={column}
                setRowchange={setRowchange}
                rowchange={rowchange}
                setElementIdCount={setElementIdCount}
                elementIdCount={elementIdCount}
                preview={preview}
                setElementType={setElementType}
                elementType={elementType}
                setEnableColumnResize={setEnableColumnResize}
                enableColumnResize={enableColumnResize}
                enableForm={enableForm}
                setEnableForm={setEnableForm}

                sectionIdCount={sectionIdCount}
                setSectionIdCount={setSectionIdCount}
                setAddSectionIndex={setAddSectionIndex}
                setSidebarOpen={setSidebarOpen}
                enableEditSection={enableEditSection}
                setEnableEditSection={setEnableEditSection}
                cssAddingBarByClick={cssAddingBarByClick}
                setSideNav={setSideNav} setRowId={setRowId}
                setEnabledrop={setEnabledrop}
                enableDrop={enableDrop}
                enableRowSection={enableRowSection}
                setEnableRowSection={setEnableRowSection}
                setEnableColumnBar={setEnableColumnBar}
                enableColumnBar={enableColumnBar}
                elementEditBar={elementEditBar}
                setElementEditBar={setElementEditBar}
                setAddRowIndex={setAddRowIndex}
                setAddColumnIndex={setAddColumnIndex}
                setColumnIdTrack={setColumnIdTrack}
                columnIdTrack={columnIdTrack}
                sidebarOpen={sidebarOpen}
                openFunnelData={openFunnelData}
                rowStateId={rowStateId}
                setRowStateId={setRowStateId}

                tabsSection={tabsSection}
                enableTabs={enableTabs}
                setEnableTabs={setEnableTabs}
                setTabSection={setTabSection}
                mobileView={mobileView}
                saving={saving}
                undoComponent={undoComponent}
                setUndoComponent={setUndoComponent}
            />
        )
    }

    function openElementbar(e) {
        var sectionIndex;
        var addSectionData = sections
        addSectionData.forEach((elem1) => {
            elem1.RowComponent.forEach((elem2) => {
                elem2.RowContainer.forEach((elem3) => {
                    if (elem3.id === id) {
                        sectionIndex = addSectionData.indexOf(elem1) + 1
                        setAddSectionIndex(addSectionData.indexOf(elem1) + 1)
                        setAddRowIndex(elem1.RowComponent.indexOf(elem2))
                        setAddColumnIndex(elem2.RowContainer.indexOf(elem3))
                    }
                })
            })
        })
        setRowchange(sectionIndex)
        setSidebarOpen(false)
        setSideNav('element')
        setTimeout(() => {
            setSidebarOpen(true)
        }, 100);
    }

    function columnMoveUp(id) {
        var sectionsData = sections
        var sectionIndex = sections.indexOf(section)
        var rowIndex = rows.indexOf(row)
        var columnIndex = row.RowContainer.indexOf(column)
        if (columnIndex > 0) {
            sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex] = sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.splice(columnIndex - 1, 1, sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex])[0]
        }
        setSections(sectionsData)
        var componentData = undoComponent
        componentData.push({ "arrayData": JSON.parse(JSON.stringify(sectionsData)), "id": id })
        setUndoComponent(componentData)
        setRowchange(sectionsData)
    }

    function columnMoveDown(id) {
        var sectionsData = sections
        var sectionIndex = sections.indexOf(section)
        var rowIndex = rows.indexOf(row)
        var columnIndex = row.RowContainer.indexOf(column)
        if (columnIndex < row.RowContainer.length - 1) {
            sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex] = sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.splice(columnIndex + 1, 1, sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex])[0]
        }
        setSections(sectionsData)
        var componentData = undoComponent
        componentData.push({ "arrayData": JSON.parse(JSON.stringify(sectionsData)), "id": id })
        setUndoComponent(componentData)
        setRowchange(sectionsData)
    }


    function handleEditorChange(value, event) {
        setContainerAddCss(value)
    }

    function saveSectionAddCss() {
        document.getElementById(`${id}Style`).innerHTML = `<style>${containerAddCss}</style>`
    }

    useEffect(() => {
        document.getElementById(`${id}responsiveStyle`).innerHTML = `<style>@media only screen and (max-width: 700px) { #${id}{${row.rowObject && column.columnObject ? `background-image:url(${column.columnObject.backgroundImageMobileSingle}) !important; ${backgroundImageDesktopSingleCss}; padding-top:${column.columnObject.paddingTopMobile} !important;  padding-bottom:${column.columnObject.paddingBottomMobile} !important;  margin-top:${column.columnObject.marginTopMobile} !important; margin-top:${column.columnObject.marginBottomMobile} !important; width:${row.rowObject.colPerRowMobile !== 'none' ? row.RowContainer.length % 2 !== 0 && row.RowContainer.length === index + 1 ? '100% !important' : '50% !important' : '100% !important'} ` : ''}} .BgImgHolder{z-index:-5 !important; } .singleImgDiv{display:none !important} }</style>`
    }, [preview, backgroundImageDesktopSingleCss ])

    return (
        <>
            <div ref={drop} handalerId={handlerId} Style={`${mobileView ? column.mobilecolumnstyle : columnStyle}; ${mobileView ? row.rowObject && row.rowObject.colPerRowMobile !== 'none' ? row.RowContainer.length % 2 !== 0 && row.RowContainer.length === index + 1 ? 'width: 100%' : 'width: 50%' : 'width: 100%' : `width: calc(${column.columnWidthPercet} + ${column.columnWidth}px)`}; position:${size.position}; `} id={id} className={`${elementclass}  ${containerClass}  ${preview ? '' : "allColumn"} columnwidth`} onClick={setColumnId}>
                {index === row.RowContainer.length - 1 || preview ? '' : <button id={`${id}_draghandle`} className='widthsetColumn' type="button" Style="display:none" onMouseDown={handler} ></button>}
                {backgroundSetElem}
                <div id={`${id}Style`} Style='display:none'>
                    <style>

                    </style>
                </div>
                <div id={`${id}responsiveStyle`} Style='display:none'>
                    <style>

                    </style>
                </div>
                {preview ? '' : <div class="modal fade" id={`${id}CssEditorModalElement`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-body">
                                <span Style="font-size: 20px;">
                                    <i class="fas fa-code"></i>
                                    <custom Style="font-size: 20px;">&nbsp; Custom CSS</custom>
                                </span>
                                <div class="mt-3 cssEditorHolder" Style='text-align:left'>
                                    <Editor
                                        height="50vh"
                                        theme="vs-dark"
                                        defaultLanguage="css"
                                        defaultValue={containerAddCss}
                                        onChange={handleEditorChange}
                                    />
                                </div>
                            </div>
                            <div class="modal-footer" Style="display:flex; justify-content: center;">
                                <div>
                                    <p Style="color:red;"><em><u>Caution!</u></em> Global selectors like <b><i>html</i></b> or <b><i>button</i></b> will result in the entire page being affected.</p>
                                    <p Style="color:#ff6a00;"><em><u>Tip:</u></em> If your styling won't work, try adding a <i>!important</i> flag after it</p>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-success saveCustomCss" Style="width: 100px;" onClick={saveSectionAddCss}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {enableForm ? <form>
                    {column.ColumnContainer.map((element, index) => {
                        return renderCard(element, index)
                    })}
                </form>
                    : <>{column.ColumnContainer.map((element, index) => {
                        return renderCard(element, index)
                    })}</>}
                {preview ? '' : <>
                    <div className='typeNameColumn disable' id={id + "typeNameColumn"} >Column</div>
                    <div className="ControlLabelsColumn disable" id={id + "ControlLabelsColumn"} Style="display: flex; z-index: 10;height:100%; flex-direction:column; flex-wrap:wrap">
                        <div className="grow MoveUp me-1" data-bs-toggle="tooltip" title="Move Up"><i className="fa fa-arrow-up" onClick={() => { columnMoveUp(id) }}></i></div>
                        <div className="grow MoveDown me-1" data-bs-toggle="tooltip" title="Move Down"><i className="fa fa-arrow-down" onClick={() => { columnMoveDown(id) }}></i></div>
                        <div className="grow CopySection me-1" data-bs-toggle="tooltip" title="Clone"><i className="fa fa-copy" onClick={() => { cloneColumn(id) }}></i></div>
                        <div className="grow openCustomCss me-1" title="Open custom css" data-bs-toggle="modal" data-bs-target={`#${id}CssEditorModalElement`}><i className="fab fa-css3-alt"></i></div>
                        <div className="grow DeleteSection me-1" data-bs-toggle="tooltip" title="Delete"><i className="fa fa-trash" onClick={() => { deleteColumn(id) }}></i></div>
                    </div></>}
                {column.ColumnContainer.length > 0 || preview ? '' : <div className="holder " Style={`height:100px;display: flex;justify-content: center;align-items: center; padding: 20px 0px;`}>
                    <div className="PlusIcon d-flex justify-content-center align-items-center elementAddbtn" id={id + "BtnAddElement"} onClick={() => { openElementbar(id) }} Style="z-index: 5; position:relative; cursor:pointer">
                        <span className='d-flex justify-content-center align-items-center'><GrChapterAdd />
                            <p className="" id={id + "BtnAddRow"} Style={`margin: 0px 5px; ${mobileView ? 'line-height: 1.5;' : ''}`}> Element </p>
                        </span>
                    </div>
                </div>}
            </div>
        </>
    )
})

export default RowContainerDustbin