import React, { useState } from 'react'
import * as FontAwesome from "react-icons/fa";
import Select from 'react-select'

function IconAdd({ setElementCss, elementCss, setRowchange }) {
    // function addIcon(data) {
    //     var elementCssData = elementCss
    //     elementCssData.elementIcon = data
    //     setElementCss(elementCssData)
    //     setRowchange(elementCssData.elementIcon)
    // }
    function addIcon(data, selectValue) {
        var elementCssData = elementCss
        elementCssData.elementIcon = data
        elementCssData.selectIconValue = selectValue
        setElementCss(elementCssData)
        setRowchange(elementCssData.elementIcon)
    }
    const options = [
        { value: '500Px ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 0) }} ><span><FontAwesome.Fa500Px /></span></div> },
        { value: 'AccessibleIcon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1) }}><span><FontAwesome.FaAccessibleIcon /></span></div> },
        { value: 'Accusoft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 2) }}><span><FontAwesome.FaAccusoft /></span></div> },
        { value: 'AcquisitionsIncorporated ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 3) }}><span><FontAwesome.FaAcquisitionsIncorporated /></span></div> },
        { value: 'Adn ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 4) }}><span><FontAwesome.FaAdn /></span></div> },
        { value: 'Adversal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 5) }}><span><FontAwesome.FaAdversal /></span></div> },
        { value: 'Affiliatetheme ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 6) }}><span><FontAwesome.FaAffiliatetheme /></span></div> },
        { value: 'Algolia ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 7) }}><span><FontAwesome.FaAlgolia /></span></div> },
        { value: 'Alipay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 8) }}><span><FontAwesome.FaAlipay /></span></div> },
        { value: 'AmazonPay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 9) }}><span><FontAwesome.FaAmazonPay /></span></div> },
        { value: 'Amazon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 10) }}><span><FontAwesome.FaAmazon /></span></div> },
        { value: 'Amilia ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 11) }}><span><FontAwesome.FaAmilia /></span></div> },
        { value: 'Android ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 12) }}><span><FontAwesome.FaAndroid /></span></div> },
        { value: 'Angrycreative ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 13) }}><span><FontAwesome.FaAngrycreative /></span></div> },
        { value: 'Angular ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 14) }}><span><FontAwesome.FaAngular /></span></div> },
        { value: 'AppStoreIos ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 15) }}><span><FontAwesome.FaAppStoreIos /></span></div> },
        { value: 'AppStore ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 16) }}><span><FontAwesome.FaAppStore /></span></div> },
        { value: 'Apper ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 17) }}><span><FontAwesome.FaApper /></span></div> },
        { value: 'ApplePay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 18) }}><span><FontAwesome.FaApplePay /></span></div> },
        { value: 'Apple ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 19) }}><span><FontAwesome.FaApple /></span></div> },
        { value: 'Artstation ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 20) }}><span><FontAwesome.FaArtstation /></span></div> },
        { value: 'Asymmetrik ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 21) }}><span><FontAwesome.FaAsymmetrik /></span></div> },
        { value: 'Atlassian ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 22) }}><span><FontAwesome.FaAtlassian /></span></div> },
        { value: 'Audible ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 23) }}><span><FontAwesome.FaAudible /></span></div> },
        { value: 'Autoprefixer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 24) }}><span><FontAwesome.FaAutoprefixer /></span></div> },
        { value: 'Avianex ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 25) }}><span><FontAwesome.FaAvianex /></span></div> },
        { value: 'Aviato ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 26) }}><span><FontAwesome.FaAviato /></span></div> },
        { value: 'Aws ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 27) }}><span><FontAwesome.FaAws /></span></div> },
        { value: 'Bandcamp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 28) }}><span><FontAwesome.FaBandcamp /></span></div> },
        { value: 'BattleNet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 29) }}><span><FontAwesome.FaBattleNet /></span></div> },
        { value: 'BehanceSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 30) }}><span><FontAwesome.FaBehanceSquare /></span></div> },
        { value: 'Behance ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 31) }}><span><FontAwesome.FaBehance /></span></div> },
        { value: 'Bimobject ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 32) }}><span><FontAwesome.FaBimobject /></span></div> },
        { value: 'Blackberry ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 33) }}><span><FontAwesome.FaBlackberry /></span></div> },
        { value: 'BloggerB ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 34) }}><span><FontAwesome.FaBloggerB /></span></div> },
        { value: 'Blogger ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 35) }}><span><FontAwesome.FaBlogger /></span></div> },
        { value: 'BluetoothB ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 36) }}><span><FontAwesome.FaBluetoothB /></span></div> },
        { value: 'Bluetooth ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 37) }}><span><FontAwesome.FaBluetooth /></span></div> },
        { value: 'Bootstrap ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 38) }}><span><FontAwesome.FaBootstrap /></span></div> },
        { value: 'Btc ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 39) }}><span><FontAwesome.FaBtc /></span></div> },
        { value: 'Buffer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 40) }}><span><FontAwesome.FaBuffer /></span></div> },
        { value: 'Buromobelexperte ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 41) }}><span><FontAwesome.FaBuromobelexperte /></span></div> },
        { value: 'BuyNLarge ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 42) }}><span><FontAwesome.FaBuyNLarge /></span></div> },
        { value: 'Buysellads ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 43) }}><span><FontAwesome.FaBuysellads /></span></div> },
        { value: 'CanadianMapleLeaf ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 44) }}><span><FontAwesome.FaCanadianMapleLeaf /></span></div> },
        { value: 'CcAmazonPay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 45) }}><span><FontAwesome.FaCcAmazonPay /></span></div> },
        { value: 'CcAmex ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 46) }}><span><FontAwesome.FaCcAmex /></span></div> },
        { value: 'CcApplePay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 47) }}><span><FontAwesome.FaCcApplePay /></span></div> },
        { value: 'CcDinersClub ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 48) }}><span><FontAwesome.FaCcDinersClub /></span></div> },
        { value: 'CcDiscover ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 49) }}><span><FontAwesome.FaCcDiscover /></span></div> },
        { value: 'CcJcb ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 50) }}><span><FontAwesome.FaCcJcb /></span></div> },
        { value: 'CcMastercard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 51) }}><span><FontAwesome.FaCcMastercard /></span></div> },
        { value: 'CcPaypal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 52) }}><span><FontAwesome.FaCcPaypal /></span></div> },
        { value: 'CcStripe ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 53) }}><span><FontAwesome.FaCcStripe /></span></div> },
        { value: 'CcVisa ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 54) }}><span><FontAwesome.FaCcVisa /></span></div> },
        { value: 'Centercode ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 55) }}><span><FontAwesome.FaCentercode /></span></div> },
        { value: 'Centos ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 56) }}><span><FontAwesome.FaCentos /></span></div> },
        { value: 'Chrome ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 57) }}><span><FontAwesome.FaChrome /></span></div> },
        { value: 'Chromecast ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 58) }}><span><FontAwesome.FaChromecast /></span></div> },
        { value: 'Cloudflare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 59) }}><span><FontAwesome.FaCloudflare /></span></div> },
        { value: 'Cloudscale ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 60) }}><span><FontAwesome.FaCloudscale /></span></div> },
        { value: 'Cloudsmith ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 61) }}><span><FontAwesome.FaCloudsmith /></span></div> },
        { value: 'Cloudversify ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 62) }}><span><FontAwesome.FaCloudversify /></span></div> },
        { value: 'Codepen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 63) }}><span><FontAwesome.FaCodepen /></span></div> },
        { value: 'Codiepie ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 64) }}><span><FontAwesome.FaCodiepie /></span></div> },
        { value: 'Confluence ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 65) }}><span><FontAwesome.FaConfluence /></span></div> },
        { value: 'Connectdevelop ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 66) }}><span><FontAwesome.FaConnectdevelop /></span></div> },
        { value: 'Contao ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 67) }}><span><FontAwesome.FaContao /></span></div> },
        { value: 'CottonBureau ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 68) }}><span><FontAwesome.FaCottonBureau /></span></div> },
        { value: 'Cpanel ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 69) }}><span><FontAwesome.FaCpanel /></span></div> },
        { value: 'CreativeCommonsBy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 70) }}><span><FontAwesome.FaCreativeCommonsBy /></span></div> },
        { value: 'CreativeCommonsNcEu ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 71) }}><span><FontAwesome.FaCreativeCommonsNcEu /></span></div> },
        { value: 'CreativeCommonsNcJp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 72) }}><span><FontAwesome.FaCreativeCommonsNcJp /></span></div> },
        { value: 'CreativeCommonsNc ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 73) }}><span><FontAwesome.FaCreativeCommonsNc /></span></div> },
        { value: 'CreativeCommonsNd ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 74) }}><span><FontAwesome.FaCreativeCommonsNd /></span></div> },
        { value: 'CreativeCommonsPdAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 75) }}><span><FontAwesome.FaCreativeCommonsPdAlt /></span></div> },
        { value: 'CreativeCommonsPd ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 76) }}><span><FontAwesome.FaCreativeCommonsPd /></span></div> },
        { value: 'CreativeCommonsRemix ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 77) }}><span><FontAwesome.FaCreativeCommonsRemix /></span></div> },
        { value: 'CreativeCommonsSa ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 78) }}><span><FontAwesome.FaCreativeCommonsSa /></span></div> },
        { value: 'CreativeCommonsSamplingPlus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 79) }}><span><FontAwesome.FaCreativeCommonsSamplingPlus /></span></div> },
        { value: 'CreativeCommonsSampling ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 80) }}><span><FontAwesome.FaCreativeCommonsSampling /></span></div> },
        { value: 'CreativeCommonsShare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 81) }}><span><FontAwesome.FaCreativeCommonsShare /></span></div> },
        { value: 'CreativeCommonsZero ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 82) }}><span><FontAwesome.FaCreativeCommonsZero /></span></div> },
        { value: 'CreativeCommons ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 83) }}><span><FontAwesome.FaCreativeCommons /></span></div> },
        { value: 'CriticalRole ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 84) }}><span><FontAwesome.FaCriticalRole /></span></div> },
        { value: 'Css3Alt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 85) }}><span><FontAwesome.FaCss3Alt /></span></div> },
        { value: 'Css3 ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 86) }}><span><FontAwesome.FaCss3 /></span></div> },
        { value: 'Cuttlefish ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 87) }}><span><FontAwesome.FaCuttlefish /></span></div> },
        { value: 'DAndDBeyond ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 88) }}><span><FontAwesome.FaDAndDBeyond /></span></div> },
        { value: 'DAndD ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 89) }}><span><FontAwesome.FaDAndD /></span></div> },
        { value: 'Dailymotion ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 90) }}><span><FontAwesome.FaDailymotion /></span></div> },
        { value: 'Dashcube ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 91) }}><span><FontAwesome.FaDashcube /></span></div> },
        { value: 'Deezer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 92) }}><span><FontAwesome.FaDeezer /></span></div> },
        { value: 'Delicious ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 93) }}><span><FontAwesome.FaDelicious /></span></div> },
        { value: 'Deploydog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 94) }}><span><FontAwesome.FaDeploydog /></span></div> },
        { value: 'Deskpro ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 95) }}><span><FontAwesome.FaDeskpro /></span></div> },
        { value: 'Dev ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 96) }}><span><FontAwesome.FaDev /></span></div> },
        { value: 'Deviantart ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 97) }}><span><FontAwesome.FaDeviantart /></span></div> },
        { value: 'Dhl ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 98) }}><span><FontAwesome.FaDhl /></span></div> },
        { value: 'Diaspora ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 99) }}><span><FontAwesome.FaDiaspora /></span></div> },
        { value: 'Digg ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 100) }}><span><FontAwesome.FaDigg /></span></div> },
        { value: 'DigitalOcean ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 101) }}><span><FontAwesome.FaDigitalOcean /></span></div> },
        { value: 'Discord ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 102) }}><span><FontAwesome.FaDiscord /></span></div> },
        { value: 'Discourse ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 103) }}><span><FontAwesome.FaDiscourse /></span></div> },
        { value: 'Dochub ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 104) }}><span><FontAwesome.FaDochub /></span></div> },
        { value: 'Docker ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 105) }}><span><FontAwesome.FaDocker /></span></div> },
        { value: 'Draft2Digital ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 106) }}><span><FontAwesome.FaDraft2Digital /></span></div> },
        { value: 'DribbbleSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 107) }}><span><FontAwesome.FaDribbbleSquare /></span></div> },
        { value: 'Dribbble ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 108) }}><span><FontAwesome.FaDribbble /></span></div> },
        { value: 'Dropbox ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 109) }}><span><FontAwesome.FaDropbox /></span></div> },
        { value: 'Drupal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 110) }}><span><FontAwesome.FaDrupal /></span></div> },
        { value: 'Dyalog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 111) }}><span><FontAwesome.FaDyalog /></span></div> },
        { value: 'Earlybirds ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 112) }}><span><FontAwesome.FaEarlybirds /></span></div> },
        { value: 'Ebay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 113) }}><span><FontAwesome.FaEbay /></span></div> },
        { value: 'EdgeLegacy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 114) }}><span><FontAwesome.FaEdgeLegacy /></span></div> },
        { value: 'Edge ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 115) }}><span><FontAwesome.FaEdge /></span></div> },
        { value: 'Elementor ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 116) }}><span><FontAwesome.FaElementor /></span></div> },
        { value: 'Ello ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 117) }}><span><FontAwesome.FaEllo /></span></div> },
        { value: 'Ember ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 118) }}><span><FontAwesome.FaEmber /></span></div> },
        { value: 'Empire ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 119) }}><span><FontAwesome.FaEmpire /></span></div> },
        { value: 'Envira ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 120) }}><span><FontAwesome.FaEnvira /></span></div> },
        { value: 'Erlang ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 121) }}><span><FontAwesome.FaErlang /></span></div> },
        { value: 'Ethereum ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 122) }}><span><FontAwesome.FaEthereum /></span></div> },
        { value: 'Etsy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 123) }}><span><FontAwesome.FaEtsy /></span></div> },
        { value: 'Evernote ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 124) }}><span><FontAwesome.FaEvernote /></span></div> },
        { value: 'Expeditedssl ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 125) }}><span><FontAwesome.FaExpeditedssl /></span></div> },
        { value: 'Fedex ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 126) }}><span><FontAwesome.FaFedex /></span></div> },
        { value: 'Fedora ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 127) }}><span><FontAwesome.FaFedora /></span></div> },
        { value: 'Figma ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 128) }}><span><FontAwesome.FaFigma /></span></div> },
        { value: 'FirefoxBrowser ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 129) }}><span><FontAwesome.FaFirefoxBrowser /></span></div> },
        { value: 'Firefox ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 130) }}><span><FontAwesome.FaFirefox /></span></div> },
        { value: 'FirstOrderAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 131) }}><span><FontAwesome.FaFirstOrderAlt /></span></div> },
        { value: 'FirstOrder ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 132) }}><span><FontAwesome.FaFirstOrder /></span></div> },
        { value: 'Firstdraft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 133) }}><span><FontAwesome.FaFirstdraft /></span></div> },
        { value: 'Flickr ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 134) }}><span><FontAwesome.FaFlickr /></span></div> },
        { value: 'Flipboard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 135) }}><span><FontAwesome.FaFlipboard /></span></div> },
        { value: 'Fly ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 136) }}><span><FontAwesome.FaFly /></span></div> },
        { value: 'FontAwesomeAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 137) }}><span><FontAwesome.FaFontAwesomeAlt /></span></div> },
        { value: 'FontAwesomeFlag ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 138) }}><span><FontAwesome.FaFontAwesomeFlag /></span></div> },
        { value: 'FontAwesomeLogoFull ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 139) }}><span><FontAwesome.FaFontAwesomeLogoFull /></span></div> },
        { value: 'FontAwesome ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 140) }}><span><FontAwesome.FaFontAwesome /></span></div> },
        { value: 'FonticonsFi ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 141) }}><span><FontAwesome.FaFonticonsFi /></span></div> },
        { value: 'Fonticons ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 142) }}><span><FontAwesome.FaFonticons /></span></div> },
        { value: 'FortAwesomeAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 143) }}><span><FontAwesome.FaFortAwesomeAlt /></span></div> },
        { value: 'FortAwesome ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 144) }}><span><FontAwesome.FaFortAwesome /></span></div> },
        { value: 'Forumbee ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 145) }}><span><FontAwesome.FaForumbee /></span></div> },
        { value: 'Foursquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 146) }}><span><FontAwesome.FaFoursquare /></span></div> },
        { value: 'FreeCodeCamp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 147) }}><span><FontAwesome.FaFreeCodeCamp /></span></div> },
        { value: 'Freebsd ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 148) }}><span><FontAwesome.FaFreebsd /></span></div> },
        { value: 'Fulcrum ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 149) }}><span><FontAwesome.FaFulcrum /></span></div> },
        { value: 'GalacticRepublic ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 150) }}><span><FontAwesome.FaGalacticRepublic /></span></div> },
        { value: 'GalacticSenate ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 151) }}><span><FontAwesome.FaGalacticSenate /></span></div> },
        { value: 'GetPocket ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 152) }}><span><FontAwesome.FaGetPocket /></span></div> },
        { value: 'GgCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 153) }}><span><FontAwesome.FaGgCircle /></span></div> },
        { value: 'Gg ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 154) }}><span><FontAwesome.FaGg /></span></div> },
        { value: 'GitAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 155) }}><span><FontAwesome.FaGitAlt /></span></div> },
        { value: 'GitSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 156) }}><span><FontAwesome.FaGitSquare /></span></div> },
        { value: 'Git ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 157) }}><span><FontAwesome.FaGit /></span></div> },
        { value: 'GithubAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 158) }}><span><FontAwesome.FaGithubAlt /></span></div> },
        { value: 'GithubSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 159) }}><span><FontAwesome.FaGithubSquare /></span></div> },
        { value: 'Github ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 160) }}><span><FontAwesome.FaGithub /></span></div> },
        { value: 'Gitkraken ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 161) }}><span><FontAwesome.FaGitkraken /></span></div> },
        { value: 'Gitlab ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 162) }}><span><FontAwesome.FaGitlab /></span></div> },
        { value: 'Gitter ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 163) }}><span><FontAwesome.FaGitter /></span></div> },
        { value: 'GlideG ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 164) }}><span><FontAwesome.FaGlideG /></span></div> },
        { value: 'Glide ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 165) }}><span><FontAwesome.FaGlide /></span></div> },
        { value: 'Gofore ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 166) }}><span><FontAwesome.FaGofore /></span></div> },
        { value: 'GoodreadsG ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 167) }}><span><FontAwesome.FaGoodreadsG /></span></div> },
        { value: 'Goodreads ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 168) }}><span><FontAwesome.FaGoodreads /></span></div> },
        { value: 'GoogleDrive ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 169) }}><span><FontAwesome.FaGoogleDrive /></span></div> },
        { value: 'GooglePay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 170) }}><span><FontAwesome.FaGooglePay /></span></div> },
        { value: 'GooglePlay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 171) }}><span><FontAwesome.FaGooglePlay /></span></div> },
        { value: 'GooglePlusG ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 172) }}><span><FontAwesome.FaGooglePlusG /></span></div> },
        { value: 'GooglePlusSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 173) }}><span><FontAwesome.FaGooglePlusSquare /></span></div> },
        { value: 'GooglePlus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 174) }}><span><FontAwesome.FaGooglePlus /></span></div> },
        { value: 'GoogleWallet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 175) }}><span><FontAwesome.FaGoogleWallet /></span></div> },
        { value: 'Google ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 176) }}><span><FontAwesome.FaGoogle /></span></div> },
        { value: 'Gratipay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 177) }}><span><FontAwesome.FaGratipay /></span></div> },
        { value: 'Grav ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 178) }}><span><FontAwesome.FaGrav /></span></div> },
        { value: 'Gripfire ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 179) }}><span><FontAwesome.FaGripfire /></span></div> },
        { value: 'Grunt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 180) }}><span><FontAwesome.FaGrunt /></span></div> },
        { value: 'Guilded ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 181) }}><span><FontAwesome.FaGuilded /></span></div> },
        { value: 'Gulp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 182) }}><span><FontAwesome.FaGulp /></span></div> },
        { value: 'HackerNewsSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 183) }}><span><FontAwesome.FaHackerNewsSquare /></span></div> },
        { value: 'HackerNews ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 184) }}><span><FontAwesome.FaHackerNews /></span></div> },
        { value: 'Hackerrank ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 185) }}><span><FontAwesome.FaHackerrank /></span></div> },
        { value: 'Hips ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 186) }}><span><FontAwesome.FaHips /></span></div> },
        { value: 'HireAHelper ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 187) }}><span><FontAwesome.FaHireAHelper /></span></div> },
        { value: 'Hive ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 188) }}><span><FontAwesome.FaHive /></span></div> },
        { value: 'Hooli ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 189) }}><span><FontAwesome.FaHooli /></span></div> },
        { value: 'Hornbill ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 190) }}><span><FontAwesome.FaHornbill /></span></div> },
        { value: 'Hotjar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 191) }}><span><FontAwesome.FaHotjar /></span></div> },
        { value: 'Houzz ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 192) }}><span><FontAwesome.FaHouzz /></span></div> },
        { value: 'Html5 ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 193) }}><span><FontAwesome.FaHtml5 /></span></div> },
        { value: 'Hubspot ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 194) }}><span><FontAwesome.FaHubspot /></span></div> },
        { value: 'Ideal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 195) }}><span><FontAwesome.FaIdeal /></span></div> },
        { value: 'Imdb ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 196) }}><span><FontAwesome.FaImdb /></span></div> },
        { value: 'Innosoft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 197) }}><span><FontAwesome.FaInnosoft /></span></div> },
        { value: 'InstagramSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 198) }}><span><FontAwesome.FaInstagramSquare /></span></div> },
        { value: 'Instagram ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 199) }}><span><FontAwesome.FaInstagram /></span></div> },
        
        { value: 'Instalod ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 200) }}><span><FontAwesome.FaInstalod /></span></div> },
        { value: 'Intercom ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 201) }}><span><FontAwesome.FaIntercom /></span></div> },
        { value: 'InternetExplorer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 202) }}><span><FontAwesome.FaInternetExplorer /></span></div> },
        { value: 'Invision ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 203) }}><span><FontAwesome.FaInvision /></span></div> },
        { value: 'Ioxhost ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 204) }}><span><FontAwesome.FaIoxhost /></span></div> },
        { value: 'ItchIo ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 205) }}><span><FontAwesome.FaItchIo /></span></div> },
        { value: 'ItunesNote ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 206) }}><span><FontAwesome.FaItunesNote /></span></div> },
        { value: 'Itunes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 207) }}><span><FontAwesome.FaItunes /></span></div> },
        { value: 'Java ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 208) }}><span><FontAwesome.FaJava /></span></div> },
        { value: 'JediOrder ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 209) }}><span><FontAwesome.FaJediOrder /></span></div> },
        { value: 'Jenkins ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 210) }}><span><FontAwesome.FaJenkins /></span></div> },
        { value: 'Jira ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 211) }}><span><FontAwesome.FaJira /></span></div> },
        { value: 'Joget ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 212) }}><span><FontAwesome.FaJoget /></span></div> },
        { value: 'Joomla ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 213) }}><span><FontAwesome.FaJoomla /></span></div> },
        { value: 'JsSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 214) }}><span><FontAwesome.FaJsSquare /></span></div> },
        { value: 'Js ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 215) }}><span><FontAwesome.FaJs /></span></div> },
        { value: 'Jsfiddle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 216) }}><span><FontAwesome.FaJsfiddle /></span></div> },
        { value: 'Kaggle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 217) }}><span><FontAwesome.FaKaggle /></span></div> },
        { value: 'Keybase ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 218) }}><span><FontAwesome.FaKeybase /></span></div> },
        { value: 'Keycdn ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 219) }}><span><FontAwesome.FaKeycdn /></span></div> },
        { value: 'KickstarterK ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 220) }}><span><FontAwesome.FaKickstarterK /></span></div> },
        { value: 'Kickstarter ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 221) }}><span><FontAwesome.FaKickstarter /></span></div> },
        { value: 'Korvue ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 222) }}><span><FontAwesome.FaKorvue /></span></div> },
        { value: 'Laravel ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 223) }}><span><FontAwesome.FaLaravel /></span></div> },
        { value: 'LastfmSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 224) }}><span><FontAwesome.FaLastfmSquare /></span></div> },
        { value: 'Lastfm ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 225) }}><span><FontAwesome.FaLastfm /></span></div> },
        { value: 'Leanpub ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 226) }}><span><FontAwesome.FaLeanpub /></span></div> },
        { value: 'Less ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 227) }}><span><FontAwesome.FaLess /></span></div> },
        { value: 'Line ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 228) }}><span><FontAwesome.FaLine /></span></div> },
        { value: 'LinkedinIn ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 229) }}><span><FontAwesome.FaLinkedinIn /></span></div> },
        { value: 'Linkedin ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 230) }}><span><FontAwesome.FaLinkedin /></span></div> },
        { value: 'Linode ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 231) }}><span><FontAwesome.FaLinode /></span></div> },
        { value: 'Linux ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 232) }}><span><FontAwesome.FaLinux /></span></div> },
        { value: 'Lyft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 233) }}><span><FontAwesome.FaLyft /></span></div> },
        { value: 'Magento ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 234) }}><span><FontAwesome.FaMagento /></span></div> },
        { value: 'Mailchimp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 235) }}><span><FontAwesome.FaMailchimp /></span></div> },
        { value: 'Mandalorian ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 236) }}><span><FontAwesome.FaMandalorian /></span></div> },
        { value: 'Markdown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 237) }}><span><FontAwesome.FaMarkdown /></span></div> },
        { value: 'Mastodon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 238) }}><span><FontAwesome.FaMastodon /></span></div> },
        { value: 'Maxcdn ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 239) }}><span><FontAwesome.FaMaxcdn /></span></div> },
        { value: 'Mdb ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 240) }}><span><FontAwesome.FaMdb /></span></div> },
        { value: 'Medapps ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 241) }}><span><FontAwesome.FaMedapps /></span></div> },
        { value: 'MediumM ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 242) }}><span><FontAwesome.FaMediumM /></span></div> },
        { value: 'Medium ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 243) }}><span><FontAwesome.FaMedium /></span></div> },
        { value: 'Medrt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 244) }}><span><FontAwesome.FaMedrt /></span></div> },
        { value: 'Meetup ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 245) }}><span><FontAwesome.FaMeetup /></span></div> },
        { value: 'Megaport ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 246) }}><span><FontAwesome.FaMegaport /></span></div> },
        { value: 'Mendeley ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 247) }}><span><FontAwesome.FaMendeley /></span></div> },
        { value: 'Microblog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 248) }}><span><FontAwesome.FaMicroblog /></span></div> },
        { value: 'Microsoft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 249) }}><span><FontAwesome.FaMicrosoft /></span></div> },
        { value: 'Mix ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 250) }}><span><FontAwesome.FaMix /></span></div> },
        { value: 'Mixcloud ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 251) }}><span><FontAwesome.FaMixcloud /></span></div> },
        { value: 'Mixer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 252) }}><span><FontAwesome.FaMixer /></span></div> },
        { value: 'Mizuni ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 253) }}><span><FontAwesome.FaMizuni /></span></div> },
        { value: 'Modx ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 254) }}><span><FontAwesome.FaModx /></span></div> },
        { value: 'Monero ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 255) }}><span><FontAwesome.FaMonero /></span></div> },
        { value: 'Napster ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 256) }}><span><FontAwesome.FaNapster /></span></div> },
        { value: 'Neos ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 257) }}><span><FontAwesome.FaNeos /></span></div> },
        { value: 'Nimblr ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 258) }}><span><FontAwesome.FaNimblr /></span></div> },
        { value: 'NodeJs ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 259) }}><span><FontAwesome.FaNodeJs /></span></div> },
        { value: 'Node ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 260) }}><span><FontAwesome.FaNode /></span></div> },
        { value: 'Npm ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 261) }}><span><FontAwesome.FaNpm /></span></div> },
        { value: 'Ns8 ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 262) }}><span><FontAwesome.FaNs8 /></span></div> },
        { value: 'Nutritionix ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 263) }}><span><FontAwesome.FaNutritionix /></span></div> },
        { value: 'OctopusDeploy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 264) }}><span><FontAwesome.FaOctopusDeploy /></span></div> },
        { value: 'OdnoklassnikiSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 265) }}><span><FontAwesome.FaOdnoklassnikiSquare /></span></div> },
        { value: 'Odnoklassniki ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 266) }}><span><FontAwesome.FaOdnoklassniki /></span></div> },
        { value: 'OldRepublic ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 267) }}><span><FontAwesome.FaOldRepublic /></span></div> },
        { value: 'Opencart ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 268) }}><span><FontAwesome.FaOpencart /></span></div> },
        { value: 'Openid ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 269) }}><span><FontAwesome.FaOpenid /></span></div> },
        { value: 'Opera ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 270) }}><span><FontAwesome.FaOpera /></span></div> },
        { value: 'OptinMonster ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 271) }}><span><FontAwesome.FaOptinMonster /></span></div> },
        { value: 'Orcid ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 272) }}><span><FontAwesome.FaOrcid /></span></div> },
        { value: 'Osi ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 273) }}><span><FontAwesome.FaOsi /></span></div> },
        { value: 'Page4 ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 274) }}><span><FontAwesome.FaPage4 /></span></div> },
        { value: 'Pagelines ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 275) }}><span><FontAwesome.FaPagelines /></span></div> },
        { value: 'Palfed ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 276) }}><span><FontAwesome.FaPalfed /></span></div> },
        { value: 'Patreon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 277) }}><span><FontAwesome.FaPatreon /></span></div> },
        { value: 'Paypal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 278) }}><span><FontAwesome.FaPaypal /></span></div> },
        { value: 'PennyArcade ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 279) }}><span><FontAwesome.FaPennyArcade /></span></div> },
        { value: 'Perbyte ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 280) }}><span><FontAwesome.FaPerbyte /></span></div> },
        { value: 'Periscope ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 281) }}><span><FontAwesome.FaPeriscope /></span></div> },
        { value: 'Phabricator ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 282) }}><span><FontAwesome.FaPhabricator /></span></div> },
        { value: 'PhoenixFramework ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 283) }}><span><FontAwesome.FaPhoenixFramework /></span></div> },
        { value: 'PhoenixSquadron ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 284) }}><span><FontAwesome.FaPhoenixSquadron /></span></div> },
        { value: 'Php ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 285) }}><span><FontAwesome.FaPhp /></span></div> },
        { value: 'PiedPiperAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 286) }}><span><FontAwesome.FaPiedPiperAlt /></span></div> },
        { value: 'PiedPiperHat ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 287) }}><span><FontAwesome.FaPiedPiperHat /></span></div> },
        { value: 'PiedPiperPp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 288) }}><span><FontAwesome.FaPiedPiperPp /></span></div> },
        { value: 'PiedPiperSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 289) }}><span><FontAwesome.FaPiedPiperSquare /></span></div> },
        { value: 'PiedPiper ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 290) }}><span><FontAwesome.FaPiedPiper /></span></div> },
        { value: 'Backspace ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 291) }}><span><FontAwesome.FaBackspace /></span></div> },
        { value: 'Backward ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 292) }}><span><FontAwesome.FaBackward /></span></div> },
        { value: 'Bacon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 293) }}><span><FontAwesome.FaBacon /></span></div> },
        { value: 'Bacteria ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 294) }}><span><FontAwesome.FaBacteria /></span></div> },
        { value: 'Bacterium ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 295) }}><span><FontAwesome.FaBacterium /></span></div> },
        { value: 'Bahai ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 296) }}><span><FontAwesome.FaBahai /></span></div> },
        { value: 'BalanceScaleLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 297) }}><span><FontAwesome.FaBalanceScaleLeft /></span></div> },
        { value: 'BalanceScaleRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 298) }}><span><FontAwesome.FaBalanceScaleRight /></span></div> },
        { value: 'BalanceScale ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 299) }}><span><FontAwesome.FaBalanceScale /></span></div> },
        
        { value: 'Ban ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 300) }}><span><FontAwesome.FaBan /></span></div> },
        { value: 'BandAid ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 301) }}><span><FontAwesome.FaBandAid /></span></div> },
        { value: 'Barcode ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 302) }}><span><FontAwesome.FaBarcode /></span></div> },
        { value: 'Bars ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 303) }}><span><FontAwesome.FaBars /></span></div> },
        { value: 'BaseballBall ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 304) }}><span><FontAwesome.FaBaseballBall /></span></div> },
        { value: 'BasketballBall ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 305) }}><span><FontAwesome.FaBasketballBall /></span></div> },
        { value: 'Bath ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 306) }}><span><FontAwesome.FaBath /></span></div> },
        { value: 'BatteryEmpty ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 307) }}><span><FontAwesome.FaBatteryEmpty /></span></div> },
        { value: 'BatteryFull ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 308) }}><span><FontAwesome.FaBatteryFull /></span></div> },
        { value: 'BatteryHalf ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 309) }}><span><FontAwesome.FaBatteryHalf /></span></div> },
        { value: 'BatteryQuarter ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 310) }}><span><FontAwesome.FaBatteryQuarter /></span></div> },
        { value: 'BatteryThreeQuarters ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 311) }}><span><FontAwesome.FaBatteryThreeQuarters /></span></div> },
        { value: 'Bed ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 312) }}><span><FontAwesome.FaBed /></span></div> },
        { value: 'Beer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 313) }}><span><FontAwesome.FaBeer /></span></div> },
        { value: 'BellSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 314) }}><span><FontAwesome.FaBellSlash /></span></div> },
        { value: 'Bell ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 315) }}><span><FontAwesome.FaBell /></span></div> },
        { value: 'BezierCurve ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 316) }}><span><FontAwesome.FaBezierCurve /></span></div> },
        { value: 'Bible ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 317) }}><span><FontAwesome.FaBible /></span></div> },
        { value: 'Bicycle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 318) }}><span><FontAwesome.FaBicycle /></span></div> },
        { value: 'Biking ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 319) }}><span><FontAwesome.FaBiking /></span></div> },
        { value: 'Binoculars ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 320) }}><span><FontAwesome.FaBinoculars /></span></div> },
        { value: 'Biohazard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 321) }}><span><FontAwesome.FaBiohazard /></span></div> },
        { value: 'BirthdayCake ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 322) }}><span><FontAwesome.FaBirthdayCake /></span></div> },
        { value: 'BlenderPhone ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 323) }}><span><FontAwesome.FaBlenderPhone /></span></div> },
        { value: 'Blender ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 324) }}><span><FontAwesome.FaBlender /></span></div> },
        { value: 'Blind ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 325) }}><span><FontAwesome.FaBlind /></span></div> },
        { value: 'Blog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 326) }}><span><FontAwesome.FaBlog /></span></div> },
        { value: 'Bold ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 327) }}><span><FontAwesome.FaBold /></span></div> },
        { value: 'Bolt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 328) }}><span><FontAwesome.FaBolt /></span></div> },
        { value: 'Bomb ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 329) }}><span><FontAwesome.FaBomb /></span></div> },
        { value: 'Bone ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 330) }}><span><FontAwesome.FaBone /></span></div> },
        { value: 'Bong ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 331) }}><span><FontAwesome.FaBong /></span></div> },
        { value: 'BookDead ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 332) }}><span><FontAwesome.FaBookDead /></span></div> },
        { value: 'BookMedical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 333) }}><span><FontAwesome.FaBookMedical /></span></div> },
        { value: 'BookOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 334) }}><span><FontAwesome.FaBookOpen /></span></div> },
        { value: 'BookReader ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 335) }}><span><FontAwesome.FaBookReader /></span></div> },
        { value: 'Book ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 336) }}><span><FontAwesome.FaBook /></span></div> },
        { value: 'Bookmark ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 337) }}><span><FontAwesome.FaBookmark /></span></div> },
        { value: 'BorderAll ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 338) }}><span><FontAwesome.FaBorderAll /></span></div> },
        { value: 'BorderNone ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 339) }}><span><FontAwesome.FaBorderNone /></span></div> },
        { value: 'BorderStyle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 340) }}><span><FontAwesome.FaBorderStyle /></span></div> },
        { value: 'BowlingBall ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 341) }}><span><FontAwesome.FaBowlingBall /></span></div> },
        { value: 'BoxOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 342) }}><span><FontAwesome.FaBoxOpen /></span></div> },
        { value: 'BoxTissue ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 343) }}><span><FontAwesome.FaBoxTissue /></span></div> },
        { value: 'Box ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 344) }}><span><FontAwesome.FaBox /></span></div> },
        { value: 'Boxes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 345) }}><span><FontAwesome.FaBoxes /></span></div> },
        { value: 'Braille ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 346) }}><span><FontAwesome.FaBraille /></span></div> },
        { value: 'Brain ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 347) }}><span><FontAwesome.FaBrain /></span></div> },
        { value: 'BreadSlice ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 348) }}><span><FontAwesome.FaBreadSlice /></span></div> },
        { value: 'BriefcaseMedical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 349) }}><span><FontAwesome.FaBriefcaseMedical /></span></div> },
        { value: 'Briefcase ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 350) }}><span><FontAwesome.FaBriefcase /></span></div> },
        { value: 'BroadcastTower ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 351) }}><span><FontAwesome.FaBroadcastTower /></span></div> },
        { value: 'Broom ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 352) }}><span><FontAwesome.FaBroom /></span></div> },
        { value: 'Brush ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 353) }}><span><FontAwesome.FaBrush /></span></div> },
        { value: 'Bug ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 354) }}><span><FontAwesome.FaBug /></span></div> },
        { value: 'Building ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 355) }}><span><FontAwesome.FaBuilding /></span></div> },
        { value: 'Bullhorn ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 356) }}><span><FontAwesome.FaBullhorn /></span></div> },
        { value: 'Bullseye ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 357) }}><span><FontAwesome.FaBullseye /></span></div> },
        { value: 'Burn ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 358) }}><span><FontAwesome.FaBurn /></span></div> },
        { value: 'BusAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 359) }}><span><FontAwesome.FaBusAlt /></span></div> },
        { value: 'Bus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 360) }}><span><FontAwesome.FaBus /></span></div> },
        { value: 'BusinessTime ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 361) }}><span><FontAwesome.FaBusinessTime /></span></div> },
        { value: 'Calculator ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 362) }}><span><FontAwesome.FaCalculator /></span></div> },
        { value: 'CalendarAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 363) }}><span><FontAwesome.FaCalendarAlt /></span></div> },
        { value: 'CalendarCheck ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 364) }}><span><FontAwesome.FaCalendarCheck /></span></div> },
        { value: 'CalendarDay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 365) }}><span><FontAwesome.FaCalendarDay /></span></div> },
        { value: 'CalendarMinus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 366) }}><span><FontAwesome.FaCalendarMinus /></span></div> },
        { value: 'CalendarPlus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 367) }}><span><FontAwesome.FaCalendarPlus /></span></div> },
        { value: 'CalendarTimes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 368) }}><span><FontAwesome.FaCalendarTimes /></span></div> },
        { value: 'CalendarWeek ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 369) }}><span><FontAwesome.FaCalendarWeek /></span></div> },
        { value: 'Calendar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 370) }}><span><FontAwesome.FaCalendar /></span></div> },
        { value: 'CameraRetro ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 371) }}><span><FontAwesome.FaCameraRetro /></span></div> },
        { value: 'Camera ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 372) }}><span><FontAwesome.FaCamera /></span></div> },
        { value: 'Campground ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 373) }}><span><FontAwesome.FaCampground /></span></div> },
        { value: 'CandyCane ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 374) }}><span><FontAwesome.FaCandyCane /></span></div> },
        { value: 'Cannabis ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 375) }}><span><FontAwesome.FaCannabis /></span></div> },
        { value: 'Capsules ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 376) }}><span><FontAwesome.FaCapsules /></span></div> },
        { value: 'CarAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 377) }}><span><FontAwesome.FaCarAlt /></span></div> },
        { value: 'CarBattery ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 378) }}><span><FontAwesome.FaCarBattery /></span></div> },
        { value: 'CarCrash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 379) }}><span><FontAwesome.FaCarCrash /></span></div> },
        { value: 'CarSide ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 380) }}><span><FontAwesome.FaCarSide /></span></div> },
        { value: 'Car ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 381) }}><span><FontAwesome.FaCar /></span></div> },
        { value: 'Caravan ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 382) }}><span><FontAwesome.FaCaravan /></span></div> },
        { value: 'CaretDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 383) }}><span><FontAwesome.FaCaretDown /></span></div> },
        { value: 'CaretLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 384) }}><span><FontAwesome.FaCaretLeft /></span></div> },
        { value: 'CaretRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 385) }}><span><FontAwesome.FaCaretRight /></span></div> },
        { value: 'CaretSquareDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 386) }}><span><FontAwesome.FaCaretSquareDown /></span></div> },
        { value: 'CaretSquareLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 387) }}><span><FontAwesome.FaCaretSquareLeft /></span></div> },
        { value: 'CaretSquareRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 388) }}><span><FontAwesome.FaCaretSquareRight /></span></div> },
        { value: 'CaretSquareUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 389) }}><span><FontAwesome.FaCaretSquareUp /></span></div> },
        { value: 'CaretUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 390) }}><span><FontAwesome.FaCaretUp /></span></div> },
        { value: 'Carrot ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 391) }}><span><FontAwesome.FaCarrot /></span></div> },
        { value: 'CartArrowDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 392) }}><span><FontAwesome.FaCartArrowDown /></span></div> },
        { value: 'CartPlus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 393) }}><span><FontAwesome.FaCartPlus /></span></div> },
        { value: 'CashRegister ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 394) }}><span><FontAwesome.FaCashRegister /></span></div> },
        { value: 'Cat ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 395) }}><span><FontAwesome.FaCat /></span></div> },
        { value: 'Certificate ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 396) }}><span><FontAwesome.FaCertificate /></span></div> },
        { value: 'Chair ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 397) }}><span><FontAwesome.FaChair /></span></div> },
        { value: 'ChalkboardTeacher ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 398) }}><span><FontAwesome.FaChalkboardTeacher /></span></div> },
        { value: 'Chalkboard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 399) }}><span><FontAwesome.FaChalkboard /></span></div> },
        
        { value: 'ChargingStation ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 400) }}><span><FontAwesome.FaChargingStation /></span></div> },
        { value: 'ChartArea ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 401) }}><span><FontAwesome.FaChartArea /></span></div> },
        { value: 'ChartBar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 402) }}><span><FontAwesome.FaChartBar /></span></div> },
        { value: 'ChartLine ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 403) }}><span><FontAwesome.FaChartLine /></span></div> },
        { value: 'ChartPie ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 404) }}><span><FontAwesome.FaChartPie /></span></div> },
        { value: 'CheckCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 405) }}><span><FontAwesome.FaCheckCircle /></span></div> },
        { value: 'CheckDouble ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 406) }}><span><FontAwesome.FaCheckDouble /></span></div> },
        { value: 'CheckSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 407) }}><span><FontAwesome.FaCheckSquare /></span></div> },
        { value: 'Check ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 408) }}><span><FontAwesome.FaCheck /></span></div> },
        { value: 'Cheese ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 409) }}><span><FontAwesome.FaCheese /></span></div> },
        { value: 'ChessBishop ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 410) }}><span><FontAwesome.FaChessBishop /></span></div> },
        { value: 'ChessBoard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 411) }}><span><FontAwesome.FaChessBoard /></span></div> },
        { value: 'ChessKing ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 412) }}><span><FontAwesome.FaChessKing /></span></div> },
        { value: 'ChessKnight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 413) }}><span><FontAwesome.FaChessKnight /></span></div> },
        { value: 'ChessPawn ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 414) }}><span><FontAwesome.FaChessPawn /></span></div> },
        { value: 'ChessQueen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 415) }}><span><FontAwesome.FaChessQueen /></span></div> },
        { value: 'ChessRook ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 416) }}><span><FontAwesome.FaChessRook /></span></div> },
        { value: 'Chess ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 417) }}><span><FontAwesome.FaChess /></span></div> },
        { value: 'ChevronCircleDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 418) }}><span><FontAwesome.FaChevronCircleDown /></span></div> },
        { value: 'ChevronCircleLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 419) }}><span><FontAwesome.FaChevronCircleLeft /></span></div> },
        { value: 'ChevronCircleRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 420) }}><span><FontAwesome.FaChevronCircleRight /></span></div> },
        { value: 'ChevronCircleUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 421) }}><span><FontAwesome.FaChevronCircleUp /></span></div> },
        { value: 'ChevronDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 422) }}><span><FontAwesome.FaChevronDown /></span></div> },
        { value: 'ChevronLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 423) }}><span><FontAwesome.FaChevronLeft /></span></div> },
        { value: 'ChevronRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 424) }}><span><FontAwesome.FaChevronRight /></span></div> },
        { value: 'ChevronUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 425) }}><span><FontAwesome.FaChevronUp /></span></div> },
        { value: 'Child ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 426) }}><span><FontAwesome.FaChild /></span></div> },
        { value: 'Church ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 427) }}><span><FontAwesome.FaChurch /></span></div> },
        { value: 'CircleNotch ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 428) }}><span><FontAwesome.FaCircleNotch /></span></div> },
        { value: 'Circle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 429) }}><span><FontAwesome.FaCircle /></span></div> },
        { value: 'City ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 430) }}><span><FontAwesome.FaCity /></span></div> },
        { value: 'ClinicMedical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 431) }}><span><FontAwesome.FaClinicMedical /></span></div> },
        { value: 'ClipboardCheck ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 432) }}><span><FontAwesome.FaClipboardCheck /></span></div> },
        { value: 'ClipboardList ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 433) }}><span><FontAwesome.FaClipboardList /></span></div> },
        { value: 'Clipboard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 434) }}><span><FontAwesome.FaClipboard /></span></div> },
        { value: 'Clock ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 435) }}><span><FontAwesome.FaClock /></span></div> },
        { value: 'Clone ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 436) }}><span><FontAwesome.FaClone /></span></div> },
        { value: 'ClosedCaptioning ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 437) }}><span><FontAwesome.FaClosedCaptioning /></span></div> },
        { value: 'CloudDownloadAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 438) }}><span><FontAwesome.FaCloudDownloadAlt /></span></div> },
        { value: 'CloudMeatball ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 439) }}><span><FontAwesome.FaCloudMeatball /></span></div> },
        { value: 'CloudMoonRain ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 440) }}><span><FontAwesome.FaCloudMoonRain /></span></div> },
        { value: 'CloudMoon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 441) }}><span><FontAwesome.FaCloudMoon /></span></div> },
        { value: 'CloudRain ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 442) }}><span><FontAwesome.FaCloudRain /></span></div> },
        { value: 'CloudShowersHeavy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 443) }}><span><FontAwesome.FaCloudShowersHeavy /></span></div> },
        { value: 'CloudSunRain ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 444) }}><span><FontAwesome.FaCloudSunRain /></span></div> },
        { value: 'CloudSun ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 445) }}><span><FontAwesome.FaCloudSun /></span></div> },
        { value: 'CloudUploadAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 446) }}><span><FontAwesome.FaCloudUploadAlt /></span></div> },
        { value: 'Cloud ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 447) }}><span><FontAwesome.FaCloud /></span></div> },
        { value: 'Cocktail ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 448) }}><span><FontAwesome.FaCocktail /></span></div> },
        { value: 'CodeBranch ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 449) }}><span><FontAwesome.FaCodeBranch /></span></div> },
        { value: 'Code ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 450) }}><span><FontAwesome.FaCode /></span></div> },
        { value: 'Coffee ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 451) }}><span><FontAwesome.FaCoffee /></span></div> },
        { value: 'Cog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 452) }}><span><FontAwesome.FaCog /></span></div> },
        { value: 'Cogs ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 453) }}><span><FontAwesome.FaCogs /></span></div> },
        { value: 'Coins ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 454) }}><span><FontAwesome.FaCoins /></span></div> },
        { value: 'Columns ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 455) }}><span><FontAwesome.FaColumns /></span></div> },
        { value: 'CommentAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 456) }}><span><FontAwesome.FaCommentAlt /></span></div> },
        { value: 'CommentDollar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 457) }}><span><FontAwesome.FaCommentDollar /></span></div> },
        { value: 'CommentDots ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 458) }}><span><FontAwesome.FaCommentDots /></span></div> },
        { value: 'CommentMedical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 459) }}><span><FontAwesome.FaCommentMedical /></span></div> },
        { value: 'CommentSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 460) }}><span><FontAwesome.FaCommentSlash /></span></div> },
        { value: 'Comment ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 461) }}><span><FontAwesome.FaComment /></span></div> },
        { value: 'CommentsDollar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 462) }}><span><FontAwesome.FaCommentsDollar /></span></div> },
        { value: 'Comments ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 463) }}><span><FontAwesome.FaComments /></span></div> },
        { value: 'CompactDisc ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 464) }}><span><FontAwesome.FaCompactDisc /></span></div> },
        { value: 'Compass ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 465) }}><span><FontAwesome.FaCompass /></span></div> },
        { value: 'CompressAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 466) }}><span><FontAwesome.FaCompressAlt /></span></div> },
        { value: 'CompressArrowsAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 467) }}><span><FontAwesome.FaCompressArrowsAlt /></span></div> },
        { value: 'Compress ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 468) }}><span><FontAwesome.FaCompress /></span></div> },
        { value: 'ConciergeBell ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 469) }}><span><FontAwesome.FaConciergeBell /></span></div> },
        { value: 'CookieBite ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 470) }}><span><FontAwesome.FaCookieBite /></span></div> },
        { value: 'Cookie ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 471) }}><span><FontAwesome.FaCookie /></span></div> },
        { value: 'Copy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 472) }}><span><FontAwesome.FaCopy /></span></div> },
        { value: 'Copyright ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 473) }}><span><FontAwesome.FaCopyright /></span></div> },
        { value: 'Couch ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 474) }}><span><FontAwesome.FaCouch /></span></div> },
        { value: 'CreditCard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 475) }}><span><FontAwesome.FaCreditCard /></span></div> },
        { value: 'CropAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 476) }}><span><FontAwesome.FaCropAlt /></span></div> },
        { value: 'Crop ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 477) }}><span><FontAwesome.FaCrop /></span></div> },
        { value: 'Cross ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 478) }}><span><FontAwesome.FaCross /></span></div> },
        { value: 'Crosshairs ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 479) }}><span><FontAwesome.FaCrosshairs /></span></div> },
        { value: 'Crow ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 480) }}><span><FontAwesome.FaCrow /></span></div> },
        { value: 'Crown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 481) }}><span><FontAwesome.FaCrown /></span></div> },
        { value: 'Crutch ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 482) }}><span><FontAwesome.FaCrutch /></span></div> },
        { value: 'Cube ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 483) }}><span><FontAwesome.FaCube /></span></div> },
        { value: 'Cubes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 484) }}><span><FontAwesome.FaCubes /></span></div> },
        { value: 'Cut ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 485) }}><span><FontAwesome.FaCut /></span></div> },
        { value: 'Database ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 486) }}><span><FontAwesome.FaDatabase /></span></div> },
        { value: 'Deaf ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 487) }}><span><FontAwesome.FaDeaf /></span></div> },
        { value: 'Democrat ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 488) }}><span><FontAwesome.FaDemocrat /></span></div> },
        { value: 'Desktop ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 489) }}><span><FontAwesome.FaDesktop /></span></div> },
        { value: 'Dharmachakra ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 490) }}><span><FontAwesome.FaDharmachakra /></span></div> },
        { value: 'Diagnoses ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 491) }}><span><FontAwesome.FaDiagnoses /></span></div> },
        { value: 'DiceD20 ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 492) }}><span><FontAwesome.FaDiceD20 /></span></div> },
        { value: 'DiceD6 ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 493) }}><span><FontAwesome.FaDiceD6 /></span></div> },
        { value: 'DiceFive ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 494) }}><span><FontAwesome.FaDiceFive /></span></div> },
        { value: 'DiceFour ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 495) }}><span><FontAwesome.FaDiceFour /></span></div> },
        { value: 'DiceOne ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 496) }}><span><FontAwesome.FaDiceOne /></span></div> },
        { value: 'DiceSix ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 497) }}><span><FontAwesome.FaDiceSix /></span></div> },
        { value: 'DiceThree ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 498) }}><span><FontAwesome.FaDiceThree /></span></div> },
        { value: 'DiceTwo ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 499) }}><span><FontAwesome.FaDiceTwo /></span></div> },
        
        { value: 'Dice ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 500) }}><span><FontAwesome.FaDice /></span></div> },
        { value: 'DigitalTachograph ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 501) }}><span><FontAwesome.FaDigitalTachograph /></span></div> },
        { value: 'Directions ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 502) }}><span><FontAwesome.FaDirections /></span></div> },
        { value: 'Disease ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 503) }}><span><FontAwesome.FaDisease /></span></div> },
        { value: 'Divide ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 504) }}><span><FontAwesome.FaDivide /></span></div> },
        { value: 'Dizzy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 505) }}><span><FontAwesome.FaDizzy /></span></div> },
        { value: 'Dna ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 506) }}><span><FontAwesome.FaDna /></span></div> },
        { value: 'Dog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 507) }}><span><FontAwesome.FaDog /></span></div> },
        { value: 'DollarSign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 508) }}><span><FontAwesome.FaDollarSign /></span></div> },
        { value: 'DollyFlatbed ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 509) }}><span><FontAwesome.FaDollyFlatbed /></span></div> },
        { value: 'Dolly ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 510) }}><span><FontAwesome.FaDolly /></span></div> },
        { value: 'Donate ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 511) }}><span><FontAwesome.FaDonate /></span></div> },
        { value: 'DoorClosed ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 512) }}><span><FontAwesome.FaDoorClosed /></span></div> },
        { value: 'DoorOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 513) }}><span><FontAwesome.FaDoorOpen /></span></div> },
        { value: 'DotCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 514) }}><span><FontAwesome.FaDotCircle /></span></div> },
        { value: 'Dove ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 515) }}><span><FontAwesome.FaDove /></span></div> },
        { value: 'Download ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 516) }}><span><FontAwesome.FaDownload /></span></div> },
        { value: 'DraftingCompass ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 517) }}><span><FontAwesome.FaDraftingCompass /></span></div> },
        { value: 'Dragon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 518) }}><span><FontAwesome.FaDragon /></span></div> },
        { value: 'DrawPolygon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 519) }}><span><FontAwesome.FaDrawPolygon /></span></div> },
        { value: 'DrumSteelpan ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 520) }}><span><FontAwesome.FaDrumSteelpan /></span></div> },
        { value: 'Drum ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 521) }}><span><FontAwesome.FaDrum /></span></div> },
        { value: 'DrumstickBite ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 522) }}><span><FontAwesome.FaDrumstickBite /></span></div> },
        { value: 'Dumbbell ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 523) }}><span><FontAwesome.FaDumbbell /></span></div> },
        { value: 'DumpsterFire ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 524) }}><span><FontAwesome.FaDumpsterFire /></span></div> },
        { value: 'Dumpster ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 525) }}><span><FontAwesome.FaDumpster /></span></div> },
        { value: 'Dungeon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 526) }}><span><FontAwesome.FaDungeon /></span></div> },
        { value: 'Edit ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 527) }}><span><FontAwesome.FaEdit /></span></div> },
        { value: 'Egg ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 528) }}><span><FontAwesome.FaEgg /></span></div> },
        { value: 'Eject ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 529) }}><span><FontAwesome.FaEject /></span></div> },
        { value: 'EllipsisH ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 530) }}><span><FontAwesome.FaEllipsisH /></span></div> },
        { value: 'EllipsisV ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 531) }}><span><FontAwesome.FaEllipsisV /></span></div> },
        { value: 'EnvelopeOpenText ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 532) }}><span><FontAwesome.FaEnvelopeOpenText /></span></div> },
        { value: 'EnvelopeOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 533) }}><span><FontAwesome.FaEnvelopeOpen /></span></div> },
        { value: 'EnvelopeSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 534) }}><span><FontAwesome.FaEnvelopeSquare /></span></div> },
        { value: 'Envelope ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 535) }}><span><FontAwesome.FaEnvelope /></span></div> },
        { value: 'Equals ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 536) }}><span><FontAwesome.FaEquals /></span></div> },
        { value: 'Eraser ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 537) }}><span><FontAwesome.FaEraser /></span></div> },
        { value: 'Ethernet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 538) }}><span><FontAwesome.FaEthernet /></span></div> },
        { value: 'EuroSign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 539) }}><span><FontAwesome.FaEuroSign /></span></div> },
        { value: 'ExchangeAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 540) }}><span><FontAwesome.FaExchangeAlt /></span></div> },
        { value: 'ExclamationCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 541) }}><span><FontAwesome.FaExclamationCircle /></span></div> },
        { value: 'ExclamationTriangle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 542) }}><span><FontAwesome.FaExclamationTriangle /></span></div> },
        { value: 'Exclamation ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 543) }}><span><FontAwesome.FaExclamation /></span></div> },
        { value: 'ExpandAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 544) }}><span><FontAwesome.FaExpandAlt /></span></div> },
        { value: 'ExpandArrowsAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 545) }}><span><FontAwesome.FaExpandArrowsAlt /></span></div> },
        { value: 'Expand ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 546) }}><span><FontAwesome.FaExpand /></span></div> },
        { value: 'ExternalLinkAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 547) }}><span><FontAwesome.FaExternalLinkAlt /></span></div> },
        { value: 'ExternalLinkSquareAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 548) }}><span><FontAwesome.FaExternalLinkSquareAlt /></span></div> },
        { value: 'EyeDropper ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 549) }}><span><FontAwesome.FaEyeDropper /></span></div> },
        { value: 'EyeSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 550) }}><span><FontAwesome.FaEyeSlash /></span></div> },
        { value: 'Eye ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 551) }}><span><FontAwesome.FaEye /></span></div> },
        { value: 'FeatherAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 552) }}><span><FontAwesome.FaFeatherAlt /></span></div> },
        { value: 'Feather ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 553) }}><span><FontAwesome.FaFeather /></span></div> },
        { value: 'Female ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 554) }}><span><FontAwesome.FaFemale /></span></div> },
        { value: 'FighterJet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 555) }}><span><FontAwesome.FaFighterJet /></span></div> },
        { value: 'FileAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 556) }}><span><FontAwesome.FaFileAlt /></span></div> },
        { value: 'FileArchive ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 557) }}><span><FontAwesome.FaFileArchive /></span></div> },
        { value: 'FileAudio ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 558) }}><span><FontAwesome.FaFileAudio /></span></div> },
        { value: 'FileCode ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 559) }}><span><FontAwesome.FaFileCode /></span></div> },
        { value: 'FileContract ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 560) }}><span><FontAwesome.FaFileContract /></span></div> },
        { value: 'FileCsv ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 561) }}><span><FontAwesome.FaFileCsv /></span></div> },
        { value: 'FileDownload ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 562) }}><span><FontAwesome.FaFileDownload /></span></div> },
        { value: 'FileExcel ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 563) }}><span><FontAwesome.FaFileExcel /></span></div> },
        { value: 'FileExport ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 564) }}><span><FontAwesome.FaFileExport /></span></div> },
        { value: 'FileImage ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 565) }}><span><FontAwesome.FaFileImage /></span></div> },
        { value: 'FileImport ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 566) }}><span><FontAwesome.FaFileImport /></span></div> },
        { value: 'FileInvoiceDollar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 567) }}><span><FontAwesome.FaFileInvoiceDollar /></span></div> },
        { value: 'FileInvoice ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 568) }}><span><FontAwesome.FaFileInvoice /></span></div> },
        { value: 'FileMedicalAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 569) }}><span><FontAwesome.FaFileMedicalAlt /></span></div> },
        { value: 'FileMedical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 570) }}><span><FontAwesome.FaFileMedical /></span></div> },
        { value: 'FilePdf ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 571) }}><span><FontAwesome.FaFilePdf /></span></div> },
        { value: 'FilePowerpoint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 572) }}><span><FontAwesome.FaFilePowerpoint /></span></div> },
        { value: 'FilePrescription ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 573) }}><span><FontAwesome.FaFilePrescription /></span></div> },
        { value: 'FileSignature ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 574) }}><span><FontAwesome.FaFileSignature /></span></div> },
        { value: 'FileUpload ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 575) }}><span><FontAwesome.FaFileUpload /></span></div> },
        { value: 'FileVideo ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 576) }}><span><FontAwesome.FaFileVideo /></span></div> },
        { value: 'FileWord ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 577) }}><span><FontAwesome.FaFileWord /></span></div> },
        { value: 'File ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 578) }}><span><FontAwesome.FaFile /></span></div> },
        { value: 'FillDrip ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 579) }}><span><FontAwesome.FaFillDrip /></span></div> },
        { value: 'Fill ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 580) }}><span><FontAwesome.FaFill /></span></div> },
        { value: 'Film ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 581) }}><span><FontAwesome.FaFilm /></span></div> },
        { value: 'Filter ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 582) }}><span><FontAwesome.FaFilter /></span></div> },
        { value: 'Fingerprint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 583) }}><span><FontAwesome.FaFingerprint /></span></div> },
        { value: 'FireAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 584) }}><span><FontAwesome.FaFireAlt /></span></div> },
        { value: 'FireExtinguisher ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 585) }}><span><FontAwesome.FaFireExtinguisher /></span></div> },
        { value: 'Fire ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 586) }}><span><FontAwesome.FaFire /></span></div> },
        { value: 'FirstAid ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 587) }}><span><FontAwesome.FaFirstAid /></span></div> },
        { value: 'Fish ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 588) }}><span><FontAwesome.FaFish /></span></div> },
        { value: 'FistRaised ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 589) }}><span><FontAwesome.FaFistRaised /></span></div> },
        { value: 'FlagCheckered ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 590) }}><span><FontAwesome.FaFlagCheckered /></span></div> },
        { value: 'FlagUsa ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 591) }}><span><FontAwesome.FaFlagUsa /></span></div> },
        { value: 'Flag ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 592) }}><span><FontAwesome.FaFlag /></span></div> },
        { value: 'Flask ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 593) }}><span><FontAwesome.FaFlask /></span></div> },
        { value: 'Flushed ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 594) }}><span><FontAwesome.FaFlushed /></span></div> },
        { value: 'FolderMinus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 595) }}><span><FontAwesome.FaFolderMinus /></span></div> },
        { value: 'FolderOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 596) }}><span><FontAwesome.FaFolderOpen /></span></div> },
        { value: 'FolderPlus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 597) }}><span><FontAwesome.FaFolderPlus /></span></div> },
        { value: 'Folder ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 598) }}><span><FontAwesome.FaFolder /></span></div> },
        { value: 'Font ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 599) }}><span><FontAwesome.FaFont /></span></div> },
        
        { value: 'FootballBall ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 600) }}><span><FontAwesome.FaFootballBall /></span></div> },
        { value: 'Forward ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 601) }}><span><FontAwesome.FaForward /></span></div> },
        { value: 'Frog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 602) }}><span><FontAwesome.FaFrog /></span></div> },
        { value: 'FrownOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 603) }}><span><FontAwesome.FaFrownOpen /></span></div> },
        { value: 'Frown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 604) }}><span><FontAwesome.FaFrown /></span></div> },
        { value: 'FunnelDollar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 605) }}><span><FontAwesome.FaFunnelDollar /></span></div> },
        { value: 'Futbol ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 606) }}><span><FontAwesome.FaFutbol /></span></div> },
        { value: 'Gamepad ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 607) }}><span><FontAwesome.FaGamepad /></span></div> },
        { value: 'GasPump ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 608) }}><span><FontAwesome.FaGasPump /></span></div> },
        { value: 'Gavel ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 609) }}><span><FontAwesome.FaGavel /></span></div> },
        { value: 'Gem ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 610) }}><span><FontAwesome.FaGem /></span></div> },
        { value: 'Genderless ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 611) }}><span><FontAwesome.FaGenderless /></span></div> },
        { value: 'Ghost ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 612) }}><span><FontAwesome.FaGhost /></span></div> },
        { value: 'Gift ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 613) }}><span><FontAwesome.FaGift /></span></div> },
        { value: 'Gifts ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 614) }}><span><FontAwesome.FaGifts /></span></div> },
        { value: 'GlassCheers ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 615) }}><span><FontAwesome.FaGlassCheers /></span></div> },
        { value: 'GlassMartiniAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 616) }}><span><FontAwesome.FaGlassMartiniAlt /></span></div> },
        { value: 'GlassMartini ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 617) }}><span><FontAwesome.FaGlassMartini /></span></div> },
        { value: 'GlassWhiskey ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 618) }}><span><FontAwesome.FaGlassWhiskey /></span></div> },
        { value: 'Glasses ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 619) }}><span><FontAwesome.FaGlasses /></span></div> },
        { value: 'GlobeAfrica ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 620) }}><span><FontAwesome.FaGlobeAfrica /></span></div> },
        { value: 'GlobeAmericas ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 621) }}><span><FontAwesome.FaGlobeAmericas /></span></div> },
        { value: 'GlobeAsia ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 622) }}><span><FontAwesome.FaGlobeAsia /></span></div> },
        { value: 'GlobeEurope ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 623) }}><span><FontAwesome.FaGlobeEurope /></span></div> },
        { value: 'Globe ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 624) }}><span><FontAwesome.FaGlobe /></span></div> },
        { value: 'GolfBall ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 625) }}><span><FontAwesome.FaGolfBall /></span></div> },
        { value: 'Gopuram ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 626) }}><span><FontAwesome.FaGopuram /></span></div> },
        { value: 'GraduationCap ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 627) }}><span><FontAwesome.FaGraduationCap /></span></div> },
        { value: 'GreaterThanEqual ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 628) }}><span><FontAwesome.FaGreaterThanEqual /></span></div> },
        { value: 'GreaterThan ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 629) }}><span><FontAwesome.FaGreaterThan /></span></div> },
        { value: 'Grimace ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 630) }}><span><FontAwesome.FaGrimace /></span></div> },
        { value: 'GrinAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 631) }}><span><FontAwesome.FaGrinAlt /></span></div> },
        { value: 'GrinBeamSweat ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 632) }}><span><FontAwesome.FaGrinBeamSweat /></span></div> },
        { value: 'GrinBeam ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 633) }}><span><FontAwesome.FaGrinBeam /></span></div> },
        { value: 'GrinHearts ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 634) }}><span><FontAwesome.FaGrinHearts /></span></div> },
        { value: 'GrinSquintTears ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 635) }}><span><FontAwesome.FaGrinSquintTears /></span></div> },
        { value: 'GrinSquint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 636) }}><span><FontAwesome.FaGrinSquint /></span></div> },
        { value: 'GrinStars ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 637) }}><span><FontAwesome.FaGrinStars /></span></div> },
        { value: 'GrinTears ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 638) }}><span><FontAwesome.FaGrinTears /></span></div> },
        { value: 'GrinTongueSquint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 639) }}><span><FontAwesome.FaGrinTongueSquint /></span></div> },
        { value: 'GrinTongueWink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 640) }}><span><FontAwesome.FaGrinTongueWink /></span></div> },
        { value: 'GrinTongue ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 641) }}><span><FontAwesome.FaGrinTongue /></span></div> },
        { value: 'GrinWink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 642) }}><span><FontAwesome.FaGrinWink /></span></div> },
        { value: 'Grin ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 643) }}><span><FontAwesome.FaGrin /></span></div> },
        { value: 'GripHorizontal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 644) }}><span><FontAwesome.FaGripHorizontal /></span></div> },
        { value: 'GripLinesVertical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 645) }}><span><FontAwesome.FaGripLinesVertical /></span></div> },
        { value: 'GripLines ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 646) }}><span><FontAwesome.FaGripLines /></span></div> },
        { value: 'GripVertical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 647) }}><span><FontAwesome.FaGripVertical /></span></div> },
        { value: 'LuggageCart ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 648) }}><span><FontAwesome.FaLuggageCart /></span></div> },
        { value: 'LungsVirus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 649) }}><span><FontAwesome.FaLungsVirus /></span></div> },
        { value: 'Lungs ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 650) }}><span><FontAwesome.FaLungs /></span></div> },
        { value: 'Magic ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 651) }}><span><FontAwesome.FaMagic /></span></div> },
        { value: 'Magnet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 652) }}><span><FontAwesome.FaMagnet /></span></div> },
        { value: 'MailBulk ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 653) }}><span><FontAwesome.FaMailBulk /></span></div> },
        { value: 'Male ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 654) }}><span><FontAwesome.FaMale /></span></div> },
        { value: 'MapMarkedAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 655) }}><span><FontAwesome.FaMapMarkedAlt /></span></div> },
        { value: 'MapMarked ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 656) }}><span><FontAwesome.FaMapMarked /></span></div> },
        { value: 'MapMarkerAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 657) }}><span><FontAwesome.FaMapMarkerAlt /></span></div> },
        { value: 'MapMarker ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 658) }}><span><FontAwesome.FaMapMarker /></span></div> },
        { value: 'MapPin ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 659) }}><span><FontAwesome.FaMapPin /></span></div> },
        { value: 'MapSigns ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 660) }}><span><FontAwesome.FaMapSigns /></span></div> },
        { value: 'Map ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 661) }}><span><FontAwesome.FaMap /></span></div> },
        { value: 'Marker ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 662) }}><span><FontAwesome.FaMarker /></span></div> },
        { value: 'MarsDouble ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 663) }}><span><FontAwesome.FaMarsDouble /></span></div> },
        { value: 'MarsStrokeH ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 664) }}><span><FontAwesome.FaMarsStrokeH /></span></div> },
        { value: 'MarsStrokeV ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 665) }}><span><FontAwesome.FaMarsStrokeV /></span></div> },
        { value: 'MarsStroke ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 666) }}><span><FontAwesome.FaMarsStroke /></span></div> },
        { value: 'Mars ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 667) }}><span><FontAwesome.FaMars /></span></div> },
        { value: 'Mask ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 668) }}><span><FontAwesome.FaMask /></span></div> },
        { value: 'Medal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 669) }}><span><FontAwesome.FaMedal /></span></div> },
        { value: 'Medkit ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 670) }}><span><FontAwesome.FaMedkit /></span></div> },
        { value: 'MehBlank ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 671) }}><span><FontAwesome.FaMehBlank /></span></div> },
        { value: 'MehRollingEyes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 672) }}><span><FontAwesome.FaMehRollingEyes /></span></div> },
        { value: 'Meh ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 673) }}><span><FontAwesome.FaMeh /></span></div> },
        { value: 'Memory ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 674) }}><span><FontAwesome.FaMemory /></span></div> },
        { value: 'Menorah ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 675) }}><span><FontAwesome.FaMenorah /></span></div> },
        { value: 'Mercury ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 676) }}><span><FontAwesome.FaMercury /></span></div> },
        { value: 'Meteor ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 677) }}><span><FontAwesome.FaMeteor /></span></div> },
        { value: 'Microchip ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 678) }}><span><FontAwesome.FaMicrochip /></span></div> },
        { value: 'MicrophoneAltSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 679) }}><span><FontAwesome.FaMicrophoneAltSlash /></span></div> },
        { value: 'MicrophoneAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 680) }}><span><FontAwesome.FaMicrophoneAlt /></span></div> },
        { value: 'MicrophoneSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 681) }}><span><FontAwesome.FaMicrophoneSlash /></span></div> },
        { value: 'Microphone ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 682) }}><span><FontAwesome.FaMicrophone /></span></div> },
        { value: 'Microscope ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 683) }}><span><FontAwesome.FaMicroscope /></span></div> },
        { value: 'MinusCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 684) }}><span><FontAwesome.FaMinusCircle /></span></div> },
        { value: 'MinusSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 685) }}><span><FontAwesome.FaMinusSquare /></span></div> },
        { value: 'Minus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 686) }}><span><FontAwesome.FaMinus /></span></div> },
        { value: 'Mitten ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 687) }}><span><FontAwesome.FaMitten /></span></div> },
        { value: 'MobileAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 688) }}><span><FontAwesome.FaMobileAlt /></span></div> },
        { value: 'Mobile ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 689) }}><span><FontAwesome.FaMobile /></span></div> },
        { value: 'MoneyBillAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 690) }}><span><FontAwesome.FaMoneyBillAlt /></span></div> },
        { value: 'MoneyBillWaveAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 691) }}><span><FontAwesome.FaMoneyBillWaveAlt /></span></div> },
        { value: 'MoneyBillWave ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 692) }}><span><FontAwesome.FaMoneyBillWave /></span></div> },
        { value: 'MoneyBill ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 693) }}><span><FontAwesome.FaMoneyBill /></span></div> },
        { value: 'MoneyCheckAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 694) }}><span><FontAwesome.FaMoneyCheckAlt /></span></div> },
        { value: 'MoneyCheck ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 695) }}><span><FontAwesome.FaMoneyCheck /></span></div> },
        { value: 'Monument ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 696) }}><span><FontAwesome.FaMonument /></span></div> },
        { value: 'Moon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 697) }}><span><FontAwesome.FaMoon /></span></div> },
        { value: 'MortarPestle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 698) }}><span><FontAwesome.FaMortarPestle /></span></div> },
        { value: 'Mosque ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 699) }}><span><FontAwesome.FaMosque /></span></div> },
        
        { value: 'Motorcycle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 700) }}><span><FontAwesome.FaMotorcycle /></span></div> },
        { value: 'Mountain ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 701) }}><span><FontAwesome.FaMountain /></span></div> },
        { value: 'MousePointer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 702) }}><span><FontAwesome.FaMousePointer /></span></div> },
        { value: 'Mouse ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 703) }}><span><FontAwesome.FaMouse /></span></div> },
        { value: 'MugHot ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 704) }}><span><FontAwesome.FaMugHot /></span></div> },
        { value: 'Music ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 705) }}><span><FontAwesome.FaMusic /></span></div> },
        { value: 'NetworkWired ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 706) }}><span><FontAwesome.FaNetworkWired /></span></div> },
        { value: 'Neuter ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 707) }}><span><FontAwesome.FaNeuter /></span></div> },
        { value: 'Newspaper ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 708) }}><span><FontAwesome.FaNewspaper /></span></div> },
        { value: 'NotEqual ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 709) }}><span><FontAwesome.FaNotEqual /></span></div> },
        { value: 'NotesMedical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 710) }}><span><FontAwesome.FaNotesMedical /></span></div> },
        { value: 'ObjectGroup ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 711) }}><span><FontAwesome.FaObjectGroup /></span></div> },
        { value: 'ObjectUngroup ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 712) }}><span><FontAwesome.FaObjectUngroup /></span></div> },
        { value: 'OilCan ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 713) }}><span><FontAwesome.FaOilCan /></span></div> },
        { value: 'Om ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 714) }}><span><FontAwesome.FaOm /></span></div> },
        { value: 'Otter ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 715) }}><span><FontAwesome.FaOtter /></span></div> },
        { value: 'Outdent ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 716) }}><span><FontAwesome.FaOutdent /></span></div> },
        { value: 'Pager ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 717) }}><span><FontAwesome.FaPager /></span></div> },
        { value: 'PaintBrush ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 718) }}><span><FontAwesome.FaPaintBrush /></span></div> },
        { value: 'PaintRoller ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 719) }}><span><FontAwesome.FaPaintRoller /></span></div> },
        { value: 'Palette ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 720) }}><span><FontAwesome.FaPalette /></span></div> },
        { value: 'Pallet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 721) }}><span><FontAwesome.FaPallet /></span></div> },
        { value: 'PaperPlane ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 722) }}><span><FontAwesome.FaPaperPlane /></span></div> },
        { value: 'Paperclip ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 723) }}><span><FontAwesome.FaPaperclip /></span></div> },
        { value: 'ParachuteBox ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 724) }}><span><FontAwesome.FaParachuteBox /></span></div> },
        { value: 'Paragraph ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 725) }}><span><FontAwesome.FaParagraph /></span></div> },
        { value: 'Parking ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 726) }}><span><FontAwesome.FaParking /></span></div> },
        { value: 'Passport ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 727) }}><span><FontAwesome.FaPassport /></span></div> },
        { value: 'PauseCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 728) }}><span><FontAwesome.FaPauseCircle /></span></div> },
        { value: 'Pause ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 729) }}><span><FontAwesome.FaPause /></span></div> },
        { value: 'Paw ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 730) }}><span><FontAwesome.FaPaw /></span></div> },
        { value: 'Peace ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 731) }}><span><FontAwesome.FaPeace /></span></div> },
        { value: 'PenAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 732) }}><span><FontAwesome.FaPenAlt /></span></div> },
        { value: 'Pen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 733) }}><span><FontAwesome.FaPen /></span></div> },
        { value: 'PenNib ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 734) }}><span><FontAwesome.FaPenNib /></span></div> },
        { value: 'PenSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 735) }}><span><FontAwesome.FaPenSquare /></span></div> },
        { value: 'Pen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 736) }}><span><FontAwesome.FaPen /></span></div> },
        { value: 'PencilAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 737) }}><span><FontAwesome.FaPencilAlt /></span></div> },
        { value: 'PencilRuler ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 738) }}><span><FontAwesome.FaPencilRuler /></span></div> },
        { value: 'PeopleArrows ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 739) }}><span><FontAwesome.FaPeopleArrows /></span></div> },
        { value: 'PeopleCarry ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 740) }}><span><FontAwesome.FaPeopleCarry /></span></div> },
        { value: 'PepperHot ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 741) }}><span><FontAwesome.FaPepperHot /></span></div> },
        { value: 'Percent ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 742) }}><span><FontAwesome.FaPercent /></span></div> },
        { value: 'Percentage ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 743) }}><span><FontAwesome.FaPercentage /></span></div> },
        { value: 'PersonBooth ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 744) }}><span><FontAwesome.FaPersonBooth /></span></div> },
        { value: 'PhoneAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 745) }}><span><FontAwesome.FaPhoneAlt /></span></div> },
        { value: 'PhoneSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 746) }}><span><FontAwesome.FaPhoneSlash /></span></div> },
        { value: 'PhoneSquareAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 747) }}><span><FontAwesome.FaPhoneSquareAlt /></span></div> },
        { value: 'PhoneSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 748) }}><span><FontAwesome.FaPhoneSquare /></span></div> },
        { value: 'PhoneVolume ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 749) }}><span><FontAwesome.FaPhoneVolume /></span></div> },
        { value: 'Phone ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 750) }}><span><FontAwesome.FaPhone /></span></div> },
        { value: 'PhotoVideo ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 751) }}><span><FontAwesome.FaPhotoVideo /></span></div> },
        { value: 'PiggyBank ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 752) }}><span><FontAwesome.FaPiggyBank /></span></div> },
        { value: 'Pills ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 753) }}><span><FontAwesome.FaPills /></span></div> },
        { value: 'PizzaSlice ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 754) }}><span><FontAwesome.FaPizzaSlice /></span></div> },
        { value: 'PlaceOfWorship ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 755) }}><span><FontAwesome.FaPlaceOfWorship /></span></div> },
        { value: 'PlaneArrival ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 756) }}><span><FontAwesome.FaPlaneArrival /></span></div> },
        { value: 'PlaneDeparture ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 757) }}><span><FontAwesome.FaPlaneDeparture /></span></div> },
        { value: 'PlaneSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 758) }}><span><FontAwesome.FaPlaneSlash /></span></div> },
        { value: 'Plane ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 759) }}><span><FontAwesome.FaPlane /></span></div> },
        { value: 'PlayCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 760) }}><span><FontAwesome.FaPlayCircle /></span></div> },
        { value: 'Play ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 761) }}><span><FontAwesome.FaPlay /></span></div> },
        { value: 'Plug ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 762) }}><span><FontAwesome.FaPlug /></span></div> },
        { value: 'PlusCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 763) }}><span><FontAwesome.FaPlusCircle /></span></div> },
        { value: 'PlusSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 764) }}><span><FontAwesome.FaPlusSquare /></span></div> },
        { value: 'Plus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 765) }}><span><FontAwesome.FaPlus /></span></div> },
        { value: 'Podcast ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 766) }}><span><FontAwesome.FaPodcast /></span></div> },
        { value: 'PollH ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 767) }}><span><FontAwesome.FaPollH /></span></div> },
        { value: 'Poll ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 768) }}><span><FontAwesome.FaPoll /></span></div> },
        { value: 'PooStorm ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 769) }}><span><FontAwesome.FaPooStorm /></span></div> },
        { value: 'Poo ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 770) }}><span><FontAwesome.FaPoo /></span></div> },
        { value: 'Poop ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 771) }}><span><FontAwesome.FaPoop /></span></div> },
        { value: 'Portrait ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 772) }}><span><FontAwesome.FaPortrait /></span></div> },
        { value: 'PoundSign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 773) }}><span><FontAwesome.FaPoundSign /></span></div> },
        { value: 'PowerOff ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 774) }}><span><FontAwesome.FaPowerOff /></span></div> },
        { value: 'Pray ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 775) }}><span><FontAwesome.FaPray /></span></div> },
        { value: 'PrayingHands ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 776) }}><span><FontAwesome.FaPrayingHands /></span></div> },
        { value: 'PrescriptionBottleAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 777) }}><span><FontAwesome.FaPrescriptionBottleAlt /></span></div> },
        { value: 'PrescriptionBottle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 778) }}><span><FontAwesome.FaPrescriptionBottle /></span></div> },
        { value: 'Prescription ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 779) }}><span><FontAwesome.FaPrescription /></span></div> },
        { value: 'Print ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 780) }}><span><FontAwesome.FaPrint /></span></div> },
        { value: 'Procedures ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 781) }}><span><FontAwesome.FaProcedures /></span></div> },
        { value: 'ProjectDiagram ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 782) }}><span><FontAwesome.FaProjectDiagram /></span></div> },
        { value: 'PumpMedical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 783) }}><span><FontAwesome.FaPumpMedical /></span></div> },
        { value: 'PumpSoap ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 784) }}><span><FontAwesome.FaPumpSoap /></span></div> },
        { value: 'PuzzlePiece ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 785) }}><span><FontAwesome.FaPuzzlePiece /></span></div> },
        { value: 'Qrcode ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 786) }}><span><FontAwesome.FaQrcode /></span></div> },
        { value: 'QuestionCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 787) }}><span><FontAwesome.FaQuestionCircle /></span></div> },
        { value: 'Question ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 788) }}><span><FontAwesome.FaQuestion /></span></div> },
        { value: 'Quidditch ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 789) }}><span><FontAwesome.FaQuidditch /></span></div> },
        { value: 'QuoteLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 790) }}><span><FontAwesome.FaQuoteLeft /></span></div> },
        { value: 'QuoteRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 791) }}><span><FontAwesome.FaQuoteRight /></span></div> },
        { value: 'Quran ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 792) }}><span><FontAwesome.FaQuran /></span></div> },
        { value: 'RadiationAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 793) }}><span><FontAwesome.FaRadiationAlt /></span></div> },
        { value: 'Radiation ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 794) }}><span><FontAwesome.FaRadiation /></span></div> },
        { value: 'Rainbow ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 795) }}><span><FontAwesome.FaRainbow /></span></div> },
        { value: 'Random ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 796) }}><span><FontAwesome.FaRandom /></span></div> },
        { value: 'Receipt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 797) }}><span><FontAwesome.FaReceipt /></span></div> },
        { value: 'RecordVinyl ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 798) }}><span><FontAwesome.FaRecordVinyl /></span></div> },
        { value: 'Recycle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 799) }}><span><FontAwesome.FaRecycle /></span></div> },
        
        { value: 'RedoAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 800) }}><span><FontAwesome.FaRedoAlt /></span></div> },
        { value: 'Redo ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 801) }}><span><FontAwesome.FaRedo /></span></div> },
        { value: 'Registered ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 802) }}><span><FontAwesome.FaRegistered /></span></div> },
        { value: 'RemoveFormat ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 803) }}><span><FontAwesome.FaRemoveFormat /></span></div> },
        { value: 'ReplyAll ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 804) }}><span><FontAwesome.FaReplyAll /></span></div> },
        { value: 'Reply ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 805) }}><span><FontAwesome.FaReply /></span></div> },
        { value: 'Republican ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 806) }}><span><FontAwesome.FaRepublican /></span></div> },
        { value: 'Restroom ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 807) }}><span><FontAwesome.FaRestroom /></span></div> },
        { value: 'Retweet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 808) }}><span><FontAwesome.FaRetweet /></span></div> },
        { value: 'Ribbon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 809) }}><span><FontAwesome.FaRibbon /></span></div> },
        { value: 'Ring ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 810) }}><span><FontAwesome.FaRing /></span></div> },
        { value: 'Road ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 811) }}><span><FontAwesome.FaRoad /></span></div> },
        { value: 'Robot ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 812) }}><span><FontAwesome.FaRobot /></span></div> },
        { value: 'Rocket ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 813) }}><span><FontAwesome.FaRocket /></span></div> },
        { value: 'Route ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 814) }}><span><FontAwesome.FaRoute /></span></div> },
        { value: 'RssSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 815) }}><span><FontAwesome.FaRssSquare /></span></div> },
        { value: 'Rss ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 816) }}><span><FontAwesome.FaRss /></span></div> },
        { value: 'RubleSign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 817) }}><span><FontAwesome.FaRubleSign /></span></div> },
        { value: 'RulerCombined ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 818) }}><span><FontAwesome.FaRulerCombined /></span></div> },
        { value: 'RulerHorizontal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 819) }}><span><FontAwesome.FaRulerHorizontal /></span></div> },
        { value: 'RulerVertical ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 820) }}><span><FontAwesome.FaRulerVertical /></span></div> },
        { value: 'Ruler ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 821) }}><span><FontAwesome.FaRuler /></span></div> },
        { value: 'Running ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 822) }}><span><FontAwesome.FaRunning /></span></div> },
        { value: 'RupeeSign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 823) }}><span><FontAwesome.FaRupeeSign /></span></div> },
        { value: 'SadCry ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 824) }}><span><FontAwesome.FaSadCry /></span></div> },
        { value: 'SadTear ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 825) }}><span><FontAwesome.FaSadTear /></span></div> },
        { value: 'SatelliteDish ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 826) }}><span><FontAwesome.FaSatelliteDish /></span></div> },
        { value: 'Satellite ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 827) }}><span><FontAwesome.FaSatellite /></span></div> },
        { value: 'Save ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 828) }}><span><FontAwesome.FaSave /></span></div> },
        { value: 'School ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 829) }}><span><FontAwesome.FaSchool /></span></div> },
        { value: 'Screwdriver ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 830) }}><span><FontAwesome.FaScrewdriver /></span></div> },
        { value: 'Scroll ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 831) }}><span><FontAwesome.FaScroll /></span></div> },
        { value: 'SdCard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 832) }}><span><FontAwesome.FaSdCard /></span></div> },
        { value: 'SearchDollar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 833) }}><span><FontAwesome.FaSearchDollar /></span></div> },
        { value: 'SearchLocation ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 834) }}><span><FontAwesome.FaSearchLocation /></span></div> },
        { value: 'SearchMinus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 835) }}><span><FontAwesome.FaSearchMinus /></span></div> },
        { value: 'SearchPlus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 836) }}><span><FontAwesome.FaSearchPlus /></span></div> },
        { value: 'Search ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 837) }}><span><FontAwesome.FaSearch /></span></div> },
        { value: 'Seedling ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 838) }}><span><FontAwesome.FaSeedling /></span></div> },
        { value: 'Server ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 839) }}><span><FontAwesome.FaServer /></span></div> },
        { value: 'Shapes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 840) }}><span><FontAwesome.FaShapes /></span></div> },
        { value: 'ShareAltSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 841) }}><span><FontAwesome.FaShareAltSquare /></span></div> },
        { value: 'ShareAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 842) }}><span><FontAwesome.FaShareAlt /></span></div> },
        { value: 'ShareSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 843) }}><span><FontAwesome.FaShareSquare /></span></div> },
        { value: 'Share ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 844) }}><span><FontAwesome.FaShare /></span></div> },
        { value: 'ShekelSign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 845) }}><span><FontAwesome.FaShekelSign /></span></div> },
        { value: 'ShieldAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 846) }}><span><FontAwesome.FaShieldAlt /></span></div> },
        { value: 'ShieldVirus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 847) }}><span><FontAwesome.FaShieldVirus /></span></div> },
        { value: 'Ship ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 848) }}><span><FontAwesome.FaShip /></span></div> },
        { value: 'ShoePrints ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 849) }}><span><FontAwesome.FaShoePrints /></span></div> },
        { value: 'ShoppingBag ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 850) }}><span><FontAwesome.FaShoppingBag /></span></div> },
        { value: 'ShoppingBasket ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 851) }}><span><FontAwesome.FaShoppingBasket /></span></div> },
        { value: 'ShoppingCart ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 852) }}><span><FontAwesome.FaShoppingCart /></span></div> },
        { value: 'Shower ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 853) }}><span><FontAwesome.FaShower /></span></div> },
        { value: 'ShuttleVan ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 854) }}><span><FontAwesome.FaShuttleVan /></span></div> },
        { value: 'SignInAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 855) }}><span><FontAwesome.FaSignInAlt /></span></div> },
        { value: 'SignLanguage ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 856) }}><span><FontAwesome.FaSignLanguage /></span></div> },
        { value: 'SignOutAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 857) }}><span><FontAwesome.FaSignOutAlt /></span></div> },
        { value: 'Sign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 858) }}><span><FontAwesome.FaSign /></span></div> },
        { value: 'Signal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 859) }}><span><FontAwesome.FaSignal /></span></div> },
        { value: 'Signature ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 860) }}><span><FontAwesome.FaSignature /></span></div> },
        { value: 'SimCard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 861) }}><span><FontAwesome.FaSimCard /></span></div> },
        { value: 'Sink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 862) }}><span><FontAwesome.FaSink /></span></div> },
        { value: 'Sitemap ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 863) }}><span><FontAwesome.FaSitemap /></span></div> },
        { value: 'Skating ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 864) }}><span><FontAwesome.FaSkating /></span></div> },
        { value: 'SkiingNordic ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 865) }}><span><FontAwesome.FaSkiingNordic /></span></div> },
        { value: 'Skiing ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 866) }}><span><FontAwesome.FaSkiing /></span></div> },
        { value: 'SkullCrossbones ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 867) }}><span><FontAwesome.FaSkullCrossbones /></span></div> },
        { value: 'Skull ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 868) }}><span><FontAwesome.FaSkull /></span></div> },
        { value: 'Slash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 869) }}><span><FontAwesome.FaSlash /></span></div> },
        { value: 'Sleigh ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 870) }}><span><FontAwesome.FaSleigh /></span></div> },
        { value: 'SlidersH ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 871) }}><span><FontAwesome.FaSlidersH /></span></div> },
        { value: 'SmileBeam ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 872) }}><span><FontAwesome.FaSmileBeam /></span></div> },
        { value: 'SmileWink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 873) }}><span><FontAwesome.FaSmileWink /></span></div> },
        { value: 'Smile ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 874) }}><span><FontAwesome.FaSmile /></span></div> },
        { value: 'Smog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 875) }}><span><FontAwesome.FaSmog /></span></div> },
        { value: 'SmokingBan ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 876) }}><span><FontAwesome.FaSmokingBan /></span></div> },
        { value: 'Smoking ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 877) }}><span><FontAwesome.FaSmoking /></span></div> },
        { value: 'Sms ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 878) }}><span><FontAwesome.FaSms /></span></div> },
        { value: 'Snowboarding ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 879) }}><span><FontAwesome.FaSnowboarding /></span></div> },
        { value: 'Snowflake ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 880) }}><span><FontAwesome.FaSnowflake /></span></div> },
        { value: 'Snowman ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 881) }}><span><FontAwesome.FaSnowman /></span></div> },
        { value: 'Snowplow ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 882) }}><span><FontAwesome.FaSnowplow /></span></div> },
        { value: 'Soap ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 883) }}><span><FontAwesome.FaSoap /></span></div> },
        { value: 'Socks ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 884) }}><span><FontAwesome.FaSocks /></span></div> },
        { value: 'SolarPanel ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 885) }}><span><FontAwesome.FaSolarPanel /></span></div> },
        { value: 'SortAlphaDownAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 886) }}><span><FontAwesome.FaSortAlphaDownAlt /></span></div> },
        { value: 'SortAlphaDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 887) }}><span><FontAwesome.FaSortAlphaDown /></span></div> },
        { value: 'SortAlphaUpAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 888) }}><span><FontAwesome.FaSortAlphaUpAlt /></span></div> },
        { value: 'SortAlphaUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 889) }}><span><FontAwesome.FaSortAlphaUp /></span></div> },
        { value: 'SortAmountDownAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 890) }}><span><FontAwesome.FaSortAmountDownAlt /></span></div> },
        { value: 'SortAmountDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 891) }}><span><FontAwesome.FaSortAmountDown /></span></div> },
        { value: 'SortAmountUpAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 892) }}><span><FontAwesome.FaSortAmountUpAlt /></span></div> },
        { value: 'SortAmountUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 893) }}><span><FontAwesome.FaSortAmountUp /></span></div> },
        { value: 'SortDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 894) }}><span><FontAwesome.FaSortDown /></span></div> },
        { value: 'SortNumericDownAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 895) }}><span><FontAwesome.FaSortNumericDownAlt /></span></div> },
        { value: 'SortNumericDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 896) }}><span><FontAwesome.FaSortNumericDown /></span></div> },
        { value: 'SortNumericUpAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 897) }}><span><FontAwesome.FaSortNumericUpAlt /></span></div> },
        { value: 'SortNumericUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 898) }}><span><FontAwesome.FaSortNumericUp /></span></div> },
        { value: 'SortUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 899) }}><span><FontAwesome.FaSortUp /></span></div> },
        
        { value: 'Sort ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 900) }}><span><FontAwesome.FaSort /></span></div> },
        { value: 'Spa ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 901) }}><span><FontAwesome.FaSpa /></span></div> },
        { value: 'SpaceShuttle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 902) }}><span><FontAwesome.FaSpaceShuttle /></span></div> },
        { value: 'SpellCheck ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 903) }}><span><FontAwesome.FaSpellCheck /></span></div> },
        { value: 'Spider ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 904) }}><span><FontAwesome.FaSpider /></span></div> },
        { value: 'Spinner ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 905) }}><span><FontAwesome.FaSpinner /></span></div> },
        { value: 'Splotch ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 906) }}><span><FontAwesome.FaSplotch /></span></div> },
        { value: 'SprayCan ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 907) }}><span><FontAwesome.FaSprayCan /></span></div> },
        { value: 'SquareFull ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 908) }}><span><FontAwesome.FaSquareFull /></span></div> },
        { value: 'SquareRootAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 909) }}><span><FontAwesome.FaSquareRootAlt /></span></div> },
        { value: 'Square ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 910) }}><span><FontAwesome.FaSquare /></span></div> },
        { value: 'Stamp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 911) }}><span><FontAwesome.FaStamp /></span></div> },
        { value: 'StarAndCrescent ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 912) }}><span><FontAwesome.FaStarAndCrescent /></span></div> },
        { value: 'StarHalf ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 913) }}><span><FontAwesome.FaStarHalf /></span></div> },
        { value: 'StarOfDavid ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 914) }}><span><FontAwesome.FaStarOfDavid /></span></div> },
        { value: 'StarOfLife ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 915) }}><span><FontAwesome.FaStarOfLife /></span></div> },
        { value: 'Star ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 916) }}><span><FontAwesome.FaStar /></span></div> },
        { value: 'StepBackward ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 917) }}><span><FontAwesome.FaStepBackward /></span></div> },
        { value: 'StepForward ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 918) }}><span><FontAwesome.FaStepForward /></span></div> },
        { value: 'Stethoscope ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 919) }}><span><FontAwesome.FaStethoscope /></span></div> },
        { value: 'StickyNote ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 920) }}><span><FontAwesome.FaStickyNote /></span></div> },
        { value: 'StopCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 921) }}><span><FontAwesome.FaStopCircle /></span></div> },
        { value: 'Stop ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 922) }}><span><FontAwesome.FaStop /></span></div> },
        { value: 'Stopwatch20 ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 923) }}><span><FontAwesome.FaStopwatch20 /></span></div> },
        { value: 'Stopwatch ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 924) }}><span><FontAwesome.FaStopwatch /></span></div> },
        { value: 'StoreAltSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 925) }}><span><FontAwesome.FaStoreAltSlash /></span></div> },
        { value: 'StoreAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 926) }}><span><FontAwesome.FaStoreAlt /></span></div> },
        { value: 'StoreSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 927) }}><span><FontAwesome.FaStoreSlash /></span></div> },
        { value: 'Store ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 928) }}><span><FontAwesome.FaStore /></span></div> },
        { value: 'Stream ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 929) }}><span><FontAwesome.FaStream /></span></div> },
        { value: 'StreetView ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 930) }}><span><FontAwesome.FaStreetView /></span></div> },
        { value: 'Strikethrough ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 931) }}><span><FontAwesome.FaStrikethrough /></span></div> },
        { value: 'Stroopwafel ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 932) }}><span><FontAwesome.FaStroopwafel /></span></div> },
        { value: 'Subscript ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 933) }}><span><FontAwesome.FaSubscript /></span></div> },
        { value: 'Subway ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 934) }}><span><FontAwesome.FaSubway /></span></div> },
        { value: 'SuitcaseRolling ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 935) }}><span><FontAwesome.FaSuitcaseRolling /></span></div> },
        { value: 'Suitcase ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 936) }}><span><FontAwesome.FaSuitcase /></span></div> },
        { value: 'Sun ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 937) }}><span><FontAwesome.FaSun /></span></div> },
        { value: 'Superscript ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 938) }}><span><FontAwesome.FaSuperscript /></span></div> },
        { value: 'Surprise ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 939) }}><span><FontAwesome.FaSurprise /></span></div> },
        { value: 'Swatchbook ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 940) }}><span><FontAwesome.FaSwatchbook /></span></div> },
        { value: 'Swimmer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 941) }}><span><FontAwesome.FaSwimmer /></span></div> },
        { value: 'SwimmingPool ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 942) }}><span><FontAwesome.FaSwimmingPool /></span></div> },
        { value: 'Synagogue ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 943) }}><span><FontAwesome.FaSynagogue /></span></div> },
        { value: 'SyncAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 944) }}><span><FontAwesome.FaSyncAlt /></span></div> },
        { value: 'Sync ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 945) }}><span><FontAwesome.FaSync /></span></div> },
        { value: 'Syringe ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 946) }}><span><FontAwesome.FaSyringe /></span></div> },
        { value: 'TableTennis ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 947) }}><span><FontAwesome.FaTableTennis /></span></div> },
        { value: 'Table ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 948) }}><span><FontAwesome.FaTable /></span></div> },
        { value: 'TabletAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 949) }}><span><FontAwesome.FaTabletAlt /></span></div> },
        { value: 'Tablet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 950) }}><span><FontAwesome.FaTablet /></span></div> },
        { value: 'Tablets ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 951) }}><span><FontAwesome.FaTablets /></span></div> },
        { value: 'TachometerAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 952) }}><span><FontAwesome.FaTachometerAlt /></span></div> },
        { value: 'Tag ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 953) }}><span><FontAwesome.FaTag /></span></div> },
        { value: 'Tags ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 954) }}><span><FontAwesome.FaTags /></span></div> },
        { value: 'Tape ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 955) }}><span><FontAwesome.FaTape /></span></div> },
        { value: 'Tasks ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 956) }}><span><FontAwesome.FaTasks /></span></div> },
        { value: 'Taxi ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 957) }}><span><FontAwesome.FaTaxi /></span></div> },
        { value: 'TeethOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 958) }}><span><FontAwesome.FaTeethOpen /></span></div> },
        { value: 'Teeth ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 959) }}><span><FontAwesome.FaTeeth /></span></div> },
        { value: 'TemperatureHigh ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 960) }}><span><FontAwesome.FaTemperatureHigh /></span></div> },
        { value: 'TemperatureLow ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 961) }}><span><FontAwesome.FaTemperatureLow /></span></div> },
        { value: 'Tenge ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 962) }}><span><FontAwesome.FaTenge /></span></div> },
        { value: 'Terminal ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 963) }}><span><FontAwesome.FaTerminal /></span></div> },
        { value: 'TextHeight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 964) }}><span><FontAwesome.FaTextHeight /></span></div> },
        { value: 'TextWidth ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 965) }}><span><FontAwesome.FaTextWidth /></span></div> },
        { value: 'ThLarge ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 966) }}><span><FontAwesome.FaThLarge /></span></div> },
        { value: 'ThList ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 967) }}><span><FontAwesome.FaThList /></span></div> },
        { value: 'Th ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 968) }}><span><FontAwesome.FaTh /></span></div> },
        { value: 'TheaterMasks ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 969) }}><span><FontAwesome.FaTheaterMasks /></span></div> },
        { value: 'ThermometerEmpty ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 970) }}><span><FontAwesome.FaThermometerEmpty /></span></div> },
        { value: 'ThermometerFull ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 971) }}><span><FontAwesome.FaThermometerFull /></span></div> },
        { value: 'ThermometerHalf ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 972) }}><span><FontAwesome.FaThermometerHalf /></span></div> },
        { value: 'ThermometerQuarter ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 973) }}><span><FontAwesome.FaThermometerQuarter /></span></div> },
        { value: 'ThermometerThreeQuarters ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 974) }}><span><FontAwesome.FaThermometerThreeQuarters /></span></div> },
        { value: 'Thermometer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 975) }}><span><FontAwesome.FaThermometer /></span></div> },
        { value: 'ThumbsDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 976) }}><span><FontAwesome.FaThumbsDown /></span></div> },
        { value: 'ThumbsUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 977) }}><span><FontAwesome.FaThumbsUp /></span></div> },
        { value: 'Thumbtack ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 978) }}><span><FontAwesome.FaThumbtack /></span></div> },
        { value: 'TicketAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 979) }}><span><FontAwesome.FaTicketAlt /></span></div> },
        { value: 'TimesCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 980) }}><span><FontAwesome.FaTimesCircle /></span></div> },
        { value: 'Times ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 981) }}><span><FontAwesome.FaTimes /></span></div> },
        { value: 'TintSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 982) }}><span><FontAwesome.FaTintSlash /></span></div> },
        { value: 'Tint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 983) }}><span><FontAwesome.FaTint /></span></div> },
        { value: 'Tired ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 984) }}><span><FontAwesome.FaTired /></span></div> },
        { value: 'ToggleOff ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 985) }}><span><FontAwesome.FaToggleOff /></span></div> },
        { value: 'ToggleOn ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 986) }}><span><FontAwesome.FaToggleOn /></span></div> },
        { value: 'ToiletPaperSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 987) }}><span><FontAwesome.FaToiletPaperSlash /></span></div> },
        { value: 'ToiletPaper ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 988) }}><span><FontAwesome.FaToiletPaper /></span></div> },
        { value: 'Toilet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 989) }}><span><FontAwesome.FaToilet /></span></div> },
        { value: 'Toolbox ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 990) }}><span><FontAwesome.FaToolbox /></span></div> },
        { value: 'Tools ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 991) }}><span><FontAwesome.FaTools /></span></div> },
        { value: 'Tooth ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 992) }}><span><FontAwesome.FaTooth /></span></div> },
        { value: 'Torah ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 993) }}><span><FontAwesome.FaTorah /></span></div> },
        { value: 'ToriiGate ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 994) }}><span><FontAwesome.FaToriiGate /></span></div> },
        { value: 'Tractor ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 995) }}><span><FontAwesome.FaTractor /></span></div> },
        { value: 'Trademark ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 996) }}><span><FontAwesome.FaTrademark /></span></div> },
        { value: 'TrafficLight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 997) }}><span><FontAwesome.FaTrafficLight /></span></div> },
        { value: 'Trailer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 998) }}><span><FontAwesome.FaTrailer /></span></div> },
        { value: 'Train ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 999) }}><span><FontAwesome.FaTrain /></span></div> },
        
        { value: 'Tram ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1000) }}><span><FontAwesome.FaTram /></span></div> },
        { value: 'TransgenderAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1001) }}><span><FontAwesome.FaTransgenderAlt /></span></div> },
        { value: 'Transgender ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1002) }}><span><FontAwesome.FaTransgender /></span></div> },
        { value: 'TrashAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1003) }}><span><FontAwesome.FaTrashAlt /></span></div> },
        { value: 'TrashRestoreAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1004) }}><span><FontAwesome.FaTrashRestoreAlt /></span></div> },
        { value: 'TrashRestore ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1005) }}><span><FontAwesome.FaTrashRestore /></span></div> },
        { value: 'Trash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1006) }}><span><FontAwesome.FaTrash /></span></div> },
        { value: 'Tree ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1007) }}><span><FontAwesome.FaTree /></span></div> },
        { value: 'Trophy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1008) }}><span><FontAwesome.FaTrophy /></span></div> },
        { value: 'TruckLoading ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1009) }}><span><FontAwesome.FaTruckLoading /></span></div> },
        { value: 'TruckMonster ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1010) }}><span><FontAwesome.FaTruckMonster /></span></div> },
        { value: 'TruckMoving ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1011) }}><span><FontAwesome.FaTruckMoving /></span></div> },
        { value: 'TruckPickup ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1012) }}><span><FontAwesome.FaTruckPickup /></span></div> },
        { value: 'Truck ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1013) }}><span><FontAwesome.FaTruck /></span></div> },
        { value: 'Tshirt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1014) }}><span><FontAwesome.FaTshirt /></span></div> },
        { value: 'Tty ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1015) }}><span><FontAwesome.FaTty /></span></div> },
        { value: 'Tv ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1016) }}><span><FontAwesome.FaTv /></span></div> },
        { value: 'UmbrellaBeach ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1017) }}><span><FontAwesome.FaUmbrellaBeach /></span></div> },
        { value: 'Umbrella ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1018) }}><span><FontAwesome.FaUmbrella /></span></div> },
        { value: 'Underline ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1019) }}><span><FontAwesome.FaUnderline /></span></div> },
        { value: 'UndoAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1020) }}><span><FontAwesome.FaUndoAlt /></span></div> },
        { value: 'Undo ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1021) }}><span><FontAwesome.FaUndo /></span></div> },
        { value: 'UniversalAccess ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1022) }}><span><FontAwesome.FaUniversalAccess /></span></div> },
        { value: 'University ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1023) }}><span><FontAwesome.FaUniversity /></span></div> },
        { value: 'Unlink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1024) }}><span><FontAwesome.FaUnlink /></span></div> },
        { value: 'UnlockAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1025) }}><span><FontAwesome.FaUnlockAlt /></span></div> },
        { value: 'Unlock ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1026) }}><span><FontAwesome.FaUnlock /></span></div> },
        { value: 'Upload ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1027) }}><span><FontAwesome.FaUpload /></span></div> },
        { value: 'UserAltSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1028) }}><span><FontAwesome.FaUserAltSlash /></span></div> },
        { value: 'UserAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1029) }}><span><FontAwesome.FaUserAlt /></span></div> },
        { value: 'UserAstronaut ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1030) }}><span><FontAwesome.FaUserAstronaut /></span></div> },
        { value: 'UserCheck ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1031) }}><span><FontAwesome.FaUserCheck /></span></div> },
        { value: 'UserCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1032) }}><span><FontAwesome.FaUserCircle /></span></div> },
        { value: 'UserClock ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1033) }}><span><FontAwesome.FaUserClock /></span></div> },
        { value: 'UserCog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1034) }}><span><FontAwesome.FaUserCog /></span></div> },
        { value: 'UserEdit ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1035) }}><span><FontAwesome.FaUserEdit /></span></div> },
        { value: 'UserFriends ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1036) }}><span><FontAwesome.FaUserFriends /></span></div> },
        { value: 'UserGraduate ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1037) }}><span><FontAwesome.FaUserGraduate /></span></div> },
        { value: 'UserInjured ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1038) }}><span><FontAwesome.FaUserInjured /></span></div> },
        { value: 'UserLock ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1039) }}><span><FontAwesome.FaUserLock /></span></div> },
        { value: 'UserMd ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1040) }}><span><FontAwesome.FaUserMd /></span></div> },
        { value: 'UserMinus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1041) }}><span><FontAwesome.FaUserMinus /></span></div> },
        { value: 'UserNinja ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1042) }}><span><FontAwesome.FaUserNinja /></span></div> },
        { value: 'UserNurse ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1043) }}><span><FontAwesome.FaUserNurse /></span></div> },
        { value: 'UserPlus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1044) }}><span><FontAwesome.FaUserPlus /></span></div> },
        { value: 'UserSecret ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1045) }}><span><FontAwesome.FaUserSecret /></span></div> },
        { value: 'UserShield ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1046) }}><span><FontAwesome.FaUserShield /></span></div> },
        { value: 'UserSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1047) }}><span><FontAwesome.FaUserSlash /></span></div> },
        { value: 'UserTag ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1048) }}><span><FontAwesome.FaUserTag /></span></div> },
        { value: 'UserTie ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1049) }}><span><FontAwesome.FaUserTie /></span></div> },
        { value: 'UserTimes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1050) }}><span><FontAwesome.FaUserTimes /></span></div> },
        { value: 'User ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1051) }}><span><FontAwesome.FaUser /></span></div> },
        { value: 'UsersCog ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1052) }}><span><FontAwesome.FaUsersCog /></span></div> },
        { value: 'UsersSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1053) }}><span><FontAwesome.FaUsersSlash /></span></div> },
        { value: 'Users ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1054) }}><span><FontAwesome.FaUsers /></span></div> },
        { value: 'UtensilSpoon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1055) }}><span><FontAwesome.FaUtensilSpoon /></span></div> },
        { value: 'Utensils ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1056) }}><span><FontAwesome.FaUtensils /></span></div> },
        { value: 'VectorSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1057) }}><span><FontAwesome.FaVectorSquare /></span></div> },
        { value: 'VenusDouble ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1058) }}><span><FontAwesome.FaVenusDouble /></span></div> },
        { value: 'VenusMars ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1059) }}><span><FontAwesome.FaVenusMars /></span></div> },
        { value: 'Venus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1060) }}><span><FontAwesome.FaVenus /></span></div> },
        { value: 'VestPatches ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1061) }}><span><FontAwesome.FaVestPatches /></span></div> },
        { value: 'Vest ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1062) }}><span><FontAwesome.FaVest /></span></div> },
        { value: 'Vial ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1063) }}><span><FontAwesome.FaVial /></span></div> },
        { value: 'Vials ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1064) }}><span><FontAwesome.FaVials /></span></div> },
        { value: 'VideoSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1065) }}><span><FontAwesome.FaVideoSlash /></span></div> },
        { value: 'Video ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1066) }}><span><FontAwesome.FaVideo /></span></div> },
        { value: 'Vihara ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1067) }}><span><FontAwesome.FaVihara /></span></div> },
        { value: 'VirusSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1068) }}><span><FontAwesome.FaVirusSlash /></span></div> },
        { value: 'Virus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1069) }}><span><FontAwesome.FaVirus /></span></div> },
        { value: 'Viruses ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1070) }}><span><FontAwesome.FaViruses /></span></div> },
        { value: 'Voicemail ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1071) }}><span><FontAwesome.FaVoicemail /></span></div> },
        { value: 'VolleyballBall ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1072) }}><span><FontAwesome.FaVolleyballBall /></span></div> },
        { value: 'VolumeDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1073) }}><span><FontAwesome.FaVolumeDown /></span></div> },
        { value: 'VolumeMute ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1074) }}><span><FontAwesome.FaVolumeMute /></span></div> },
        { value: 'VolumeOff ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1075) }}><span><FontAwesome.FaVolumeOff /></span></div> },
        { value: 'VolumeUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1076) }}><span><FontAwesome.FaVolumeUp /></span></div> },
        { value: 'VoteYea ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1077) }}><span><FontAwesome.FaVoteYea /></span></div> },
        { value: 'VrCardboard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1078) }}><span><FontAwesome.FaVrCardboard /></span></div> },
        { value: 'Walking ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1079) }}><span><FontAwesome.FaWalking /></span></div> },
        { value: 'Wallet ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1080) }}><span><FontAwesome.FaWallet /></span></div> },
        { value: 'Warehouse ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1081) }}><span><FontAwesome.FaWarehouse /></span></div> },
        { value: 'Water ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1082) }}><span><FontAwesome.FaWater /></span></div> },
        { value: 'WaveSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1083) }}><span><FontAwesome.FaWaveSquare /></span></div> },
        { value: 'WeightHanging ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1084) }}><span><FontAwesome.FaWeightHanging /></span></div> },
        { value: 'Weight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1085) }}><span><FontAwesome.FaWeight /></span></div> },
        { value: 'Wheelchair ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1086) }}><span><FontAwesome.FaWheelchair /></span></div> },
        { value: 'Wifi ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1087) }}><span><FontAwesome.FaWifi /></span></div> },
        { value: 'Wind ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1088) }}><span><FontAwesome.FaWind /></span></div> },
        { value: 'WindowClose ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1089) }}><span><FontAwesome.FaWindowClose /></span></div> },
        { value: 'WindowMaximize ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1090) }}><span><FontAwesome.FaWindowMaximize /></span></div> },
        { value: 'WindowMinimize ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1091) }}><span><FontAwesome.FaWindowMinimize /></span></div> },
        { value: 'WindowRestore ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1092) }}><span><FontAwesome.FaWindowRestore /></span></div> },
        { value: 'WineBottle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1093) }}><span><FontAwesome.FaWineBottle /></span></div> },
        { value: 'WineGlassAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1094) }}><span><FontAwesome.FaWineGlassAlt /></span></div> },
        { value: 'WineGlass ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1095) }}><span><FontAwesome.FaWineGlass /></span></div> },
        { value: 'WonSign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1096) }}><span><FontAwesome.FaWonSign /></span></div> },
        { value: 'Wrench ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1097) }}><span><FontAwesome.FaWrench /></span></div> },
        { value: 'XRay ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1098) }}><span><FontAwesome.FaXRay /></span></div> },
        { value: 'YenSign ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1099) }}><span><FontAwesome.FaYenSign /></span></div> },
        
        { value: 'YinYang ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1100) }}><span><FontAwesome.FaYinYang /></span></div> },
        { value: 'RegAddressBook ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1101) }}><span><FontAwesome.FaRegAddressBook /></span></div> },
        { value: 'RegAddressCard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1102) }}><span><FontAwesome.FaRegAddressCard /></span></div> },
        { value: 'RegAngry ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1103) }}><span><FontAwesome.FaRegAngry /></span></div> },
        { value: 'RegArrowAltCircleDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1104) }}><span><FontAwesome.FaRegArrowAltCircleDown /></span></div> },
        { value: 'RegArrowAltCircleLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1105) }}><span><FontAwesome.FaRegArrowAltCircleLeft /></span></div> },
        { value: 'RegArrowAltCircleRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1106) }}><span><FontAwesome.FaRegArrowAltCircleRight /></span></div> },
        { value: 'RegArrowAltCircleUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1107) }}><span><FontAwesome.FaRegArrowAltCircleUp /></span></div> },
        { value: 'RegBellSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1108) }}><span><FontAwesome.FaRegBellSlash /></span></div> },
        { value: 'RegBell ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1109) }}><span><FontAwesome.FaRegBell /></span></div> },
        { value: 'RegBookmark ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1110) }}><span><FontAwesome.FaRegBookmark /></span></div> },
        { value: 'RegBuilding ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1111) }}><span><FontAwesome.FaRegBuilding /></span></div> },
        { value: 'RegCalendarAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1112) }}><span><FontAwesome.FaRegCalendarAlt /></span></div> },
        { value: 'RegCalendarCheck ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1113) }}><span><FontAwesome.FaRegCalendarCheck /></span></div> },
        { value: 'RegCalendarMinus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1114) }}><span><FontAwesome.FaRegCalendarMinus /></span></div> },
        { value: 'RegCalendarPlus ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1115) }}><span><FontAwesome.FaRegCalendarPlus /></span></div> },
        { value: 'RegCalendarTimes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1116) }}><span><FontAwesome.FaRegCalendarTimes /></span></div> },
        { value: 'RegCalendar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1117) }}><span><FontAwesome.FaRegCalendar /></span></div> },
        { value: 'RegCaretSquareDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1118) }}><span><FontAwesome.FaRegCaretSquareDown /></span></div> },
        { value: 'RegCaretSquareLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1119) }}><span><FontAwesome.FaRegCaretSquareLeft /></span></div> },
        { value: 'RegCaretSquareRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1120) }}><span><FontAwesome.FaRegCaretSquareRight /></span></div> },
        { value: 'RegCaretSquareUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1121) }}><span><FontAwesome.FaRegCaretSquareUp /></span></div> },
        { value: 'RegChartBar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1122) }}><span><FontAwesome.FaRegChartBar /></span></div> },
        { value: 'RegCheckCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1123) }}><span><FontAwesome.FaRegCheckCircle /></span></div> },
        { value: 'RegCheckSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1124) }}><span><FontAwesome.FaRegCheckSquare /></span></div> },
        { value: 'RegCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1125) }}><span><FontAwesome.FaRegCircle /></span></div> },
        { value: 'RegClipboard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1126) }}><span><FontAwesome.FaRegClipboard /></span></div> },
        { value: 'RegClock ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1127) }}><span><FontAwesome.FaRegClock /></span></div> },
        { value: 'RegClone ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1128) }}><span><FontAwesome.FaRegClone /></span></div> },
        { value: 'RegClosedCaptioning ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1129) }}><span><FontAwesome.FaRegClosedCaptioning /></span></div> },
        { value: 'RegCommentAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1130) }}><span><FontAwesome.FaRegCommentAlt /></span></div> },
        { value: 'RegCommentDots ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1131) }}><span><FontAwesome.FaRegCommentDots /></span></div> },
        { value: 'RegComment ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1132) }}><span><FontAwesome.FaRegComment /></span></div> },
        { value: 'RegComments ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1133) }}><span><FontAwesome.FaRegComments /></span></div> },
        { value: 'RegCompass ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1134) }}><span><FontAwesome.FaRegCompass /></span></div> },
        { value: 'RegCopy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1135) }}><span><FontAwesome.FaRegCopy /></span></div> },
        { value: 'RegCopyright ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1136) }}><span><FontAwesome.FaRegCopyright /></span></div> },
        { value: 'RegCreditCard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1137) }}><span><FontAwesome.FaRegCreditCard /></span></div> },
        { value: 'RegDizzy ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1138) }}><span><FontAwesome.FaRegDizzy /></span></div> },
        { value: 'RegDotCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1139) }}><span><FontAwesome.FaRegDotCircle /></span></div> },
        { value: 'RegEdit ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1140) }}><span><FontAwesome.FaRegEdit /></span></div> },
        { value: 'RegEnvelopeOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1141) }}><span><FontAwesome.FaRegEnvelopeOpen /></span></div> },
        { value: 'RegEnvelope ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1142) }}><span><FontAwesome.FaRegEnvelope /></span></div> },
        { value: 'RegEyeSlash ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1143) }}><span><FontAwesome.FaRegEyeSlash /></span></div> },
        { value: 'RegEye ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1144) }}><span><FontAwesome.FaRegEye /></span></div> },
        { value: 'RegFileAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1145) }}><span><FontAwesome.FaRegFileAlt /></span></div> },
        { value: 'RegFileArchive ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1146) }}><span><FontAwesome.FaRegFileArchive /></span></div> },
        { value: 'RegFileAudio ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1147) }}><span><FontAwesome.FaRegFileAudio /></span></div> },
        { value: 'RegFileCode ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1148) }}><span><FontAwesome.FaRegFileCode /></span></div> },
        { value: 'RegFileExcel ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1149) }}><span><FontAwesome.FaRegFileExcel /></span></div> },
        { value: 'RegFileImage ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1150) }}><span><FontAwesome.FaRegFileImage /></span></div> },
        { value: 'RegFilePdf ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1151) }}><span><FontAwesome.FaRegFilePdf /></span></div> },
        { value: 'RegFilePowerpoint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1152) }}><span><FontAwesome.FaRegFilePowerpoint /></span></div> },
        { value: 'RegFileVideo ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1153) }}><span><FontAwesome.FaRegFileVideo /></span></div> },
        { value: 'RegFileWord ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1154) }}><span><FontAwesome.FaRegFileWord /></span></div> },
        { value: 'RegFile ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1155) }}><span><FontAwesome.FaRegFile /></span></div> },
        { value: 'RegFlag ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1156) }}><span><FontAwesome.FaRegFlag /></span></div> },
        { value: 'RegFlushed ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1157) }}><span><FontAwesome.FaRegFlushed /></span></div> },
        { value: 'RegFolderOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1158) }}><span><FontAwesome.FaRegFolderOpen /></span></div> },
        { value: 'RegFolder ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1159) }}><span><FontAwesome.FaRegFolder /></span></div> },
        { value: 'RegFontAwesomeLogoFull ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1160) }}><span><FontAwesome.FaRegFontAwesomeLogoFull /></span></div> },
        { value: 'RegFrownOpen ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1161) }}><span><FontAwesome.FaRegFrownOpen /></span></div> },
        { value: 'RegFrown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1162) }}><span><FontAwesome.FaRegFrown /></span></div> },
        { value: 'RegFutbol ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1163) }}><span><FontAwesome.FaRegFutbol /></span></div> },
        { value: 'RegGem ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1164) }}><span><FontAwesome.FaRegGem /></span></div> },
        { value: 'RegGrimace ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1165) }}><span><FontAwesome.FaRegGrimace /></span></div> },
        { value: 'RegGrinAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1166) }}><span><FontAwesome.FaRegGrinAlt /></span></div> },
        { value: 'RegGrinBeamSweat ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1167) }}><span><FontAwesome.FaRegGrinBeamSweat /></span></div> },
        { value: 'RegGrinBeam ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1168) }}><span><FontAwesome.FaRegGrinBeam /></span></div> },
        { value: 'RegGrinHearts ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1169) }}><span><FontAwesome.FaRegGrinHearts /></span></div> },
        { value: 'RegGrinSquintTears ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1170) }}><span><FontAwesome.FaRegGrinSquintTears /></span></div> },
        { value: 'RegGrinSquint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1171) }}><span><FontAwesome.FaRegGrinSquint /></span></div> },
        { value: 'RegGrinStars ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1172) }}><span><FontAwesome.FaRegGrinStars /></span></div> },
        { value: 'RegGrinTears ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1173) }}><span><FontAwesome.FaRegGrinTears /></span></div> },
        { value: 'RegGrinTongueSquint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1174) }}><span><FontAwesome.FaRegGrinTongueSquint /></span></div> },
        { value: 'RegGrinTongueWink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1175) }}><span><FontAwesome.FaRegGrinTongueWink /></span></div> },
        { value: 'RegGrinTongue ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1176) }}><span><FontAwesome.FaRegGrinTongue /></span></div> },
        { value: 'RegGrinWink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1177) }}><span><FontAwesome.FaRegGrinWink /></span></div> },
        { value: 'RegGrin ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1178) }}><span><FontAwesome.FaRegGrin /></span></div> },
        { value: 'RegHandLizard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1179) }}><span><FontAwesome.FaRegHandLizard /></span></div> },
        { value: 'RegHandPaper ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1180) }}><span><FontAwesome.FaRegHandPaper /></span></div> },
        { value: 'RegHandPeace ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1181) }}><span><FontAwesome.FaRegHandPeace /></span></div> },
        { value: 'RegHandPointDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1182) }}><span><FontAwesome.FaRegHandPointDown /></span></div> },
        { value: 'RegHandPointLeft ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1183) }}><span><FontAwesome.FaRegHandPointLeft /></span></div> },
        { value: 'RegHandPointRight ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1184) }}><span><FontAwesome.FaRegHandPointRight /></span></div> },
        { value: 'RegHandPointUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1185) }}><span><FontAwesome.FaRegHandPointUp /></span></div> },
        { value: 'RegHandPointer ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1186) }}><span><FontAwesome.FaRegHandPointer /></span></div> },
        { value: 'RegHandRock ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1187) }}><span><FontAwesome.FaRegHandRock /></span></div> },
        { value: 'RegHandScissors ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1188) }}><span><FontAwesome.FaRegHandScissors /></span></div> },
        { value: 'RegHandSpock ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1189) }}><span><FontAwesome.FaRegHandSpock /></span></div> },
        { value: 'RegHandshake ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1190) }}><span><FontAwesome.FaRegHandshake /></span></div> },
        { value: 'RegHdd ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1191) }}><span><FontAwesome.FaRegHdd /></span></div> },
        { value: 'RegHeart ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1192) }}><span><FontAwesome.FaRegHeart /></span></div> },
        { value: 'RegHospital ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1193) }}><span><FontAwesome.FaRegHospital /></span></div> },
        { value: 'RegHourglass ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1194) }}><span><FontAwesome.FaRegHourglass /></span></div> },
        { value: 'RegIdBadge ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1195) }}><span><FontAwesome.FaRegIdBadge /></span></div> },
        { value: 'RegIdCard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1196) }}><span><FontAwesome.FaRegIdCard /></span></div> },
        { value: 'RegImage ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1197) }}><span><FontAwesome.FaRegImage /></span></div> },
        { value: 'RegImages ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1198) }}><span><FontAwesome.FaRegImages /></span></div> },
        { value: 'RegKeyboard ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1199) }}><span><FontAwesome.FaRegKeyboard /></span></div> },
        
        { value: 'RegKissBeam ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1200) }}><span><FontAwesome.FaRegKissBeam /></span></div> },
        { value: 'RegKissWinkHeart ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1201) }}><span><FontAwesome.FaRegKissWinkHeart /></span></div> },
        { value: 'RegKiss ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1202) }}><span><FontAwesome.FaRegKiss /></span></div> },
        { value: 'RegLaughBeam ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1203) }}><span><FontAwesome.FaRegLaughBeam /></span></div> },
        { value: 'RegLaughSquint ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1204) }}><span><FontAwesome.FaRegLaughSquint /></span></div> },
        { value: 'RegLaughWink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1205) }}><span><FontAwesome.FaRegLaughWink /></span></div> },
        { value: 'RegLaugh ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1206) }}><span><FontAwesome.FaRegLaugh /></span></div> },
        { value: 'RegLemon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1207) }}><span><FontAwesome.FaRegLemon /></span></div> },
        { value: 'RegLifeRing ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1208) }}><span><FontAwesome.FaRegLifeRing /></span></div> },
        { value: 'RegLightbulb ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1209) }}><span><FontAwesome.FaRegLightbulb /></span></div> },
        { value: 'RegListAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1210) }}><span><FontAwesome.FaRegListAlt /></span></div> },
        { value: 'RegMap ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1211) }}><span><FontAwesome.FaRegMap /></span></div> },
        { value: 'RegMehBlank ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1212) }}><span><FontAwesome.FaRegMehBlank /></span></div> },
        { value: 'RegMehRollingEyes ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1213) }}><span><FontAwesome.FaRegMehRollingEyes /></span></div> },
        { value: 'RegMeh ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1214) }}><span><FontAwesome.FaRegMeh /></span></div> },
        { value: 'RegMinusSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1215) }}><span><FontAwesome.FaRegMinusSquare /></span></div> },
        { value: 'RegMoneyBillAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1216) }}><span><FontAwesome.FaRegMoneyBillAlt /></span></div> },
        { value: 'RegMoon ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1217) }}><span><FontAwesome.FaRegMoon /></span></div> },
        { value: 'RegNewspaper ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1218) }}><span><FontAwesome.FaRegNewspaper /></span></div> },
        { value: 'RegObjectGroup ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1219) }}><span><FontAwesome.FaRegObjectGroup /></span></div> },
        { value: 'RegObjectUngroup ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1220) }}><span><FontAwesome.FaRegObjectUngroup /></span></div> },
        { value: 'RegPaperPlane ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1221) }}><span><FontAwesome.FaRegPaperPlane /></span></div> },
        { value: 'RegPauseCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1222) }}><span><FontAwesome.FaRegPauseCircle /></span></div> },
        { value: 'RegPlayCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1223) }}><span><FontAwesome.FaRegPlayCircle /></span></div> },
        { value: 'RegPlusSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1224) }}><span><FontAwesome.FaRegPlusSquare /></span></div> },
        { value: 'RegQuestionCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1225) }}><span><FontAwesome.FaRegQuestionCircle /></span></div> },
        { value: 'RegRegistered ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1226) }}><span><FontAwesome.FaRegRegistered /></span></div> },
        { value: 'RegSadCry ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1227) }}><span><FontAwesome.FaRegSadCry /></span></div> },
        { value: 'RegSadTear ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1228) }}><span><FontAwesome.FaRegSadTear /></span></div> },
        { value: 'RegSave ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1229) }}><span><FontAwesome.FaRegSave /></span></div> },
        { value: 'RegShareSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1230) }}><span><FontAwesome.FaRegShareSquare /></span></div> },
        { value: 'RegSmileBeam ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1231) }}><span><FontAwesome.FaRegSmileBeam /></span></div> },
        { value: 'RegSmileWink ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1232) }}><span><FontAwesome.FaRegSmileWink /></span></div> },
        { value: 'RegSmile ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1233) }}><span><FontAwesome.FaRegSmile /></span></div> },
        { value: 'RegSnowflake ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1234) }}><span><FontAwesome.FaRegSnowflake /></span></div> },
        { value: 'RegSquare ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1235) }}><span><FontAwesome.FaRegSquare /></span></div> },
        { value: 'RegStarHalf ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1236) }}><span><FontAwesome.FaRegStarHalf /></span></div> },
        { value: 'RegStar ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1237) }}><span><FontAwesome.FaRegStar /></span></div> },
        { value: 'RegStickyNote ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1238) }}><span><FontAwesome.FaRegStickyNote /></span></div> },
        { value: 'RegStopCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1239) }}><span><FontAwesome.FaRegStopCircle /></span></div> },
        { value: 'RegSun ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1240) }}><span><FontAwesome.FaRegSun /></span></div> },
        { value: 'RegSurprise ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1241) }}><span><FontAwesome.FaRegSurprise /></span></div> },
        { value: 'RegThumbsDown ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1242) }}><span><FontAwesome.FaRegThumbsDown /></span></div> },
        { value: 'RegThumbsUp ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1243) }}><span><FontAwesome.FaRegThumbsUp /></span></div> },
        { value: 'RegTimesCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1244) }}><span><FontAwesome.FaRegTimesCircle /></span></div> },
        { value: 'RegTired ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1245) }}><span><FontAwesome.FaRegTired /></span></div> },
        { value: 'RegTrashAlt ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1246) }}><span><FontAwesome.FaRegTrashAlt /></span></div> },
        { value: 'RegUserCircle ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1247) }}><span><FontAwesome.FaRegUserCircle /></span></div> },
        { value: 'RegUser ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1248) }}><span><FontAwesome.FaRegUser /></span></div> },
        { value: 'RegWindowClose ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1249) }}><span><FontAwesome.FaRegWindowClose /></span></div> },
        { value: 'RegWindowMaximize ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1250) }}><span><FontAwesome.FaRegWindowMaximize /></span></div> },
        { value: 'RegWindowMinimize ', label: <div className='icons iconPicker' Style='cursor:pointer' onClick={(e) => { addIcon(e.target.outerHTML, 1251) }}><span><FontAwesome.FaRegWindowMinimize /></span></div> },
    ]
    return (
        <div>
             <Select options={options} defaultValue={options[elementCss.selectIconValue]} classNamePrefix="react-select" />
        </div>
    )
}

export default IconAdd