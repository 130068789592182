import React, { useState } from 'react'
import { SketchPicker, CirclePicker, SwatchesPicker } from 'react-color'

function ShapeColor({spaceCss,setSpaceCss, setShapeColor, setEnableShapeColor}) {

    const [enableCustomColor, setEnableCustomColor] = useState(false)
    const [enableGradientColor, setEnableGradientColor] = useState(false)
    function addcustomcolor() {
        setEnableGradientColor(false)
        setEnableCustomColor(!enableCustomColor)
    }
    function addgradientcolor() {
        setEnableCustomColor(false)
        setEnableGradientColor(!enableGradientColor)
    }
    function handleChange(currentcolor) {
        if (currentcolor === 'rgb(217, 217, 217)' || currentcolor === 'rgba(255, 255, 255, 0)' ) {
            setShapeColor(currentcolor)
            var data = spaceCss
            data.shapecolor = currentcolor
            setSpaceCss(data)
        }
        else {
            var colordata= `rgba(${currentcolor.rgb.r}, ${currentcolor.rgb.g}, ${currentcolor.rgb.b})`
            setShapeColor(colordata)
            var sectionCssData = spaceCss
            sectionCssData.shapecolor = colordata
            setSpaceCss(sectionCssData)

        }
        setTimeout(() => {
            if (enableCustomColor === false) {
                setEnableShapeColor(false)
            }
        }, 10);
    }
    return (
        <div Style='margin:auto;padding:20px'>
            <div Style='margin-bottom:10px'>
                <CirclePicker onChangeComplete={handleChange} />
                <div className='d-flex flex-wrap justify-content-center'>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { addcustomcolor() }}>Custom</button>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { addgradientcolor() }}>Gradient</button>
                    <button className='btn btn-primary m-2' Style='font-size: 14px;' onClick={() => { handleChange('rgba(255, 255, 255, 0)') }}>Transparent</button>
                </div>
            </div>
            {enableCustomColor ? <SketchPicker onChangeComplete={handleChange} /> : ''}
            {enableGradientColor ? <SwatchesPicker onChangeComplete={handleChange} /> : ''}
        </div>
    )
}

export default ShapeColor