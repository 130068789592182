import React,{useContext} from 'react'
import { MdOutlineReviews } from "react-icons/md";
import { FaCrown } from "react-icons/fa";
import { HomeData } from '../../../../NavigationComponents/Home';

function TestimonialElement({setSidebarOpen, addSectionIndex, sections, setSections, setRowchange, addRowIndex, addColumnIndex,elementIdCount, setElementIdCount,setElementType,undoComponent, setUndoComponent, }) {
    const {pro, proElement}=useContext(HomeData)
    function addElementInColumn() {
        var sectionData=sections
        var id=sectionData[addSectionIndex-1].RowComponent[addRowIndex].RowContainer[addColumnIndex].id
        sectionData[addSectionIndex-1].RowComponent[addRowIndex].RowContainer[addColumnIndex].ColumnContainer.push({ "id": id + "elem" + elementIdCount, "name": "testimonial", "elementName": "Testimonial", "value": "", "elementStyle": "height:120px; width:100%; position:relative", "elementTextStyle": 'text-align: left; opacity: 1; text-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;', "imageFeatureCssObj": { "headerMobileFontSize": "30", "headerDesktopFontSize": "30", "textMobileFontSize": "16", "textDesktopFontSize": "16", "imgFeatureHeaderColor": '', "imgFeatureTextColor": '' }, "date": "2024-1-5", "time": "12.00" },)
        setSections(sectionData)
        var componentData = undoComponent
        componentData.push({"arrayData" :JSON.parse(JSON.stringify(sectionData)), "id": id + "elem" + elementIdCount})
        setUndoComponent(componentData)
        setElementIdCount(elementIdCount+1)
        setElementType('testimonial')
        setRowchange(sectionData[addSectionIndex-1].RowComponent[addRowIndex].RowContainer[addColumnIndex].ColumnContainer)
        setSidebarOpen(false)
    }
    function openUpgrade(){
        document.getElementById("upgradeModalBtn").click()
    }

    return (
        <button class="custom-btn elementBtn sectionBox" onClick={ !pro && proElement.includes("testimonial")?openUpgrade:addElementInColumn}>
            <span>Testimonial</span>
            <span Style='font-size: 35px;align-items: center;display: flex; justify-content:center'>
                <MdOutlineReviews />
                {!pro && proElement.includes("testimonial")?
                <div className='proIcon'>
                    <FaCrown/>
                </div>:''}
            </span>
        </button>
    )
}

export default TestimonialElement