import React, { useEffect, useState } from 'react'

function Link({ size,
  id,
  addCustomStyle,
  element,
  activeElement,
  preview,
  mobileView,
  setElementType }
) {
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('link')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])

  useEffect(() => {
    document.getElementById(`link_${id}responsiveStyle`).innerHTML = `<style>@media only screen and (max-width: 700px) { #${id}{${element.elementObject ? `font-size:${element.elementObject.mobileFontSize} !important` : ''}} }</style>`
  }, [preview])

  return (
    <>
    <div id={`link_${id}responsiveStyle`} Style='display:none'>
        <style>

        </style>
      </div>
      <div Style={element.elementObject? mobileView ? `text-align: none; opacity: ${element.elementObject.elementOpacity}; text-shadow: ${element.elementObject.elementTextShadow};font-size:${element.elementObject.mobileFontSize}px; text-align:${element.elementObject.textAlign};font-family:${element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}`:`text-align: none; opacity: ${element.elementObject.elementOpacity}; text-shadow: ${element.elementObject.elementTextShadow};font-size:${element.elementObject.desktopFontSize}px; text-align:${element.elementObject.textAlign}; font-family:${element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}`:''} className={` global${element.elementObject ? element.elementObject.fonttype : ''}Font `} id={id} onClick={handleEditorClick}>
        <a Style={`text-decoration:none;color:${element.elementObject ? element.elementObject.textColor : 'black'};`} href={element.elementObject ? element.elementObject.link : ''} target={(element.elementObject && element.elementObject.gotonewpage) ? "_blank" : ''}>{element.elementObject ? element.elementObject.linktext : ''}</a>
      </div>
    </>
  )
}

export default Link