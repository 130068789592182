import React, { useContext, useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { HomeData } from '../../../../NavigationComponents/Home';
function Testimonial({ size,
    id,
    addCustomStyle,
    element,
    activeElement,
    setElementType,

}) {
    
    const {saving} = useContext(HomeData)

    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
        e.stopPropagation();
        setElementType('testimonial')
        if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
        }
    }

    useEffect(() => {
        addCustomStyle(`elem_${id}`);
    }, [])
    var i = 0;
    return (
        <>
            {!saving ?<OwlCarousel className='owl-theme' loop margin={10} onClick={handleEditorClick}>
                {element.elementObject ? element.elementObject.testimonialdata.map((elem) => {
                    return <div class='item'>
                        <div Style="width: 356px; height:260px;" class="item" id="testimonialItem2aa77">
                            <div class="card pl-4 pr-4 pt-4">
                                <p class="descLabel ignore">{elem.desc}</p>
                                <div class="d-flex">
                                    <div class="userimg" Style={`border-radius: 100%;overflow: hidden;width: 50px;height: 50px;background-size: cover;background-position: center center;background-image: url(${elem.avatar});`}></div>
                                    <div class="d-flex ml-3" Style="flex-direction: column;">
                                        <p class="m-0 nameLabel ignore">{elem.name}</p>
                                        <div numofstars="5" class="stars mt-2" Style="display:flex;align-items: center;width:100%;height:10px;">
                                            {elem.stars.split("").map((elem, index) => {
                                                return <span Style="font-size: 13px;color: #ffd700;"><i class="fas fa-star"></i></span>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }) : ''}
            </OwlCarousel>:<div class="owl-carousel owl-theme">
            {element.elementObject ? element.elementObject.testimonialdata.map((elem) => {
                    return <div class='item'>
                        <div Style="width: 356px; height:260px;" class="item" id="testimonialItem2aa77">
                            <div class="card pl-4 pr-4 pt-4">
                                <p class="descLabel ignore">{elem.desc}</p>
                                <div class="d-flex">
                                    <div class="userimg" Style={`border-radius: 100%;overflow: hidden;width: 50px;height: 50px;background-size: cover;background-position: center center;background-image: url(${elem.avatar});`}></div>
                                    <div class="d-flex ml-3" Style="flex-direction: column;">
                                        <p class="m-0 nameLabel ignore">{elem.name}</p>
                                        <div numofstars="5" class="stars mt-2" Style="display:flex;align-items: center;width:100%;height:10px;">
                                            {elem.stars.split("").map((elem, index) => {
                                                return <span Style="font-size: 13px;color: #ffd700;"><i class="fas fa-star"></i></span>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }) : ''}
            </div>}
        </>
    )
}

export default Testimonial