
import React, { useState, useMemo, useEffect } from 'react'
import { Section } from '../../Section'
import { Container } from '../../../Container'


function Tabs({ element,
  index,
  size,
  id,
  columnId,
  moveCardElement,
  columnHandlerId,
  addCustomStyle,
  sections,
  setSections,
  columnIndex,
  rowIndex,
  sectionIndex,
  activeElement,
  prevElement,
  section,
  row,
  column,
  setRowchange,
  rowchange,
  elementIdCount,
  setElementIdCount,
  preview,
  setElementType,
  elementType,
  openElementbar,
  setEnableColumnResize,
  enableForm,
  setEnableForm,
  rowId,
  sectionIdCount,
  setSectionIdCount,
  setAddSectionIndex,
  setSidebarOpen,


  enableEditSection,
  setEnableEditSection,
  cssAddingBarByClick,
  setSideNav,
  setRowId,
  setEnabledrop,
  enableDrop,
  enableRowSection,
  setEnableRowSection,
  setEnableColumnBar,
  enableColumnBar,
  elementEditBar,
  setElementEditBa,
  setAddRowIndex,
  setAddColumnIndex,
  setColumnIdTrack,
  columnIdTrack,
  sidebarOpen,
  openFunnelData,
  setElementEditBar,
  rowStateId,
  setRowStateId,

  tabsSection,
  enableTabs,
  setEnableTabs,
  setTabSection, }) {


  const [tabjson, setTabjson] = useState(element.tabComponent)
  const [sectionComponent, setSectionComponent] = useState()
  useEffect(() => {
    setTabjson(tabsSection)
  }, [tabsSection])

  const moveCard = () => {

  }
  useEffect(() => {
    setSectionComponent(<Container
      containType={'tabContainer'}
      setSections={setTabjson}
      sections={tabjson}
      setRowchange={setRowchange}
      rowchange={rowchange}
      sectionIdCount={sectionIdCount}
      setSectionIdCount={setSectionIdCount}
      setAddSectionIndex={setAddSectionIndex}
      setSidebarOpen={setSidebarOpen}
      enableEditSection={enableEditSection}
      setEnableEditSection={setEnableEditSection}
      cssAddingBarByClick={cssAddingBarByClick}
      setSideNav={setSideNav}
      setRowId={setRowStateId}
      rowId={rowStateId}
      setEnabledrop={setEnabledrop}
      enableDrop={enableDrop}
      enableRowSection={enableRowSection}
      setEnableRowSection={setEnableRowSection}
      setEnableColumnBar={setEnableColumnBar}
      enableColumnBar={enableColumnBar}
      elementIdCount={elementIdCount}
      setElementIdCount={setElementIdCount}
      elementEditBar={elementEditBar}
      setElementEditBar={setElementEditBar}
      setAddRowIndex={setAddRowIndex}
      setAddColumnIndex={setAddColumnIndex}
      setColumnIdTrack={setColumnIdTrack}
      columnIdTrack={columnIdTrack}
      preview={preview}
      setElementType={setElementType}
      elementType={elementType}
      sidebarOpen={sidebarOpen}
      openFunnelData={openFunnelData}

      tabsSection={tabsSection}
      enableTabs={enableTabs}
      setEnableTabs={setEnableTabs}
      setTabSection={setTabSection}
    />)
  }, [])

  return (
    <div>
      <nav>
        <div class="nav nav-tabs globalHeaderFont" id="custom-nav-tabs" role="tablist" Style="justify-content: start; height: 53px">
          <button Style="border:none; outline: none; background:none; color:#4a5568; padding-right: 20px; padding-left: 0px;" class="customBtnTab show" data-toggle="tab" href="#nav-ea5a2" role="tab" aria-selected="false">First tab</button>
          <button Style="border:none; outline: none; background:none; color:#4a5568; padding: 0px 20px;" class="customBtnTab active" data-toggle="tab" href="#nav-dad67" role="tab" aria-selected="true">Second Tab</button>
        </div>
      </nav>
      <section>
        {sectionComponent}
      </section>
    </div>
  )
}

export default Tabs