import React, { useState, useEffect } from 'react'
import ColorPicker from '../ColorPicker';
import img from '../../static/images/backgroundfreeimage.png'
import IconAdd from '../IconAdd';
import ElementVideoAdd from './LeftElementComponents/ElementVideoAdd';
import ElementPopupVideoAdd from './LeftElementComponents/ElementPopupVideoAdd';
import TextIconAdd from '../TextIconAdd';
import ElementFontStyle from './ElementFontStyle';
import { Editor } from '@monaco-editor/react';
import { BsFileEarmarkCode } from "react-icons/bs";
import { Slider, TextField, Select, InputLabel, MenuItem, FormControl, Button } from "@mui/material";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TbGridDots } from "react-icons/tb";
import { RiImageAddLine } from "react-icons/ri";

function ElementGeneralBar({ setAttachedImage, attachedImage, setElementCss, elementCss, setAllImg, funnelId, allImg, setElementName, elementName, setColor, color, setRowchange, setTextColor, textColor, elementType, setIconColorColor, setSeparatorColor, iconColor, separatorColor, imgFeatureHeaderColor, imgFeatureTextColor, setImgFeatureHeaderColor, setImgFeatureTextColor, }) {
    const [enableBgColor, setEnableBgColor] = useState(false)
    const [enableBtnBgColor, setEnableBtnBgColor] = useState(false)
    const [enableTextColor, setEnableTextColor] = useState(false)
    const [enableSeparatorColor, setEnableSeparatorTextColor] = useState(false)
    const [enableIconColor, setEnableIconColor] = useState(false)
    const [enableImgFeatureHeaderColor, setEnableImgFeatureHeaderColor] = useState(false)
    const [enableImgFeatureTextColor, setEnableImgFeatureTextColor] = useState(false)
    const [enableFormBgColor, setEnableFormBgColor] = useState(false)
    const [enableFormLabelColor, setEnableFormLabelColor] = useState(false)
    const [enableFormInputColor, setEnableFormInputColor] = useState(false)
    const [enableTabTextColor, setEnableTabTextColor] = useState(false)
    const [enableThemeColor, setEnableThemeColor] = useState(false)
    const [enableCountDownBgColor, setEnableCountDownBgColor] = useState(false)
    const [enableCountDownTextColor, setEnableCountDownTextColor] = useState(false)
    const [imageListData, setImageListData] = useState()
    const [imageListIndex, setImageListIndex] = useState()
    const [slideShowCase, setSlideShowCase] = useState({ "name": "image", "url": "https://via.placeholder.com/400x300.png?text=Put Your Image Here", "height": "", "width": "", "altText": "Image Alt text", "sliderRedirect": "URL", "sliderRedirectURL": "", })
    const [slideShowCaseIndex, setSlideShowCaseIndex] = useState()
    const [openButton, setOpenButton] = useState()
    const [openBtnButton, setOpenBtnButton] = useState()
    const [openTextColButton, setOpenTextColButton] = useState()
    const [iconColorBtn, setIconColorBtn] = useState()
    const [separatorColorBtn, setSeparatorColorBtn] = useState()
    const [imgFeatureTextBtn, setImgFeatureTextBtn] = useState()
    const [imgFeatureHeaderBtn, setImgFeatureHeaderBtn] = useState()
    const [formBgColor, setFormBgColor] = useState()
    const [formLabelColor, setFormLabelColor] = useState()
    const [formInputColor, setFormInputColor] = useState()
    const [openTabTextColor, setOpenTabTextColor] = useState()
    const [openThemeColor, setOpenThemeColor] = useState()
    const [openCountDownBgBtn, setOpenCountDownBgBtn] = useState()
    const [openCountDownTextBtn, setOpenCountDownTextBtn] = useState()

    const [surveyDataIndex, setSurveyDataIndex] = useState(0)
    const [surveyObjData, setSurveyObjData] = useState(elementCss.surveyData ? elementCss.surveyData[surveyDataIndex] : { "tabtitle": '1', "question": "Question Goes Here", "subtext": 'SubText Goes Here', "fieldname": 'button1', "tabtype": 'buttons', "buttonsData": [{ "buttonvalue": 'Option 1', "buttontext": 'button 1' }, { "buttonvalue": 'Option 2', "buttontext": 'button 2' }, { "buttonvalue": 'Option 3', "buttontext": 'button 3' }, { "buttonvalue": 'Option 4', "buttontext": 'button 4' }], "inputData": [] })

    const [openFontStyle, setOpenFontStyle] = useState(false)

    const [testimonialObjData, setTestimonialObjData] = useState(elementCss.testimonialdata[0])
    const [testimonailIndex, setTestimonialIndex] = useState(0)

    const [customHTML, setCustomHTML] = useState()
    const [customTabIndex, setCustomTabIndex] = useState(0)
    const [customTabHTML, setCustomTabHTML] = useState()

    const [mapLat, setMapLat] = useState('49.46800006494457')
    const [mapLon, setMapLon] = useState('17.11514008755796')


    const [alignment, setAlignment] = React.useState('left');

    const [urlTarget, setUrlTarget] = useState()
    const [urlTargetIndex, setUrlTargetIndex] = useState()

    const [selectedImg, setSelectedImg] = useState()

    const [crouselImageIndex, setCrouselImageIndex] = useState()

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
        var elementCssData = elementCss
        elementCssData.textAlign = newAlignment
        setElementCss(elementCssData)
        setRowchange(elementCssData.imageList)
    };

    useEffect(() => {
        var elementCssData = elementCss
        elementCssData.imageList[imageListIndex] = imageListData
        setElementCss(elementCssData)
        setRowchange(elementCssData.imageList)
    }, [imageListData])

    useEffect(() => {
        if (elementCss.elementbackgroundcolor === 'transparent') {
            setOpenButton(`background-image: url(${img})`)
        }
        else {
            setOpenButton(`background-color: ${color} !important`)
        }
    }, [elementCss.elementbackgroundcolor])


    useEffect(() => {
        if (elementCss.textColor === 'transparent' || elementCss.textColor === 'none') {
            setOpenTextColButton(`background-image: url(${img})`)
        }
        else {
            setOpenTextColButton(`background-color: ${textColor} !important`)
        }
    }, [elementCss.textColor])


    useEffect(() => {
        if (elementCss.iconColor === 'transparent') {
            setIconColorBtn(`background-image: url(${img})`)
        }
        else {
            setIconColorBtn(`background-color: ${iconColor} !important`)
        }
    }, [elementCss.iconColor])


    useEffect(() => {
        if (elementCss.separatorColor === 'transparent') {
            setSeparatorColorBtn(`background-image: url(${img})`)
        }
        else {
            setSeparatorColorBtn(`background-color: ${separatorColor} !important`)
        }
    }, [elementCss.separatorColor])


    useEffect(() => {
        if (elementCss.imgFeatureTextColor === 'transparent') {
            setImgFeatureTextBtn(`background-image: url(${img})`)
        }
        else {
            setImgFeatureTextBtn(`background-color: ${imgFeatureTextColor} !important`)
        }
    }, [elementCss.imgFeatureTextColor])


    useEffect(() => {
        if (elementCss.imgFeatureHeaderColor === 'transparent') {
            setImgFeatureHeaderBtn(`background-image: url(${img})`)
        }
        else {
            setImgFeatureHeaderBtn(`background-color: ${imgFeatureHeaderColor} !important`)
        }
    }, [elementCss.imgFeatureHeaderColor])

    useEffect(() => {
        if (elementCss.btnBackgroundColor === 'transparent' || elementCss.btnBackgroundColor === 'none') {
            setOpenBtnButton(`background-image: url(${img})`)
        }
        else {
            setOpenBtnButton(`background-color: ${elementCss.btnBackgroundColor} !important`)
        }
    }, [elementCss.btnBackgroundColor])

    useEffect(() => {
        if (elementCss.formBackgroundcolor === 'transparent' || elementCss.formBackgroundcolor === 'none') {
            setFormBgColor(`background-image: url(${img})`)
        }
        else {
            setFormBgColor(`background-color: ${elementCss.formBackgroundcolor} !important`)
        }
    }, [elementCss.formBackgroundcolor])

    useEffect(() => {
        if (elementCss.formLabelColor === 'transparent' || elementCss.formLabelColor === 'none') {
            setFormLabelColor(`background-image: url(${img})`)
        }
        else {
            setFormLabelColor(`background-color: ${elementCss.formLabelColor} !important`)
        }
    }, [elementCss.formLabelColor])

    useEffect(() => {
        if (elementCss.inputTextColor === 'transparent' || elementCss.inputTextColor === 'none') {
            setFormInputColor(`background-image: url(${img})`)
        }
        else {
            setFormInputColor(`background-color: ${elementCss.inputTextColor} !important`)
        }
    }, [elementCss.inputTextColor])

    useEffect(() => {
        if (elementCss.themecolor === 'transparent') {
            setOpenThemeColor(`background-image: url(${img})`)
        }
        else {
            setOpenThemeColor(`background-color: ${elementCss.themecolor} !important`)
        }
    }, [elementCss.themecolor])

    useEffect(() => {
        if (elementCss.tabtextcolor === 'transparent') {
            setOpenTabTextColor(`background-image: url(${img})`)
        }
        else {
            setOpenTabTextColor(`background-color: ${elementCss.tabtextcolor} !important`)
        }
    }, [elementCss.tabtextcolor])

    useEffect(() => {
        if (elementCss.countdowntextColor === 'transparent') {
            setOpenCountDownTextBtn(`background-image: url(${img})`)
        }
        else {
            setOpenCountDownTextBtn(`background-color: ${elementCss.countdowntextcolor} !important`)
        }
    }, [elementCss.countdowntextcolor])

    useEffect(() => {
        if (elementCss.countdownbgcolor === 'transparent') {
            setOpenCountDownBgBtn(`background-image: url(${img})`)
        }
        else {
            setOpenCountDownBgBtn(`background-color: ${elementCss.countdownbgcolor} !important`)
        }
    }, [elementCss.countdownbgcolor])


    function addListItem() {
        var elementCssData = elementCss
        elementCssData.imageList.push('New List')
        setElementCss(elementCssData)
        setRowchange(elementCss.imageList)
    }

    function editListItem(index) {
        var elementCssData = elementCss
        setImageListData(elementCssData.imageList[index])
        setImageListIndex(index)
        document.getElementById('imageListModal').click()
    }

    function editSlideShowCase(index) {
        setSlideShowCase(elementCss.sliderShowCase[index])
        setSlideShowCaseIndex(index)
        setTimeout(() => {
            document.getElementById('slidershowcaseModal').click()
        }, 100);
    }

    function deleteSlideCase(index) {
        var cssData = elementCss
        cssData.sliderShowCase.splice(index, 1)
        setElementCss(cssData)
        setRowchange(elementCss.sliderShowCase)
    }

    function addSlideCase() {
        var cssData = elementCss
        cssData.sliderShowCase.push({ "name": "image", "url": "https://via.placeholder.com/400x300.png?text=Put Your Image Here", "height": "", "width": "", "altText": "Image Alt text", "sliderRedirect": "URL", "sliderRedirectURL": "", })
        setElementCss(cssData)
        setRowchange(elementCss.sliderShowCase)
    }

    useEffect(() => {
        if (slideShowCaseIndex >= 0) {
            var cssData = elementCss
            cssData.sliderShowCase[slideShowCaseIndex] = slideShowCase
            setElementCss(cssData)
            setRowchange(elementCss.sliderShowCase)
        }
    }, [slideShowCase])


    function deleteListItem(index) {
        var elementCssData = elementCss
        elementCssData.imageList.splice(index, 1)
        setElementCss(elementCssData)
        setRowchange(elementCss.imageList)
        document.getElementById('imageListModal').click()
    }

    useEffect(() => {
        if (document.getElementById('elementOpacity')) {
            document.getElementById('elementOpacity').value = elementCss.elementOpacity
        }
        if (document.getElementById('elementTextShadow')) {
            document.getElementById('elementTextShadow').value = elementCss.elementTextShadow
        }
        if (document.getElementById('elementLetterSpacing')) {
            document.getElementById('elementLetterSpacing').value = elementCss.elementLetterSpacing
        }
        if (document.getElementById('imagelistfontweight')) {
            document.getElementById('imagelistfontweight').value = elementCss.imagelistfontweight
        }
        if (document.getElementById('boxShadow')) {
            document.getElementById('boxShadow').value = elementCss.boxShadow
        }
        if (document.getElementById('AutoplayOptionSwitch')) {
            document.getElementById('AutoplayOptionSwitch').checked = elementCss.autoPlay
        }
        if (document.getElementById('ControlOptionSwitch')) {
            document.getElementById('ControlOptionSwitch').checked = elementCss.controls
        }
        if (document.getElementById('videoWidth')) {
            document.getElementById('videoWidth').value = elementCss.videoWidth
        }
        if (document.getElementById('columnSize')) {
            document.getElementById('columnSize').value = elementCss.columnSize
        }
        if (document.getElementById('columnOrder')) {
            document.getElementById('columnOrder').value = elementCss.columnOrder
        }
        if (document.getElementById('newTabSwitch')) {
            document.getElementById('newTabSwitch').checked = elementCss.gotonewpage
        }
        if (document.getElementById('FacebookSwitch')) {
            document.getElementById('FacebookSwitch').checked = elementCss.enableFacebook
        }
        if (document.getElementById('TwitterSwitch')) {
            document.getElementById('TwitterSwitch').checked = elementCss.enableTwitter
        }
        if (document.getElementById('LinkedinSwitch')) {
            document.getElementById('LinkedinSwitch').checked = elementCss.enableLinkedin
        }
        if (document.getElementById('inputtype')) {
            document.getElementById('inputtype').value = elementCss.inputtype
        }
        if (document.getElementById('showlabel')) {
            document.getElementById('showlabel').checked = elementCss.showlabel
        }
        if (document.getElementById('inputrequired')) {
            document.getElementById('inputrequired').checked = elementCss.inputrequired
        }
        if (document.getElementById('selectLabelType')) {
            document.getElementById('selectLabelType').value = elementCss.selectLabelType
        }
        if (document.getElementById('countdownposition')) {
            document.getElementById('countdownposition').value = elementCss.countdownposition
        }
        if (document.getElementById('linkaction')) {
            document.getElementById('linkaction').value = elementCss.linkaction
        }
        if (document.getElementById('divideralign')) {
            document.getElementById('divideralign').value = elementCss.divideralign
        }
        if (document.getElementById('dividerheight')) {
            document.getElementById('dividerheight').value = elementCss.dividerheight
        }
        if (document.getElementById('dividerlineheight')) {
            document.getElementById('dividerlineheight').value = elementCss.dividerlineheight
        }
        if (document.getElementById('dividerwidth')) {
            document.getElementById('dividerwidth').value = elementCss.dividerwidth
        }
        if (document.getElementById('progresswidth')) {
            document.getElementById('progresswidth').value = elementCss.progresswidth
        }
        if (document.getElementById('progresssize')) {
            document.getElementById('progresssize').value = elementCss.progresssize
        }
        if (document.getElementById('stripeanimation')) {
            document.getElementById('stripeanimation').checked = elementCss.stripeanimation
        }
        if (document.getElementById('mapType')) {
            document.getElementById('mapType').value = elementCss.mapType
        }



        // needed
        if (document.getElementById('sliderRedirect')) {
            document.getElementById('sliderRedirect').value = slideShowCase.sliderRedirect
        }
        if (document.getElementById('testimonialstars')) {
            document.getElementById('testimonialstars').value = testimonialObjData.stars
        }


    }, [])

    function placeHolderChange(data) {
        var cssData = elementCss;
        cssData.inputtype = data
        cssData.inputplaceholdertext = `Enter your ${data} here...`
        setElementCss(cssData)
        setRowchange(data)
    }

    function updateCustomSelectLabel(data, name, index) {
        var cssData = elementCss
        if (name === 'value') {
            cssData.customSelectLabel[index].value = data
        }
        else if (name === 'text') {
            cssData.customSelectLabel[index].text = data
        }
        setElementCss(cssData)
        setRowchange(data)
    }
    function addCustomLabel() {
        var cssData = elementCss
        cssData.customSelectLabel.push({ 'value': '', 'text': '' })
        setElementCss(cssData)
        setRowchange(cssData)
    }

    function deleteCustomLebel(index) {
        var cssData = elementCss
        cssData.customSelectLabel.splice(index, 1)
        setElementCss(cssData)
        setRowchange(cssData)
    }


    function updateServeyData(data, index) {
        setSurveyObjData(data)
        setSurveyDataIndex(index)
        document.getElementById('openServeyModalBtn').click()
    }

    useEffect(() => {
        var cssData = elementCss
        if (cssData.surveyData) {
            cssData.surveyData[surveyDataIndex] = surveyObjData
            setElementCss(cssData)
        }
        else {
            cssData.surveyData = [{ "tabtitle": '1', "question": "Question Goes Here", "subtext": 'SubText Goes Here', "fieldname": 'button1', "tabtype": 'buttons', "buttonsData": [{ "buttonvalue": 'Option 1', "buttontext": 'button 1' }, { "buttonvalue": 'Option 2', "buttontext": 'button 2' }, { "buttonvalue": 'Option 3', "buttontext": 'button 3' }, { "buttonvalue": 'Option 4', "buttontext": 'button 4' }], "inputData": [] },]
            setElementCss(cssData)
        }
        setRowchange(surveyObjData)
    }, [surveyObjData])

    function addNewButton() {
        var cssData = elementCss
        var length = cssData.surveyData[surveyDataIndex].buttonsData.length
        cssData.surveyData[surveyDataIndex].buttonsData.push({ "buttonvalue": `Option ${length + 1}`, "buttontext": `button ${length + 1}` })
        setElementCss(cssData)
        setRowchange(cssData.surveyData[surveyDataIndex].buttonsData)
    }

    function delSurveyButton(index) {
        var cssData = elementCss
        cssData.surveyData[surveyDataIndex].buttonsData.splice(index, 1)
        setElementCss(cssData)
        setRowchange(cssData.surveyData[surveyDataIndex].buttonsData)
    }

    function addServeyData() {
        var cssData = elementCss
        cssData.surveyData.push({ "tabtitle": `${cssData.surveyData.length + 1}`, "question": "Question Goes Here", "subtext": 'SubText Goes Here', "fieldname": 'button1', "tabtype": 'buttons', "buttonsData": [{ "buttonvalue": 'Option 1', "buttontext": 'button 1' }, { "buttonvalue": 'Option 2', "buttontext": 'button 2' }, { "buttonvalue": 'Option 3', "buttontext": 'button 3' }, { "buttonvalue": 'Option 4', "buttontext": 'button 4' }], "inputData": [] })
        setElementCss(cssData)
        setRowchange(cssData.surveyData)
    }

    function delServeyData(index) {
        var cssData = elementCss
        cssData.surveyData.splice(index, 1)
        setElementCss(cssData)
        setRowchange(cssData.surveyData)
    }

    function addNewInput() {
        var cssData = elementCss
        var length = cssData.surveyData[surveyDataIndex].inputData.length
        cssData.surveyData[surveyDataIndex].inputData.push({ "inputlabel": `New Input${length + 1}`, "inputrequired": 'true', "inputfield": "new_input" })
        setElementCss(cssData)
        setRowchange(cssData.surveyData[surveyDataIndex].inputData)
    }


    function testimonialEdit(index) {
        var cssData = elementCss
        setTestimonialObjData(cssData.testimonialdata[index])
        setTestimonialIndex(index)
        document.getElementById('testimonialModalBtn').click()
    }

    function saveTestimonialCard() {
        var cssData = elementCss
        cssData.testimonialdata[testimonailIndex] = testimonialObjData
        setElementCss(cssData)
        setRowchange(cssData.testimonialdata)
        document.getElementById('testimonialModalBtn').click()
    }

    function addTestimonialCard() {
        var cssData = elementCss
        var indexData = cssData.testimonialdata.length
        cssData.testimonialdata.push({ "name": `Name${indexData + 1}`, "desc": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo", "avatar": 'https://i.imgur.com/0slNT2o.jpg', "stars": "5" })
        setElementCss(cssData)
        setRowchange(cssData.testimonialdata)
    }

    function deleteTestimonialCard(index) {
        var cssData = elementCss
        cssData.testimonialdata.splice(index, 1)
        setElementCss(cssData)
        setRowchange(cssData.testimonialdata)
    }

    function handleEditorChange(value) {
        setCustomHTML(value)
    }

    function saveHTML() {
        var elemCss = elementCss
        elemCss.customHtml = customHTML
        setElementCss(elemCss)
        setRowchange(customHTML)
        document.getElementById('openCustomEditor').click()
    }

    useEffect(() => {
        var elemCss = elementCss
        elemCss.lonlatURL = `https://maps.google.com/maps?q=${mapLat},${mapLon}&hl=es&z=14&amp;output=embed`
        setElementCss(elemCss)
        setRowchange(elemCss)
    }, [mapLat, mapLon])


    function handleTabEditorChange(value) {
        setCustomTabHTML(value)
    }

    function saveTabHTML() {
        var elemCss = elementCss
        elemCss.customTabs[customTabIndex].tabHTML = customTabHTML
        setElementCss(elemCss)
        setRowchange(customTabHTML)
        document.getElementById('openTabEditorBtn').click()
    }

    function openTabEditor(index) {
        var elemCss = elementCss
        var tabData = elemCss.customTabs[index].tabHTML
        setCustomTabHTML(tabData)
        setCustomTabIndex(index)
        setRowchange(index)
        setTimeout(() => {
            document.getElementById('openTabEditorBtn').click()
        }, 1000);
    }


    function deleteTabEditor(index) {
        var elemCss = elementCss
        elemCss.customTabs.splice(index, 1)
        setElementCss(elemCss)
        setRowchange(elemCss.customTabs)
    }

    function addNewTab() {
        var elemCss = elementCss
        elemCss.customTabs.push({ "tabName": 'New Tab', "tabHTML": '' })
        setElementCss(elemCss)
        setRowchange(elemCss.customTabs)
    }

    function carouselImgURL(value, index) {
        var copyCss = elementCss
        copyCss.carouselImages[index] = value
        setElementCss(copyCss)
        setRowchange(copyCss.carouselImages)
    }

    function deleteCarouselImgURL(index) {
        var copyCss = elementCss
        copyCss.carouselImages.splice(index, 1)
        setElementCss(copyCss)
        setRowchange(copyCss.carouselImages)
    }

    function addCarouselImgURL() {
        var copyCss = elementCss
        copyCss.carouselImages.push("https://via.placeholder.com/400x300.png?text=Put")
        setElementCss(copyCss)
        setRowchange(copyCss.carouselImages)
    }



    function saveFileData(imgdata) {
        document.getElementById('elementModalContentFileMan').classList.add('modalBodyFileMan')
        document.getElementById('elementShowLoad').classList.remove('d-none')
        var reader = new FileReader();
        reader.readAsDataURL(imgdata);
        reader.onload = function () {
            uploadImg(reader.result, 2289).then((data) => {
                fetchImg().then((imagedata) => {
                    setAllImg(imagedata.image_path)
                    setRowchange(imagedata.image_path)
                    document.getElementById('elementModalContentFileMan').classList.remove('modalBodyFileMan')
                    document.getElementById('elementShowLoad').classList.add('d-none')
                })
            })
        };
        reader.onerror = function (error) {
            alert('Error, Please Enter valid data');
        };
    }

    async function uploadImg(data, user_id) {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/php/addImages.php`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                funnel_id: funnelId,
                imagedata: data,
            })
        })
        return response.json()
    }

    async function fetchImg() {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/php/viewImages.php`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                funnel_id: funnelId
            })
        })
        return response.json()
    }

    function addImgURL(urldata) {
        var sectionCss = elementCss
        if (urlTarget === 'imageURL') {
            sectionCss.imageURL = urldata
        }
        else if (urlTarget === 'imagepopupURL') {
            sectionCss.imagepopupURL = urldata
        }
        else if (urlTarget === 'crouselimage') {
            sectionCss.carouselImages[crouselImageIndex] = urldata
        }
        else if (urlTarget === 'sideShowCaseImageURL') {
            sectionCss.sliderShowCase[slideShowCaseIndex].url = urldata
        }
        else if (urlTarget === 'avatarURL') {
            sectionCss.testimonialdata[testimonailIndex].avatar = urldata
        }
        setElementCss(sectionCss)
        setRowchange(sectionCss.urlTarget)
        document.getElementById('elementFileManagerClose').click()
    }

    function addSelected() {
        if(selectedImg){
            var imageData = attachedImage
            if (imageData.length === 0) {
                imageData.push(selectedImg)
            }
            else {
                if (!imageData.includes(selectedImg)) {
                    imageData.push(selectedImg)
                }
            }
            setAttachedImage(imageData)
            addImgURL(`${process.env.REACT_APP_BASE_URL}/${selectedImg.image_path}`)
        }
        else{
            document.getElementById("elementImageSelectError").classList.remove("d-none")
            setTimeout(() => {
                document.getElementById("elementImageSelectError").classList.add("d-none")
            }, 5000);
        }
    }

    async function deleteImg() {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/php/deleteImages.php`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                file_path: selectedImg.image_path,
                image_id: selectedImg.image_id
            })
        })
        return response.json()
    }

    function deleteImgFileMan() {
        document.getElementById('elementConformaionTab').classList.add('d-none')
        document.getElementById('elementShowLoad').classList.remove('d-none')
        if (selectedImg) {
            deleteImg().then((data) => {
                fetchImg().then((imagedata) => {
                    setAllImg(imagedata.image_path)
                    setRowchange(imagedata.image_path)
                    document.getElementById('elementModalContentFileMan').classList.remove('modalBodyFileMan')
                    document.getElementById('elementShowLoad').classList.add('d-none')
                })

            })
        }
    }
    function openConfirmTab(type) {
        if (type === 'open') {
            document.getElementById('elementModalContentFileMan').classList.add('modalBodyFileMan')
            document.getElementById('elementConformaionTab').classList.remove('d-none')
        }
        else if (type === 'close') {
            document.getElementById('elementModalContentFileMan').classList.remove('modalBodyFileMan')
            document.getElementById('elementConformaionTab').classList.add('d-none')
        }

    }

    function addCrouselCustomImage(value, index) {
        setUrlTarget(value)
        setCrouselImageIndex(index)
    }

    function setSlideShowCaseUrl(data) {
        if (data === 'showcase') {
            document.getElementById('slidershowcaseModal').click()
            setUrlTarget('sideShowCaseImageURL')
            document.getElementById('elementFileManagerBtn').click()
        }
        else if (data === 'avatar') {
            document.getElementById('testimonialModalBtn').click()
            setUrlTarget('avatarURL')
            document.getElementById('elementFileManagerBtn').click()
        }
    }

    function removeIcon(){
        var elementCssData = elementCss
        elementCssData.elementTextIcon = "blank"
        setElementCss(elementCssData)
        setRowchange(elementCssData.elementTextIcon)
    }

    return (
        <div className="tab-pane fade active show" id="nav-general" role="tabpanel">
            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#elementFileManager" id='elementFileManagerBtn'>
                Launch static backdrop modal
            </button>
            <div className="modal fade" id="elementFileManager" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" Style=' margin-top: 80px;'>
                    <div className="modal-content ">
                        <div id='elementConformaionTab' className='confirmTab d-none'>
                            <h6 Style='color: black;margin-bottom: 20px;font-weight: 900;'>Are you sure to delete image</h6>
                            <button className='btn btn-danger me-4' onClick={() => { openConfirmTab('close') }}>Cancel</button>
                            <button className='btn btn-primary ms-4' onClick={deleteImgFileMan}>Confirm</button>
                        </div>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Image Manager</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='elementFileManagerClose' Style='z-index:5000'></button>
                        </div>
                        <div className="modal-body " id='elementModalContentFileMan'>

                            <div className="lds-roller d-none" id='elementShowLoad'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            <div className="row mb-3">
                                <div className="col-sm-5">
                                    <label className="input-group-text btn btn-primary me-2" for="inputGroupFile01"><i className="fas fa-upload"></i></label>
                                    <input type="file" className="form-control d-none" id="inputGroupFile01" onChange={(e) => { saveFileData(e.target.files[0]) }} />
                                    <button type="button" title="Delete" id="button-delete" className="btn btn-danger" onClick={() => { openConfirmTab('open') }} ><i className="fas fa-trash-alt"></i></button>
                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                {allImg !== undefined ? allImg.map((element) => {
                                    return <div className='col col-2'>
                                        <div className="card" Style={`padding: 5px; border-radius: 10px; background-color:${selectedImg === element ? 'red' : 'white'}`}>
                                            <img src={`${process.env.REACT_APP_BASE_URL}/${element.image_path}`} className="card-img-top" alt={`${process.env.REACT_APP_BASE_URL}/${element.image_path}`} id={`img_${element.image_path}`} Style='border-radius: 5px; cursor:pointer' onClick={() => { setSelectedImg(element) }} onDoubleClick={() => { addImgURL(`${process.env.REACT_APP_BASE_URL}/${element.image_path}`) }} />
                                        </div>
                                    </div>
                                }) : ''}
                            </div>
                            <hr />
                            <p className='text-danger mb-3 d-none' id="elementImageSelectError">Please select an image to process</p>
                            <button type="button" className="btn btn-primary" onClick={addSelected}>Attach</button>
                        </div>
                    </div>
                </div>
            </div>


            <button type="button" id='imageListModal' className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#imageListEditModal" Style='display:none'>
                Launch demo modal
            </button>
            <div className="modal fade" id="imageListEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" Style='box-shadow:rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset'>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Image List</h1>
                        </div>
                        <div className="modal-body">
                            <input id='imageListInput' Style='width: 100%; height: 50px; border-radius: 10px;' value={imageListData} onChange={(e) => { setImageListData(e.target.value) }} />
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <button type="button" Style='box-shadow:rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset' className="btn btn-danger" onClick={() => { deleteListItem(imageListIndex) }}>Delete</button>
                            <button type="button" Style='box-shadow:rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset' className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <button type="button" id='slidershowcaseModal' className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#slidershowcaseEditModal" Style='display:none'>
                Launch demo modal
            </button>
            <div className="modal fade" id="slidershowcaseEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" Style='text-align:start'>
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Showcase item</h5>
                        </div>
                        <div className="modal-body">
                            <div className="input-group mb-3 SectionTitle">
                                <TextField label="Name" value={slideShowCase ? slideShowCase.name : ''} name='name' size="small" className="form-control TitleInput" onChange={(e) => { setSlideShowCase({ ...slideShowCase, [e.target.name]: e.target.value }) }} />
                            </div>

                            <div className="input-group mt-2">
                                <TextField label="Image URL" Style='width:100%' value={slideShowCase ? slideShowCase.url : ''} size="small" name='url' onChange={(e) => { setSlideShowCase({ ...slideShowCase, [e.target.name]: e.target.value }) }} />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary bgImage btn-7" type="button" onClick={() => { setSlideShowCaseUrl('showcase') }}><span><RiImageAddLine /></span></button>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col col-md-6">
                                    <TextField label="Width (PX)" value={slideShowCase ? slideShowCase.width : ''} name='width' size="small" className="form-control TitleInput" onChange={(e) => { setSlideShowCase({ ...slideShowCase, [e.target.name]: e.target.value }) }} />
                                </div>

                                <div className="col col-md-6">
                                    <TextField label="Height (PX)" value={slideShowCase ? slideShowCase.height : ''} name='height' size="small" className="form-control TitleInput" onChange={(e) => { setSlideShowCase({ ...slideShowCase, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className='mt-3'>
                                <TextField label="Alt Text (For SEO!)" value={slideShowCase ? slideShowCase.altText : ''} name='altText' size="small" className="form-control TitleInput" onChange={(e) => { setSlideShowCase({ ...slideShowCase, [e.target.name]: e.target.value }) }} />
                            </div>

                            <div className='d-flex flex-column mt-3'>
                                <span>Redirect Action</span>
                                <select class="form-select" aria-label="Default select example" id='sliderRedirect' name='sliderRedirect' onClick={(e) => { setSlideShowCase({ ...slideShowCase, [e.target.name]: e.target.value }) }}>
                                    <option value="URL">Go to website URL</option>
                                    <option value="gotostep">Go to Step</option>
                                </select>
                            </div>

                            <div className="URLAction mt-3">
                                <TextField label="URL" value={slideShowCase ? slideShowCase.sliderRedirectURL : ''} name='sliderRedirectURL' size="small" className="form-control TitleInput" onChange={(e) => { setSlideShowCase({ ...slideShowCase, [e.target.name]: e.target.value }) }} />
                                <p Style="color:red;" id="urlMessage">Please ensure the URL is FULL and VALID e.g. "https://www.Google.com"</p>
                            </div>

                            {/* <div id="StepAction" Style="display:none;">
                                <span>Funnel Step</span>
                                <select className="form-select BtnStepSelect mb-3" controlling="5" Style="width: 100%!important;">
                                    <option value="/">Root Page</option><option value="/newfunnels/Eran70/home/test.php">test</option></select>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>


            <button className='btn d-none' data-bs-toggle="modal" data-bs-target="#EditSurveyModal" id='openServeyModalBtn'></button>
            <div className="modal fade " id="EditSurveyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Tab Name</h5>
                        </div>
                        <div className="modal-body" Style='text-align:left'><>
                            <custom>Tab Title</custom>
                            <input type="text" className="form-control customTabTitleInput mb-3" maxlength="23" name='tabtitle' value={surveyObjData.tabtitle} onChange={(e) => { setSurveyObjData({ ...surveyObjData, [e.target.name]: e.target.value }) }} />

                            <custom>Question</custom>
                            <input type="text" className="form-control questionInput mb-3" name='question' value={surveyObjData.question} onChange={(e) => { setSurveyObjData({ ...surveyObjData, [e.target.name]: e.target.value }) }} />

                            <custom>SubText</custom>
                            <input type="text" className="form-control subTextInput mb-3" name='subtext' value={surveyObjData.subtext} onChange={(e) => { setSurveyObjData({ ...surveyObjData, [e.target.name]: e.target.value }) }} />

                            <custom>Field Name</custom>
                            <input type="text" className="form-control formBtnInput mb-3" name='fieldname' value={surveyObjData.fieldname} onChange={(e) => { setSurveyObjData({ ...surveyObjData, [e.target.name]: e.target.value }) }} />

                            <custom>Tab Type</custom>
                            <select className="form-select mb-3 surveyTabTypeSelect" name='tabtype' onClick={(e) => { setSurveyObjData({ ...surveyObjData, [e.target.name]: e.target.value }) }}>
                                <option value="buttons" selected="">Buttons</option>
                                <option value="inputs">Inputs</option>
                            </select>
                            {surveyObjData.tabtype === 'buttons' ? <div id="surveyButtonsBlock" controlling="#nav-3919a" className="">
                                <button className="btn addSurveyButton mb-3" Style="width:100%;" onClick={() => { addNewButton() }}><i className="fa fa-plus mr-3"></i> Add New Button</button>
                                {surveyObjData.buttonsData.map((buttonData, index) => {
                                    return <div className="btnInputDivs" controlling="#surveyBtn-fd64f">
                                        <label>Button Text</label>
                                        <input type="text" className="form-control surveyBtnInput mb-3" maxlength="23" value={buttonData.buttonvalue} />
                                        <label>Button Value</label>
                                        <div className="d-flex">
                                            <input type="text" className="form-control surveyBtnValInput mb-3" maxlength="23" value={buttonData.buttontext} />
                                            {surveyObjData.buttonsData.length > 1 ? <span><button className="btn delSurveyItem" Style="text-align:end;" onClick={() => { delSurveyButton(index) }}><i className="fa fa-trash"></i></button></span> : ''}
                                        </div>
                                    </div>
                                })}
                            </div> : ''}
                            {surveyObjData.tabtype === 'inputs' ? <>
                                <div id="surveyInputsBlock" controlling="#nav-3919a" className="">
                                    <button className="btn addSurveyInput mb-3" Style="width:100%;" onClick={() => { addNewInput() }}><i className="fa fa-plus mr-3"></i> Add New Input</button>
                                </div>
                                {surveyObjData.inputData.map((data) => {
                                    return <div className="inputEditDivs" controlling="#surveyInput-12f94">
                                        <label>Input Label</label>
                                        <input type="text" className="form-control labelInput mb-3" maxlength="23" value={data.inputlabel} />
                                        <label>Required</label>
                                        <select className="form-select RequiredSelect mb-3">
                                            <option value='true' selected="">Required</option>
                                            <option value="false">Not Required</option>
                                        </select>
                                        <label>Input Field Name</label>
                                        <div className="d-flex">
                                            <input type="text" className="form-control fieldNameInput mb-3" maxlength="23" value={data.inputfield} />
                                            <span><button className="btn delSurveyBtn" Style="text-align:end;"><i className="fa fa-trash"></i></button></span>
                                        </div>
                                    </div>
                                })}
                            </> : ''}
                        </>
                        </div>
                    </div>
                </div>
            </div>


            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" id='testimonialModalBtn' data-bs-target="#testimonialModal">
                Launch demo modal
            </button>

            <div className="modal fade" id="testimonialModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" Style='text-align:left'>
                        <div className="modal-header">
                            <h5 className="modal-title">Testimonial Item Settings</h5>
                        </div>
                        <div className="modal-body">
                            <div className='d-flex'>
                                <TextField label="Name" value={testimonialObjData.name} size="small" name='name' sx={{ m: 1, width: '100%' }} onChange={(e) => { setTestimonialObjData({ ...testimonialObjData, [e.target.name]: e.target.value }) }} />
                            </div>

                            <div className='d-flex'>
                                <TextField label="Desc." value={testimonialObjData.desc} size="small" name='desc' sx={{ m: 1, width: '100%' }} onChange={(e) => { setTestimonialObjData({ ...testimonialObjData, [e.target.name]: e.target.value }) }} />
                            </div>

                            <div className="input-group mt-2">
                                <TextField label="Avatar" sx={{ m: 1, width: '100%' }} value={testimonialObjData.avatar} size="small" name='avatar' onChange={(e) => { setTestimonialObjData({ ...testimonialObjData, [e.target.name]: e.target.value }) }} />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary bgImage btn-7" Style="margin-top: 5px;" type="button" data-bs-toggle="modal" data-bs-target="#elementFileManager" onClick={() => { setSlideShowCaseUrl('avatar') }}><span><RiImageAddLine /></span></button>
                                </div>
                            </div>

                            <custom>Stars</custom>
                            <select className="form-select testimonialStarsSelect" id="testimonialstars" name='stars' onClick={(e) => { setTestimonialObjData({ ...testimonialObjData, [e.target.name]: e.target.value }) }}>
                                <option value="s">1</option>
                                <option value="ss">2</option>
                                <option value="sss">3</option>
                                <option value="ssss">4</option>
                                <option value="sssss">5</option>
                            </select>

                        </div>
                        <div className="modal-footer" Style="justify-content: flex-end;">
                            <div Style="justify-content: flex-end;">
                                <button type="button" className="btn btn-success saveTestimonialItem" onClick={saveTestimonialCard}>Save</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id={`HTMLEditorElement`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-body">
                            <span Style="font-size: 20px;">
                                <i className="fas fa-code"></i>
                                <custom Style="font-size: 20px;">&nbsp; Custom CSS</custom>
                            </span>
                            <div className="mt-3 cssEditorHolder" Style='text-align:left'>
                                <Editor
                                    height="50vh"
                                    theme="vs-dark"
                                    defaultLanguage="html"
                                    defaultValue={customHTML}
                                    onChange={handleEditorChange}
                                />
                            </div>
                        </div>
                        <div className="modal-footer" Style="display:flex; justify-content: center;">
                            {/* <div>
                                    <p Style="color:red;"><em><u>Caution!</u></em> Global selectors like <b><i>html</i></b> or <b><i>button</i></b> will result in the entire page being affected.</p>
                                    <p Style="color:#ff6a00;"><em><u>Tip:</u></em> If your styling won't work, try adding a <i>!important</i> flag after it</p>
                                </div> */}
                            <div>
                                <button type="button" className="btn btn-success saveCustomCss" Style="width: 100px;" onClick={saveHTML}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <button className='d-none' id='openTabEditorBtn' data-bs-toggle="modal" data-bs-target={`#TabHTMLEditor`}  ></button>
            <div className="modal fade" id={`TabHTMLEditor`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-body">
                            <span Style="font-size: 20px;">
                                <i className="fas fa-code"></i>
                                <custom Style="font-size: 20px;">&nbsp; Custom CSS</custom>
                            </span>
                            <div className="mt-3 cssEditorHolder" Style='text-align:left'>
                                <Editor
                                    height="50vh"
                                    theme="vs-dark"
                                    defaultLanguage="html"
                                    defaultValue={customTabHTML}
                                    onChange={handleTabEditorChange}
                                />
                            </div>
                        </div>
                        <div className="modal-footer" Style="display:flex; justify-content: center;">
                            {/* <div>
                                    <p Style="color:red;"><em><u>Caution!</u></em> Global selectors like <b><i>html</i></b> or <b><i>button</i></b> will result in the entire page being affected.</p>
                                    <p Style="color:#ff6a00;"><em><u>Tip:</u></em> If your styling won't work, try adding a <i>!important</i> flag after it</p>
                                </div> */}
                            <div>
                                <button type="button" className="btn btn-success saveCustomCss" Style="width: 100px;" onClick={saveTabHTML}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="SideBarTitle" Style="width: 92%;margin: auto; ">
                <div className="input-group mb-3 SectionTitle">
                    <TextField label="Name" value={elementName} size="small" className="form-control TitleInput" onChange={(e) => { setElementName(e.target.value) }} />
                </div>
            </div>
            <div>
                {elementType === 'customjshtml' ? '' : <div id="ColourOptions">
                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                            <span>Background Color:</span>
                            <div className="color-picker-button float-right" Style={`${openButton};border-radius: 15px;`} onClick={() => { setEnableBgColor(!enableBgColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                        </div>
                        {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                            <button className="color-picker-button btn float-right" Style={`${openButton}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableBgColor(!enableBgColor) }}>Background Color</button>
                        </div> */}
                        {enableBgColor ? <ColorPicker color={color} setColor={setColor} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableBgColor} type='element' /> : ''}
                    </div>
                </div>}
                {elementType === 'button' ? <div id="ColourOptions">
                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                            <span>Button Background Color:</span>
                            <div className="color-picker-button float-right" Style={`${openBtnButton};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableBtnBgColor(!enableBtnBgColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                        </div>
                        {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                            <button className="color-picker-button btn float-right" Style={`${openBtnButton}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableBtnBgColor(!enableBtnBgColor) }}>Button Background Color:</button>
                        </div> */}
                        {enableBtnBgColor ? <ColorPicker color={elementCss.btnBackgroundColor} setRowchange={setRowchange} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableBtnBgColor} type='btnBackground' /> : ''}
                    </div>
                </div> : ''}
                {elementType === 'imagefeature' ? <><div id="ColourOptions">
                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                            <span>Header Color:</span>
                            <div className="color-picker-button float-right" Style={`${imgFeatureHeaderBtn};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableImgFeatureHeaderColor(!enableImgFeatureHeaderColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                        </div>
                        {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                            <button className="color-picker-button btn float-right" Style={`${imgFeatureHeaderBtn}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableImgFeatureHeaderColor(!enableImgFeatureHeaderColor) }}>Header Color:</button>
                        </div> */}
                        {enableImgFeatureHeaderColor ? <ColorPicker setRowchange={setRowchange} color={imgFeatureHeaderColor} setColor={setImgFeatureHeaderColor} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableImgFeatureHeaderColor} type='imagefeatureheader' /> : ''}
                    </div>
                </div><div id="ColourOptions">
                        <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                            <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                <span>Text Color:</span>
                                <div className="color-picker-button float-right" Style={`${imgFeatureTextBtn};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableImgFeatureTextColor(!enableImgFeatureTextColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                            </div>
                            {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                                <button className="color-picker-button btn float-right" Style={`${imgFeatureTextBtn}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableImgFeatureTextColor(!enableImgFeatureTextColor) }}>Text Color:</button>
                            </div> */}
                            {enableImgFeatureTextColor ? <ColorPicker setRowchange={setRowchange} color={imgFeatureTextColor} setColor={setImgFeatureTextColor} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableImgFeatureTextColor} type='imagefeaturetext' /> : ''}
                        </div>
                    </div></> : ''}
                {elementType === 'video' || elementType === 'image' || elementType === 'imagepopup' || elementType === 'videopopup' || elementType === 'slidershowcase' || elementType === 'imagefeature' || elementType === 'socialshare' || elementType === 'inputfield' || elementType === 'selectfield' || elementType === 'textarea' || elementType === 'survey' || elementType === "countdown" || elementType === "daytimer" || elementType === "minutetimer" || elementType === "testimonial" || elementType === 'customjshtml' || elementType === 'map' || elementType === "carousel" || elementType === "singlecarousel" ? '' : <div id="ColourOptions">
                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px;'>
                            {elementType === 'divider' ? <span>Color:</span> : <span>Text Color:</span>}
                            <div className="color-picker-button float-right" Style={`${openTextColButton};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableTextColor(!enableTextColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                        </div>
                        {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                            <button className="color-picker-button btn float-right" Style={`${openTextColButton}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableTextColor(!enableTextColor) }}>{elementType === 'divider' ? <span>Color:</span> : <span>Text Color:</span>}</button>
                        </div> */}
                        {enableTextColor ? <ColorPicker color={textColor} setColor={setTextColor} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableTextColor} type='elementTextColor' /> : ''}
                    </div>
                </div>}
                {elementType === 'inputfield' || elementType === 'textarea' || elementType === 'selectfield' ? <><div className="picker-wrapper all-picker-wrappers" Style="display:block">
                    <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                        <span>Form Background Color:</span>
                        <div className="color-picker-button float-right" Style={`${formBgColor};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableFormBgColor(!enableFormBgColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                    </div>
                    {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                        <button className="color-picker-button btn float-right" Style={`${formBgColor}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableFormBgColor(!enableFormBgColor) }}>Form Background Color:</button>
                    </div> */}
                    {enableFormBgColor ? <ColorPicker color={elementCss.formBackgroundcolor} setRowchange={setRowchange} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableFormBgColor} type='formBackground' /> : ''}
                </div>
                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                            <span>Form Label Color:</span>
                            <div className="color-picker-button float-right" Style={`${formLabelColor};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableFormLabelColor(!enableFormLabelColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                        </div>
                        {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                            <button className="color-picker-button btn float-right" Style={`${formLabelColor}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableFormLabelColor(!enableFormLabelColor) }}>Form Label Color:</button>
                        </div> */}
                        {enableFormLabelColor ? <ColorPicker color={elementCss.formLabelColor} setRowchange={setRowchange} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableFormLabelColor} type='formLabelColor' /> : ''}
                    </div><div className="picker-wrapper all-picker-wrappers" Style="display:block">
                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px; font-size: 15px; margin-bottom: 15px;'>
                            <span>Input text Color (not placeholder):</span>
                            <div className="color-picker-button float-right" Style={`${formInputColor};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableFormInputColor(!enableFormInputColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                        </div>
                        {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                            <button className="color-picker-button btn float-right" Style={`${formInputColor}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableFormInputColor(!enableFormInputColor) }}>Input text Color (not placeholder):</button>
                        </div> */}
                        {enableFormInputColor ? <ColorPicker color={elementCss.inputTextColor} setRowchange={setRowchange} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableFormInputColor} type='inputTextColor' /> : ''}
                    </div></> : ''}
            </div>

            {
                elementType === 'survey' ? <div >
                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                        {/* <div Style='display:flex;justify-content: space-between;padding: 0px 30px;'>
                            <span>Tab Text Color:</span>
                            <div className="color-picker-button float-right" Style={`${openTabTextColor};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableTabTextColor(!enableTabTextColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                        </div> */}
                        <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                            <button className="color-picker-button btn float-right" Style={`${openTabTextColor}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableTabTextColor(!enableTabTextColor) }}>Tab Text Color:</button>
                        </div>
                        {enableTabTextColor ? <ColorPicker color={elementCss.tabtextcolor} setRowchange={setRowchange} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableTabTextColor} type='tabtextcolor' /> : ''}
                    </div>
                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                        {/* <div Style='display:flex;justify-content: space-between; padding: 15px 30px 0px 30px;'>
                            <span>Theme Color:</span>
                            <div className="color-picker-button float-right" Style={`${openThemeColor};border-radius: 15px;border: 1px solid black;`} onClick={() => { setEnableThemeColor(!enableThemeColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>

                        </div> */}
                        <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                            <button className="color-picker-button btn float-right" Style={`${openThemeColor}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableThemeColor(!enableThemeColor) }}>Theme Color:</button>
                        </div>
                        {enableThemeColor ? <ColorPicker color={elementCss.themecolor} setRowchange={setRowchange} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableThemeColor} type='themecolor' /> : ''}
                    </div>
                    <div className="container" Style="padding: 0px 10px 0px 10px;text-align:start">
                        <div className="SectionPropsWrapper mb-3 mt-3 p-2">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        {elementCss.surveyData.map((data, index) => {
                                            return <div id="customTabList">
                                                <div subtext="SubText Goes Here" question="Question Goes Here" tabinputname="button1" tabtype="buttons" controlling="#nav-3919a" className="customTab p-2 m-1" Style="background-color: #fff; border-radius: 5px;box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;">
                                                    <div Style="display:inline-block; width:68%;">{data.tabtitle}</div>
                                                    <div Style="display:inline-flex; width:30%;">
                                                        <span>
                                                            <button className="btn editCustomTab" Style="text-align:end;" clicked="false" data-bs-toggle="modal" data-bs-target="#EditSurveyModal" onClick={() => { updateServeyData(data, index) }}><i className="fa fa-edit"></i></button>
                                                        </span>
                                                        <span>
                                                            <button className="btn delCustomTab" Style="text-align:end;" onClick={() => { delServeyData(index) }}><i className="fa fa-trash"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <button className="btn addCustomTabBtn mb-3" Style="width:100%;" onClick={addServeyData}><i className="fa fa-plus mr-3"></i> Add New Tab</button>
                                    </div>
                                </div>
                            </div>

                            <div className="inputBlock">
                                <span>Submission Text</span>
                                <input className="form-control propInput mb-3" type="text" name='submissiontext' value={elementCss.submissiontext} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>


                            <div className="redirectBlock" controlling="">
                                <span>Link Action</span>
                                <select className="form-select simpleActionSelect mb-3" name='linkaction' id='linkaction' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                    <option value="simpleURLAction">Go to website URL</option>
                                    <option value="simpleStepAction">Go to Step</option>
                                </select>

                                <div className="simpleURLAction" Style="display: block;">
                                    <span>URL</span>
                                    <input className="form-control simplyURLinput mb-3" type="text" name='surveyurl' value={elementCss.surveyurl} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    <p Style="color:red; display:none;" id="urlMessage">Please ensure the URL is FULL and VALID e.g. "https://www.Google.com"</p>
                                </div>

                                <div className="simpleStepAction" Style="display: none;">
                                    <span>Funnel Step</span>
                                    <select className="simpleStepSelect form-select BtnStepSelect mb-3" controlling="c5e6b">
                                        <option value="/">Root Page</option><option value="/newfunnels/Eran70/home/test.php">test</option>
                                    </select>
                                </div>
                            </div>


                            <div className="fontSelectorGroup">
                                <span>Font</span>
                                <select className="form-select FontSelect mb-3" controlling="1317f">
                                    <option value="Header">Header</option>
                                    <option value="Content">Content</option>
                                    <option value="Custom">Custom</option>
                                </select>

                                <div id="customFont" Style="display:none;" className="mb-3">
                                    <span>Font Family</span><br />
                                    <button id="fontBtn" controlling="Surveyfaabe" selectedelm="" className="btn" Style="width:95%; background-color: rgb(255, 255, 255); border:1px solid #ced4da;" onclick="openFontsNav()">Lato</button>
                                </div>
                            </div>


                            <span>Question Font Size</span>
                            <div className="input-group mb-3">
                                <input type="range" className="form-control-range propSlider addCssByDragingInput" min="0" max="100" step="1" name='questionfontsize' value={elementCss.questionfontsize} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                <input className="input-group-text PropLabels propSliderLabel addCssByDragingValue" name='questionfontsize' value={elementCss.questionfontsize} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>


                            <span>Sub Text Font Size</span>
                            <div className="input-group mb-3">
                                <input type="range" className="form-control-range propSlider addCssByDragingInput" min="0" max="100" step="1" name='subtextfontsize' value={elementCss.subtextfontsize} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                <input className="input-group-text PropLabels propSliderLabel addCssByDragingValue" name='subtextfontsize' value={elementCss.subtextfontsize} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>


                            <span>Button Text Font Size</span>
                            <div className="input-group mb-3">
                                <input type="range" className="form-control-range propSlider addCssByDragingInput" min="0" max="100" step="1" name='buttontextfontsize' value={elementCss.buttontextfontsize} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                <input className="input-group-text PropLabels propSliderLabel addCssByDragingValue" name='buttontextfontsize' value={elementCss.buttontextfontsize} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>

                        </div>
                    </div>
                </div> : ''
            }

            {
                elementType === 'socialshare' ? '' : <div id="">
                    <div className="container" Style="padding: 0px 10px 0px 10px;text-align:start">
                        <div className="SectionPropsWrapper mb-3 mt-3 p-2">
                            {elementType === 'imagefeature' ? <div Style="margin-bottom: 10px;">
                                <div>
                                    <div className="PropsTitle">Font Size Options</div>
                                    <div className="SectionProps">

                                        <div className='d-flex flex-column'>
                                            <TextField label="Header Mobile Font Size" id="headerMobileFontSize" value={elementCss.headerMobileFontSize} size="small" name='headerMobileFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.headerMobileFontSize} aria-label="Default" valueLabelDisplay="auto" name='headerMobileFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>

                                        <div className='d-flex flex-column'>
                                            <TextField label="Header Desktop Font Size" id="headerDesktopFontSize" value={elementCss.headerDesktopFontSize} size="small" name='headerDesktopFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.headerDesktopFontSize} aria-label="Default" valueLabelDisplay="auto" name='headerDesktopFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>

                                        <div className='d-flex flex-column'>
                                            <TextField label="Text Mobile Font Size" id="textMobileFontSize" value={elementCss.textMobileFontSize} size="small" name='textMobileFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.textMobileFontSize} aria-label="Default" valueLabelDisplay="auto" name='textMobileFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>

                                        <div className='d-flex flex-column'>
                                            <TextField label="Text Desktop Font Size" id="textDesktopFontSize" value={elementCss.textDesktopFontSize} size="small" name='textDesktopFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.textDesktopFontSize} aria-label="Default" valueLabelDisplay="auto" name='textDesktopFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}
                            {elementType === 'button' ? <div Style="margin-bottom: 10px;">
                                <div>
                                    <div className="PropsTitle">Font Size Options</div>
                                    <div className="SectionProps">

                                        <div className='d-flex flex-column'>
                                            <TextField label="Header Mobile Font Size" id="headerMobileFontSizeBtn" value={elementCss.headerMobileFontSizeBtn} size="small" name='headerMobileFontSizeBtn' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.headerMobileFontSizeBtn} aria-label="Default" valueLabelDisplay="auto" name='headerMobileFontSizeBtn' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>

                                        <div className='d-flex flex-column'>
                                            <TextField label="Header Desktop Font Size" id="headerDesktopFontSizeBtn" value={elementCss.headerDesktopFontSizeBtn} size="small" name='headerDesktopFontSizeBtn' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.headerDesktopFontSizeBtn} aria-label="Default" valueLabelDisplay="auto" name='headerDesktopFontSizeBtn' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>

                                        <div className='d-flex flex-column'>
                                            <TextField label="Sub-Text Mobile Font Size" id="textMobileFontSizeBtn" value={elementCss.textMobileFontSizeBtn} size="small" name='textMobileFontSizeBtn' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.textMobileFontSizeBtn} aria-label="Default" valueLabelDisplay="auto" name='textMobileFontSizeBtn' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>

                                        <div className='d-flex flex-column'>
                                            <TextField label="Sub-Text Desktop Font Size" id="textDesktopFontSizeBtn" value={elementCss.textDesktopFontSizeBtn} size="small" name='textDesktopFontSizeBtn' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.textDesktopFontSizeBtn} aria-label="Default" valueLabelDisplay="auto" name='textDesktopFontSizeBtn' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}

                            {elementType === 'customtab' ? <div id="customTabList">
                                {elementCss.customTabs.map((elem, index) => {
                                    return <div subtext="null" question="null" tabinputname="null" tabtype="null" controlling="#nav-b6a41" className="customTab p-2 m-1" Style="background-color: #e4e4e4;border-radius: 5px;box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;display: flex; justify-content: center; align-items: center;">
                                        <div Style="display:inline-block; width:68%;">{elem.tabName}</div>
                                        <div Style="display:flex; width:30%;">
                                            <span>
                                                <button className="btn delCustomTab" Style='padding: 5px;font-size: 20px;' onClick={() => { openTabEditor(index) }}><BsFileEarmarkCode /></button>
                                            </span>
                                            {index > 0 ? <span Style='display:flex;margin-bottom:-4px'>
                                                <button className="btn editCustomTab" Style='padding: 5px;font-size: 20px;' onClick={() => { deleteTabEditor(index) }}><i className="fa fa-trash"></i></button>
                                            </span> : ''}
                                        </div>
                                    </div>
                                })}
                                <button className="btn addItemBtn mb-3 mt-2" onClick={addNewTab} Style='width: 100%;box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;'><i className="fa fa-plus mr-3"></i> Add New Tan</button>
                            </div> : ''}

                            {elementType && (elementType === 'imagelist' || elementType === 'video' || elementType === 'slidershowcase' || elementType === 'inputfield' || elementType === 'selectfield' || elementType === 'textarea' || elementType === 'checkbox' || elementType === 'survey' || elementType === "countdown" || elementType === "minutetimer" || elementType === "daytimer" || elementType === "divider" || elementType === 'progressbar' || elementType === "testimonial" || elementType === 'customjshtml' || elementType === 'map' || elementType === "carousel" || elementType === "singlecarousel") ? '' : <>
                                {elementType === 'image' || elementType === 'imagepopup' || elementType === 'videopopup' || elementType === 'imagefeature' || elementType === 'button' || elementType === 'customtab' ? '' : <>
                                    <div className="input-group mb-3 addCssByDraging">
                                        <div className='d-flex flex-column'>
                                            <TextField label="Mobile Font Size" value={elementCss.mobileFontSize} size="small" name='mobileFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.mobileFontSize} aria-label="Default" valueLabelDisplay="auto" name='mobileFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-3 addCssByDraging">
                                        <div className='d-flex flex-column'>
                                            <TextField label="Desktop Font Size" value={elementCss.desktopFontSize} size="small" name='desktopFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.desktopFontSize} aria-label="Default" valueLabelDisplay="auto" name='desktopFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>
                                    </div>
                                </>}
                                {elementType === 'icon' || elementType === 'inputfield' || elementType === 'selectfield' || elementType === 'textarea' || elementType === 'checkbox' || elementType === "countdown" || elementType === "minutetimer" || elementType === "daytimer" || elementType === 'customjshtml' || elementType === 'customtab' ? '' : <><div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="elementOpacity-Sticky">Opacity</InputLabel>
                                        <Select
                                            labelId="elementOpacity"
                                            id="elementOpacity"
                                            value={elementCss.elementOpacity}
                                            name='elementOpacity'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="elementOpacity"
                                        >
                                            <MenuItem value={'1'}>None</MenuItem>
                                            <MenuItem value={'0.8'}>Light Fade</MenuItem>
                                            <MenuItem value={"0.5"}>Half Fade</MenuItem>
                                            <MenuItem value={"0.3"}>Heavy Fade</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                    <Slider value={elementCss.elementOpacity} aria-label="Default" valueLabelDisplay="auto" name='elementOpacity' min={0.1} max={1} step={0.1} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    {elementType === 'image' || elementType === 'imagepopup' || elementType === 'videopopup' ? '' : <>
                                        <div className='d-flex'>
                                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                                <InputLabel id="elementTextShadow-Sticky">Text-shadow</InputLabel>
                                                <Select
                                                    labelId="elementTextShadow"
                                                    id="elementTextShadow"
                                                    value={elementCss.elementTextShadow}
                                                    name='elementTextShadow'
                                                    onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                                    label="elementTextShadow"
                                                >
                                                    <MenuItem value={'rgba(0, 0, 0, 0) 0px 0px 0px'}>No Shadow</MenuItem>
                                                    <MenuItem value={'rgba(0, 0, 0, 0.2) 1px 1px 1px'}>Light Shadow</MenuItem>
                                                    <MenuItem value={"rgba(0, 0, 0, 0.4) 1px 1px 2px"}>Mid Shadow</MenuItem>
                                                    <MenuItem value={"rgba(0, 0, 0, 0.5) 1px 1px 3px"}>Strong Shadow</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='d-flex'>
                                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                                <InputLabel id="elementLetterSpacing-Sticky">Letter Spacing</InputLabel>
                                                <Select
                                                    labelId="elementLetterSpacing"
                                                    id="elementLetterSpacing"
                                                    value={elementCss.elementLetterSpacing}
                                                    name='elementLetterSpacing'
                                                    onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                                    label="elementLetterSpacing"
                                                >
                                                    <MenuItem value={'0'}>Normal</MenuItem>
                                                    <MenuItem value={'1px'}>1px</MenuItem>
                                                    <MenuItem value={"2px"}>2px</MenuItem>
                                                    <MenuItem value={"3px"}>3px</MenuItem>
                                                    <MenuItem value={"-1px"}>-1px</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>}
                                </>}
                                <div className='d-flex flex-column align-items-center'>
                                    <span>Align</span>
                                    <ToggleButtonGroup
                                        value={elementCss.textAlign}
                                        exclusive
                                        onChange={handleAlignment}
                                        aria-label="text alignment"
                                        name='textAlign'
                                    >
                                        <ToggleButton value="left" aria-label="left aligned">
                                            <FormatAlignLeftIcon />
                                        </ToggleButton>
                                        <ToggleButton value="center" aria-label="centered">
                                            <FormatAlignCenterIcon />
                                        </ToggleButton>
                                        <ToggleButton value="right" aria-label="right aligned">
                                            <FormatAlignRightIcon />
                                        </ToggleButton>
                                        <ToggleButton value="justify" aria-label="justified" >
                                            <FormatAlignJustifyIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </>}

                            {elementType && elementType === 'imagelist' ?
                                <>
                                    <div id="ColourOptions">
                                        <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                                            <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                                <span>Separator Color:</span>
                                                <div className="color-picker-button float-right" Style={`${separatorColorBtn};border-radius: 15px;`} onClick={() => { setEnableSeparatorTextColor(!enableSeparatorColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                            </div>
                                            {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                                                <button className="color-picker-button btn float-right" Style={`${separatorColorBtn}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableSeparatorTextColor(!enableSeparatorColor) }}>Separator Color:</button>
                                            </div> */}
                                            {enableSeparatorColor ? <ColorPicker color={separatorColor} setColor={setSeparatorColor} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableSeparatorTextColor} type='separatorColor' /> : ''}
                                        </div>
                                    </div>
                                    <div id="ColourOptions">
                                        <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                                            <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                                <span>Icon Color:</span>
                                                <div className="color-picker-button float-right" Style={`${iconColorBtn};border-radius: 15px;`}onClick={() => { setEnableIconColor(!enableIconColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                            </div>
                                            {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                                                <button className="color-picker-button btn float-right" Style={`${iconColorBtn}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableIconColor(!enableIconColor) }}>Icon Color:</button>
                                            </div> */}
                                            {enableIconColor ? <ColorPicker color={iconColor} setColor={setIconColorColor} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableIconColor} type='iconColor' /> : ''}
                                        </div>
                                    </div>
                                    <div className='container'>
                                        <TextField label="Icon Size" id="iconSize" value={elementCss.iconSize} size="small" name='iconSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        <Slider value={elementCss.iconSize} aria-label="Default" valueLabelDisplay="auto" name='iconSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </div>
                                    <div className='d-flex'>
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="imagelistfontweight-Sticky">Letter Spacing</InputLabel>
                                            <Select
                                                labelId="imagelistfontweight"
                                                id="imagelistfontweight"
                                                value={elementCss.imagelistfontweight}
                                                name='imagelistfontweight'
                                                onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                                label="imagelistfontweight"
                                            >
                                                <MenuItem value={'300'}>Light</MenuItem>
                                                <MenuItem value={'400'}>Normal</MenuItem>
                                                <MenuItem value={"700"}>Bold</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <span>Align</span>
                                        <ToggleButtonGroup
                                            value={elementCss.textAlign}
                                            exclusive
                                            onChange={handleAlignment}
                                            aria-label="text alignment"
                                            name='textAlign'
                                        >
                                            <ToggleButton value="left" aria-label="left aligned">
                                                <FormatAlignLeftIcon />
                                            </ToggleButton>
                                            <ToggleButton value="center" aria-label="centered">
                                                <FormatAlignCenterIcon />
                                            </ToggleButton>
                                            <ToggleButton value="right" aria-label="right aligned">
                                                <FormatAlignRightIcon />
                                            </ToggleButton>
                                            <ToggleButton value="justify" aria-label="justified" >
                                                <FormatAlignJustifyIcon />
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </> : ''
                            }

                            {elementType === "countdown" || elementType === "minutetimer" || elementType === "daytimer" ? <div id="">
                                <div className="container">
                                    {elementType === "countdown" ? <>
                                        <custom>End Date</custom>
                                        <input id="datePickerInput" className="form-control mb-3" type="date" name='endDate' value={elementCss.endDate} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </> : ''}
                                    {elementType === "daytimer" || elementType === "countdown" ? <>
                                        <custom>End Time</custom>
                                        <input id="timePickerInput" className="form-control mb-3" type="time" name='endTime' value={elementCss.endTime} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </> : ''}
                                    {elementType === "minutetimer" ? <>
                                        <div className="PropsTitle">Minute Settings</div>
                                        <div className="SectionProps">
                                            <div className='d-flex' Style='position:relative'>
                                                <TextField label='Hours' size="small" className=" mb-3 " name='minuteTimerHour' value={elementCss.minuteTimerHour} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            </div>
                                            <div className='d-flex' Style='position:relative'>
                                                <TextField label='Minutes' size="small" className=" mb-3 " name='minuteTimerMinute' value={elementCss.minuteTimerMinute} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            </div>
                                            <div className='d-flex' Style='position:relative'>
                                                <TextField label='Seconds' size="small" className=" mb-3 " name='minuteTimerSecond' value={elementCss.minuteTimerSecond} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            </div>
                                        </div>
                                    </> : ''}
                                </div>

                                <div className="container">
                                    <div className="picker-wrappere2b17 all-picker-wrappers " Style="padding: 10px 0px;">
                                        <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                                            <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                                <span>Items Background Color:</span>
                                                <div className="color-picker-button float-right" Style={`${openCountDownBgBtn};border-radius: 15px;`} onClick={() => { setEnableCountDownBgColor(!enableCountDownBgColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                            </div>
                                            {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                                                <button className="color-picker-button btn float-right" Style={`${openCountDownBgBtn}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableCountDownBgColor(!enableCountDownBgColor) }}>Items Background Color:</button>
                                            </div> */}
                                            {enableCountDownBgColor ? <ColorPicker color={elementCss.countdownbgcolor} Css={elementCss} setCss={setElementCss} setRowchange={setRowchange} setEnableBgColor={setEnableCountDownBgColor} type='countdownbgColor' /> : ''}
                                        </div>
                                    </div>
                                    <div className="picker-wrappera8035 all-picker-wrappers " Style="padding: 10px 0px;">
                                        <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                                            <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                                <span>Text Color:</span>
                                                <div className="color-picker-button float-right" Style={`${openCountDownTextBtn};border-radius: 15px;`} onClick={() => { setEnableCountDownTextColor(!enableCountDownTextColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                            </div>
                                            {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                                                <button className="color-picker-button btn float-right" Style={`${openCountDownTextBtn}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableCountDownTextColor(!enableCountDownTextColor) }}>Text Color:</button>
                                            </div> */}
                                            {enableCountDownTextColor ? <ColorPicker color={elementCss.countdowntextcolor} Css={elementCss} setCss={setElementCss} setRowchange={setRowchange} setEnableBgColor={setEnableCountDownTextColor} type='countdowntextColor' /> : ''}
                                        </div>
                                    </div>
                                    <div className="selectBlock d-flex">
                                        <FormControl size="small" sx={{ m: '1', width: '100%', paddingBottom: '15px' }}>
                                            <InputLabel id="countdownposition-Sticky">Positioning</InputLabel>
                                            <Select
                                                labelId="countdownposition"
                                                id="countdownposition"
                                                value={elementCss.countdownposition}
                                                name='countdownposition'
                                                onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                                label="countdownposition"
                                            >
                                                <MenuItem value={'center'}>center</MenuItem>
                                                <MenuItem value={'flex-start'}>Left</MenuItem>
                                                <MenuItem value={"flex-end"}>Right</MenuItem>
                                                <MenuItem value={"space-around"}>Space Around</MenuItem>
                                                <MenuItem value={"space-evenly"}>Space Evenly</MenuItem>
                                                <MenuItem value={"space-between"}>Space Between</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <TextField label="Header Mobile Font Size" value={elementCss.countDownMobFontSize} size="small" name='countDownMobFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        <Slider value={elementCss.countDownMobFontSize} aria-label="Default" valueLabelDisplay="auto" name='countDownMobFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <TextField label="Header Desktop Font Size" value={elementCss.countDownDeskFontSize} size="small" name='countDownDeskFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        <Slider value={elementCss.countDownDeskFontSize} aria-label="Default" valueLabelDisplay="auto" name='countDownDeskFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <TextField label="Sub-Text Mobile Font Size" value={elementCss.countDownTextMobFontSize} size="small" name='countDownTextMobFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        <Slider value={elementCss.countDownTextMobFontSize} aria-label="Default" valueLabelDisplay="auto" name='countDownTextMobFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <TextField label="Sub-Text Desktop Font Size" value={elementCss.countDownTextDeskFontSize} size="small" name='countDownTextDeskFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        <Slider value={elementCss.countDownTextDeskFontSize} aria-label="Default" valueLabelDisplay="auto" name='countDownTextDeskFontSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </div>
                                </div>
                            </div> : ''}
                            {elementType === 'divider' || elementType === 'progressbar' ? <div >
                                <div className="selectBlock">
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="divideralign-Sticky">Align</InputLabel>
                                        <Select
                                            labelId="divideralign"
                                            id="divideralign"
                                            value={elementCss.divideralign}
                                            name='divideralign'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="divideralign"
                                        >
                                            <MenuItem value={'start'}>Left</MenuItem>
                                            <MenuItem value={'center'}>Middle</MenuItem>
                                            <MenuItem value={"end"}>Right</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {elementType === 'divider' ? <>
                                    <div className="selectBlock">
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="dividerwidth-Sticky">Width (%)</InputLabel>
                                            <Select
                                                labelId="dividerwidth"
                                                id="dividerwidth"
                                                value={elementCss.dividerwidth}
                                                name='dividerwidth'
                                                onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                                label="dividerwidth"
                                            >
                                                <MenuItem value={'3%'}>0 Percent</MenuItem>
                                                <MenuItem value={'10%'}>10 Percent</MenuItem>
                                                <MenuItem value={'20%'}>20 Percent</MenuItem>
                                                <MenuItem value={'30%'}>30 Percent</MenuItem>
                                                <MenuItem value={'40%'}>40 Percent</MenuItem>
                                                <MenuItem value={'50%'}>50 Percent</MenuItem>
                                                <MenuItem value={'60%'}>60 Percent</MenuItem>
                                                <MenuItem value={'70%'}>70 Percent</MenuItem>
                                                <MenuItem value={'80%'}>80 Percent</MenuItem>
                                                <MenuItem value={'90%'}>90 Percent</MenuItem>
                                                <MenuItem value={'100%'}>100 Percent</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="selectBlock">
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="dividerlineheight-Sticky">Height</InputLabel>
                                            <Select
                                                labelId="dividerlineheight"
                                                id="dividerlineheight"
                                                value={elementCss.dividerlineheight}
                                                name='dividerlineheight'
                                                onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                                label="dividerlineheight"
                                            >
                                                <MenuItem value={'1px'}>1px</MenuItem>
                                                <MenuItem value={'2px'}>2px</MenuItem>
                                                <MenuItem value={'3px'}>3px</MenuItem>
                                                <MenuItem value={'5px'}>5px</MenuItem>
                                                <MenuItem value={'10px'}>10px</MenuItem>
                                                <MenuItem value={'15px'}>15px</MenuItem>
                                                <MenuItem value={'20px'}>20px</MenuItem>
                                                <MenuItem value={'30px'}>30px</MenuItem>
                                                <MenuItem value={'40px'}>40px</MenuItem>
                                                <MenuItem value={'50px'}>50px</MenuItem>
                                                <MenuItem value={'60px'}>60px</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="selectBlock">
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="dividerheight-Sticky">Align</InputLabel>
                                            <Select
                                                labelId="dividerheight"
                                                id="dividerheight"
                                                value={elementCss.dividerheight}
                                                name='dividerheight'
                                                onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                                label="dividerheight"
                                            >
                                                <MenuItem value={'solid'}>Solid</MenuItem>
                                                <MenuItem value={'dashed'}>Dashed</MenuItem>
                                                <MenuItem value={"dotted"}>Dotted</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </> : ''}
                            </div> : ''}
                            {elementType === 'progressbar' ? <>
                                <div className="selectBlock">
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="progresswidth-Sticky">Width (%)</InputLabel>
                                        <Select
                                            labelId="progresswidth"
                                            id="progresswidth"
                                            value={elementCss.progresswidth}
                                            name='progresswidth'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="progresswidth"
                                        >
                                            <MenuItem value={'3%'}>0 Percent</MenuItem>
                                            <MenuItem value={'10%'}>10 Percent</MenuItem>
                                            <MenuItem value={'20%'}>20 Percent</MenuItem>
                                            <MenuItem value={'30%'}>30 Percent</MenuItem>
                                            <MenuItem value={'40%'}>40 Percent</MenuItem>
                                            <MenuItem value={'50%'}>50 Percent</MenuItem>
                                            <MenuItem value={'60%'}>60 Percent</MenuItem>
                                            <MenuItem value={'70%'}>70 Percent</MenuItem>
                                            <MenuItem value={'80%'}>80 Percent</MenuItem>
                                            <MenuItem value={'90%'}>90 Percent</MenuItem>
                                            <MenuItem value={'100%'}>100 Percent</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="inputBlockbrandText d-flex p-2">
                                    <TextField label="Percent Text" size="small" className="mb-3" type="text" name='progresstext' value={elementCss.progresstext} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                                <div className="form-check form-switch mb-3">
                                    <input type="checkbox" className="form-check-input propswitch" id="stripeanimation" name='stripeanimation' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                                    <label className="form-check-label" for="27fba"><custom>Stripe Animation</custom></label>
                                </div>
                                <div className="selectBlock">
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="elementOpacity-Sticky">Text Shadow</InputLabel>
                                        <Select
                                            labelId="elementOpacity"
                                            id="elementOpacity"
                                            value={elementCss.elementOpacity}
                                            name='elementOpacity'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="elementOpacity"
                                        >
                                            <MenuItem value={'1'}>None</MenuItem>
                                            <MenuItem value={'0.8'}>Light Fade</MenuItem>
                                            <MenuItem value={"0.5"}>Half Fade</MenuItem>
                                            <MenuItem value={"0.3"}>Heavy Fade</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <Slider value={elementCss.elementOpacity} aria-label="Default" valueLabelDisplay="auto" name='elementOpacity' min={0.1} max={1} step={0.1} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                <div className="selectBlock">
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="progresssize-Sticky">Size</InputLabel>
                                        <Select
                                            labelId="progresssize"
                                            id="progresssize"
                                            value={elementCss.progresssize}
                                            name='progresssize'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="progresssize"
                                        >
                                            <MenuItem value={'25px'}>Small</MenuItem>
                                            <MenuItem value={'35px'}>Medium</MenuItem>
                                            <MenuItem value={"45px"}>Large</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </> : ''}
                            {elementType === "testimonial" ? <>{elementCss.testimonialdata.map((element, index) => {
                                return <div className="TestimonialListItem p-2 m-1" Style="background-color: #fff;border-radius: 5px;    box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;" >
                                    <div Style="display:inline-block; width:68%;">{element.name}</div>
                                    <div Style="display:inline-flex; width:30%;">
                                        <span>
                                            <button className="btn editTestimonialBtn p-1" data-toggle="modal" data-target="#EditTestimonialModal" Style="text-align:end;" clicked="false" onClick={() => { testimonialEdit(index) }}>
                                                <i className="fa fa-edit"></i>
                                            </button>
                                        </span>
                                        {index>2?<span>
                                            <button className="btn delTestimonialBtn p-1" Style="text-align:end;" onClick={() => { deleteTestimonialCard(index) }}>
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </span>:''}
                                    </div>
                                </div>
                            })}
                                <button className="btn addItemBtn mb-3" Style='    box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px; width:100%; margin-top:10px;' onClick={addTestimonialCard} ><i className="fa fa-plus mr-3"></i> Add new Item</button>
                            </> : ''}
                            {elementType === 'customjshtml' ? <button Style='width: 100%; margin: 10px 0px;' className='btn btn-primary ' data-bs-toggle="modal" data-bs-target={`#HTMLEditorElement`} id='openCustomEditor'>Open Custom Editor</button> : ''}
                            {elementType === 'map' ? <>
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%', }}>
                                        <InputLabel id="mapType-Sticky">Map Type</InputLabel>
                                        <Select
                                            labelId="mapType"
                                            id="mapType"
                                            value={elementCss.mapType}
                                            name='mapType'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="mapType"
                                        >
                                            <MenuItem value={'embaded'}>Enter Embaded Code</MenuItem>
                                            <MenuItem value={'latlong'}>Enter Latitude and Longitude</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {elementCss.mapType === "embaded" ? <><div className='d-flex container'><TextField label="Map Embaded Code" size="small" className="form-control linkTextInput mb-3" name='mapEmbaded' value={elementCss.mapEmbaded} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} /></div></>
                                    : <>
                                        <div className='d-flex container'><TextField label="Map Longitude" size="small" className="form-control linkTextInput mb-3" type="text" name='mapLon' value={mapLon} onChange={(e) => { setMapLon(e.target.value) }} /></div>
                                        <div className='d-flex container'><TextField label="Map Latitude" size="small" className="form-control linkTextInput mb-3" type="text" name='mapLat' value={mapLat} onChange={(e) => { setMapLat(e.target.value) }} /></div>
                                    </>}
                                <div className="input-group mb-3 addCssByDraging">
                                    <div className='d-flex flex-column'>
                                        <TextField label="Map Height" value={elementCss.mapHeight} size="small" name='mapHeight' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        <Slider value={elementCss.mapHeight} aria-label="Default" min={0} max={500} valueLabelDisplay="auto" name='mapHeight' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </div>
                                </div>
                            </> : ''}

                            {elementType === "carousel" || elementType === "singlecarousel" ? <div id="">
                                {elementType === "carousel" ? <>
                                    <div className="container">
                                        <div className='d-flex flex-column'>
                                            <TextField label="Desktop Width" value={elementCss.carouselDesktopWidth} size="small" name='carouselDesktopWidth' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.carouselDesktopWidth} aria-label="Default" valueLabelDisplay="auto" name='carouselDesktopWidth' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className='d-flex flex-column'>
                                            <TextField label="Mobile Width" value={elementCss.carouselMobileWidth} size="small" name='carouselMobileWidth' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                            <Slider value={elementCss.carouselMobileWidth} aria-label="Default" valueLabelDisplay="auto" name='carouselMobileWidth' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        </div>
                                    </div>
                                </> : ''}
                                <div >
                                    <div className="slickImagesGroup">
                                        {elementCss.carouselImages.map((element, index) => {
                                            return <div className="SlickListItem p-2 m-1" Style="background-color: #fff; box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;border-radius: 5px;" controlling="0">
                                                <div Style="display:inline-block; width:80%;">
                                                    <div className="input-group mt-2">
                                                        <TextField label="Image" value={element} size="small" onChange={(e) => { carouselImgURL(e.target.value, index) }} />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-outline-secondary bgImage btn-7" type="button" data-bs-toggle="modal" data-bs-target="#elementFileManager" onClick={() => { addCrouselCustomImage('crouselimage', index) }}><span><RiImageAddLine /></span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {index>2?<div Style="display:inline-flex; width:15%;">

                                                    <span Style=" margin-left: auto; ">
                                                        <button className="btn btn-danger delSlickItemBtn ms-1" Style="text-align: end; padding: 10px; border: 1px solid rgb(216, 216, 216); transition: all 0.5s ease 0s; display: inline-block;" onClick={() => { deleteCarouselImgURL(index) }}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </span>
                                                </div>:''}

                                            </div>
                                        })}
                                    </div>
                                    <button className="btn addItemBtn mb-3" Style="width:100%;     box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px; margin-top:5px" onClick={addCarouselImgURL}><i className="fa fa-plus mr-3"></i> Add new Image</button>
                                </div>
                                {/* <div className="container">
                                    <button className="btn carouselMakeProductBtn mb-3" Style="display: block; margin: 0 auto; margin: 0 auto; display: block;border: 2px solid #25a1e5;"><i className="fa fa-shopping-bag mr-3"></i> Make product from these images</button>
                                </div> */}
                               {elementType === "carousel" ? <div className="container">
                                    <div className="form-check form-switch">
                                        <input type="checkbox" className="form-check-input" id='hideThumbnails' name='hideThumbnails' checked={elementCss.hideThumbnails} onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                                        <label className="form-check-label" for="2a687"><custom>Hide Thumbnails</custom></label>
                                    </div>
                                </div>:''}
                            </div> : ' '}

                            <div className='d-flex pt-2'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="boxShadow-Sticky">Box Shadow</InputLabel>
                                    <Select
                                        labelId="boxShadow"
                                        id="boxShadow"
                                        value={elementCss.boxShadow}
                                        name='boxShadow'
                                        onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                        label="boxShadow"
                                    >
                                        <MenuItem value={'none'}>No Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.05) 0px 1px 3px 0px'}>5% Drop Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.1) 0px 1px 5px 0px'}>10% Drop Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px'}>20% Drop Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.3) 0px 2px 5px 2px'}>30% Drop Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.4) 0px 2px 5px 2px'}>40% Drop Shadow</MenuItem>

                                        <MenuItem value={'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px inset'}>5% Inner Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.1) 0px 1px 5px 0px inset'}>10% Inner Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px inset'}>20% Inner Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.3) 0px 2px 5px 2px inset'}>30% Inner Shadow</MenuItem>
                                        <MenuItem value={'rgba(0, 0, 0, 0.4) 0px 2px 5px 2px inset'}>40% Inner Shadow</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {elementType === 'headline' || elementType === 'subheadline' || elementType === 'paragraph' || elementType === 'bulletlist' || elementType === 'imagelist' || elementType === 'link' || elementType === 'button' || elementType === 'premadeform' || elementType === 'inputfield' || elementType === 'textarea' || elementType === 'checkbox' || elementType === 'survey' || elementType === 'selectfield' || elementType === 'countdown' || elementType === 'minutetimer' || elementType === 'daytimer' || elementType === 'progressbar' ? <div className="fontSelectorGroup">

                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="fonttype-Sticky">Font</InputLabel>
                                        <Select
                                            labelId="fonttype"
                                            id="fonttype"
                                            value={elementCss.fonttype}
                                            name='fonttype'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="fonttype"
                                        >
                                            <MenuItem value={'Header'}>Header</MenuItem>
                                            <MenuItem value={'Custom'}>Custom</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {elementCss.fonttype === "Custom" ? <div id="customFont" Style="display: block;" className="mb-3">
                                    <span>Font Family</span>
                                    <br />
                                    <Button variant="contained" className="btn w-100" onClick={() => { setOpenFontStyle(true) }}>{elementCss.fontstyle}</Button>
                                </div> : ''}
                            </div> : ''}
                            {openFontStyle ? <ElementFontStyle setOpenFontStyle={setOpenFontStyle} setElementCss={setElementCss} elementCss={elementCss} setRowchange={setRowchange} /> : ''}
                            {elementType === 'video' || elementType === 'image' || elementType === 'imagepopup' || elementType === 'videopopup' || elementType === 'slidershowcase' || elementType === 'imagefeature' || elementType === 'link' || elementType === 'button' || elementType === 'icon' || elementType === 'inputfield' || elementType === 'selectfield' || elementType === 'textarea' || elementType === 'checkbox' || elementType === 'imagelist' || elementType === 'survey' || elementType === "countdown" || elementType === "minutetimer" || elementType === "daytimer" || elementType === "divider" || elementType === 'progressbar' || elementType === "testimonial" || elementType === 'customjshtml' || elementType === 'map' || elementType === 'customtab' || elementType === "carousel" || elementType === "singlecarousel" ? '' : <div>
                                <div className='container' Style="z-index:5 !important; position:relative;">
                                    <span>Icons</span>
                                    <TextIconAdd setElementCss={setElementCss} elementCss={elementCss} setRowchange={setRowchange} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button className='btn btn-danger mt-2' onClick={removeIcon}>Remove Icon</button>
                                </div>
                                <div id="ColourOptions" className='mt-2'>
                                    <div className="picker-wrapper all-picker-wrappers" Style="display:block">
                                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                            <span>Icon color:</span>
                                            <div className="color-picker-button float-right" Style={`${iconColorBtn};border-radius: 15px;`} onClick={() => { setEnableIconColor(!enableIconColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                        </div>
                                        {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                                            <button className="color-picker-button btn float-right" Style={`${iconColorBtn}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableIconColor(!enableIconColor) }}>Icon Color:</button>
                                        </div> */}
                                        {enableIconColor ? <ColorPicker color={iconColor} setColor={setIconColorColor} Css={elementCss} setCss={setElementCss} setEnableBgColor={setEnableIconColor} type='iconColor' /> : ''}
                                    </div>
                                </div>
                            </div>}
                            {elementType === 'icon' || elementType === 'imagelist' ? <div>
                                <div className='container' Style="z-index:5 !important; position:relative;">
                                    <span>Icons</span>
                                    <IconAdd setElementCss={setElementCss} elementCss={elementCss} setRowchange={setRowchange} />
                                </div>
                            </div> : ''}
                            {elementType === 'link' ? <div className="">
                                <div className="PropsTitle">Link Options</div>
                                <div className="container">

                                    <div className="form-check form-switch mb-3">
                                        <input type="checkbox" className="form-check-input" id="newTabSwitch" name='gotonewpage' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                                        <label className="form-check-label" for="newTabSwitch"><span>Open in new tab</span></label>
                                    </div>

                                    <TextField label="Outlined" size="small" variant="outlined" name='linktext' value={elementCss.linktext} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />

                                    {/* <span>Link</span>
                                    <select className="form-select BtnActionSelect mb-3" name=''>
                                        <option value="URL">Go to website URL</option>
                                        <option value="gotostep">Go to Step</option>
                                    </select> */}

                                    <TextField label="URL" size="small" className='mt-3' variant="outlined" name='link' value={elementCss.link} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />

                                    {/* <div id="StepAction" Style="display:none;">
                                        <span>Funnel Step</span>
                                        <select className="form-select BtnStepSelect mb-3" controlling="Link7ce0a">
                                            <option value="/">Root Page</option>
                                            <option value="/newfunnels/Eran70/home/test.php">test</option></select>
                                    </div> */}
                                </div>
                            </div> : ''}
                            {elementType && elementType === 'imagelist' ? <div className="container">
                                <span>Image List Items</span>
                                <div id="ImageListItemEditPanel" className="ui-sortable">
                                    {elementCss.imageList.map((element, index) => {
                                        return <div className="imageListItemControllerGroup" Style="display: flex; align-items: center; justify-content:center">
                                            <TbGridDots />
                                            <div Style="display: flex; align-items: center; padding: 10px 13px; width:80%; text-align:left; font-size:20px;white-space: pre; text-overflow: ellipsis; overflow: hidden;" controlling="ImageList15270" controllingitem="imageListItem0977a">
                                                <p className="m-2">{element}</p>
                                            </div>
                                            <button className="btn editItemButtons" data-toggle="modal" data-target="#EditImageListModal" Style="text-align:right;" clicked="false" onClick={() => { editListItem(index) }}>
                                                <i className="fa fa-edit"></i>
                                            </button>
                                        </div>
                                    })}
                                </div>
                                <button className="addImageListItemBtn btn btn-primary w-100 mt-3" onClick={addListItem}>Add Image List Item</button>
                            </div> : ''}
                            {elementType === 'video' ? <div className='container'>
                                <ElementVideoAdd setElementCss={setElementCss} elementCss={elementCss} />
                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input" id="AutoplayOptionSwitch" name='autoPlay' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                                    <label className="form-check-label" htmlFor="AutoplayOptionSwitch"><span>Autoplay</span></label>
                                </div>

                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input" id="ControlOptionSwitch" name='controls' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                                    <label className="form-check-label" htmlFor="ControlOptionSwitch"><span>Controls</span></label>
                                </div>

                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="videoWidth-Sticky">Width</InputLabel>
                                        <Select
                                            labelId="videoWidth"
                                            id="videoWidth"
                                            value={elementCss.videoWidth}
                                            name='videoWidth'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="videoWidth"
                                        >
                                            <MenuItem value={'100'}>Full Width</MenuItem>
                                            <MenuItem value={'75'}>3/4 Width</MenuItem>
                                            <MenuItem value={"50"}>1/2 Width</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div> : ''}
                            {elementType === 'imagefeature' ? <div >
                                <div className="PropsTitle">Image Feature Options</div>
                                <div className="d-flex">
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="columnSize-Sticky">Column Sizes</InputLabel>
                                        <Select
                                            labelId="columnSize"
                                            id="columnSize"
                                            value={elementCss.columnSize}
                                            name='columnSize'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="columnSize"
                                        >
                                            <MenuItem value={"1"}>Text(90) / Image(10)</MenuItem>
                                            <MenuItem value={"3"}>Text(80) / Image(20)</MenuItem>
                                            <MenuItem value={"4"}>Text(70) / Image(30)</MenuItem>
                                            <MenuItem value={"5"}>Text(60) / Image(40)</MenuItem>
                                            <MenuItem value={"6"}>Text(50) / Image(50)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="d-flex">
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="columnOrder-Sticky">Column Sizes</InputLabel>
                                        <Select
                                            labelId="columnOrder"
                                            id="columnOrder"
                                            value={elementCss.columnOrder}
                                            name='columnOrder'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="columnOrder"
                                        >
                                            <MenuItem value={"row"}>Image - Text</MenuItem>
                                            <MenuItem value={"row flex-row-reverse"}>Text - Image</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div> : ''}
                            {elementType === 'image' || elementType === 'imagepopup' || elementType === 'videopopup' || elementType === 'imagefeature' ? <div className="SectionProps mt-0 pt-0">
                                <div className="input-group mt-2">
                                    <TextField label="Image" value={elementCss.imageURL} size="small" name='imageURL' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary bgImage btn-7" type="button" data-bs-toggle="modal" data-bs-target="#elementFileManager" onClick={() => { setUrlTarget('imageURL') }}><span><RiImageAddLine /></span></button>
                                    </div>
                                </div>
                                {elementType === 'imagepopup' ? <>
                                    <div className="input-group mt-2">
                                        <TextField label="Fullsize Image" value={elementCss.imagepopupURL} size="small" name='imagepopupURL' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary bgImage btn-7" type="button" data-bs-toggle="modal" data-bs-target="#elementFileManager" onClick={() => { setUrlTarget('imagepopupURL') }}><span><RiImageAddLine /></span></button>
                                        </div>
                                    </div>
                                </> : ''}
                                {elementType === 'videopopup' ? <>
                                    <div className="input-group mt-2">
                                        <ElementPopupVideoAdd setElementCss={setElementCss} elementCss={elementCss} />
                                    </div>
                                </> : ''}
                                <TextField label="Width (PX)" className='mt-3 pt-0' min={1} size="small" name='imageWidth' value={elementCss.imageWidth} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />

                                <TextField label="Height (PX)" className='mt-3' min={1} size="small" name='imageHeight' value={elementCss.imageHeight} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />

                                <TextField label="Alt Text (For SEO!)" className='mt-3' min={1} size="small" name='imageAltText' value={elementCss.imageAltText} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />


                                {/* <span>Redirect Action</span>
                                <select className="form-select BtnActionSelect mb-3" controlling="Image1a856" Style="width: 100%!important;">
                                    <option value="none" selected="">None</option>
                                    <option value="URL">Go to website URL</option>
                                    <option value="gotostep">Go to Step</option>
                                </select>

                                <div id="URLAction" Style="display: none;">
                                    <span>URL</span>
                                    <input className="form-control URLinput mb-3" type="text" controlling="Image1a856" placeholder="Enter your URL here" value="" />
                                    <p Style="color:red; display:none;" id="urlMessage">Please ensure the URL is FULL and VALID e.g. "https://www.google.co.uk"</p>
                                </div>

                                <div id="StepAction" Style="display:none;">
                                    <span>Funnel Step</span>
                                    <select className="form-select BtnStepSelect mb-3" controlling="Image1a856" Style="width: 100%!important;">
                                        <option value="/">Root Page</option><option value="/newfunnels/Eran70/home/test.php">test</option></select>
                                </div> */}

                            </div> : ''}
                            {elementType === 'slidershowcase' ? <div className="container">
                                <div className="ItemsHolder">
                                    {elementCss.sliderShowCase.map((element, index) => {
                                        return <div className="ImageItem p-2 m-1" Style="background-color: #fff;border-radius: 5px;" controlling="0">
                                            <div Style="display:inline-block;">
                                                {element.name}
                                            </div>
                                            <div Style="display:inline-flex;">
                                                <span Style=" margin-left: auto; ">
                                                    <button className="btn btn-primary editItemBtn" data-toggle="modal" data-target="#EditShowcaseModal" Style="text-align: right; border: 1px solid #d8d8d8; transition: 0.5s;" onClick={() => { editSlideShowCase(index) }}>
                                                        <i className="fa fa-edit"></i>
                                                    </button>
                                                </span>

                                                {index>2?<span Style=" margin-left: auto; ">
                                                    <button className="btn btn-danger delItemBtn" Style="text-align: right; border: 1px solid #d8d8d8; transition: 0.5s;" onClick={() => { deleteSlideCase(index) }}>
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </span>:''}
                                            </div>

                                        </div>
                                    })}
                                </div>
                                <button className="btn addImageItemBtn mb-3 mt-2" Style="width:100%;    box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;" onClick={addSlideCase}><i className="fa fa-plus mr-3"></i> Add new Image</button>

                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="sliderDirection-Sticky">Starting Direction</InputLabel>
                                        <Select
                                            labelId="sliderDirection"
                                            id="sliderDirection"
                                            value={elementCss.sliderDirection}
                                            name='sliderDirection'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="sliderDirection"
                                        >
                                            <MenuItem value={"left"}>Left</MenuItem>
                                            <MenuItem value={"right"}>Right</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                            </div> : ''}
                            {elementType === 'button' ? <><div className='container'>
                                <div className="PropsTitle">Text Options</div>
                                <div className='d-flex mt-2'>
                                    <TextField label="Text" variant="outlined" size="small" name='btntext' value={elementCss.btntext} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                                <div className='d-flex mt-3'>
                                    <TextField label="Sub Text" variant="outlined" size="small" name='btnsubtext' value={elementCss.btnsubtext} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>
                                <div className='mb-2'>
                                    <div className="PropsTitle">Button Options</div>
                                    <div className='d-flex'>
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="buttonAction-Sticky">Button Action</InputLabel>
                                            <Select
                                                labelId="buttonAction"
                                                id="buttonAction"
                                                value={elementCss.buttonAction}
                                                name='buttonAction'
                                                onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                                label="buttonAction"
                                            >
                                                <MenuItem value={"none"}>none</MenuItem>
                                                <MenuItem value={"URL"}>Go to website URL</MenuItem>
                                                {/* <MenuItem value={"gotostep"}>Go to Step</MenuItem>
                                                <MenuItem value={"popup"}>Open the Popup</MenuItem>
                                                <MenuItem value={"hide"}>Hide Elements</MenuItem>
                                                <MenuItem value={"scroll"}>Scroll to Element</MenuItem>
                                                <MenuItem value={"updownsell"}>One click up/down sell product</MenuItem>
                                                <MenuItem value={"call"}>Click to Call</MenuItem>
                                                <MenuItem value={"SMS"}>Click to SMS</MenuItem>
                                                <MenuItem value={"logout"}>Logout</MenuItem>
                                                <MenuItem value={"SubmitspanForm"}>Submit span Form</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {elementCss.buttonAction === 'URL' ? <div className="btnOptionBlocks d-flex container" >
                                        <TextField label="URL" variant="outlined" size="small" name='buttonActionValue' value={elementCss.buttonActionValue} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    </div> : ''}

                                    {/* <div className="btnOptionBlocks" id="AfterSubmitOption" Style="display:none;">
                                            <span>Go to after form submit...</span>
                                            <select className="form-select nextRedirectSelect mb-3" controlling="5374b">
                                                <option value="none">Stay on page</option>
                                                <option value="URL">Go to website URL</option>
                                                <option value="gotostep">Go to Step</option>
                                            </select>
                                        </div>

                                        <div className="btnOptionBlocks" id="StepAction" Style="display:none;">
                                            <span>Funnel Step</span>
                                            <select className="form-select BtnStepSelect mb-3" controlling="5374b">
                                                <option selected="" value="">Please select a step</option>
                                                <option value="/">Root Page</option><option value="/newfunnels/Eran70/home/test.php">test</option>
                                            </select>
                                        </div>

                                        <div className="btnOptionBlocks" id="ScrollAction" Style="display:none;">
                                            <span>Scroll to Element</span>
                                            <select className="form-select BtnScrollSelect mb-3" controlling="5374b">
                                                <option value="Sectiona39b8">Section</option><option value="Row35b64">Row</option><option value="Colb4d45">Column</option><option value="Col733cc">Column</option><option value="Col46b8e">Column</option><option value="Buttonb4c18">Button</option><option value="Videof1831">Video</option></select>
                                            <p Style="color:#0061ff;">Be sure to give the element you want to scroll to a <u>name</u></p>
                                        </div>

                                        <div className="btnOptionBlocks" id="HideAction" Style="display:none;">
                                            <span>Hide Elements</span>
                                            <div className="dropdown">
                                                <p Style="color:#0061ff;">Be sure to give the element you want to hide a <u>name</u></p>
                                                <button className="btn btn-default" type="button" data-toggle="dropdown" Style="border: 1px solid #ced4da; width: 100%;">Choose Element</button>
                                                <ul className="dropdown-menu checkbox-menu allow-focus" controlling="5374b" Style="width: 100%; margin-top: 0px;">
                                                    <li><label><input type="checkbox" entityid="Sectiona39b8" />Section</label></li><li><label><input type="checkbox" entityid="Row35b64" />Row</label></li><li><label><input type="checkbox" entityid="Colb4d45" />Column</label></li><li><label><input type="checkbox" entityid="Col733cc" />Column</label></li><li><label><input type="checkbox" entityid="Col46b8e" />Column</label></li><li><label><input type="checkbox" entityid="Buttonb4c18" />Button</label></li><li><label><input type="checkbox" entityid="Videof1831" />Video</label></li></ul>
                                            </div>
                                        </div>

                                        <div className="btnOptionBlocks" id="updownsellAction" Style="display:none;">
                                            <span>products</span>
                                            <select className="form-select BtnProductsSelect mb-3" controlling="5374b">
                                                <option selected="" disabled="">There are no products linked to this page</option>
                                            </select>

                                            <span>Sale Action</span>
                                            <select className="form-select BtnSaleActionSelect mb-3" controlling="5374b">
                                                <option value="URL">Go to website URL</option>
                                                <option value="gotostep">Go to Step</option>
                                            </select>

                                            <div className="btnOptionBlocks" id="SaleURLAction" Style="display: block;">
                                                <span>URL</span>
                                                <input className="form-control SaleURLinput mb-3" type="text" controlling="5374b" />
                                                <p Style="color:red; display:none;" id="urlMessage">Please ensure the URL is FULL and VALID e.g. "https://www.Google.com"</p>
                                            </div>

                                            <div className="btnOptionBlocks" id="SaleStepAction" Style="display:none;">
                                                <span>Funnel Step</span>
                                                <select className="form-select BtnSaleStepSelect mb-3" controlling="5374b">
                                                    <option selected="" value="">Please select a step</option>
                                                    <option value="/">Root Page</option><option value="/newfunnels/Eran70/home/test.php">test</option>
                                                </select>
                                            </div> */}

                                </div>
                            </> : ''}
                        </div>
                    </div>
                </div >
            }
            {
                elementType === 'socialshare' ? <div Style='text-align:start; padding:10px'>
                    <div className="SectionPropsWrapper container p-3">
                        <div className="form-check form-switch mb-3">
                            <input type="checkbox" className="form-check-input" id="FacebookSwitch" name='enableFacebook' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                            <label className="form-check-label" for="FacebookSwitch"><span>Facebook</span></label>
                        </div>

                        {elementCss.enableFacebook ? <div className="facebookOptions">

                            <div className='d-flex'>
                                <TextField label="Url" size="small" variant="outlined" name='facebookUrl' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>

                            {/* <div id="FBStepAction" Style="display:none;">
                                <span>Funnel Step</span>
                                <select className="FBStepSelect form-select BtnStepSelect mb-3" controlling="Share2cddc">
                                    <option value="/">Root Page</option><option value="/newfunnels/Eran70/home/test.php">test</option></select>
                            </div> */}
                        </div> : ''}


                        <div className="form-check form-switch mb-3">
                            <input type="checkbox" className="form-check-input" id="TwitterSwitch" name='enableTwitter' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                            <label className="form-check-label" for="TwitterSwitch"><span>Twitter</span></label>
                        </div>

                        {elementCss.enableTwitter ? <div className="twitterOptions">
                            {/* <span>Link Action</span>
                            <select className="form-select TWActionSelect mb-3" controlling="Share2cddc">
                                <option value="URL">Go to website URL</option>
                                <option value="gotostep">Go to Step</option>
                            </select> */}

                            <div className='d-flex'>
                                <TextField label="Url" size="small" variant="outlined" name='twitterUrl' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>

                            {/* <div id="TWStepAction" Style="display:none;">
                                <span>Funnel Step</span>
                                <select className="TWStepSelect form-select BtnStepSelect mb-3" controlling="Share2cddc">
                                    <option value="/">Root Page</option><option value="/newfunnels/Eran70/home/test.php">test</option></select>
                            </div> */}
                        </div> : ''}

                        <div className="form-check form-switch mb-3">
                            <input type="checkbox" className="form-check-input" id="LinkedinSwitch" name='enableLinkedin' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                            <label className="form-check-label" for="LinkedinSwitch"><span>LinkedIn</span></label>
                        </div>

                        {elementCss.enableLinkedin ? <div className="linkedinOptions">
                            {/* <span>Link Action</span>
                            <select className="form-select LIActionSelect mb-3" controlling="Share2cddc">
                                <option value="URL">Go to website URL</option>
                                <option value="gotostep">Go to Step</option>
                            </select> */}

                            <div className='d-flex'>
                                <TextField label="Url" size="small" variant="outlined" name='linkedinUrl' value={elementCss.linkedinUrl} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>

                            {/* <div id="LIStepAction" Style="display:none;">
                                <span>Funnel Step</span>
                                <select className="LIStepSelect form-select BtnStepSelect mb-3" controlling="Share2cddc">
                                    <option value="/">Root Page</option><option value="/newfunnels/Eran70/home/test.php">test</option></select>
                            </div> */}
                        </div> : ''}

                    </div>

                </div> : ''
            }
            {
                elementType === 'inputfield' || elementType === 'selectfield' || elementType === 'textarea' || elementType === 'checkbox' ? <div Style="padding: 10px; text-align:start"> <div className="container SectionPropsWrapper  mb-3 mt-3" Style='padding: 5px 10px;'>
                    {elementType === 'inputfield' ? <>
                        <span>Input Type</span>
                        <select className="form-select InputTypeSelect mb-3" id='inputtype' name='inputtype' onClick={(e) => { placeHolderChange(e.target.value) }}>
                            <option selected="" value="EmailAddress">Email Address</option>
                            <option value="FullName">Full Name</option>
                            <option value="FirstName">First Name</option>
                            <option value="LastName">Last Name</option>
                            <option value="PhoneNumber">Phone Number</option>
                            <option value="Address">Address</option>
                            <option value="City">City</option>
                            <option value="State">State</option>
                            <option value="Country">Country</option>
                            <option value="Zip">Zip</option>
                            <option value="ShippingAddress">Shipping Address</option>
                            <option value="ShippingCity">Shipping City</option>
                            <option value="ShippingState">Shipping State</option>
                            <option value="ShippingCountry">Shipping Country</option>
                            <option value="ShippingZip">Shipping Zip</option>
                            <option value="VATNumber">VAT Number</option>
                            <option value="span">span</option>
                        </select>
                    </> : ''}
                    {elementType === 'selectfield' || elementType === 'textarea' || elementType === 'checkbox' ? <>
                        {elementType === 'checkbox' ? <div className="inputBlocktext">
                            <span>Input Text</span>
                            <input className="form-control propInput mb-3" type="text" name='checkBoxInputText' value={elementCss.checkBoxInputText} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                        </div> : ''}
                        <div className="inputBlockname">
                            <span>Address</span>
                            <input className="form-control propInput mb-3" type="text" controlling="ffb33" value="" />
                        </div>
                        {elementType === 'selectfield' ? <>
                            <span>Input Type</span>
                            <select className="form-select SelectTypeSelect mb-3" id='selectLabelType' name='selectLabelType' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                <option value="allCountries">All Countries</option>
                                <option value="custom">Custom</option>
                            </select>
                            {elementCss.selectLabelType === 'custom' ? <div Style='text-align:center'>
                                <div className="PropsTitle" Style='text-align:center'>Custom Options</div>
                                <div className="SectionProps" Style="padding:10px">

                                    <div className="spanOptionsHolder">

                                        {elementCss.customSelectLabel.map((data, index) => {
                                            return <div className="option1 row spanOption" Style='position:relative'>
                                                <div className="col-6 pr-0">
                                                    <span>Value:</span>
                                                    <input placeholder="e.g. US" type="text" min="1" name='value' value={elementCss.customSelectLabel[index].value} onChange={(e) => { updateCustomSelectLabel(e.target.value, e.target.name, index) }} className="form-control OptionValueInputs mb-3" />
                                                </div>
                                                <div className="col-6 pl-1">
                                                    <span>Text:</span>
                                                    <input placeholder="e.g. United States" type="text" min="1" name='text' value={elementCss.customSelectLabel[index].text} onChange={(e) => { updateCustomSelectLabel(e.target.value, e.target.name, index) }} className="form-control OptionTextInputs mb-3" />
                                                </div>
                                                {index > 0 ? <button className="DeleteOptionBtn" onClick={() => { deleteCustomLebel(index) }}>X</button> : ''}
                                            </div>
                                        })}

                                    </div>
                                    <button Style="background-color:#dedede;" type="button" className="AddspanOptionBtn btn" onClick={addCustomLabel}>Add New Option</button>
                                </div>
                            </div> : ''}
                        </> : ''}
                    </> : ''}
                    <div Style="display:none;" id="spanTypeGroup">
                        <span>Field Name</span>
                        <input type="text" min="1" className="form-control spanTextInput mb-3" controlling="spanInput051af" />
                    </div>
                    {elementType === 'checkbox' ? '' : <>
                        {elementType === 'inputfield' ? <>
                            <span>Placeholder Text</span>
                            <input type="text" min="1" className="form-control PlaceholderInput mb-3" id='inputplaceholdertext' value={elementCss.inputplaceholdertext} name='inputplaceholdertext' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                        </> : ''}
                        {elementType === 'textarea' ? <>
                            <span>Placeholder Text</span>
                            <input type="text" min="1" className="form-control PlaceholderInput mb-3" id='textareaplaceholdertext' value={elementCss.textareaplaceholdertext} name='textareaplaceholdertext' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                        </> : ''}
                        {elementType === 'selectfield' ? <>
                            <span>Placeholder Text</span>
                            <input type="text" min="1" className="form-control PlaceholderInput mb-3" id='selectfieldplaceholdertext' value={elementCss.selectfieldplaceholdertext} name='selectfieldplaceholdertext' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                        </> : ''}
                        <div className="form-check form-switch mb-3">
                            <input type="checkbox" className="form-check-input" id='showlabel' name='showlabel' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.checked }) }} />
                            <label className="form-check-label" for="labelSwitch">Show Label</label>
                        </div>
                        {elementType === 'inputfield' ? <>
                            <div className="labelInputOptions" Style="display: block;">
                                <span>Label Text</span>
                                <input type="text" min="1" className="form-control LabelInput mb-3" value={elementCss.inputlabeltext} name='inputlabeltext' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>
                        </> : ''}
                        {elementType === 'textarea' ? <>
                            <div className="labelInputOptions" Style="display: block;">
                                <span>Label Text</span>
                                <input type="text" min="1" className="form-control LabelInput mb-3" value={elementCss.textarealabeltext} name='textarealabeltext' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>
                        </> : ''}
                        {elementType === 'selectfield' ? <>
                            <div className="labelInputOptions" Style="display: block;">
                                <span>Label Text</span>
                                <input type="text" min="1" className="form-control LabelInput mb-3" value={elementCss.selectfieldlabeltext} name='selectfieldlabeltext' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>
                        </> : ''}
                    </>}
                    <span>Required</span>
                    <select disabled="" className="form-select RequiredSelect mb-3" id='inputrequired' name='inputrequired' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                        <option value="true">Required</option>
                        <option value="false">Not Required</option>
                    </select>

                    <div className="input-group mb-3">
                        <div className='d-flex flex-column'>
                            <TextField label="Mobile Font Size" value={elementCss.formInputMobileSize} size="small" name='formInputMobileSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            <Slider value={elementCss.formInputMobileSize} aria-label="Default" valueLabelDisplay="auto" name='formInputMobileSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                        </div>
                    </div>

                    <div className="input-group mb-3">
                        <div className='d-flex flex-column'>
                            <TextField label="Desktop Font Size" value={elementCss.formInputDesktopSize} size="small" name='formInputDesktopSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            <Slider value={elementCss.formInputDesktopSize} aria-label="Default" valueLabelDisplay="auto" name='formInputDesktopSize' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                        </div>
                    </div>
                    {elementType === 'checkbox' ? '' : <>
                        <span>Height</span>
                        <div className="input-group mb-3">
                            <div className='d-flex flex-column'>
                                <TextField label="Height" value={elementCss.formInputHeight} size="small" name='formInputHeight' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                <Slider value={elementCss.formInputHeight} aria-label="Default" valueLabelDisplay="auto" name='formInputHeight' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>
                        </div>
                    </>}
                </div> </div> : ''
            }

            <div id="SideBarProps" >
                <div className="container" Style="padding: 0px 10px 0px 10px;">
                    <div className="SectionPropsWrapper mb-3 mt-3">
                        <div className="SectionProps">
                            <div className="input-group mb-3  addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Top (Mobile)" value={elementCss.paddingTopMobile} size="small" name='paddingTopMobile' onChange={(e) => {e.target.value>4? setElementCss({ ...elementCss, [e.target.name]: e.target.value }): setElementCss({ ...elementCss, [e.target.name]: 5 })}} />
                                    <Slider value={elementCss.paddingTopMobile} aria-label="Default" valueLabelDisplay="auto" name='paddingTopMobile' onChange={(e) => {e.target.value>4? setElementCss({ ...elementCss, [e.target.name]: e.target.value }): setElementCss({ ...elementCss, [e.target.name]: 5 })}} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Bottom (Mobile)" value={elementCss.paddingBottomMobile} size="small" name='paddingBottomMobile' onChange={(e) => {e.target.value>4? setElementCss({ ...elementCss, [e.target.name]: e.target.value }): setElementCss({ ...elementCss, [e.target.name]: 5 })}} />
                                    <Slider value={elementCss.paddingBottomMobile} aria-label="Default" valueLabelDisplay="auto" name='paddingBottomMobile' onChange={(e) => {e.target.value>4? setElementCss({ ...elementCss, [e.target.name]: e.target.value }): setElementCss({ ...elementCss, [e.target.name]: 5 })}} />
                                </div>
                            </div>

                            <div className="input-group mb-3  addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Top (Desktop)" value={elementCss.paddingTop} size="small" name='paddingTop' onChange={(e) => {e.target.value>4? setElementCss({ ...elementCss, [e.target.name]: e.target.value }): setElementCss({ ...elementCss, [e.target.name]: 5 })}} />
                                    <Slider value={elementCss.paddingTop} aria-label="Default" valueLabelDisplay="auto" name='paddingTop' onChange={(e) => {e.target.value>4? setElementCss({ ...elementCss, [e.target.name]: e.target.value }): setElementCss({ ...elementCss, [e.target.name]: 5 })}} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Bottom (Desktop)" value={elementCss.paddingBottom} size="small" name='paddingBottom' onChange={(e) => {e.target.value>4? setElementCss({ ...elementCss, [e.target.name]: e.target.value }): setElementCss({ ...elementCss, [e.target.name]: 5 })}} />
                                    <Slider value={elementCss.paddingBottom} aria-label="Default" valueLabelDisplay="auto" name='paddingBottom' onChange={(e) => {e.target.value>4? setElementCss({ ...elementCss, [e.target.name]: e.target.value }): setElementCss({ ...elementCss, [e.target.name]: 5 })}} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Left" value={elementCss.paddingLeft} size="small" name='paddingLeft' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={elementCss.paddingLeft} aria-label="Default" valueLabelDisplay="auto" name='paddingLeft' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Padding Right" value={elementCss.paddingRight} size="small" name='paddingRight' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={elementCss.paddingRight} aria-label="Default" valueLabelDisplay="auto" name='paddingRight' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Margin Top (Mobile)" value={elementCss.marginTopMobile} size="small" name='marginTopMobile' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={elementCss.marginTopMobile} aria-label="Default" valueLabelDisplay="auto" name='marginTopMobile' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Margin Bottom (Mobile)" value={elementCss.marginBottomMobile} size="small" name='marginBottomMobile' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={elementCss.marginBottomMobile} aria-label="Default" valueLabelDisplay="auto" name='marginBottomMobile' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>

                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Margin Top (Desktop)" value={elementCss.marginTop} size="small" name='marginTop' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={elementCss.marginTop} aria-label="Default" valueLabelDisplay="auto" name='marginTop' min={-10} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>
                            <div className="input-group mb-3 addCssByDraging">
                                <div className='d-flex flex-column'>
                                    <TextField label="Margin Bottom (Desktop)" value={elementCss.marginBottom} size="small" name='marginBottom' onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                    <Slider value={elementCss.marginBottom} aria-label="Default" valueLabelDisplay="auto" name='marginBottom' min={-10} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ElementGeneralBar