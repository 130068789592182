import React,{useEffect,useState} from 'react'
import glass from '../../../../static/images/glass.png'
import blackhole from '../../../../static/images/blackhole.png'
import mountain from '../../../../static/images/mountain.png'
import scribblemask from '../../../../static/images/scribblemask.png'
import speechmask from '../../../../static/images/speechmask.png'
import splash from '../../../../static/images/splash.png'
import streak from '../../../../static/images/streak.png'
 
function Image(
  { id,
    addCustomStyle,
    element,
    activeElement,
    setElementType }
) {
  const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
        e.stopPropagation();
        setElementType('image')
        if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
        }
    }

    useEffect(() => {
        addCustomStyle(`elem_${id}`);
    }, [])

    var imageMask;
    if(element.elementObject && element.elementObject.imageMask){
      if(element.elementObject.imageMask==='none'){
        imageMask=''
      }
      else if(element.elementObject.imageMask==='glass'){
        imageMask=`-webkit-mask-image: url(${glass})`
      }
      else if(element.elementObject.imageMask==='streak'){
        imageMask=`-webkit-mask-image: url(${streak})`
      }
      else if(element.elementObject.imageMask==='splash'){
        imageMask=`-webkit-mask-image: url(${splash})`
      }
      else if(element.elementObject.imageMask==='speechmask'){
        imageMask=`-webkit-mask-image: url(${speechmask})`
      }
      else if(element.elementObject.imageMask==='scribblemask'){
        imageMask=`-webkit-mask-image: url(${scribblemask})`
      }
      else if(element.elementObject.imageMask==='mountain'){
        imageMask=`-webkit-mask-image: url(${mountain})`
      }
      else if(element.elementObject.imageMask==='blackhole'){
        imageMask=`-webkit-mask-image: url(${blackhole})`
      }
    }
  return (
    <div Style={`overflow:visible; width:100% !important; text-align:${element.elementObject?element.elementObject.textAlign:'center'} ;overflow: visible; position: relative; ${imageMask}; -webkit-mask-size: contain; -webkit-mask-position: center top; -webkit-mask-repeat: no-repeat;  width: fit-content;`} id={id} onClick={handleEditorClick}>
      <img action="URL" className={`${element.elementObject?element.elementObject.imageAnimation:''}`} src={element.elementObject?element.elementObject.imageURL:''} alt={element.elementObject?element.elementObject.imageAltText:''}  width={element.elementObject?element.elementObject.imageWidth:''}  height={element.elementObject?element.elementObject.imageHeight:''}  Style={`max-width: 100%; box-shadow: ${element.elementObject?element.elementObject.imageBoxShadow:''}; border: ${element.elementObject?element.elementObject.imageBoxBorder:''}; border-radius:${element.elementObject?element.elementObject.imageRadius:''}; opacity: ${element.elementObject?element.elementObject.elementOpacity:''}; filter: ${element.elementObject?element.elementObject.imageEditing:''};`} />
    </div>
  )
}

export default Image