import React, { useEffect, useState, useContext } from "react";
import { HomeData } from "../../../../NavigationComponents/Home";
import ReactQuill from "react-quill";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

function undoChange() {
  this.quill.history.undo();
  console.log(this.quill.history)
}
function redoChange() {
  console.log(this.quill.history)
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor

// Formats objects for setting up the Quill editor
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

function BulletList({
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  setElementType,
  preview,
  mobileView
}) {
  const { setEnableColumnResize, enableColumnResize } = useContext(HomeData)
  const [clickDetect, setClickDetect] = useState(0)
  const [iconData, setIconData] = useState('<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>')
  const [iconStyle, setIconStyle] = useState()
  const [HTMLValue, setHTMLValue] = useState({ value: element.value });

  const handleChange = (value) => {
    setHTMLValue({ value });
    // console.log(value)
    var sectionData = sections
    sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[index].value = value
    setSections(sectionData)
  };
  const modules = {
    toolbar: {
      container: `#toolbar_${id}`,
      handlers: {
        undo: undoChange,
        redo: redoChange
      }
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true
    }
  };


  const handleEditorClick = (e) => {
    e.stopPropagation();
    if (!preview) {
      setElementType('bulletlist')
      if (activeElement !== `elem_${id}`) {
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
    document.getElementById("toolbar_" + id).style.display = `none`
  }, [])



  useEffect(() => {
    if (activeElement && prevElement) {
      var prevElemName;
      var activeElemName;
      sections.forEach(elem1 => {
        elem1.RowComponent.forEach((elem2) => {
          elem2.RowContainer.forEach((elem3) => {
            elem3.ColumnContainer.forEach((elem4) => {
              if (elem4.id === prevElement.slice(5)) {
                prevElemName = elem4.name
              }
              if (elem4.id === activeElement.slice(5)) {
                activeElemName = elem4.name
              }
            })
          })
        })
      });
    }
    setTimeout(() => {
      document.getElementById("toolbar_" + id).style.display = `none`
      if (prevElement) {
        var activId = activeElement
        var prevId = prevElement
        if (prevElemName === '' || prevElemName === 'headline' || prevElemName === 'subheadline' || prevElemName === 'paragraph' || prevElemName === 'bulletlist') {
          document.getElementById("toolbar_" + prevId.slice(5)).style.display = "none"
        }
        if (activeElement && activeElemName === 'headline' || activeElemName === 'subheadline' || activeElemName === 'paragraph' || activeElemName === 'bulletlist') {
          document.getElementById("toolbar_" + activId.slice(5)).style.display = "block"
        }
      }
    }, 180);

  }, [prevElement, activeElement, clickDetect])



  useEffect(() => {
    setIconData(element.icon)
  }, [element.icon])


  useEffect(() => {
    if (preview === true) {
      document.getElementById("toolbar_" + id).style.display = `none`
      var editorEditable = document.getElementById(id + "_bullet_list_editor").children[0].children[0]
      editorEditable.contentEditable = 'false'
    }
    if (preview === false) {
      setTimeout(() => {
        var editorEditable = document.getElementById(id + "_bullet_list_editor").children[0].children[0]
        editorEditable.contentEditable = 'true'
      }, 300);
    }
  }, [preview, clickDetect])

 

  useEffect(() => {
    var sectionData = sections
    sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[index].value = HTMLValue.value
    setSections(sectionData)
  }, [HTMLValue])

  useEffect(() => {
    if (element.icon) {
      if (element.icon !== "blank") {
        if (element.icon.includes("</svg>")) {
          setIconStyle(`#${id + "_bullet_list_editor"}>.ql-container>.ql-editor>ul>li::before {
          content: url('data:image/svg+xml, <svg stroke="currentColor" fill="${element.iconColor?element.iconColor:'currentcolor'}" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg">${element.icon.slice(element.icon.indexOf("<path"), element.icon.indexOf("</svg>"))}</svg>');
          display: inline-block;
          width: ${element.icon ? mobileView?element.mobileIconWidth:element.iconWidth : ''}px;
        };
        `)
        } else {
          setIconStyle(`#${id + "_bullet_list_editor"}>.ql-container>.ql-editor>ul>li::before {
          content: url('data:image/svg+xml, <svg stroke="currentColor" fill="${element.iconColor?element.iconColor:'currentcolor'}" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto"  xmlns="http://www.w3.org/2000/svg">${element.icon}</svg>');
          display: inline-block;
          width: ${element.icon ? mobileView?element.mobileIconWidth:element.iconWidth : ''}px;
        };
        `)
        }
      }
      else {
        setIconStyle(`#${id + "_bullet_list_editor"}>.ql-container>.ql-editor>ul>li::before {
        content: \'\\2022\';
      };
      `)
      }
    }
  }, [element.icon, element.iconWidth,element.iconColor, element.mobileIconWidth, mobileView])

  
  useEffect(() => {
    setTimeout(() => {
      document.getElementById(id + "_bullet_list_editor").children[0].children[0].style.textAlign = element.elementObject.textAlign
      mobileView ? document.getElementById(id + "_bullet_list_editor").children[0].style = element.mobileElementTextStyle : document.getElementById(id + "_bullet_list_editor").children[0].style = element.elementTextStyle
    }, 230);
  }, [element.elementTextStyle, mobileView,element.mobileElementTextStyle])



  useEffect(() => {
    document.getElementById(`bullet_${id}responsiveStyle`).innerHTML = `<style>@media only screen and (max-width: 768px) { #${ id + "_bullet_list_editor>.ql-container>.ql-editor"}{${element.elementObject ? `font-size:${element.elementObject.mobileFontSize} !important` : ''}} #${id + "_bullet_list_editor"}>.ql-container>.ql-editor>ul>li::before{width: ${element.icon ? element.mobileIconWidth : ''}px !important;} }</style>`
  }, [preview])

  function mouseDown() {
    setEnableColumnResize(true)
  }
  function mouseUp() {
    setEnableColumnResize(false)
  }

 
  return (
    <>
      <div Style='display:none'>
        <style >
          {iconStyle}
        </style>
      </div>
      <div id={`bullet_${id}responsiveStyle`} Style='display:none'>
        <style>

        </style>
      </div>
      <div>
        <div className="text-editor" id={id} onClick={handleEditorClick} onMouseDown={mouseDown} onMouseUp={mouseUp}>
          <ReactQuill
            theme="snow"
            value={HTMLValue.value}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            id={id + "_bullet_list_editor"}
            preserveWhitespace={true}
            // onKeyDown={pressEnter}
          />
        </div>
        <div id={`toolbar_${id}`}>
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
            <button className="ql-list" value="bullet" />
          </span>
          <span className="ql-formats">
            <select className="ql-color" />
            <select className="ql-background" />
          </span>
        </div>
      </div>
    </>
  )
}

export default BulletList