import React from 'react'

function CheckBox({ size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType }) {
    return (
        <div className={`form-check  global${element.elementObject? element.elementObject.fonttype:''}Font`} Style={`border-radius: 5px; text-align:left font-family:${element.elementObject && element.elementObject.fonttype==="Custom" ? element.elementObject.fontstyle : ''}`}>
            <input required={element.elementObject && element.elementObject.inputrequired === 'true' ? true : false} disabled="" name="checkbox" className="form-check-input customInputElm" type="checkbox" Style="cursor:not-allowed; border-radius:5px;" />
            <label Style={`line-height: ${element.elementObject?element.elementObject.lineHeight:''}; text-transform:${element.elementObject?element.elementObject.btnTextFormat:''}; font-size:${element.elementObject?element.elementObject.formInputDesktopSize:'16'}px; letter-spacing: ${element.elementObject?element.elementObject.ckBoxLetterSpacing:''}; opacity:${element.elementObject?element.elementObject.ckBoxTextShadow:''}`} className="form-check-label globalHeaderFont customInputLabel" dfontsize="16px" mfontsize="16px">{element.elementObject ? element.elementObject.checkBoxInputText : 'This is a Checkbox element'}</label>
        </div>
    )
}

export default CheckBox